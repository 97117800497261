import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  Captions,
  Fullscreen,
  Slideshow,
  Thumbnails,
  Video,
  Zoom,
} from "yet-another-react-lightbox/plugins";

import PropTypes from "prop-types";

export const SeatMap = (props) => {
  const [open, setOpen] = useState(props.showModal);
  const [items, setItems] = useState([]);

  React.useEffect(() => {
    let imgs = [];
    props.imgArr &&
      props.imgArr.map((img) => {
        if (img) {
          imgs.push({
            src: img,
          });
        }
      });
    setItems(imgs);
  }, []);

  return (
    <Lightbox
      open={open}
      close={() => {
        setOpen(false);
        props.handleClose && props.handleClose();
      }}
      slides={items}
      plugins={[Captions, Fullscreen, Video, Zoom]}
      carousel={{ finite: items.length <= 1 }}
      render={{
          buttonPrev: items.length <= 1 ? () => null : undefined,
          buttonNext: items.length <= 1 ? () => null : undefined,
      }}
    />
  );
};

SeatMap.propTypes = {
  imgArr: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};


const User = {
    user:{
        login:{
            loginStatus: 1, // 1 is no user loggedin
            lineItemList: null,
            totalLineItems: null,
            timeLeftSeconds: null,
            pastTransactionDetails: null,
            addOnList: null,
            totalAddonItems: null,
            patron: null
        },
        planVisit:{
            dateRange:null,
            priceRange:null,
            categories:null,
            venue:null
        }
    }
}
export default User;
import React, { useState, useEffect } from "react";
import { Tab, Tabs, Grid, Box, Stack } from "@mui/material";
import { Constants } from "../../../utils";
import { EventCard } from "../../planYourVisit/components/EventCard";
import SearchArea from "./SearchArea";
import AttractionsService from "../../../services/AttractionsService";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
export const Category = (props = {}) => {
  const { smallDown } = useCustomMediaQuery();
  const [categories, setcategories] = useState([]);
  const [isMobile, setIsMobile] = React.useState(props.isMobile);
  const [Attractiondata, setAttractiondata] = useState([]);
  const [catvalue, setCatValue] = useState(0);
  const [search, setSearch] = useState("");
  const qp = new URLSearchParams(window.location.search);
  useEffect(() => {
    apiCall(catvalue);
    AttractionsService.getAttractionsCategory().then((res) => {
      setcategories(res.data);
    });
  }, []);
  const handleChange = (event, newValue) => {
    setCatValue(newValue);
    apiCall(newValue);
  };
  const handleSearchCallback = (data) => {
    setSearch(data);
    apiCall(catvalue);
  };
  const apiCall = (catvalue) => {
    const getSearchVal = search;
    const params = {
      first: 0,
      // limit: Constants.LIMIT,
      sort_type: "date",
      sort_order: "ASC",
      category: catvalue === 0 ? "" : catvalue,
      client: Constants.CLIENT,
    };
    if (getSearchVal) {
      params.search = getSearchVal;
    }
    AttractionsService.getData(params).then((res) => {
      setAttractiondata(res.data.data);
    });
  };
  return (
    <>
      <Grid container className={"genre"}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
            pt={smallDown ? 0 : 1}
          >
            <Tabs
              scrollButtons={false}
              value={catvalue}
              variant="scrollable"
              onChange={handleChange}
              aria-label="Language"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              <Tab
                label="All"
                value={0}
                sx={{
                  fontFamily: "Rubik",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  fontWeight: "400",
                  letterSpacing: "0.25px",
                }}
              />
              {categories &&
                categories.data &&
                categories.data.length > 0 &&
                categories.data.map((item, i) => {
                  return (
                    <Tab
                      sx={{
                        fontFamily: "Rubik",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: "400",
                        letterSpacing: "0.25px",
                      }}
                      label={item.name}
                      value={item.id}
                    />
                  );
                })}
            </Tabs>
          </Stack>
        </Grid>
      </Grid>
      {/* <SearchArea {...props} monitorSearch={handleSearchCallback} /> */}

      <Box
        fontFamily={"Montserrat"}
        fontSize="1.25rem"
        color="#343434"
        fontWeight={700}
        textAlign="center"
        width={"100%"}
        py={smallDown ? 0 : 2}
      >
        Attractions
      </Box>

      <Box className={"eventListings-container"}>
        <Grid
          container
          columnSpacing={{
            sm: "15px",
            xs: "10px",
          }}
          rowSpacing={{
            sm: "20px",
            xs: "15px",
          }}
        >
          {Attractiondata &&
            Attractiondata.map((event) => (
              <Grid item>
                <EventCard details={event} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};
export default Category;

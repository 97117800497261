import React, {
    useState,
    useEffect,
} from "react";
import {
    Button
} from '@mui/material';
import BaseContext from '../../../contexts';
import { Constants, Utilities } from "../../../utils";
import HomeService from "../../../services/HomeService";


export const GenreCategories = (props) => {
    const base = React.useContext(BaseContext);
    const [state, setState] = useState({
        selected: []
    });
    const [sortedGenre, setSortGenre] = useState([]);

    useEffect(()=>{
        if (
          base.data &&
          base.data.user &&
          base.data.user.planVisit &&
          base.data.user.planVisit.categories &&
          base.data.user.planVisit.categories.length > 0
        ) {
          base.data.user.planVisit.categories.map((cid) => onCatSelection(cid));
        } 
    },[]);

    // useEffect(()=>{
    //     /** sort */
    //     const sortGenre = props.categories && props.categories.sort((a, b) => {
    //           return a.name > b.name ? 1 : -1;
    //     });
    //     setSortGenre(sortGenre);
    // },[]);

    useEffect(() => {
      setSortGenre(Utilities.rearrangeGenre(props.categories));
    }, []);

    const onCatSelection = (cid) => {
        let curSelection = state.selected;

        if(curSelection.includes(cid)){
            const index = curSelection.indexOf(cid);
            curSelection.splice(index, 1);
        }else{
            curSelection.push(cid);
        }

        setState({...state, selected: curSelection});
        props.updateBase && props.updateBase({
            user:{
                ...base.data.user,
                planVisit:{
                    ...base.data.user.planVisit,
                    categories: (curSelection && curSelection.length > 0) ? curSelection : null
                }
            }
        });
    }

    return (
        <div className={'plan-visit-step-genreCategories'}>
            <h2>Select the types of events that interests you the most.</h2>
            <p>You may select multiple genre/categories that interests you.</p>
            <div className={'btn-groups'}>
                {(sortedGenre && sortedGenre.length > 0) && sortedGenre.map((rw, key)=>{
                    return (<>
                        {key < 15 && 
                            <Button 
                                variant="outlined" 
                                className={['btn', state.selected.includes(rw.id) ? 'active' : ''].join(" ")}
                                onClick={()=> onCatSelection(rw.id)}>
                                    {rw.name}
                            </Button>
                        }
                    </>)
                })}
            </div>
        </div>
    );
}
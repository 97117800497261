import React from 'react';
import {
    Box
} from '@mui/material';
import BaseContext from '../contexts';
import { EventListing, EventDetails } from '../components';
import useDeviceDetect from '../hooks/useDeviceDetect';
export const EventDetailsPage = (props) => {
    const base = React.useContext(BaseContext);
    const { isMobile } = useDeviceDetect();
    const topPicks = base.data.recommendations && base.data.recommendations;

    return (
        <div className={''}>
          <div className={'eventdetails'}>
                <EventDetails {...props} isMobile={isMobile}
                />
           </div>
            {/* {(topPicks && topPicks.length > 0) && <EventListing
                title={"Similar Events"}
                items={topPicks}
                swipe={true}
                className={'stix-featured'}
                isMobile={isMobile}
                slidesToShow={5}
                slidesToScroll={5}
            />} */}
        </div>);
}

import React, { useState, useEffect } from "react";
import { Chip } from "@mui/material";
import moment from "moment";
import { StixDropDownCheck, StixDropDownDate, StixDropDownPrice } from "../";
import { AllFunctions } from "../../helper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export const FilterDesktop = (props) => {

  const [selectedFilters, setSelectedFilters] = useState({});
  const [state, setState] = useState({
    cat: true,
    date: true,
    price: false,
  });

  const [filterCategories, setFilterCategories] = useState({
    items: [],
    total: 0,
  });

  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });

  const [filterPrice, setFilterPrice] = useState({
    min: 0,
    max: 100,
  });

  const handleCallBack = (id, val = {}, status = false) => {
    switch (id) {
      case "categories":
        setState({ ...state, cat: status });
        setSelectedFilters({
          ...selectedFilters,
          categories: val
        })
        return handleFilterCallBack({
          ...selectedFilters,
          categories: val,
        });
      case "dateRange":
        setState({ ...state, date: status });
        setSelectedFilters({
          ...selectedFilters,
          dateRange: val
        })
        return handleFilterCallBack({
          ...selectedFilters,
          dateRange: val
        });
      case "priceRange":
        setState({ ...state, price: status });
        setSelectedFilters({
          ...selectedFilters,
          priceRange: val
        })
        return handleFilterCallBack({
          ...selectedFilters,
          priceRange: val,
        });
      default:
        return true;
    }
  };

  const handleResetCallBack = () => {
    props.resetCategoriesCallBack && props.resetCategoriesCallBack();
  };

  const handleDelete = (id = "") => {
    setState({
      ...state,
      [id]: false,
    });

    const filtersObj = { ...selectedFilters };
    switch (id) {
      case "cat":
        delete filtersObj.categories;
        break;
      case "date":
        delete filtersObj.dateRange;
        break;
      case "price":
        delete filtersObj.priceRange;
        break;
      default:
        break;
    }
    setSelectedFilters({ ...filtersObj });
    handleFilterCallBack({ ...filtersObj });
  };

  const handleFilterCallBack = (data) => {
    props.filterCallBack && props.filterCallBack(data);
  };

  const handleWhenDropCheckChange = () => {
    // when genre change from filter
    AllFunctions.eventsDeleteUrlParam('genre');
  }

  return (
    <div className={"stix-desktop"}>
      <div className={"stix-desktop-filter"}>
        <StixDropDownCheck
          name="Genre / Categories"
          items={props.category}
          selectedCategories={props.forFilter?.categories?.items || []}
          overrideItem={props.onCatChange}
          id={"categories"}
          componentCallBack={handleCallBack}
          triggerReset={state}
          triggerResetCallBack={handleResetCallBack}
          callBackOnItemChange={handleWhenDropCheckChange}
        />{" "}
        <span className={"xdivider"}></span>
        <StixDropDownDate
          name="Date Range"
          items={props.category}
          id={"dateRange"}
          componentCallBack={handleCallBack}
        />{" "}
        <span className={"xdivider"}></span>
        <StixDropDownPrice
          name="Price range"
          items={props.category}
          id={"priceRange"}
          componentCallBack={handleCallBack}
          priceRange={props.forFilter.priceRange}
        />{" "}
        {/* <span className={"xdivider"}></span>
        <StixDropDownCheck
          name="Accessibility"
          id={"accessibility"}
          items={props.accessibility}
          componentCallBack={handleCallBack}
        />{" "}
        <span className={"xdivider"}></span>
        <StixDropDownCheck
          name="More Filters"
          id={"moreFilters"}
          componentCallBack={handleCallBack}
          items={[]}
        /> */}
      </div>
      <div className={"stix-desktop-filter-list"}>
        {selectedFilters?.categories?.total > 0 && state.cat && (
          <Chip
            label={selectedFilters?.categories?.total + " Genres"}
            variant="outlined"
            deleteIcon={<CloseOutlinedIcon />}
            onDelete={() => handleDelete("cat")}
          />
        )}

        {selectedFilters?.dateRange?.start && selectedFilters?.dateRange?.end && state.date && (
          <Chip
            label={[
              moment.utc(selectedFilters.dateRange.start).add(1, "days").format("DD MMM YYYY"),
              selectedFilters.dateRange.end ? " - " : "",
              selectedFilters.dateRange.end
                ? moment
                  .utc(selectedFilters.dateRange.end)
                  .add(1, "days")
                  .format("DD MMM YYYY")
                : "",
            ].join("")}
            variant="outlined"
            deleteIcon={<CloseOutlinedIcon />}
            onDelete={() => handleDelete("date")}
          />
        )}

        {state.price === true && (
          <Chip
            label={["S$", selectedFilters?.priceRange?.min, " - ", "S$", selectedFilters?.priceRange?.max].join(
              ""
            )}
            variant="outlined"
            deleteIcon={<CloseOutlinedIcon />}
            onDelete={() => handleDelete("price")}
          />
        )}

        {/* <Chip //Uncomment when available
            label=''
            variant="outlined"
            deleteIcon={<CloseOutlinedIcon />} 
            onDelete={handleDelete} /> */}

        {/* <Chip  //Uncomment when available
            label={''}
            variant="outlined"
            deleteIcon={<CloseOutlinedIcon />} 
            onDelete={handleDelete} /> */}
      </div>
    </div>
  );
};

import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link } from 'react-scroll';
import ReactPlayer from 'react-player';
import Scrollbar from "../../../components/Scrollbar"
import './style.scss';
import { Constants } from '../../../utils';
import AdvertisementService from '../../../services/AdvertisementService';
import Image from '../../../components/Image';
import { Utilities } from '../../../utils';
import EventHeading from '../../../components/EventHeading';
import { useCustomWidth } from '../../../components/CustomHooks';
import { OneBigTwoSmall } from '../../../components/ShimmerEffect/HomeShimmer';

const CustomSectionThree = ({
  heading,
  customData,
  isHomePage,
  orientation
}) => {
  useCustomWidth();
  let vdoSectionRef = useRef();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [channelTitle, setChannelTitle] = useState(null);
  const [views, setViews] = useState(null);
  const [postedDate, setPostedDate] = useState(null);

  const [controls, setControls] = useState(false);

  const [pip, setPip] = useState(false);
  const [muted, setMuted] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [light, setLight] = useState(true);
  const [volume, setVolume] = useState(null);
  const [vdoIndex, setVdoIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState('');

  useEffect(() => {
    if (customData && customData.length > 0) {
      setData(customData);
      setUrl(customData[0].video_url);
      setTitle(customData[0].title);
      setChannelTitle(!isHomePage && customData[0].channel_title);
      setViews(!isHomePage && customData[0].count);
      setPostedDate(!isHomePage && customData[0].posted_date);
      setVdoIndex(0);
      setLoading(false);
    } else if (customData && !customData.length && !orientation) {
      getData();
    }
  }, [customData]);

  const getData = () => {
    const params = {
      client: Constants.CLIENT
    };
    AdvertisementService.getCustomizeSectionThree(params)
      .then(res => {
        if (res && res.data) {
          setData(res.data.data);
          setLoading(false);
          if (res.data.data && res.data.data[0]) {
            setUrl(res.data.data[0].video_url);
            setTitle(res.data.data[0].title);
            setVdoIndex(0);
          }
        }
      })
      .catch(err => {
        if (err && err.response) {
          console.log(err.response);
        }
      });
  };

  const displayDuration = duration => {
    if (duration) {
      let allDuration;
      let durationIndex = duration
        .replace('H', ':')
        .replace('M', ':')
        .replace('S', '')
        .indexOf(':');
      let vdoDuration = duration
        .replace('H', ':')
        .replace('M', ':')
        .replace('S', '');
      if (durationIndex === -1) {
        allDuration = 0 + ':' + vdoDuration;
      } else if (vdoDuration.length === 2) {
        allDuration = vdoDuration + 0;
      } else {
        allDuration = vdoDuration;
      }
      return allDuration;
    }
  };

  const scrollInToView = isPlaying => {
    setIsPlaying(isPlaying);
  };

  if (!loading && data && data.length === 0) {
    return null;
  }

  // if (isPlaying === 'playing' || isPlaying === 'finished') {
  //   vdoSectionRef.current &&
  //     window.scrollTo(0, vdoSectionRef.current.offsetTop);
  // }

  return (
    <div>
      {loading ? (
        <OneBigTwoSmall customClass="custom-section-two" />
      ) : (
        <section className="video-gallery" ref={vdoSectionRef}>
          <div className="container-fluid custom-container">
            <div className="section-top-wrapper">
              <h2>{heading}</h2>
            </div>
            <div className="video-gallery-wrapper" id="vdo-section">
              <div className="video-only-section">
                <ReactPlayer
                  width="100%"
                  height={Utilities.mobilecheck() ? '190px' : '465px'}
                  controls
                  pip={pip}
                  muted={muted}
                  url={url && url}
                  playing={playing}
                  light={true}
                  volume={volume}
                  onPlay={() => scrollInToView('playing')}
                  onEnded={() => scrollInToView('finished')}
                />
                <EventHeading
                  title={title}
                  lines={1}
                  height={Utilities.mobileAndTabletcheck() ? 20 : 20}
                />
                {!isHomePage && (
                  <span>
                    {channelTitle} {channelTitle ? ' . ' : null}{' '}
                    {views !== ' views' ? views : null}{' '}
                    {views !== ' views' ? ' . ' : null} {postedDate}
                  </span>
                )}
              </div>
              <div className="video-subwrapper">
                <Scrollbar>
                  {data &&
                    data.map((vdo, index) => {
                      return (
                        <Link to="vdo-section" offset={-200}>
                          <div
                            className={
                              vdoIndex === index
                                ? 'video-item-image active'
                                : 'video-item-image'
                            }
                            key={index}
                            onClick={() => {
                              setUrl(vdo.video_url);
                              setTitle(vdo.title);
                              setChannelTitle(vdo.channel_title);
                              setPostedDate(vdo.posted_date);
                              setViews(vdo.count);
                              setControls(true);
                              setPip(true);
                              setLight(false);
                              setMuted(false);
                              setVolume(0.5);
                              setPlaying(true);
                              setVdoIndex(index);
                            }}
                          >
                            <span className="video-subwrapper-image">
                              <div className="video-restrict-overlay">
                                <Image
                                  src={vdo.video_thumb}
                                  alt=""
                                  className="img-fluid"
                                  type="VdoSmall"
                                />
                                <span className="video-duration">
                                  {displayDuration(vdo.duration)}
                                </span>
                              </div>
                            </span>
                            <div className="video-subwrapper-text">
                              <a>
                                <EventHeading
                                  title={vdo.title}
                                  lines={isHomePage ? 3 : 2}
                                  height={
                                    Utilities.mobilecheck()
                                      ? 20
                                      : Utilities.mobileAndTabletcheck()
                                      ? 20
                                      : 20
                                  }
                                />
                              </a>
                              {!isHomePage && <span>{vdo.channel_title}</span>}

                              {!isHomePage && (
                                <span>
                                  {vdo.count !== ' views' ? vdo.count : null}{' '}
                                  {vdo.count && vdo.count !== ' views'
                                    ? '. '
                                    : null}
                                  {vdo.posted_date}
                                </span>
                              )}
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                </Scrollbar>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default CustomSectionThree;

import * as React from 'react';

export const SectionTitle = (props) => {
  return (<div className={["sistic-title", props.containerClass ? props.containerClass : ''].join(' ')}>
            {/* <span className={'dvider'}></span> */}
            <h3 id="centered" className={props.titleClass ? props.titleClass : ''} style={{marginBottom: 0, paddingBottom: 0}}>
                {props.title && props.title}
            </h3>
            <div className={["see-more", props.seeMoreClass ? props.seeMoreClass : ''].join(' ')}>{props.seeMore && props.seeMore}</div>
            <p>{props.description && props.description}</p>
    </div>);
};

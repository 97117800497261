import React from 'react';
import ReactDOM from 'react-dom';
import { Constants } from '../../../../utils';
import Small from '../../../../assets/images/small.png';
import BluePin from '../../../../assets/images/bluepin.svg';
import RedPin from '../../../../assets/images/redpin.svg';
import Image from '../../../UI/Image';
import DirectionIcon from '../../../../assets/images/redirect.svg';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';


const InfowindowData = ({ selectedPlace, DirectionIcon}) => {
    let latitude = selectedPlace.latitude
      ? selectedPlace.latitude
      : selectedPlace.position && selectedPlace.position.lat;
  
    let longitude = selectedPlace.longitude
      ? selectedPlace.longitude
      : selectedPlace.position && selectedPlace.position.lng;
  
    return (
      <div className="map-info-popup">
        <div className="map-img">
          <div id="iwc" />
        </div>
        <div className="map-name-address">
          <h5>{selectedPlace.name}</h5>
          <p
            dangerouslySetInnerHTML={{
              __html: selectedPlace.address
            }}
          ></p>
  
          <a
            href={`http://maps.google.com/?q=${latitude},${longitude}`}
            className="direcrtion-icn"
            target="_blank"
          >
            <img height="20" width="20" src={DirectionIcon} alt="direction" />
          </a>
        </div>
      </div>
    );
  };


export const GoogleMap = (props) => {
    const [state, setState] = React.useState({
        showInfoWindow: false,
        details: [],
        initialCenterPosition: {
            lat: 1.3521,
            lng: 103.8198
        },
        centerPosition: {
            lat: 1.3521,
            lng: 103.8198
        },
        zoomValue: 11,
        venues: props.venues
    })

    React.useEffect(()=> {
        setState({
            ...state,
            venues: (props.search && props.search.length > 0) ? props.searchResults : props.venues
        });
    },[props.searchResults]);

    const onMarkerClick = (details) => {
        setState({
            ...state,
            showInfoWindow: true,
            details: details,
            centerPosition:{
                lat: details.latitude,
                lng: details.longitude
            },
            zoomValue: 14
        })
    }

    const onInfoWindowClose = () => {
        setState({
            ...state,
            showInfoWindow: false,
            details: {
                lat: 1.3521,
                lng: 103.8198
            },
            zoomValue: 11
        })
    }

    const onInfoWindowOpen = () => {
        ReactDOM.render(
          React.Children.only(
            <Image
              src={state.details.image || state.details.imgPath || Small}
              type="Small"
            />
          ),
          document.getElementById('iwc')
        );
    };

    let newFilterVenue = state.venues;
    if(props.selectedVenues && props.selectedVenues.length > 0){
        newFilterVenue = state.venues.filter((r) => (props.selectedVenues.includes(r.id) === true));
        if(props.search && props.search.length > 0){
            newFilterVenue = [...newFilterVenue, ...props.searchResults];
        }
    }
    
    return (
        <div className={'stixGmap-container'}>
            <Map 
                google={props.google} 
                zoom={state.zoomValue}
                initialCenter={state.initialCenterPosition}
                center={state.centerPosition}
                >

                {(newFilterVenue && newFilterVenue.length > 0) && newFilterVenue.map((vns)=>{
                    return (<Marker
                        onClick={() => onMarkerClick(vns)}
                        title={vns.address}
                        name={vns.name}
                        position={{
                            lat: Number(vns.latitude), 
                            lng: Number(vns.longitude)
                        }} 
                        icon={{
                            url: (props.selectedVenues && props.selectedVenues.includes(vns.id)) ? BluePin: RedPin,
                            scaledSize: new google.maps.Size(30, 50)
                          }}
                        />)
                })}

                <InfoWindow 
                    options={{
                        pixelOffset: new google.maps.Size(0, -40)
                    }}
                    onOpen={() => {
                        onInfoWindowOpen();
                      }}
                    position={{
                        lat: state.details.id
                          ? state.details.latitude
                            ? Number(state.details.latitude)
                            : Number(state.details.position.lat)
                          : 0,
                        lng: state.details.id
                          ? state.details.latitude
                            ? Number(state.details.longitude)
                            : Number(state.details.position.lng)
                          : 0
                      }}
                    visible={state.showInfoWindow}
                    onClose={onInfoWindowClose}
                    >
                    <InfowindowData
                        selectedPlace={state.details}
                        DirectionIcon={DirectionIcon}
                    />
                </InfoWindow>
            </Map>
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: Constants.GOOGLE_MAP_API_KEY
  })(GoogleMap);
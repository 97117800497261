import React, { useEffect, useRef, useState, useCallback, cloneElement } from 'react';
import {
    ImageListItem,
    ImageListItemBar,Box
} from '@mui/material';
import Slider from 'react-slick';
import { useNavigate  } from "react-router-dom";
import {SectionTitle} from '../';
import {
    ChevronRight,
    ChevronLeft,
  } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CreatedWithStixLite from "../../assets/images/created-with-stixlite.svg";
import useDeviceDetect from '../../hooks/useDeviceDetect';
import HomePageItemComp from '../HomePageItemComp';

export const CustomPanel = (props = {}) => {
    const [items, setItems] = useState([])
    const [useSlick, setUseSlick] = useState(false)
    const sliderRef = useRef();
    const navigate = useNavigate();
    const [dragging, setDragging] = useState(false)
    const [iconFontSize, setIconFontSize] = useState(0)
    const [showArrows, setShowArrows] = useState(props?.showArrows !== undefined ? props?.showArrows : false)
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const { isMobile } = useDeviceDetect();
    // useEffect(() => {
    //     setIsMobile(props.isMobile);
    // }, [props.isMobile]);

    const handleOnClick = (e) => {
        if (e.target.id) window.location.href = e.target.id;
    }
    const handleBeforeChange = (currentSlide, nextSlide) => {
        if (nextSlide === 0) {
          setCanScrollLeft(false);
        } else {
          setCanScrollLeft(true);
        }
        if (nextSlide === items.length - 1) {
          setCanScrollRight(false);
        } else {
          setCanScrollRight(true);
        }
      };

    const handleAfterChange = useCallback(() => {
        setDragging(false)
    }, [setDragging])

    const handleOnItemClick = useCallback(e => {
        if(dragging) {
            e.stopPropagation()
        } else if(!dragging) {
            const url = e.currentTarget.id;
            if(url){
                if (url.includes("http")){
                    window.location.href =url;
                } else {
                    navigate(url);
                }
                setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 500);
            } 
        }
    }, [dragging]) 
    useEffect(() => {
        if (props.items && props.items.length > 0) {
            // Need to add dummy data if items.length < slidesToShow (due to limitations of Slider component)
            if(props.items.length <= props.slidesToShow) {
                let cloneArray = [...props.items]
                let dummyObj = {...cloneArray[0]}

                Object.keys(dummyObj).forEach(key => dummyObj[key] = '')
                let additionalDummyDataLength = props.slidesToShow - props.items.length;
                for (let i = 0; i < additionalDummyDataLength; i++) {
                    // console.log("dummy: ", dummyObj)
                    cloneArray.push(dummyObj);
                }
                setItems(cloneArray)
            } else {
                setItems(props.items)
                if(props?.showArrows === undefined) {
                    setShowArrows(true)
                }
            }
        }
    },[props])
    const getUrl = (item) => {
        let newUrl = '';
        if (item.navigation_link){
            newUrl = item.navigation_link;
        } else if (item.alias){
            if (!item.alias.includes("http")){
                newUrl = "/events/" + item.alias;
            } else{
                newUrl = item.alias;
            }
        }
        else{
            newUrl="https://www.sistic.com.sg/explore/1/"+ item.id;
        }
        return newUrl;
    }


    const onSeeMore = () => {
        if (props.seeMore) {
           window.location.href = props.seeMore; 
        } 
    }
  
          const itemsComponent = () => {
        return(
               items.map((item, i) => {
                let imgUrl = item?.id == "" ? "" : (item?.horizontal_image ? item.horizontal_image : './imgs/default-thumb-mobile.png');
                if (imgUrl == "") return (<div></div>);              
                    if (i < 20 && item) {
                        return (
                            <HomePageItemComp 
                                index={i}
                                handleOnItemClick={handleOnItemClick}
                                itemId={getUrl(item)}
                                isBlur={props.isBlur}
                                noAnimate={props.noAnimate}
                                tag_colour={item?.tag_colour}
                                tag_name={item?.tag_name}
                                imgUrl={imgUrl}
                                title={props.title}
                                showTrendingTitle={false}
                                eventName={item?.event_name}
                                stixLite={item?.stixLite}
                                isMobile={isMobile}
                                subTitle={""}
                            />
                          )
                    }})
                    )
                }
            
                const handleClickLeft = () => {
                    if (isInitialized) {
                        sliderRef?.current?.slickPrev()
                    }
                  };
                
                  const handleClickRight = () => {
                    if (isInitialized) {
                        sliderRef?.current?.slickNext()
                    }
                  };
            
                const itemsWithSlider = () => {
                    return (
                        <Box
                            sx={{ position: "relative", display: "inline-flex", minHeight: {sm: 200, md: 300}, width: "100%", flexDirection: "row" }}
                            onMouseEnter={() => { setIconFontSize(40) }}
                            onMouseLeave={() => { setIconFontSize(0) }}
                        >
                            <Box sx={{ position: "relative", display: "flex", height: { sm: 180, md: 240 }, width: "2%", justifyContent: "center", alignItems: "center" }}>
                                {showArrows && canScrollLeft && (
                                    <IconButton
                                        onClick={handleClickLeft}
                                        aria-label="ChevronLeft"
                                        size={"medium"}
                                    >
                                        <ChevronLeft sx={{ fontSize: iconFontSize }} />
                                    </IconButton>
                                )}
                            </Box>
                            <Box sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
                                <Slider
                                    style={{
                                        paddingTop: 0
                                    }}
                                    initialSlide={0}
                                    animation={true}
                                    infinite={true}
                                    speed={500}
                                    focusOnSelect={true}
                                    pauseOnHover={true}
                                    draggable={false}
                                    centerMode={false}
                                    lazyLoad={true}
                                    arrows={false}
                                    dots={false}
                                    ref={sliderRef}
                                    slidesToScroll={3}
                                    // useTransform={false}
                                    onInit={() => setIsInitialized(true)}
                                    responsive={[
                                        {
                                            breakpoint: 1024,
                                            settings: {
                                                infinite: false,
                                                slidesToShow: 3,
                                                slidesToScroll: 3
                                            }
                                        },
                                        {
                                            breakpoint: 600,
                                            settings: {
                                                infinite: false,
                                                slidesToShow: 2,
                                                slidesToScroll: 2
                                            }
                                        },
                                        {
                                            breakpoint: 480,
                                            settings: {
                                                infinite: false,
                                                slidesToShow: 2,
                                                slidesToScroll: 2
                                            }
                                        }
                                    ]}
                                    beforeChange={handleBeforeChange}
                                    afterChange={handleAfterChange}
                                    {...props}
                                >
                                    {itemsComponent()}
                                </Slider>
                            </Box>
                            <Box sx={{ position: "relative", display: "flex", height: { sm: 180, md: 240 }, width: "2%", justifyContent: "center", alignItems: "center" }}>
                                {showArrows && canScrollRight && (<IconButton
                                    onClick={handleClickRight}
                                    aria-label="ChevronRight"
                                    size={"medium"}
                                >
                                    <ChevronRight sx={{ fontSize: iconFontSize }} />
                                </IconButton>
                                )}
                            </Box>
                        </Box>
                    )
                }
            
                return (
                    <>
                        <div className={['stix-featured-container no-arrow', props.containerClass && props.containerClass].join(" ")}>
                            <SectionTitle 
                                containerClass={props.sectionTitleClass ? props.sectionTitleClass : ''}
                                titleClass={props.titleClass ? props.titleClass : ''}
                                seeMoreClass={props.seeMoreClass ? props.seeMoreClass : ''}
                                title={props.title && props.title}
                                seeMore={<a onClick={onSeeMore}>See more</a>}
                            />
            
                            { (items && items.length >= props.slidesToShow) 
                                ? itemsWithSlider()
                                : <div className='unslick-container' style={{paddingLeft: "3%"}}> {itemsComponent()} </div>
                            }    
                        </div>
                    </>
                );
            }


            // return (
            //     <ImageListItem key={i} onClickCapture={handleOnItemClick} id={getUrl(item)} className={[props.isBlur ? 'blur' : 'noblur'].join(" ")}>
            //         <div className={["animate-img-sec", props.noAnimate ? "stop" : ""].join(" ")} style={{ position: "relative" }}>

            //             <img
            //                 id={getUrl(item)}
            //                 src={`${imgUrl}?w=248&fit=crop&auto=format`}
            //                 srcSet={`${imgUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
            //                 alt={""}
            //                 loading={props.title === "Featured Events" ? "" : "lazy"}
            //             />

            //             {item.stixLite ?
            //                 <img
            //                     src={CreatedWithStixLite}
            //                     alt="created with stixlite"
            //                     height={isMobile ? "10px" : "15px"}
            //                     style={{
            //                         position: "absolute",
            //                         bottom: 0,
            //                         zIndex: 1,
            //                         backgroundColor: "#B0B0B0",
            //                         opacity: "0.9",
            //                         borderRadius: "0px 0px 10px 10px",
            //                         padding: "2px 0px"
            //                     }}
            //                 /> : ""
            //             }
            //         </div>

            //     </ImageListItem>
            // );
import { useEffect, useState } from "react";

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();
  const convertTZ = (date) => {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US",{timeZone: "Asia/Singapore",})
    );
  };
  let currenttime = convertTZ(new Date());
  const [countDown, setCountDown] = useState(
    countDownDate - currenttime.getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      let newServerTime = convertTZ(new Date());
      setCountDown(new Date(targetDate).getTime() - newServerTime.getTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  
  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdown };

import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import banner from '../../assets/images/location-banner.png';
import { Constants } from '../../utils';
import IconsNavigate from './IconsNavigate';
import WhereBuyTicketsService from '../../services/WhereBuyTicketsService';
import ApiPartnerService from '../../services/ApiPartnersService';
import './style.scss';
import Content from './Content';
import ShimmerEffect from '../../components/ShimmerEffect';
//import MetaData from '../../shared/components/MetaData';
import Image from '../../components/Image';

const WhereBuyTicket = props => {
  const [state, setState] = useState({});
  const [apiPartners, setapiPartners] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (!state.data) {
      fetchWhereBuyTickets();
    }
    fetchApiPartners();
  }, []);

  const fetchWhereBuyTickets = () => {
    const params = {
      client: Constants.CLIENT
    };
    WhereBuyTicketsService.getWhereBuyTickets(params)
      .then(res => {
        setTimeout(() => {
          if (res && res.data && res.data.data) {
            setState(res.data)
          }
        }, 700);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchApiPartners = () => {
    const params = {
      client: Constants.CLIENT,
      first: 0,
      limit: 6
    };
    ApiPartnerService.getApiPartnersService(params)
      .then(res => {
        setapiPartners(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      {/* <MetaData location={props.location} data={props.staticContext} /> */}
      <section className="where-to-buy-tickets">
        <div className="banner-wrapper">
          <Image src={banner} className="img-fluid" alt="page-banner" />
          <div className="banner-overlay">
            <h1>Where to Buy Tickets</h1>
          </div>
        </div>
        {state.data ? (
          <Fragment>
            <IconsNavigate tabsArray={state.data} />
            <Content
              whereBuyTicketsDetails={state.data}
              apiPartners={apiPartners}
            />
          </Fragment>
        ) : (
          <ShimmerEffect
            propCls="shm_col-xs-6 col-md-12"
            height={80}
            count={3}
            type="TILE"
          />
        )}
      </section>
    </Fragment>
  );
};

export default WhereBuyTicket;

import * as React from 'react';
import {
    Grid,
    Box
} from '@mui/material';
import {
    SocialMedia,
    AppDownload,
    IsoCertificate,
    CyberCertificate
} from '../../../../components';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';


export const SisticFooter = (props) => {

  const mbottom = props.footer ? props.footer: {};
  const { isMobile } = useDeviceDetect();   

  const stayConnectedComponents = () => {
    return(
        <>
            {/* <Grid item xs={12} md={12}>
                <AppDownload appDownload={mbottom.appDownload} />
            </Grid> */}
            <Grid 
                item 
                xs={12} 
                md={12} 
                container
                className="socialmedia-iso"
                style={{}}
            >
                    <SocialMedia socmed={mbottom.social_media}/>
            </Grid>
            <Grid 
                item 
                xs={12} 
                md={12} 
                container
                className="socialmedia-iso"
                style={{flexDirection: "row", alignItems: "center"}}
            >
                    <IsoCertificate isoCertificate={mbottom.isoCertificate}/>
                    <div style={{width: 10}}></div>
                    <CyberCertificate cyberCertificate={mbottom.cyberCertificate}/>
            </Grid>
        </>
    )
  }

  return (
        <footer className="sistic-footer">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={12/5}>
                        <div className="sistic-footer-links">
                            <h3>Our Company</h3>
                            <ul>
                                {mbottom.ourCompany && mbottom.ourCompany.map((oc)=>{
                                    return (<li key={oc.id}>
                                        <a href={oc.url} target="_blank" rel="noreferrer">{oc.label}</a>
                                    </li>)
                                })}
                            </ul>
                        </div>
                    </Grid>

                    <Grid item xs={6} md={12/5}>
                        <div className="sistic-footer-links helpful-links">
                            <h3>Ticketing</h3>
                            <ul>
                                {mbottom.ticketingLinks && mbottom.ticketingLinks.map((ml)=>{
                                    return (<li key={ml.id}>
                                    <a href={ml.url} target="_blank" rel="noreferrer">{ml.label}</a>
                                    </li>)
                                })}
                            </ul>
                        </div>
                    </Grid>

                    <Grid item xs={6} md={12/5}>
                        <div className="sistic-footer-links helpful-links">
                            <h3>Helpful Links</h3>
                            <div className="footer-groups">
                                <ul>
                                    {mbottom.helpfulLinks && mbottom.helpfulLinks.map((hl)=>{
                                        return (<li key={hl.id}>
                                            <a href={hl.url} target="_blank" rel="noreferrer">{hl.label}</a>
                                        </li>)
                                    })}
                                </ul>
                            </div>      
                        </div>
                    </Grid>

                    <Grid item xs={6} md={12/5}>
                        <div className="sistic-footer-links helpful-links">
                            <h3>Support</h3>
                            <div className="footer-groups">
                                <ul>
                                    {mbottom.stayConnectedLinks && mbottom.supportLinks.map((hl)=>{
                                        return (<li key={hl.id}>
                                            <a href={hl.url} target="_blank" rel="noreferrer">{hl.label}</a>
                                        </li>)
                                    })}
                                </ul>
                            </div>      
                        </div>
                    </Grid>

                    <Grid item xs={6} md={12/5}>
                        <div className="sistic-footer-links helpful-links">
                            <h3>Stay Connected</h3>
                            <div className="footer-groups">
                                <ul>
                                    {mbottom.stayConnectedLinks && mbottom.stayConnectedLinks.map((hl)=>{
                                        return (<li key={hl.id} style={{padding: 0, margin: 0}}>
                                            <a href={hl.url} target="_blank" rel="noreferrer">{hl.label}</a>
                                        </li>)
                                    })}
                                </ul>
                            </div>      
                        </div>
                        {isMobile ? null : stayConnectedComponents()}
                    </Grid>

                    {isMobile ? stayConnectedComponents() : null}

                    <Grid item xs={12}>
                        <div className="sistic-footer-bottom">
                            <div className="copyrights">
                                <span>Copyright 1998 - {new Date().getFullYear()}. © SISTIC.com Pte Ltd</span>
                            </div>
                            <ul className="sistic-footer-btm-links">
                                <li>
                                    <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/terms-and-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a>
                                </li>
                            </ul>
                        </div>
                    </Grid>

                </Grid>
            </Box>
        </footer>
    );
};

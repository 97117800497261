
import React, {useEffect, useState} from 'react';
//import { Helmet } from 'react-helmet-async';
import {Helmet} from "react-helmet";
import { AllFunctions } from "../../helper/functions";
import HomeService from "../../services/HomeService";

const metaContent = {
    canonical_url:{
        label:"rel",
        name:"canonical",
        defaultlabel:"",
        defaultValue:""
    },
    title:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:"SISTIC Singapore"
    },
    description:{
        label:"name",
        name:"description",
        defaultlabel:"content",
        defaultValue:"Connecting people to entertainment, SISTIC is the ticketing service and solution provider behind more than 1000 events every year. The leading provider in Singapore, it sells tickets to events ranging from pop concerts, to musicals, theatre, family entertainment and sports. Readily accessible, ticket buyers can browse for and buy tickets through the SISTIC website, customer hotline +65 6348 5555 and mobile app. Additionally, more than 40 SISTIC Authorised Agents are located throughout Singapore, and also in Malaysia, Indonesia, Taiwan and Vietnam. SISTIC also offers STiX, a web-based and customer-oriented ticketing solution suitable for ticketing companies, event promoters and venues, under a licensing arrangement. STiX is being used for events held at various performance venues in Singapore, Malaysia, Indonesia, Hong Kong and Macau. SISTIC has been providing innovative ticketing services since December 1991 and licensing its ticketing solution STiX since 2003."
    },
    image_src:{
        label:"rel",
        name:"image_src",
        defaultlabel:"href",
        defaultValue:""
    },
    og_url:{
        label:"property",
        name:"og:url",
        defaultlabel:"content",
        defaultValue:""
    },
    og_image:{
        label:"property",
        name:"og:image",
        defaultlabel:"content",
        defaultValue:""
    },
    og_description:{
        label:"property",
        name:"og:description",
        defaultlabel:"content",
        defaultValue:""
    },
    og_title:{
        label:"property",
        name:"og:title",
        defaultlabel:"content",
        defaultValue:""
    },
    og_site_name:{
        label:"property",
        name:"og:site_name",
        defaultlabel:"content",
        defaultValue:""
    },
    twitter_cards_type:{
        label:"name",
        name:"twitter:card",
        defaultlabel:"content",
        defaultValue:""
    },
    twitter_cards_image:{
        label:"name",
        name:"twitter:image",
        defaultlabel:"content",
        defaultValue:""
    },
    twitter_cards_title:{
        label:"name",
        name:"twitter:title",
        defaultlabel:"content",
        defaultValue:""
    },
    twitter_cards_description:{
        label:"name",
        name:"twitter:description",
        defaultlabel:"content",
        defaultValue:""
    },
   schema_event_end_date:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_id:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_door_time:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_event_attendance_mode:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_name:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_event_status:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_type:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_previous_start_date:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_start_date:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_description:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_url:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    schema_event_image:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    tracking_code:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    insert_tracking_code:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    },
    tt_code:{
        label:"",
        name:"",
        defaultlabel:"",
        defaultValue:""
    }
}

export const MetaData = (props) => {
    const [state, setState] = useState({});
    const mtags = ['name', 'property', 'http-equiv'];
    const ltags = ['rel'];

    useEffect(() => {
        const cleanUrl = props.pageAlias.substring(1)
        getThePageMeta(cleanUrl);
    }, [props.pageAlias]);

    const getThePageMeta = async (alias) => {
        const reqparams = {
            client: 1,
            url: alias
        };

        await HomeService.fetchMetadata(reqparams).then((res) => {
            setState(res.data);
            const metadatas = res.data;
            // if(Array.isArray(metadatas)){
            //     /** handle array scenario as the return from the api was array when there is no value */
            //     Object.keys(metaContent).map((mm)=>{
            //         if(mm === 'title'){
            //             AllFunctions.customTitle(metaContent[mm].defaultValue);
            //         }
            //         if(mtags.includes(metaContent[mm].label)){
            //             AllFunctions.removeMetaWithSameName(metaContent[mm].label, metaContent[mm].name); //remove meta
            //             AllFunctions.createCustomMetaElement(metaContent[mm].label, metaContent[mm].name, metaContent[mm].defaultValue);
            //         }
            //         if(ltags.includes(metaContent[mm].label)){
            //             AllFunctions.createCustomLinkElement(metaContent[mm].label, metaContent[mm].defaultValue, metaContent[mm].name);
            //         }
            //     });
            // }else{
            //     metadatas && Object.keys(metadatas).map((r, key)=>{
            //         if(metaContent[r] && metadatas[r]){
            //             if(r === 'title'){
            //                 AllFunctions.customTitle(metadatas[r] ? metadatas[r] : metaContent[r].defaultValue);
            //             }
            //             if(mtags.includes(metaContent[r].label)){
            //                 AllFunctions.removeMetaWithSameName(metaContent[r].label, metaContent[r].name); //remove meta
            //                 AllFunctions.createCustomMetaElement(metaContent[r].label, metaContent[r].name, metadatas[r]);
            //             }
            //             if(ltags.includes(metaContent[r].label)){
            //                 AllFunctions.createCustomLinkElement(metaContent[r].label, metadatas[r], metaContent[r].name );
            //             }
            //         }
            //     })
            // }

        },function(){
            console.log("err handler: ");
        });

    }

    return (<Helmet>
        {(Array.isArray(state)) && Object.keys(metaContent).map((mm, key)=>{
            let metas;
                if(mm === 'title'){
                    metas = <title key={key}>{metaContent[mm].defaultValue}</title>;
                }
                if(mtags.includes(metaContent[mm]?.label)){
                    let newAttr = {};
                    newAttr[metaContent[mm].label] = metaContent[mm].name;
                    metas = <meta {...newAttr} content={metaContent[mm].defaultValue}  key={key} />;
                }
                if(ltags.includes(metaContent[mm]?.label)){
                    let newAttr = {};
                    newAttr[metaContent[mm].label] = metaContent[mm].name;
                    metas = <link {...newAttr} rel={metaContent[mm].name}  key={key}/>;
                }
            return metas;
        })}

        {(!Array.isArray(state) && state) && Object.keys(state).map((r, key)=>{
            let metas;
            if(r === 'title'){
                metas = <title key={key}>{state[r] ? state[r] : metaContent[r].defaultValue}</title>;
            }
            if(mtags.includes(metaContent[r]?.label)){
                let newAttr = {};
                newAttr[metaContent[r].label] = metaContent[r].name;
                metas = <meta {...newAttr} content={state[r]} key={key}/>;
            }
            if(ltags.includes(metaContent[r]?.label)){
                let newAttr = {};
                newAttr[metaContent[r].label] = metaContent[r].name;
                metas = <link {...newAttr} rel={metaContent[r].name} key={key} />;
            }
            return metas;
        })}

    </Helmet>);
}
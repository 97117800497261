import React, {useEffect} from "react";
import "../styles/sistic/_createEvent.scss";
import {Stack, Typography, Box, Container, Divider, Button} from "@mui/material";
import CreateEventComp from "../components/createEventComp";

const contentData = [
    {
        title: "Self-service",
        subTitle: "Free to use for free events",
        btnTitle: "GET STARTED",
        link: "https://www.corporate.sistic.com.sg/stixlite",
        data: [
            "Create and manage your event with StixLite",
            "No technical experience required",
            "Free listing of your event on SISTIC’s extensive marketing network",
            "Suitable for general admission events, such as MICE, workshops, fairs, free standing/seating events, workshops",
            "Competitive fees for paid events."
        ]
    },
    {
        title: "Full suite",
        subTitle: "Custom pricing",
        btnTitle: "GET A QUOTE",
        link: "https://www.corporate.sistic.com.sg/contact-us",
        data: [
            "Events created and managed by experts",
            "Get greater access to SISTIC’s marketing channels & ecosystem partners",
            "Leverage our data analytics & business intelligence tools to sell more",
            "License our proprietary technology to build your own branded ticketing site",
            "Suitable for all events, including large-scale shows, concerts and festivals"
          ]
    }
]
  

const CreateEventPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="create-event">
            <Stack direction={"column"} alignItems={"center"} style={{marginBottom: 30}}>
                <Typography
                    variant={"h6"}
                    fontFamily={"Rubik"}
                    fontWeight={600}
                    color={"#343434"}
                >
                    {"Create your events with SISTIC"}
                </Typography>
                <Typography
                    variant={"body2"}
                    fontFamily={"Rubik"}
                    fontWeight={300}
                    color={"#747474"}
                    fontSize={16}
                    style={{whiteSpace: "pre-line", textAlign: "center"}}
                >
                    {`Be it a small workshop or large concert, SISTIC has got you covered.\n Select the plan that works best for your event.`}
                </Typography>
            </Stack>
            <Stack direction={{sm: "column", md: "row"}} spacing={{xs: 2, sm: 2}}>
                {contentData.map((object, index) => {
                    return (<CreateEventComp
                        title={object.title}
                        subTitle={object.subTitle}
                        data={object.data}
                        btnTitle={object.btnTitle}
                        link={object.link}
                    />)
                })}
            </Stack>
        </div>
    )
}

export default CreateEventPage;
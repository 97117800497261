import React from "react";
import { Box, ImageListItem, ImageListItemBar, Stack } from "@mui/material";
import { Utilities } from "../../utils";
import CreatedWithStixLite from "../../assets/images/created-with-stixlite.svg";


const HomePageItemComp = ({
    index, itemId, isBlur, handleOnItemClick, noAnimate,title, tag_colour, tag_name, 
    imgUrl, showTrendingTitle,eventName, stixLite, isMobile, h4Class, subtitleClass, subTitle, lblPosition}) => {

    const brTitleStr = eventName?.replace(/&lt;br&gt;/g, "<br>");
    const updatedTitle =  Utilities.removeBrfromString(brTitleStr);

    return (
        <ImageListItem
            key={index}
            onClickCapture={handleOnItemClick}
            id={itemId}
            className={[isBlur ? "blur" : "noblur"].join(" ")}
        >
            <div
                className={["animate-img-sec", noAnimate ? "stop" : "",].join(" ")}
                style={{ position: "relative" }}
            >
                <span
                    className={"utaginfo"}
                    style={{
                        background: tag_colour ? "#" + tag_colour : "",
                    }}
                >
                    {tag_name ? tag_name : ""}
                </span>
                <img
                    id={itemId}
                    src={`${imgUrl}?w=248&fit=crop&auto=format`}
                    srcSet={`${imgUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={""}
                    loading={title === "Featured Events" ? "" : "lazy"}
                />
                {showTrendingTitle && (
                    <Box
                        sx={{
                            flex: 1,
                            height: "50%",
                            width: "100%",
                            background: `linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))`,
                            bottom: 0,
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            position: "absolute",
                            display: "flex",
                            overflow: "hidden",
                        }}
                    >
                        <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{ alignItems: "flex-end", pl: "5px", pr: "5px" }}
                        >
                            <span
                                style={{
                                    color: "#FFFFFF",
                                    opacity: "40%",
                                    fontSize: 100,
                                    fontWeight: 500,
                                    marginBottom: "-22px",
                                    fontFamily: "Rubik",
                                }}
                            >
                                {index + 1}
                            </span>
                            <Stack
                                direction={"column"}
                                spacing={0}
                                style={{ marginLeft: 0, marginBottom: 5 }}
                            >
                                <h4
                                    style={{
                                        color: "#FFFFFF",
                                        fontSize: 14,
                                        fontWeight: 400,
                                        fontFamily: "Rubik",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: updatedTitle,
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                )}
                {stixLite ? (
                    <img
                        src={CreatedWithStixLite}
                        alt="created with stixlite"
                        height={isMobile ? "10px" : "15px"}
                        style={{
                            position: "absolute",
                            bottom: 0,
                            zIndex: 1,
                            backgroundColor: "#B0B0B0",
                            opacity: "0.9",
                            borderRadius: "0px 0px 10px 10px",
                            padding: "2px 0px",
                        }}
                    />
                ) : null}
            </div>
            {!showTrendingTitle && (
                <ImageListItemBar
                    title={
                        <h4
                            className={h4Class ? h4Class : ""}
                            dangerouslySetInnerHTML={{
                                __html: updatedTitle,
                            }}
                        />
                    }
                    subtitle={
                        <p
                            className={subtitleClass ? subtitleClass : ""}
                            style={{ height: subTitle?.length > 0 ? null : 0 }}
                        >
                            {subTitle}
                        </p>
                    }
                    position={lblPosition ? lblPosition : "below"}
                />
            )}
        </ImageListItem>
    );
}

export default HomePageItemComp;
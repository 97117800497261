import React, { useState, useEffect } from 'react';
import './style.scss';
import ShimmerEffect from '../../../components/ShimmerEffect';
import { Constants } from '../../../utils';
import AdvertisementService from '../../../services/AdvertisementService';
import Arrow from '../../../assets/images/right-arrow.svg';
import Image from '../../../components/Image';
import { Utilities } from '../../../utils';
import { OneBigTwoSmall } from '../../../components/ShimmerEffect/HomeShimmer';
import Ellipsis from '../../../components/Ellipsis';
import EventHeading from '../../../components/EventHeading';
import { AdvWrapper } from '../../../components/AdvWrapper';

const CustomSectionTwo = ({
  heading,
  customData,
  url,
  isMoreFrom,
  orientation
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (customData && customData.length > 0) {
      setData(customData);
      setLoading(false);
    } else if (customData && !customData.length && !orientation) {
      getData();
    }
  }, [customData]);
  const getData = () => {
    const params = {
      client: Constants.CLIENT,
      limit: 3,
      first: 0
    };
    AdvertisementService.getCustomizeSectionTwo(params)
      .then(res => {
        if (res && res.data) {
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch(err => {
        if (err && err.response) {
          console.log(err.response);
        }
      });
  };
  if (!loading && data && data.length === 0) {
    return null;
  }

  return loading ? (
    Utilities.mobilecheck() ? (
      <div className="custom-section-two">
        <ShimmerEffect
          height={80}
          count={2}
          type="TILE"
          propCls={`shm_col-xs-2 col-md-6`}
        />
      </div>
    ) : (
      <OneBigTwoSmall customClass="custom-section-two" />
    )
  ) : (
    <section className="royal-wrapper">
      <div className="container-fluid custom-container">
        <div className="royal-side-padding">
          <div className="section-top-wrapper">
            <h2>{heading}</h2>
            <div className="carousel-dots">
              {isMoreFrom && url && (
                // <span className="text-right">
                <AdvWrapper navigationLink={url} linkClass={'text-right'}>
                  More from {heading}{' '}
                  <img src={Arrow} className="img-fluid" alt="arrow" />
                </AdvWrapper>
                // </span>
              )}
            </div>
          </div>
          <div className="royal-items-wrapper">
            {!Utilities.mobilecheck() && (
              // <span className="royal-leftsection">

              <AdvWrapper
                navigationLink={data && data[0] && data[0].navigation_link}
                linkClass={'royal-leftsection'}
              >
                <div>
                  <Image
                    src={data[0].full_image}
                    alt={data[0].alt_text}
                    className="img-fluid"
                    type="BigBanner"
                  />
                </div>
              </AdvWrapper>
              // </span>
            )}
            <div className="royal-rightsection">
              {!Utilities.mobilecheck() && (
                // <span className="royal-rightside-textwrapper">
                <AdvWrapper
                  navigationLink={data && data[0] && data[0].navigation_link}
                  linkClass={'royal-rightside-textwrapper'}
                >
                  <EventHeading
                    title={data && data[0] && data[0].title}
                    lines={2}
                    height={
                      Utilities.mobilecheck()
                        ? 20
                        : Utilities.mobileAndTabletcheck()
                        ? 32
                        : 32
                    }
                  />
                  {data && data[0] && data[0].description && (
                    <Ellipsis
                      title={data && data[0] && data[0].description}
                      lines={1}
                      height={Utilities.mobilecheck() ? 25 : 30}
                    />
                  )}
                  {(data && data[0] && data[0].event_date === null) ||
                  (data && data[0] && data[0].event_date === '') ? (
                    <p className="dt-srt-end"></p>
                  ) : (
                    <Ellipsis
                      title={data[0].event_date}
                      lines={1}
                      height={Utilities.mobilecheck() ? 17 : 18}
                      allowTooltip={false}
                      customClass="dt-srt-end"
                    />
                  )}
                </AdvWrapper>
                // </span>
              )}
              <div className="royal-items">
                {data &&
                  data
                    .slice(Utilities.mobilecheck() ? 0 : 1, data.length)
                    .map((elem, i) => {
                      return (
                        // <span
                        //   className="item-img"
                        //    href={elem.navigation_link}
                        //    target="_blank"
                        //    key={i}
                        //   className="item-wrapper"
                        // >
                        <AdvWrapper
                          navigationLink={elem && elem.navigation_link}
                          linkClass={'item-wrapper'}
                        >
                          <div className="item-img">
                            <Image
                              src={elem.full_image}
                              alt={elem.alt_text}
                              className="img-fluid"
                              type="BigBanner"
                            />
                          </div>

                          <div className="royal-item-content">
                            {/* <a> */}
                            <EventHeading
                              title={elem.title}
                              lines={2}
                              height={
                                Utilities.mobileAndTabletcheck() ? 20 : 20
                              }
                            />
                            {/* </a> */}
                            {elem.description && (
                              <Ellipsis
                                title={elem.description}
                                lines={1}
                                height={Utilities.mobilecheck() ? 15 : 18}
                              />
                            )}
                            {elem.event_date === null ||
                            elem.event_date === '' ? (
                              <p className="dt-srt-end"></p>
                            ) : (
                              <Ellipsis
                                title={elem.event_date}
                                lines={1}
                                height={Utilities.mobilecheck() ? 17 : 18}
                                allowTooltip={false}
                                customClass="dt-srt-end"
                              />
                            )}
                          </div>
                        </AdvWrapper>
                        // </span>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomSectionTwo;

import React from 'react';
import StixMap from './StixMap';
import { AllFunctions } from '../../../../helper';
import BaseContext from '../../../../contexts';
import {
    Box,
    Grid,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Card,
    CardContent
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

export const DesktopVenue = (props) => {
    const base = React.useContext(BaseContext);
    const [state, setState] = React.useState({
        searchVal:'',
        results: props.initialVenues,
        allResults: [],
        selectedVenues: []
    });

    const handleOnSearch = (e) => {
        const val = e.target.value;
        let results = [], allresults=[];

        (props.venues && props.venues.length > 0) && props.venues.map((venue)=>{
            const name = venue.name;
            const venueChecker = name.toLowerCase().search(val.toLowerCase());
            if( venueChecker !== -1){
                if(results.length < 4){
                    results.push(venue);
                }
                allresults.push(venue);
            }
        });

        setState({
            ...state,
            searchVal: val,
            results: val.length > 0 ? results : props.initialVenues,
            allResults: val.length > 0 ? allresults : []
        });
    }

    const onCardClick = (svn) => {
        let newVNs = state.selectedVenues;
        if(newVNs.includes(svn.id)){
            const index = newVNs.indexOf(svn.id);
            newVNs.splice(index, 1);
        }else{
            newVNs.push(svn.id);
        }

        setState({
            ...state,
            selectedVenues: newVNs
        });

        props.updateBase && props.updateBase({
            user:{
                ...base.data.user,
                planVisit:{
                    ...base.data.user.planVisit,
                    venue: (newVNs && newVNs.length > 0) ? newVNs : null
                }
            }
        });
    }

    const handleClearSearch = () => {
        setState({
            ...state,
            searchVal:"",
            results: props.initialVenues,
            allResults:[]
        });
    }

    const countResults = state.allResults.length;
    const resLabel = countResults > 1 ? 'results' : 'result';
    return (
        <div className={'desktop'}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} >
                    <Grid item xs={4} className={'stixMapVenues'}>
                        <div className={'stixMapSearchArea'}>
                            <FormControl variant="outlined">
                                <OutlinedInput
                                    id="search-id"
                                    type={'text'}
                                    onChange={handleOnSearch}
                                    placeholder='Search venue'
                                    startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle clear"
                                        edge="end"
                                        onClick={handleClearSearch}
                                        >
                                        {<ClearIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    value={state.searchVal}
                                    label="Search venue"
                                />
                            </FormControl>

                            <div className={'results-container'}>
                                <h3>{state.searchVal ? [countResults, resLabel].join(" "): 'Venues in Singapore'}</h3>
                                
                                {(state.results && state.results.length > 0) && state.results.map((vnr)=>{
                                    const vnrName=AllFunctions.removeTags(vnr.name);
                                    const vnrAddress=AllFunctions.removeTags(vnr.address);

                                    return (<Card 
                                        className={['results-card-wrapper', state.selectedVenues.includes(vnr.id) ? 'active' : ''].join(" ")}>
                                        <CardContent className={'results-card-content'} onClick={()=>onCardClick(vnr)}>
                                            <h1>{AllFunctions.addEllipsis(vnrName, 24)}</h1>
                                            <p>{AllFunctions.addEllipsis(vnrAddress, 32)}</p>
                                            <a 
                                                href={`http://maps.google.com/?q=${vnr.latitude},${vnr.longitude}`}
                                                className="direcrtion-icn"
                                                target="_blank">
                                                    Get Directions
                                            </a>
                                        </CardContent>
                                    </Card>);
                                })}

                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={8} className={'stixGMap'}>
                        <StixMap {...props} searchResults={state.allResults} search={state.searchVal} selectedVenues={state.selectedVenues} />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}


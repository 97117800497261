import {API} from '../../helper/services';
import axios from "axios";

class ExploreService {
  getExploreArticleList(params) {
    return API.get(`/get-articles-listing`, { params });
  }
  getTemplateTwo(params, addPreview) {
    const url = addPreview ? `preview/get-template-2` : `get-template-2`
    return API.get(url, { params });
  }
  getArticle(params, addPreview) {
    const url = addPreview ? `preview/get-template-1` : `get-template-1`
    return API.get(url, { params });
  }
  getCategories() {
    return API.get(`get-template-categories`);
  }
  getTags() {
    return API.get(`get-template-tags`);
  }
  getExploreLanding() {
    return API.get(`get-explore-landing`);
  }
  getSurvey(params) {
    return API.get(`get-surveys`, { params });
  }
  getQuiz(params) {
    return API.get(`get-quiz`, { params });
  }
  submitQuiz(data) {
    return API.post(`quiz-results`, data);
  }
  submitSurvey(data) {
    return API.post(`surveys-results`, data);
  }
}

export default new ExploreService();

import React, { useState, useRef, useEffect } from 'react';
import closeIcon from '../../assets/images/close-ad.svg';
import closeIconhover from '../../assets/images/cross.svg';
import { Utilities } from '../../utils/utilities';
import './style.scss';

const Cookies = () => {
  const [style, setStyle] = useState({ display: 'none' });
  useEffect(() => {
    if (!Utilities.getCookie('consent')) {
      setStyle({ display: 'flex' });
    }else {
      setStyle({ display: 'none' });
    }
  }, []);

  let handleAccept = () => {
    Utilities.setCookie('consent', 'Yes', 50 * 365);
    setStyle({ display: 'none' });
  };

  let handleDecline = () => {
    setStyle({ display: 'none' });
  };

  if (Utilities.getCookie('consent')) {
    return null;
  }

  return (
    <div className={'cookies-new'} style={style}>
      <div className="heading">
        <h3>Cookie Policy</h3>
      </div>
      <div className="cookie-detail">
        <p>
          We use cookies on this site to enhance your user experience. For a
          complete overview of all cookies used, please see our{' '}
          <a href="/cookie-policy" target="_blank">
            cookie policy
          </a>
          .
        </p>
      </div>
      <div className='cookie-actions-new'>
        <span className="accept-btn" onClick={handleAccept}>
          Accept
        </span>
      </div>

      <span className="hide-cookie" onClick={handleDecline}>
        <img src={closeIcon} alt="" />
        <img src={closeIconhover} className="hover-img" alt="" />
      </span>
    </div>
  );
};

export default Cookies;

import React, { useEffect, useState,useContext  } from 'react';
import {
    Container, Grid, Avatar, Stack
} from '@mui/material';
import { Constants } from '../../utils';
import BaseContext from '../../contexts';
import Category from './components/Category';

export const AttractionResult = (props = {}) => {
    const [isMobile, setIsMobile] = React.useState(props.isMobile);
    const [attractiondata, setattractiondata] = useState([]);
    const base = useContext(BaseContext);
    const middleBanner = base.data.midAdvertisement && base.data.midAdvertisement.data;
   
    return (<Container maxWidth="xl" style={{maxWidth: "1093px"}}>
        <Grid container rowSpacing={3} my={2}>
            <Category {...props}/>
            <Grid xs={12}>
                 {middleBanner && (
                    <Grid item xs={12}>
                      <Stack pb={6}>                  
                      { middleBanner[0] ? <a href={middleBanner[0].navigation_link?middleBanner[0].navigation_link:'#'} 
                    target={middleBanner[0].navigation_link? '_blank':''}>
                    <img src={ middleBanner[0].full_image} alt={middleBanner[0].title} /></a> : ''}
                      </Stack>
                    </Grid>
                )}
              </Grid>
        </Grid>
    </Container>);
}

import {createContext} from 'react';
import User from './user';
import Error from './error';
import RotationBanners from './rotationBanner';
import FeaturedEvents from './featuredEvents';
import TopPicksEvent from './topPicks';
import SingleBanner from './singleBanner';
import Explore from './explore';
import WhatsNew from './whatsNew';
import Recommendations from './recommendation';
import ThisWeek from './thisWeek';
import MidAdvertisement from './midAdvertisement';
import FlashSale from './flashSale';
import EventResult from './eventResult';
import VideoEvents from './videoEvents';
import News from './news';
import EN from './en';
import TrendingNow from './trendingNow';

const BaseContext = createContext({
    data:{
        ...User,
        ...Error,
        ...SingleBanner,
        ...RotationBanners,
        ...FeaturedEvents,
        ...TopPicksEvent,
        ...WhatsNew,
        ...Explore,
        ...ThisWeek,
        ...Recommendations,
        ...MidAdvertisement,
        ...FlashSale,
        ...EventResult,
        ...VideoEvents,
        ...News,
        ...TrendingNow,
        config:{
            ...EN.en
        },
        search:'',
        predictApiResponseData: {}
    },
    updateBase: (base) => {},
});

export default BaseContext;

import * as React from 'react';
import {
    Button,
    Menu,
    MenuItem
} from '@mui/material';

export const SortSection = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (type, asc) => {
      setAnchorEl(null);
      (props.callBack && type) && props.callBack(type, asc)
    };

    return (<div>
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className={['stix-sort',open ? 'open' : '' ].join(" ")}
          onClick={handleClick}
        >
          <img src="../imgs/sort-by.svg" alt="" />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={()=>handleClose('date',true)}>Events Earliest to Latest (Default)</MenuItem>
          <MenuItem onClick={()=>handleClose('date',false)}>Events Latest to Earliest</MenuItem>
          <MenuItem onClick={()=>handleClose('title',true)}>Events A-Z</MenuItem>
          <MenuItem onClick={()=>handleClose('title',false)}>Events Z-A</MenuItem>
          <MenuItem onClick={()=>handleClose('price',false)}>Price High to Low</MenuItem>
          <MenuItem onClick={()=>handleClose('price',true)}>Price Low to High</MenuItem>
        </Menu>
      </div>);
}
import React from 'react';

export const CyberCertificate = (props) => {

    const cyberCertificate = props.cyberCertificate ? props.cyberCertificate : {}

    return (
        <div className={'cyber-cert'}>
            <ul className="medias">
                {Object.keys(cyberCertificate).map((sm) => {
                    return(
                        <img src={cyberCertificate[sm].icon} className="" alt="" key={sm}/>
                    )
                })}
            </ul>
        </div>
    );
}
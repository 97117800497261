import React, { useState, useEffect, useRef } from 'react';
import { Utilities } from '../../../../utils';
import Slider from 'react-slick';
import './style.scss';
import Item from './Item';

const FeaturedArticles = ({ featuredArticles, history, articleDataIsNull }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    horizontal: true,
    prevArrow: <div className="slick-arrow slick-prev">Previous</div>,
    nextArrow: <div className="slick-arrow slick-next">Next</div>,
    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 3
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      }
  ]
  };

  return Utilities.mobilecheck() ? (
    <div style={{ width: '30em', overflowX: 'auto', whiteSpace: 'nowrap' }}>
      <div className="grid-container">
        {featuredArticles &&
          featuredArticles.map((event, index) => {
            return <Item event={event} history={history} key={index} />;
          })}
      </div>
    </div>
  ) : (
    <Slider {...settings}>
    {featuredArticles &&
      featuredArticles.map((event, index) => {
        return (
          <div className="grid-container" key={index}>
            <Item event={event} history={history} />
          </div>
        );
      })}
  </Slider>
  );
};

export default FeaturedArticles;

import React from 'react';
import './style.scss';
import Image from '../../../../components/Image';
import ShimmerEffect from '../../../../components/ShimmerEffect';
import noEvent from '../../../../assets/images/no-event.svg';
import { Utilities } from '../../../../utils';
import EventHeading from '../../../../components/EventHeading';
import { Link } from 'react-router-dom';

let availableCategories = (selectedCategories, cardDataCategories) => {
  try {
    let categories = selectedCategories
      .filter(c => c.isChecked)
      .map(el => el.name);
    return categories.find(selected => cardDataCategories.includes(selected));
  } catch (e) {
    return '';
  }
};
const CardList = React.forwardRef((props, ref) => {
  const { history, articleList, totalRecords, selectedCategories } = props;

  let textLimit = Utilities.mobilecheck() ? 45 : 50;
  return articleList.length ? (
    articleList.map(cardData => {
      let routePath = Utilities.linkAccToType(cardData.type, cardData.id);
      let categoryToShow =
        availableCategories(selectedCategories, cardData.categories) ||
        ((cardData.categories &&
          cardData.categories.length &&
          cardData.categories.slice(0, 1)) ||
          '');
      return (
        <Link to={routePath} key={cardData.id}>
          <div className="event-block" ref={ref}>
            <div className="article-category-group">
              {categoryToShow ? (
                <span className="category">{categoryToShow}</span>
              ) : null}
            </div>
            <div className="event-img">
              <Image src={cardData.thumb_image} />
            </div>
            <div className="event-details">
              <div className="event-detail-prime">
                <div className="category-group">
                  <ul>
                    {cardData.tags &&
                      cardData.tags.slice(0, 4).map(genreName => {
                        if (genreName) {
                          return (
                            <li key={genreName}>
                              <span className="">{genreName}</span>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </div>
                <div className="article-bottom-details">
                  <div className='' style={{marginBottom: 8}}>
                    <EventHeading
                      title={cardData.title}
                      lines={2}
                      height={20}
                    />
                    <div className="item-title">
                      <span>{cardData.description.slice(0, textLimit)}</span>
                      <span className="article-show-more">...More</span>
                    </div>
                  </div>
                  <div className="item-bottom">
                    <span className="item-author">
                      {cardData.author_name
                        ? `By ${cardData.author_name}`
                        : null}
                    </span>
                    <span className="item-date">{cardData.date}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    })
  ) : totalRecords === 0 ? (
    <ShimmerEffect
      propCls={`${
        Utilities.mobileAndTabletcheck() ? 'shm_col-xs-6' : ''
      } col-md-4`}
      height={150}
      count={Utilities.mobileAndTabletcheck() ? 2 : 3}
      type="LIST"
    />
  ) : (
    <div className="no-data">
      <img src={noEvent} alt="No Event Data" />
      <p>
        <strong>No Data found</strong>
      </p>
      <p>Try again with more general search</p>
    </div>
  );
});
export default CardList;

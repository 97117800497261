
const EN = {
    en:{
        "_comment_top": "Properties",
        "main_id":"",
        "meta":{
            "title": "SISTIC Singapore",
            "description": "SISTIC has been providing innovative ticketing services since December 1991 and licensing its ticketing solution STiX since 2003. In Singapore, SISTIC is the largest ticketing service provider selling tickets for a wide variety of arts, entertainment and sports events; through a ticket distribution network that extends beyond Singapore to countries such as Malaysia and Indonesia.   SISTIC also offers STiX, a web-based and customer-oriented ticketing solution, to ticketing companies, event promoters and venues under a licensing arrangement. STiX has been used for events held at various performing venues in Singapore, Malaysia, Australia, Hong Kong and China."
        },
        "link":{
            "favicon": "/favicons/sistic-favicon.ico"
        }, 
        
        "_comment_Header": "Header Details",
        "header":{
            "parentClass":"sistic-header-container",
            "logo": "/imgs/sistic/logo.png",
            "cartIcon": "/imgs/cart.svg",
            "userIcon": "/imgs/user.svg",
            "searchIcon": "/imgs/search.svg",
            "fieldSearchIcon": "/imgs/field-search.png",
            "loginDescription": "Log in",
            "menu_l":[
                {
                    "id": "#",
                    "label": "Events",
                    "url": "/events"
                },
                {
                    "id": "attractions",
                    "label": "Attractions",
                    "url": "/attractions"
                },
                {
                    "id": "promotions",
                    "label": "Promotions",
                    "url": "/promotions"
                },
                {
                    "id": "explore",
                    "label": "Explore",
                    "url": "/explore"
                },
                {
                    "id": "planvisit",
                    "label": "Plan Visit",
                    "url": "/planvisit"
                },
                {
                    "id": "create-event",
                    "label": "Create Event",
                    "url": "/create-event"
                }
            ],
            "menu_r":[],
            "menu_mobile":[
                {
                    "id": "event_mobile",
                    "label": "Events",
                    "url": "/events"
                },
                {
                    "id": "attractions_mobile",
                    "label": "Attractions",
                    "url": "/attractions"
                },
                {
                    "id": "more_mobile",
                    "label": "More"
                }
            ],
            "events_dropdown":[
                {
                    "id": "concert",
                    "label": "Concert",
                    "url": "/concert"
                },
                {
                    "id": "theatre",
                    "label": "Theatre",
                    "url": "/theatre"
                },
                {
                    "id": "musical",
                    "label": "Musical",
                    "url": "/musical"
                },
                {
                    "id": "lifestyle",
                    "label": "Lifestyle",
                    "url": "/lifestyle"
                },
                {
                    "id": "comedy",
                    "label": "Comedy",
                    "url": "/comedy"
                },
                {
                    "id": "sports",
                    "label": "Sports",
                    "url": "/sports"
                },
                {
                    "id": "orchestra",
                    "label": "Orchestra",
                    "url": "/orchestra"
                },
                {
                    "id": "familyentertainment",
                    "label": "Family Entertainment",
                    "url": "/familyentertainment"
                },
                {
                    "id": "familydance",
                    "label": "Family Dance",
                    "url": "/familydance"
                }
            ],
            "social_media":{
                "header": "Social Media",
                "facebook": {
                    "url":"https://www.facebook.com/SISTICsingapore",
                    "icon":"/imgs/sistic/fb_sistic.svg"
                },
                "instagram": {
                    "url":"https://www.instagram.com/sisticsingapore/",
                    "icon":"/imgs/sistic/insta_sistic-unfill.svg"
                }
            },
            "appDownload": {
                "header": "Sistic on Mobile",
                "appstore": {
                    "topLabel":"Download on the",
                    "label":"Apple Store",
                    "url":"https://apps.apple.com/us/app/id500601166",
                    "icon":"/imgs/apple.svg"
                },
                "googleplay": {
                    "topLabel":"GET IT ON",
                    "label":"Google Play",
                    "url":"https://play.google.com/store/apps/details?id=com.rainmakerlabs.sistic",
                    "icon":"/imgs/android.png"
                }
            }
        },
        
    
        "_comment_Footer": "Footer Details",
        "footer":{
            "ourCompany":[
                {   
                    "id":"aboutus",
                    "label":"About Us",
                    "url":"https://www.corporate.sistic.com.sg/"
                },
                {
                    "id":"as",
                    "label":"Articles",
                    "url":"https://www.sistic.com.sg/explore/articles"
                },
                {
                    "id":"md",
                    "label":"Media",
                    "url":"https://www.corporate.sistic.com.sg/news"
                },
                {
                    "id":"careers",
                    "label":"Careers",
                    "url":"/corporate/careers"
                }
            ],
            "ticketingLinks":[
                {
                    "id":"selwus",
                    "label":"Sell with Us",
                    "url":"https://www.corporate.sistic.com.sg/ticket-with-us"
                },
                {
                    "id":"ticktech",
                    "label":"Ticketing Technology",
                    "url":"https://www.corporate.sistic.com.sg/business-solutions"
                },
                {
                    "id":"partnerwus",
                    "label":"Partner with Us",
                    "url":"https://www.corporate.sistic.com.sg/advertise-with-us"
                }
            ],
            "helpfulLinks":[
                {
                    "id":"ma",
                    "label":"My Account",
                    "url":"https://ticketing.sistic.com.sg/sistic/patron/management"
                },
                {
                    "id":"wbt",
                    "label":"Where to Buy Tickets",
                    "url":"/where-to-buy-tickets"
                },
                {
                    "id":"laa",
                    "label":"Locate an Agent",
                    "url":"/agents"
                },
                {
                    "id":"lav",
                    "label":"Locate a Venue",
                    "url":"/venues"
                }
            ],
            "supportLinks":[
                {
                    "id":"gv",
                    "label":"Gift Vouchers",
                    "url":"/gift-vouchers"
                },
                {
                    "id":"faq",
                    "label":"FAQ",
                    "url":"/faq/frequently-asked-questions"
                    // "url":"/faq/top-questions"
                },
                {
                    "id":"csr",
                    "label":"Cancellations/Refunds",
                    "url":"/faq/cancellation-refunds"
                },
                {
                    "id":"cu",
                    "label":"Contact Us",
                    "url":"https://www.corporate.sistic.com.sg/contact-us"
                }
            ],
            "stayConnectedLinks":[
                {
                    "id":"jon",
                    "label":"Join Our Newsletter",
                    "url":"/explore/1/18024"
                }
            ],
            "social_media":{
                "header": "",
                "facebook": {
                    "url":"https://www.facebook.com/SISTICsingapore",
                    "icon":"/imgs/sistic/fb-sistic-light.png"
                },
                "instagram": {
                    "url":"https://www.instagram.com/sisticsingapore/",
                    "icon":"/imgs/sistic/insta-sistic-light.png"
                },
                "telegram": {
                    "url":"https://t.me/s/sisticsingapore",
                    "icon":"/imgs/sistic/telegram-sistic-light.png"
                },
                "tiktok": {
                    "url":"https://www.tiktok.com/@sistic.singapore",
                    "icon":"/imgs/sistic/16px-tiktok.svg"
                }
            },
            "appDownload": {
                "header": "",
                "appstore": {
                    "topLabel":"Download on the",
                    "label":"Apple Store",
                    "url":"https://apps.apple.com/us/app/id500601166",
                    "icon":"/imgs/appleStore-light.png"
                },
                "googleplay": {
                    "topLabel":"GET IT ON",
                    "label":"Google Play",
                    "url":"https://play.google.com/store/apps/details?id=com.rainmakerlabs.sistic",
                    "icon":"/imgs/googlePlay-light.png"
                }
            },
            "isoCertificate": {
                "header": "",
                "iso9001": {
                    "topLabel":"",
                    "label":"ISO 9001",
                    "url":"https://www.sistic.com.sg/",
                    "icon":"/imgs/sistic/ISO9001_light.png"
                }
            },
            "cyberCertificate": {
                "header": "",
                "cyberImage": {
                    "topLabel":"",
                    "label":"CSA Cyber",
                    "url":"https://www.sistic.com.sg/",
                    "icon":"/imgs/sistic/CSA_Cyber_Essentials_Certified_White.png"
                }
            }
        },
        "_comment_Body": ""
    }
}
export default EN;
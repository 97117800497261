import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import Image from '../../../../../components/Image';
import { Utilities } from '../../../../../utils';

const Article = ({ article }) => {
  return (
    <Link
      className="articles-item"
      to={Utilities.linkAccToType(article.type, article.id)}
    >
      <div className="article-item-desc">
        <div className="category-group">
          <ul>
            {article &&
              article.tags &&
              article.tags.slice(0, 4).map(data => {
                return data ? (
                  <li key={data}>
                    <a>{data}</a>
                  </li>
                ) : null;
              })}
          </ul>
        </div>
        <a>
          <h3>{article.title}</h3>
        </a>
        <div className="article-place-date">
          {article.author_name && (
            <span className="article-place">
              By{' '}
              {article.author_name[0].toUpperCase() +
                article.author_name.slice(1)}
            </span>
          )}
          <span className="date">{article.date}</span>
        </div>
        <p>
          {Utilities.showLimitedChars(
            article.description,
            Utilities.mobilecheck() ? 140 : 180
          )}
          <a>More</a>
        </p>
      </div>
      <div className="article-item-img">
        <Image
          src={article && article.image}
          type="BigBanner"
          className="img-fluid"
        />
      </div>
    </Link>
  );
};

export default Article;

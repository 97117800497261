import React, { useEffect } from 'react';
import Slider from 'react-slick';

export const HomeBanner = (props = {}) => {
    const [isMobile, setIsMobile] = React.useState(props.isMobile);

    useEffect(()=>{
        setIsMobile(props.isMobile);
    },[props.isMobile]);

    const handleOnClick = (e) => {
      let url = e.target.getAttribute("navUrl");
      if (url) {
        window.location.href = url;
      }
    };

    var items = [];
    if(props.items){
        items = props.items
    }
    
    return (<>
        <div className={'stix-banner-container'}>
            <Slider
                {...props}
                speed={500}
                autoplaySpeed={5000}
                infinite={true}
                centerPadding={isMobile ? "0px" : "200px"}
                focusOnSelect={true}
                autoplay={true}
                swipe={true}
                lazyLoad= {true}
                slidesToShow={1}
                slidesToScroll={1}
                dots={true}
                pauseOnHover={false}
                draggable={true}
                centerMode={true}
                arrow={false}
                >
                {
                    items.map( (item, i) => { 
                        let img = '';
                        if(i < 20){
                            if(isMobile){
                                img = item.mobile_image ? <img 
                                key={i}
                                navUrl={item.navigation_link}
                                id={item.title.replace(/\s/g, '')} 
                                className={"rotation-banner"}
                                src={item.mobile_image} 
                                onClick={handleOnClick}
                                /> : <img key={i} src={'./imgs/default-banner-mobile.png'} />
                            }else{
                                img = item.full_image ? <img 
                                key={i} 
                                navUrl={item.navigation_link}
                                id={item.title.replace(/\s/g, '')} 
                                className={"rotation-banner"}
                                src={item.full_image} 
                                onClick={handleOnClick} 
                                /> : <img key={i} src={'./imgs/default-banner.png'} />
                            }
                            return img;
                        }
                    })
                }
            </Slider>
        </div>
    </>);
}
import { useState } from "react";
import { Typography } from "@mui/material";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { AllFunctions } from "../../../helper";

export const TruncateText = ({ text,length }) => {
  const [readMore, setReadMore] = useState(false);
  const { smallDown } = useCustomMediaQuery();

  const handleRead = () => {
    setReadMore(!readMore);
  };

  const getWordCount = (str) => {
    return str.trim().split(/\s+/).length;
  }

  const readLink = readMore ? "Read less" : "Read more";

  text = `${text}`.replace(/(^|[.!?]\s+)([a-z])/g, (c) => c.toUpperCase());

  const desc = text.slice(0, length);

  const extraContent = text;
  
  let wordCutter = text;

  if(getWordCount(text) > 60){
    wordCutter = text.split(' ').slice(0, 60).join(' ') + " " + "...";
  }
  
  /** add target to href */
    wordCutter = AllFunctions.addTargetToHref(wordCutter);
    text =  AllFunctions.addTargetToHref(text);
  /** end add target */
  
  return (
    <>
      <Typography
        variant="caption"
        fontSize="1rem"
        color="#626262"
        fontWeight={400}
      >
        {/* {`${desc}${!readMore && "..."}`}
        {readMore && extraContent} */}
        {!readMore && <p id="decription" className={"p"} dangerouslySetInnerHTML={{
          __html: text && wordCutter

        }}></p>}
        {readMore && <p id="decription" className={"p"} dangerouslySetInnerHTML={{
          __html: text && text

        }}></p>}
      </Typography>
      {getWordCount(text) > 60 ? (
        <Typography
          align="center"
          fontSize="0.75rem"
          color="#0082D6"
          sx={{ cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={handleRead}
        >
          {smallDown ? '' : readLink}
          {readMore ? <></> : <img src="../imgs/24px-chevron-down.svg" alt="" />}
        </Typography>
      ) : (
        ""
      )}
    </>
  );
};


import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  ButtonGroup,
  Button,
  ToggleButton,
  Typography,
} from "@mui/material";
import BaseContext from "../../../contexts";
import { StixDropDownDate } from "../../UI";
import moment from "moment";

export const DateRange = (props) => {
  const options = ["Today", "This weekend", "This month"];
  const base = React.useContext(BaseContext);
  const [selectedButton, setSelectedButton] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const [dateRangeActive, setDateRangeActive] = useState(false);

  useEffect(() => {
    if (
      base.data &&
      base.data.user &&
      base.data.user.planVisit &&
      base.data.user.planVisit.dateRange &&
      base.data.user.planVisit.dateRange.prevSelection
    ) {
      const dateRangeSelected = base.data.user.planVisit.dateRange;
      const prevSelection = dateRangeSelected.prevSelection;
      switch (prevSelection) {
        case "CustomRange":
          handleCallBack(
            "id",
            {
              start: dateRangeSelected.start,
              end: dateRangeSelected.end,
            },
            true
          );
          break;
        default:
          handleButtonClick(prevSelection);
      }
    }
  }, []);

  const getDateFromButton = (option) => {
    const today = moment();
    switch (option) {
      case "Today":
        return { start: today, end: null };
      case "This weekend":
        const saturdayDate = today.clone().startOf("week").add(5, "days");
        const sundayDate = today.clone().startOf("week").add(6, "days");

        return { start: saturdayDate, end: sundayDate };
      case "This month":
        const startOfMonth = today.clone().startOf("month");
        const endOfMonth = today.clone().endOf("month");

        return { start: startOfMonth, end: endOfMonth };
      default:
        return { start: null, end: null };
    }
  };

  const handleButtonClick = (option) => {
    setSelectedButton(option);
    setDateRange({ start: null, end: null });
    setDateRangeActive(false);
    props.updateBase &&
      props.updateBase({
        user: {
          ...base.data.user,
          planVisit: {
            ...base.data.user.planVisit,
            dateRange: {
              ...getDateFromButton(option),
              prevSelection: option,
            },
          },
        },
      });
  };

  const handleCallBack = (id, val = {}, status = false) => {
    setSelectedButton(null);
    setDateRange({ ...val });
    setDateRangeActive(status);
    props.updateBase &&
      props.updateBase({
        user: {
          ...base.data.user,
          planVisit: {
            ...base.data.user.planVisit,
            dateRange: {
              ...val,
              prevSelection: "CustomRange",
            },
          },
        },
      });
  };

  const formatDate = (dateObj) => {
    return dateObj.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div className={"plan-visit-step-dateRange"}>
      <Grid container direction="column">
        <Typography>When are you planning to go?</Typography>

        <Grid item className={"toggleButtons-container"}>
          {options.map((option) => (
            <Button
              onClick={() => handleButtonClick(option)}
              className={selectedButton === option ? "selected" : ""}
            >
              {option}
            </Button>
          ))}
        </Grid>
        <Grid item className={"dateRange-container"}>
          <StixDropDownDate
            name={
              dateRange.start === null
                ? "Date Range"
                : dateRange.end === null
                ? `${formatDate(dateRange.start)}`
                : `${formatDate(dateRange.start)} - ${formatDate(
                    dateRange.end
                  )}`
            }
            id={"dateRange"}
            componentCallBack={handleCallBack}
            dateRangeActive={dateRangeActive}
          />
        </Grid>
      </Grid>
    </div>
  );
};

import React from 'react';
import { RouteObject, useRoutes } from "react-router-dom";
import { 
  AppLayout,
  LandingPage,
  NotFound,
  DateSelectionPage,
  ConfirmationPage,
  EventResultPage,
  NewEventResultPage,
  EventDetailsPage,
  AttractionResultPage,
  ForRedirectionPage,
   
  PlanVisitPage,
  AgreementPage,
  ExplorePage,
  ClearCachePage,
  CreateEventPage
} from '../pages';

import Festival from '../scenes/Explore/Festival';
import Explore from "../scenes/Explore/LandingPage";
import Article from '../scenes/Explore/Article';
import ArticleList from '../scenes/Explore/ArticleList';
import Faq from '../scenes/Faq';
import Careers from '../scenes/Careers';
import WhereBuyTicket from '../scenes/WhereBuyTickets';
import Agents from '../scenes/Agents';
import Venues from '../scenes/Venues';
import GiftVouchers from '../scenes/GiftVouchers';
import Promotions from '../scenes/Promotions';
import JobDescription from '../scenes/Careers/JobDescription';

import { Listings } from '../components/planYourVisit/components/_Listings';

import BmgTable from '../scenes/BmgTable';
import NewMultiLangTerms from '../scenes/NewMultiLangTerms';

export const AppRouters = (props) => {
    let routes = [
        {
          path: "/",
          element: <AppLayout {...props} />,
          children: [
            { 
              index: true, 
              path:"*",
              element: <ForRedirectionPage {...props}/> 
            },
            { 
              index: true, 
              path:"/",
              element: <LandingPage {...props}/> 
            },
            { 
              index: true, 
              path:"/events",
              element: <NewEventResultPage {...props}/> 
              //element: <EventResultPage {...props}/> 
            },
            // { 
            //   index: true, 
            //   path:"/eventsTemp",
            //   element: <NewEventResultPage {...props}/> 
            // },
            { 
              index: true, 
              path:"/events/:id",
              element: <EventDetailsPage {...props}/> 
            },
            { 
              path:"/attractions",
              element: <AttractionResultPage {...props}/> 
            },{ 
              path:"/planvisit",
              element: <PlanVisitPage {...props}/> 
            },
            { 
              path:"/plan-listings",
              element: <Listings {...props}/> 
            },
            { 
              index: true, 
              path:"/tempexplore",
              element: <ExplorePage {...props}/> 
            },
            { 
              index: true, 
              path:"/explore",
              element: <Explore {...props}/> 
            },
            { 
              index: true, 
              path:"/explore/1/:id",
              element: <Article {...props}/> 
            },
            { 
              index: true, 
              path:"/explore/2/:id",
              element: <Festival {...props}/> 
            },
            { 
              index: true, 
              path:"/explore/articles",
              element: <ArticleList {...props}/> 
            },
            { 
              index: true, 
              path:"/faq/:id",
              element: <Faq {...props}/> 
            },
            { 
              index: true, 
              path:"/corporate/careers",
              element: <Careers {...props}/> 
            },
            { 
              index: true, 
              path:'/corporate/career/:jobId',
              element: <JobDescription {...props}/> 
            },
            { 
              index: true, 
              path:"/where-to-buy-tickets",
              element: <WhereBuyTicket {...props}/> 
            },
            { 
              index: true, 
              path:"/agents",
              element: <Agents {...props}/> 
            },
            { 
              index: true, 
              path:"/venues",
              element: <Venues {...props}/> 
            },
            { 
              index: true, 
              path:"/gift-vouchers",
              element: <GiftVouchers {...props}/> 
            },
            { 
              index: true, 
              path:"/promotions",
              element: <Promotions {...props}/> 
            },
            { 
              index: true, 
              path:"/promotions/1/:id",
              element: <Promotions {...props}/> 
            },
            { 
              index: true, 
              path:"/bmg-table",
              element: <BmgTable {...props}/> 
            },
            // clear caches
            {
              path: "/clearcache",
              element: <ClearCachePage {...props}/>
            },
            {
              path: "/create-event",
              element: <CreateEventPage {...props}/>
            },
            /** policies */
            { 
              path:"/terms-and-conditions",
              element: <AgreementPage {...props} id={"terms_cond"}/> 
            },
            { 
              path:"/privacy-policy",
              element: <AgreementPage {...props} id={"privacy_policy"}/> 
            },
            { 
              path:"/conditions-of-access",
              element: <AgreementPage {...props} id={"conditions"}/> 
            },
            { 
              path:"/cookie-policy",
              element: <AgreementPage {...props} id={"cookie_policy"}/> 
            },
            /** end policies */
            {
              path: "/404",
              element: <NotFound {...props}/>
            }
          ]
        },
        // new multi lnaguage polices
        {
          path: "/hk",
          children: [
            {
              path: "/hk/terms",
              element: <NewMultiLangTerms {...props} id={"terms_cond"} path={"hk"}/>
            },
            {
              path: "/hk/privacy",
              element: <NewMultiLangTerms {...props} id={"privacy_policy"} path={"hk"}/>
            },
            {
              path: "/hk/conditions",
              element: <NewMultiLangTerms {...props} id={"conditions"} path={"hk"}/>
            },
            {
              path: "/hk/cookie",
              element: <NewMultiLangTerms {...props} id={"cookie_policy"} path={"hk"}/>
            },
          ]
        },
        {
          path: "/vn",
          children: [
            {
              path: "/vn/terms",
              element: <NewMultiLangTerms {...props} id={"terms_cond"} path={"vn"}/>
            },
            {
              path: "/vn/privacy",
              element: <NewMultiLangTerms {...props} id={"privacy_policy"} path={"vn"}/>
            }
          ]
        },
        // {
        //   path: "*", 
        //   element: <NotFound {...props}/> 
        // }
      ];

    return useRoutes(routes); 
}
import React, { useEffect, useState } from "react";
import BaseContext from "../contexts";
import { PlanYourVisit } from "../components";
import useDeviceDetect from "../hooks/useDeviceDetect";
import EventsPlannerIllustration from "../assets/images/events-planner-illustration.svg";

export const PlanVisitPage = (props) => {
  const base = React.useContext(BaseContext);
  const { isMobile } = useDeviceDetect();

  return (
    <div className={"plan-visit"}>
      <div className={"plan-visit-header"}>
        <div className="plan-visit-header-wrapper">
          <div className="text">
            <h1>Plan your visit with us!</h1>
            <p>
              Plan and share unique activities and experiences with your family
              and friends.
            </p>
          </div>
          <img src={EventsPlannerIllustration} />
        </div>
      </div>
      <div className={"plan-visit-page"}>
        <div className={"plan-visit-page-body"}>
          <PlanYourVisit {...props} />
        </div>
      </div>
    </div>
  );
};

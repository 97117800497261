import React, { Component, Fragment } from 'react';
import './style.scss';
import PromotionService from '../../services/PromotionService';
import Tabs from '../../components/Tabs';
import { Constants, Utilities } from '../../utils';
import Breadcrumb from '../App/Breadcrumb';
import PageBanner from '../../assets/images/promotions-banner.png';
import SortBy from '../../components/SortBy';
import sortbyIcon from '../../assets/images/events/sortby.svg';

class PromotionsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultTabId: null,
      tabsArray: [],
      sortBy: 'ASC',
      first: 0,
      totalRecords:
        props.staticContext &&
        props.staticContext.response &&
        props.staticContext.response.response &&
        props.staticContext.response.response.promotions &&
        props.staticContext.response.response.promotions.totalRecords,
      listingArray:
        props.staticContext &&
        props.staticContext.response &&
        props.staticContext.response.response &&
        props.staticContext.response.response.promotions.data
          ? props.staticContext.response.response.promotions.data
          : [],
      loading:
        props.staticContext &&
        props.staticContext.response &&
        props.staticContext.response.response &&
        props.staticContext.response.response.promotions.data &&
        props.staticContext.response.response.promotions.data.length > 0
          ? false
          : true,
      promotionDetail: '',
      promotionTab: 0,
      tabDetailId: '',
      count: 0,
      sortByFlag: false,
      sortApply: false,
      sortFilters: null,

      filteredSortOrder: 'date',
      localsortBy: 'ASC',
      updatedLocation: null
    };
    this.tabsSort = {
      isSortBy: true,
      sortList: [
        {
          sortType: '',
          sortOrder: 'ASC',
          sortTitle: 'Promotion Date - Earliest to Latest',
          sortTag: 'Promotion Date - Earliest to Latest'
        },
        {
          sortType: '',
          sortOrder: 'DESC',
          sortTitle: 'Promotion Date - Latest to Earliest',
          sortTag: 'Promotion Date - Latest to Earliest'
        }
      ]
    };
    this.breadCrumbData = {
      page_banner: PageBanner,
      page_banner_blur: PageBanner,
      page: 'Promotions',
      count: '0',
      breadcrumb_slug: [
        { path: '/', title: 'Home' },
        { path: '/promotions', title: 'Promotions' }
      ]
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchPromotionCategoriesData();
    if (
      window.__INITIAL_DATA__ &&
      window.__INITIAL_DATA__.response &&
      window.__INITIAL_DATA__.response.promotions &&
      window.__INITIAL_DATA__.response.promotions.data &&
      window.__INITIAL_DATA__.response.promotions.data.length > 0
    ) {
      this.setState({
        loading: false,
        totalRecords: window.__INITIAL_DATA__.response.promotions.totalRecords,
        listingArray: window.__INITIAL_DATA__.response.promotions.data
      });
    } else {
      this.fetchPromotionListingData();
    }
    this.fetchSpecificTabData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { defaultTabId, sortBy, first, listingArray } = this.state;
    if (
      prevState.defaultTabId !== defaultTabId ||
      prevState.sortBy !== sortBy ||
      prevState.first !== first
    ) {
      if (
        window.__INITIAL_DATA__ &&
        window.__INITIAL_DATA__.response &&
        window.__INITIAL_DATA__.response.promotions &&
        window.__INITIAL_DATA__.response.promotions.data &&
        window.__INITIAL_DATA__.response.promotions.data.length > 0
      ) {
        delete window.__INITIAL_DATA__;
      } else {
        if (
          prevState.defaultTabId !== defaultTabId ||
          prevState.sortBy !== sortBy
        ) {
          this.setState({
            listingArray: [],
            loading: true,
            first: 0,
            totalRecords: 0
          });
        }

        const params = {
          client: Constants.CLIENT,
          category: defaultTabId,
          first: first,
          limit: Constants.LIMIT,
          sort_type: 'date',
          sort_order: sortBy
        };

        PromotionService.getPromotionList(params)
          .then(res => {
            if (res.data && res.data.data) {
              const listing = res.data.data;
              this.setState({ listingArray: [] });
              this.setState({
                loading: false,
                totalRecords: res.data.total_records,
                listingArray:
                  prevState.first !== first
                    ? [...listingArray, ...listing]
                    : listing
              });
            }
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    }
  }

  fetchSpecificTabData = () => {
    const defaultTabId = this.props.params.id;
    const id = this.props.location.pathname.split('/')[3];
    if (defaultTabId && id) {
      this.fetchPromotionDetailData(`node/${id}`, id, defaultTabId);
    }
  };

  fetchPromotionCategoriesData = () => {
    const params = {
      client: Constants.CLIENT
    };
    PromotionService.getPromotionCategories(params)
      .then(res => {
        const category = res.data.data;
        const defaultTabId = category[0].id;
        this.setState({
          tabsArray: category,
          defaultTabId: defaultTabId,
          count: category[0].promotions
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  //Not called
  fetchPromotionListingData = () => {
    const { defaultTabId, sortBy, first } = this.state;
    const params = {
      client: Constants.CLIENT,
      category: defaultTabId || 1,
      first: first,
      limit: Constants.LIMIT,
      sort_type: 'date',
      sort_order: sortBy
    };
    PromotionService.getPromotionList(params).then(res => {
      if (res.data && res.data.data) {
        const listing = res.data.data;
        this.setState({
          loading: false,
          totalRecords: res.data.total_records,
          listingArray: listing
        });
      }
    });
  };

  fetchPromotionDetailData = (alias, id, defaultTabId, promotionTab) => {
    const params = {
      client: Constants.CLIENT,
      alias: alias
    };
    PromotionService.getPromotionDetail(params)
      .then(res => {
        if (res.data.data.length > 0 && res.data.data[0]) {
          this.setState({
            promotionDetail: res.data.data[0],
            promotionTab: 1,
            tabDetailId: id,
            updatedLocation: id
          });
        } else {
          this.setState({
            promotionDetail: '',
            promotionTab: 0
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
    if (window.location.href && window.location.href.split('?')[1]) {
      let shareUrl =
        `/promotions/1/${id}` +
        '/?' +
        window.location.href.split('?')[1];
      //this.props.navigate(shareUrl);
      window.history.pushState(null, false, shareUrl);
    } else {
      let shareUrl = `/promotions/1/${id}`;
      //this.props.navigate(shareUrl);
      window.history.pushState(null, false, shareUrl);
    }
  };

  handleActiveTab = data => {
    this.setState({
      defaultTabId: data,
      promotionTab: 0
    });
  };

  handleLoadMore = data => {
    this.setState({ first: data });
  };

  handleFilters = obj => {
    if (!Utilities.mobilecheck()) {
      this.setState({
        sortBy: obj.filteredSortOrder,
        promotionTab: 0
      });
    } else {
      this.setState({
        localsortBy: obj.localfilteredSortOrder,
        promotionTab: 0
      });
    }
  };

  handlePromotionDetailTab = data => {
    this.setState({ promotionTab: data });
    let initialUrl = `/promotions`;
    window.history.pushState(null, false, initialUrl);
  };

  toggleSortBy = () => {
    document.body.classList.toggle('fixed-body');
    this.setState({
      sortByFlag: !this.state.sortByFlag,
      localsortBy: this.state.sortBy
    });
  };

  handleSortFilters = sortFilters => {
    this.setState({
      sortFilters
    });
  };

  handleSortApply = () => {
    this.setState({
      sortByFlag: !this.state.sortByFlag,
      sortBy: this.state.localsortBy,
      promotionTab: 0
    });
    document.body.classList.toggle('fixed-body');
  };

  clearSortFilters = () => {
    let sortFilters = { ...this.state.sortFilters };
    sortFilters.filteredSortOrder = 'date';
    this.setState({
      // filteredSortOrder: '',
      localsortBy: '',
      sortFilters
    });
  };

  handleLocation = updatedLocation => {
    this.setState({ updatedLocation });
  };

  render() {
    const { count, updatedLocation } = this.state;
    this.breadCrumbData.count = count;
    return (
      <div className="promotions-full-wrapper">

        <Breadcrumb breadCrumbData={this.breadCrumbData} />
        <section className="promotions-wrapper">
          <div className="container-fluid">
            <div className="container">
              {this.state.listingArray && (
                <Fragment>
                  {' '}
                  <Tabs
                    state={this.state}
                    tabsSort={this.tabsSort}
                    handleSortBy={this.handleSortBy}
                    handleLoadMore={this.handleLoadMore}
                    handleActiveTab={this.handleActiveTab}
                    handleFilters={this.handleFilters}
                    limit={Constants.LIMIT}
                    fetchPromotionDetailData={this.fetchPromotionDetailData}
                    handlePromotionDetailTab={this.handlePromotionDetailTab}
                    handleLocation={this.handleLocation}
                  />
                  {Utilities.mobilecheck() && (
                    <>
                      <div className="wrapper-events-listing">
                        <div
                          className={`events-listing ${
                            this.state.sortByFlag ? 'open' : ''
                          }`}
                        >
                          <div className="event-listing-sorting">
                            <SortBy
                              defaultSortType="Promotion Date - Earliest to Latest"
                              handleFilters={this.handleFilters}
                              sortList={this.tabsSort.sortList}
                              sortByFlag={this.state.sortByFlag}
                              promotion
                              filteredSortType="title"
                              filteredSortOrder={
                                Utilities.mobilecheck()
                                  ? this.state.localsortBy
                                  : this.state.sortBy
                              }
                              clearSortFilters={this.clearSortFilters}
                            >
                              <div className="fixed-buttons hide-inner">
                                <a
                                  onClick={() => {
                                    this.toggleSortBy();
                                  }}
                                  className="close"
                                >
                                  Close
                                </a>

                                <a
                                  onClick={() => {
                                    this.handleSortApply();
                                  }}
                                  className="apply"
                                >
                                  Apply
                                </a>
                              </div>
                            </SortBy>
                          </div>
                        </div>
                      </div>
                      <div className="fixed-buttons-events">
                        <a
                          className="sortby"
                          onClick={() => {
                            this.toggleSortBy();
                          }}
                        >
                          sort by
                          <img src={sortbyIcon} alt="icon" />
                        </a>
                      </div>
                    </>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PromotionsMain;
import React, { useState, useEffect } from "react";
import BaseContext from "../../../contexts";
import { RiSearchLine } from "react-icons/ri";
import { FiSliders } from "react-icons/fi";
import queryString from 'query-string';
import {
  Grid,
  Stack,
  InputAdornment,
  Drawer
} from "@mui/material";

import { CustomInput } from "../../attractionResult/components/CustomInput";
import { Constants, Utilities } from '../../../utils';
import HomeService from '../../../services/HomeService';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

import {
  FilterMobile,
  FilterDesktop,
  SortSection
} from '../../eventFilter';

const FilterContainer = (props) => {
  let qString = queryString.parse(window.location.search);
  let isSearch = qString.s && qString.s !== "" ? true : false; 
  const base = React.useContext(BaseContext);
  const { isMobile } = useDeviceDetect();
  const [filterToggle, setFilterToggle] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [categories, setCategories] = useState({});
  const [accessibility, setaccessibility] = useState([]);
  const [search, setSearch] = useState({
    value:''
  });
  const { smallDown } = useCustomMediaQuery();

  
  const handleMobileFilterbtn = () => {
    setFilterToggle(filterToggle ? false : true);
  }

  const handleOnSearchChange = (e) => {
    const val = e.currentTarget.value;
    setSearch({
      ...search,
      value: val
    });
    props.updateBase && props.updateBase({...base.data, search: val});
  }

  const handleOnSearchBlur = (e) => {
    const val = e.currentTarget.value;
    let q = queryString.parse(window.location.search);
    q.s = val; // change url param val
    window.history.pushState(null, false, "/events?"+queryString.stringify(q));
  }

  const handleCallBackFromDesktopFilter = (data={}) => {
    props.callbackFromDesktopFilter && props.callbackFromDesktopFilter(data);
  }

  useEffect(()=>{
    setSearch({
      ...search,
      value: qString.s ? qString.s : ''
    });
    props.updateBase && props.updateBase({...base.data, search: qString.s});
  },[qString.s]);

  useEffect(()=>{
    const accessdata=[
      {"id":0,"name":"Wheelchair Accessible"},
      {"id":1,"name":"Close Captioning"},
      {"id":2,"name":"Close Captioning Disabled"},
      {"id":3,"name":"Assistive Listening "},
      {"id":4,"name":"Sign Languages"},
      {"id":5,"name":"Audio Description"},
      {"id":6,"name":"Relaxed Performance"}];

      setaccessibility(accessdata);
      let param = { client: Constants.CLIENT };
      HomeService.getGenre(param).then((res) => {
        setCategories(Utilities.rearrangeGenre(res.data.data));
        setOpenFilter(isSearch ? false : true);
      });
  },[]);
   
  return (
    <>
      <Grid item xs={12} className={"stix"} sx={{marginTop: 3}}>
        <div className={'stix-filter-container'} >
            <p style={{marginTop: 5}}>{isSearch ? "Search Results" : "Events"}</p>
            <ul>
              {isSearch && <li>
                  {!smallDown && <CustomInput
                    autoComplete="off"
                    placeholder="Search..."
                    type="search"
                    value={search.value}
                    onChange={handleOnSearchChange}
                    onBlur={handleOnSearchBlur}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RiSearchLine size={24} style={{ color: "#CCCCCC" }} />
                        </InputAdornment>
                      ),
                      style: {
                        color: "text.secondary",
                        borderColor: "#CCCCCC",
                        borderRadius: "10px",
                        minWidth: "240px",
                        height: "40px",
                        backgroundColor: "#fff",
                      },
                    }}
                  />}
              </li>}
              <li>
                  <Stack
                    sx={{ cursor: "pointer" }}
                    className={'stix-filter-icon'}
                    onClick={() => isMobile ? handleMobileFilterbtn() : setOpenFilter(!openFilter)}
                    >
                    <FiSliders
                      size={24}
                      color={!openFilter ? "#B0B0B0" : "#0082D6"}
                    />
                  </Stack>
              </li>
              <li>
                  <Stack sx={{ cursor: "pointer" }}>
                    {/* <img src="../imgs/sort-by.svg" alt="" /> */}
                    <SortSection callBack={props.sortCallBack}/>
                  </Stack>
              </li>
            </ul>
        </div>
      </Grid>
      {openFilter && !smallDown &&( <FilterDesktop 
         // callbackWithFilter={props.callbackWithFilter}
          setBulkForFilter={props.setBulkForFilter}
          forFilter={props.forFilter}
          searchVal={search.value}
          category={categories}
          dateValue={dateValue}
          priceRange={false}
          accessibility={accessibility}
          moreFilters={false}
          onCatChange={props.onCatChange}
          filterCallBack={handleCallBackFromDesktopFilter}
          onDrawerClose={()=> setFilterToggle(false)}
          />)}
        <Drawer
            anchor={'top'}
            open={filterToggle}
            onClose={() => setFilterToggle(false)}
          >
        <FilterMobile 
          callbackWithFilter={props.callbackWithFilter}
          setBulkForFilter={props.setBulkForFilter}
          forFilter={props.forFilter}
          searchVal={search.value}
          category={categories}
          dateValue={dateValue}
          priceRange={false}
          accessibility={accessibility}
          moreFilters={false}
          onDrawerClose={()=> setFilterToggle(false)}
          isMobile={isMobile}
          />
      </Drawer>
    </>
  );
};

export default FilterContainer; 
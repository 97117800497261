import React, { Fragment, useState, useEffect } from "react";
import EventsService from "../../../services/EventsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import {
  TextField,
  DialogContent,
  FormHelperText,
  Typography,
  Stack,
  FormControl,
  Dialog,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ReCAPTCHA from 'react-google-recaptcha';
import { Constants } from "../../../utils";
export const Organizer = (props = {}) => {
  const { smallDown } = useCustomMediaQuery();
  const [patronName, setpatronName] = useState("");
  const [patronEmail, setpatronEmail] = useState("");
  const [patronContact, setpatronContact] = useState("");
  const [ patronMessage, setpatronMessage] = useState("");
  const { eventicc } = props;
  const [submitResponse, setSubmitResponse] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [divFlg, setDivFlg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [headerErr, setHeaderErr] = useState("");
  const [submit, setSubmit] = useState(false);
  const closeBox = props.closeBox;
  const [captcha, setCaptcha] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
     const validRegex =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!validRegex.test(patronEmail)) {
      setpatronEmail("");
      setErrMsg("Invalid Email") 
    } 
   else if (patronName && patronEmail && patronContact && patronMessage && captcha) {
      setErrMsg("");
      setLoading(true);
      const data = {
        patronName,
        patronEmail,
        patronContact,
        patronMessage,
        iccCode: eventicc,
      };
     submitForm(data);
    } else {
      setErrMsg("Please complete all fields");
    }
  };
  const handleCaptcha = captcha => {
    setCaptcha(captcha);
  };
  const submitForm = (data) => {    
    EventsService.oraganizerformSubmission(data)
      .then((res) => {
        if (res ) {
          setTimeout(() => {
            setLoading(false);
            setSuccessMsg(res.data.message);
            setSubmit(true);
            setpatronName("");
            setpatronEmail("");
            setpatronContact("");
            setpatronMessage("");
            setDivFlg(true);
            setError(false);
            setErrMsg("");
            setCaptcha('');
          }, 1000);
        }
      })
      .catch((err) => {
        if (err && err.res === null) {
          setHeaderErr("Something went wrong ");
          setLoading(false);
        } else if (err && !err.res.data.message) {
          setError(true);
          setErrMsg("");
          setTimeout(() => {
            setSubmitResponse(err.res);
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setHeaderErr(err.res.data.message);
            setLoading(false);
          }, 1000);
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target; 
    let val = value.trim();
    switch (name) {
      case "patronName":       
        if (val.length > 0) {
          setpatronName(value);
        } else {
          setpatronName("");
        }
        break;
      case "patronEmail": 
      if (val.length > 0) {
        setpatronEmail(value);
      } else {
        setpatronEmail("");
      }
        break;
      case "patronContact":
        const contact_numberregEx = /^[0-9\b]+$/g;
        if (!contact_numberregEx.test(value) && value !== "") {
          // setcontact_number('');
        } else {
          setpatronContact(value);
        }
          break;
        case "patronMessage": 
         val = value.trim();
        if (val.length > 0) {
          setpatronMessage(value);
        } else {
          setpatronMessage("");
        }
          break;
      default:
        return;
    }

    setErrMsg("");
    setSubmitResponse([]);
    setLoading(false);
    setError(false);
    setHeaderErr("");
    setSuccessMsg("");
  };
 
  return (
    <>
      <DialogContent>
        <Stack>       
          {!divFlg  ? (
            <form >
              <Stack mt={smallDown?4:3} spacing={3}>
                <FormControl fullWidth>
                  <TextField                   
                    label="Full Name"
                    autoComplete="off"
                    name="patronName"
                    id="patronName"                   
                    type="text"
                    value={patronName}                   
                    onChange={handleChange}
                    variant="outlined"
                    required
                    helperText={
                      errMsg &&
                      !patronName && (
                        <FormHelperText
                          error={errMsg && !patronName}
                          sx={{ color: "red" }}
                        >
                          Please enter name
                        </FormHelperText>
                      )
                    }
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    name="patronEmail" autoComplete="off"
                    label="Email"
                    fullWidth={!smallDown}
                    id="patronEmail"
                    type="email"
                    value={patronEmail}                   
                    onChange={handleChange}  required
                    variant="outlined"
                      helperText={
                      errMsg &&
                      !patronEmail && (
                        <FormHelperText
                          error={errMsg && !patronEmail}
                          sx={{ color: "red" }}
                        >
                          Please enter valid email 
                        </FormHelperText>
                      )
                    }
                  />                  
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    name="patronContact" 
                    label="Mobile Number"
                    fullWidth={!smallDown} autoComplete="off"
                    id="patronContact"
                    value={patronContact}
                    onChange={handleChange}   required
                    variant="outlined"
                      helperText={
                      errMsg &&
                      !patronContact && (
                        <FormHelperText
                          error={errMsg && !patronContact}
                          sx={{ color: "red" }}
                        >
                          Please enter mobile number
                        </FormHelperText>
                      )
                    }                 
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    name="patronMessage"
                    multiline
                    rows={3}
                    maxRows={5} 
                    label="Message"
                    fullWidth={!smallDown} autoComplete="off"
                    id="patronMessage"
                    value={patronMessage}   required
                    onChange={handleChange}
                    variant="outlined"   
                      helperText={
                      errMsg &&
                      !patronMessage && (
                        <FormHelperText
                          error={errMsg && !patronMessage}
                          sx={{ color: "red" }}
                        >
                          Please enter message
                        </FormHelperText>
                      )
                    }               
                  />
                </FormControl>               
                <div className={errMsg && !captcha ? 'form-group err' : 'form-group'}>        
                            <ReCAPTCHA
                              sitekey={Constants.SITE_KEY}
                              name="captcha"
                              onChange={handleCaptcha}
                            /> 
                             </div>
                            {errMsg && !captcha ? (
                              <span className="error-msg">
                                Please mark the security check
                              </span>
                            ): null}
       
                {submitResponse &&
                  typeof submitResponse === "object" &&
                  submitResponse.map((elem, index) => {
                    return (
                      <h5
                        key={index}
                        className={error ? "text-danger" : "text-success"}
                      >
                        {elem}
                      </h5>
                    );
                  })}
                {typeof submitResponse === "string" && (
                  <h5 className={error ? "text-danger" : "text-success"}>
                    {submitResponse}
                  </h5>
                )}
                {headerErr && <h5 className="text-danger">{headerErr}</h5>}
              </Stack>
            </form>
          ) : (
            successMsg && <Typography variant="h5"  align="center">{successMsg}</Typography>
          )}
        </Stack>
      </DialogContent>
      {!divFlg  && (
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <LoadingButton
            disableElevation
            variant="contained"
            loading={loading}
            disabled={loading}
            loadingIndicator="Submitting.."
            onClick={handleSubmit}
            sx={{ width: 100, height: 38 }}
            type="submit"
          >
            {loading?'Submitting..':'Submit'}
          </LoadingButton>
        </DialogActions>
      )}
    </>
  );
};
import React from 'react';
import BaseContext from '../../contexts';
import {AllFunctions} from '../../helper/functions';

export const Footer = (props) => {    

    return (
    <BaseContext.Consumer>
        {data => (
            <FooterContent {...props} data={data}/>
        )}
    </BaseContext.Consumer>
    );
}

const FooterContent = (props) => {
    const FooterTemplate = AllFunctions.loadTemplate(props.env).Footer;
    return (<>
        <FooterTemplate {...props}/>
    </>)
}

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import SliderBanner from '../SliderBanner';
import '../../bootstrap/bootstrap.scss';
import '../../bootstrap/bootstrap-grid.scss';
import './style.scss';
import DefaultUser from '../../../assets/images/user.jpg';
import ExploreContainer from '../../../components/ExploreContainer';
import BreadcrumbSlug from '../../../components/BreadcrumbSlug';
import SocialShare from '../../../components/SocialShare';
import ShareIcon from '../../../assets/images/share-icon.svg';
import ExploreService from '../../../services/ExploreService';
import FeaturedArticles from './FeaturedArticle';
import Image from '../../../components/Image';
import { Utilities, Constants } from '../../../utils';

const pathNameList = ["previewuatcdn", "previewlink"];
const hostNameList = [
  "previewuatcdn.sistic.com.sg",
  "previewlink.sistic.com.sg",
  "https://previewuatcdn.sistic.com.sg",
  "https://previewlink.sistic.com.sg",
];

const Article = props => {
  const [articleData, setArticleData] = useState(null);
  const [showSocialShare, setShowSocialShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const descriptionRef = useRef();
  const handleSocialShare = () => {
    setShowSocialShare(!showSocialShare);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setArticleData(null);
    setLoading(true);
    getArticle();
  }, [params.id]);

  const getArticle = () => {
    const param = {
      id: params.id
    };
    let addPreviewInUrl = false;
    if ( pathNameList.includes(window.location.pathname) || hostNameList.includes(window.location.hostname) ) {
      addPreviewInUrl = true;
    }
    ExploreService.getArticle(param, addPreviewInUrl)
      .then(res => {
        setArticleData(res.data.data[0]);
        setLoading(false);
        if (window && window.instgrm) {
          window.instgrm.Embeds.process();
        }
      })
      .catch(err => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (descriptionRef.current) {
      let captureElement = document.getElementsByTagName('iframe');
      let elementLength = captureElement.length;
      if (captureElement && elementLength) {
        for (let i = 0; i < elementLength; i++) {
          captureElement[i].parentElement.className = 'iframe-wrapper';
        }
      }
    }
  }, [descriptionRef.current]);
  return (
    <section className="articledetail-wrapper">

      {articleData && articleData.image && (
        <SliderBanner data={articleData.image} />
      )}
      <div className="acticle">
        <div className="container-fluid custom-container">
          <div className="offset-lg-2 breadcrumb-category-group">
            <div className="row">
              <div className="breadcrumb-category-group col-md-10">
                <div className="breadcrumbs-section">
                  <BreadcrumbSlug
                    breadcrumbSlug={[
                      { path: '/', title: 'Home' },
                      {
                        path: '/explore',
                        title: 'Explore'
                      },
                      {
                        path: '/article',
                        title: 'Article'
                      }
                    ]}
                  />
                  <span className="share" onClick={handleSocialShare}>
                    <img src={ShareIcon} alt="share-icon" />
                    <SocialShare
                      shareUrl={
                        typeof window == 'undefined'
                          ? Constants.WEBSITE_URL
                          : window.location.href
                      }
                      showSocialShare={showSocialShare}
                      title={articleData && articleData.title}
                    />
                  </span>
                </div>
                {(articleData && articleData.tags) && (
                  <ul className="category-section">
                    {articleData.tags.map(tag => (
                      <li key={tag}>{tag}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-2 pl-lg-0">
              <div className="artist-block">
                {articleData && (
                  <Image
                    src={articleData.author_image || DefaultUser}
                    alt="Artist"
                  />
                )}
                <div className="artist-content">
                  {articleData && <h4>{articleData.author_name}</h4>}
                  {articleData && <span>{articleData.author_posted_date}</span>}
                </div>
              </div>
            </div>

            <div className="col-xs-8 col-lg-8 mb-5 col-lg-offset-4">
              <div className="article-content">
                {articleData && articleData.title && (
                  <h2>{articleData.title}</h2>
                )}
                {(articleData && articleData.description) && (
                  <div
                    className="sub-text"
                    ref={descriptionRef}
                    dangerouslySetInnerHTML={{
                      __html: articleData.description.replace(
                        /<a href/g,
                        '<a target="_blank" href'
                      )
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="article-featured featured-events">
        <div className="container-fluid custom-container">
          <div class="section-top-wrapper">
            {articleData && articleData.featured_articles.length ? (
              <h2>Featured in Article</h2>
            ) : null}
          </div>
          <FeaturedArticles
            featuredArticles={articleData && articleData.featured_articles}
            articleDataIsNull={articleData === null}
            history={props.history}
          />
        </div>
      </div>
      <div className="article-explore">
        <ExploreContainer
          article
          heading="Read Also"
          excludeId={props && props.match && params.id}
        />
      </div>
    </section>
  );
};

export default Article;

import React, { useEffect, useState } from "react";
import { AllFunctions } from "../../../helper";
import {
    Box,
    Button,
    Container,
    Divider,
    DialogTitle,
    Dialog,
    DialogContent,
    Grid,
    Modal,
    Stack,
    Typography,
    IconButton,
  } from "@mui/material";
  import {
    Close,
  } from "@mui/icons-material";

export const PopUpMessage = (props) => {
  const [open, setOpen] = useState(false);
  const [smallDown, setSmallDown] = useState(false);

  const closepopup = () => setOpen(false);

  useEffect(()=>{
    props.isOpen && setOpen(props.isOpen);
  },[props.isOpen]);

  useEffect(()=>{
    props.IsSmallDown && setSmallDown(props.IsSmallDown ? true : false);
  },[props.IsSmallDown]);

  return (<>
    <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          PaperProps={{ style: { borderRadius: 20 } }}
          onClose={closepopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle sx={{ pt: smallDown ? 4 : 3 }}>
            <Typography
              variant="h2"
              align="center"
              fontSize={smallDown ? "1rem" : "1rem"}
              fontWeight={600}
            >
              {props.title && props.title}
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                right: smallDown ? "0" : "10px",
                top: smallDown ? "0" : "10px",
              }}
              onClick={closepopup}
              aria-label="close"
            >
              <Close />
            </IconButton>{" "}
          </DialogTitle>
          <Typography
            variant="h2"
            align="left"
            fontSize={smallDown ? "1rem" : "1rem"}
            //fontWeight={600}
            sx={{ margin: "0 30px 20px 15px" }}
          >
            <p
              className={"p"}
              dangerouslySetInnerHTML={{
                __html: props.description &&
                  AllFunctions.addTargetToHref(props.description),
              }}
            ></p>
          </Typography>
        </Dialog>
  </>
  );
};


import React, { useState, useEffect } from "react";
import AdvertisementService from "../../services/AdvertisementService";
import { useCustomWidth } from "../CustomHooks";

export const Leaderboard = (props = {}) => {
  const { isLandingPage } = props;
  const customWidth = useCustomWidth();
  const [data, setData] = useState({});
  
  const params = {
    client: 1,
    page: 5,
    first: 0,
    limit: 1,
  };

  useEffect(() => {
    AdvertisementService.fetchLeaderboardImage(params).then((res) => {
      setData(res.data[0]);
    });
  }, []);

  return (
    isLandingPage &&
    data &&
    Object.keys(data).length > 0 && (
      <div className="leaderboard-container">
        <a href={data.navigation_link && data.navigation_link}>
          <img
            id="leaderboard"
            src={
              customWidth < 1080
                ? data.full_image_mobile
                : data.full_image
            }
            onError={(currentTarget) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/imgs/default-banner-1920.png"; // default fallback img
            }}
            alt={data.alt_text ? data.alt_text : data.title}
          />
        </a>
      </div>
    )
  );
};

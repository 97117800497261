import React, { useState, useRef, useEffect, memo, Fragment} from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import HomeService from '../../services/HomeService';
import { Constants } from '../../utils';
import StructureData from './structuredhtml';
function MetaData({ location, data }) {

  const [metaData, setMetaData] = useState(
    (data &&
      data.response &&
      data.response.metaData &&
      data.response.metaData.data) ||
      {}
  );

  let current_location = location?.pathname ? Constants.WEBSITE_URL+location?.pathname : Constants.WEBSITE_URL;

  //without http/https concatenated url 
  const replaceUrl = () =>{
    var els = document.querySelectorAll('p a[href^="www"]');
    var split_last_index = current_location.split('/').pop();
    var replace_location = current_location;

    //alert(split_last_index);
    if(split_last_index != ""){
      replace_location = replace_location.replace(split_last_index,'');
    }
    //alert(replace_location);
    for (var i = 0, l = els.length; i < l; i++) {
      var el = els[i].href;
      var replace_url = el.replace(/www/gi, 'http://www');
      els[i].href = replace_url.replace(replace_location,'');
    }
  };

  useEffect(() => {
    //console.log('meta_location',location);
    if (window.__INITIAL_DATA__ && window.__INITIAL_DATA__.metaData) {
      //console.log('window__INITIAL_DATA__metaData',window.__INITIAL_DATA__);
      //console.log('window__INITIAL_DATA__metaData',Object.keys(window.__INITIAL_DATA__.metaData).length);
      setMetaData(
        (window.__INITIAL_DATA__.metaData &&
          window.__INITIAL_DATA__.metaData.data) ||
          {}
      );

      //without http/https concatenated url 
      setTimeout(function(){ 
        replaceUrl();
      }, 1500);

      delete window.__INITIAL_DATA__.metaData;
    } else {
      //console.log('window__INITIAL_DATA__metaData','else');
      //Call Meta data API
      getMetaData(location?.pathname).then(response => {
        //console.log('response_data', response.data);

        if (response && response.data && response.data.data) {
          setMetaData(response.data.data || {});

          //without http/https concatenated url 
          setTimeout(function(){ 
            replaceUrl();
          }, 1500);
        }
      });
    }
  }, [location?.pathname]);

  useEffect(() => {

    if (metaData && metaData.tracking_code) {
      import('postscribe').then(module => {
        if (typeof module.default === 'function') {
          if(metaData.insert_tracking_code==='head'){
            module.default('#tracking-code', metaData.tracking_code);
            }
            else {
            module.default('#tracking-div', metaData.tracking_code);
            }
        }        
      });
    }
    if (metaData && metaData.tt_code) {
      import('postscribe').then(module => {
        if (typeof module.default === 'function') {
          module.default('#tt-div', metaData.tt_code);
        }
      });
    }
    return () => {
      const trackingDiv = document.getElementById('tracking-div');
      if (trackingDiv) {
        trackingDiv.innerHTML = '';
      }
      const ttDiv = document.getElementById('tt-div');
      if (ttDiv) {
        ttDiv.innerHTML = '';
      }
    };
    
  }, [metaData]);

  const getMetaData = url => {
    let payload = url;
    if (url && typeof url == 'string') {
      let pathArr = url.split('/');
      if (pathArr.length > 1 && pathArr[1] === 'promotions' && pathArr[2]) {
        if (pathArr[2] && pathArr[3]) {
          payload = pathArr[3];
          return HomeService.getMetadata(payload);
        }
      } else if (
        pathArr.length > 1 &&
        (pathArr[1] === 'venues' || pathArr[1] === 'agents')
      ) {
        const urlParams = new URLSearchParams(window.location.search);
        const idParam = urlParams.get('id');
        if (idParam) {
          return HomeService.getMetadata(idParam);
        }
      }
    }
    return HomeService.getMetadata(url && url.substr(1));
  };


  const titleContent = metaData && metaData.title && metaData.title.replace(/(<([^>]+)>)/gi, '');
  

  var og_image = metaData && metaData.og_image, replace_Image = '', og_image_ext = '';
  if(og_image){
    replace_Image = og_image && og_image.replace('http://34.87.21.17/','https://cmsuat.sistic.com.sg/');
    og_image_ext = og_image.split('.').pop();
    //console.log('og_image_ext',og_image_ext);
  }

  var location_path_name = location?.pathname, split_path, path_length;
  
  if(location_path_name){
    split_path = location_path_name.split('/');
    path_length = split_path.length;
    //console.log('split_path', split_path);
  }
  
 console.log('current_location', current_location);

 var default_mets_desc = 'Connecting people to entertainment, SISTIC is the ticketing service and solution provider behind more than 1000 events every year. The leading provider in Singapore, it sells tickets to events ranging from pop concerts, to musicals, theatre, family entertainment and sports. Readily accessible, ticket buyers can browse for and buy tickets through the SISTIC website, customer hotline +65 6348 5555 and mobile app. Additionally, more than 40 SISTIC Authorised Agents are located throughout Singapore, and also in Malaysia, Indonesia, Taiwan and Vietnam. SISTIC also offers STiX, a web-based and customer-oriented ticketing solution suitable for ticketing companies, event promoters and venues, under a licensing arrangement. STiX is being used for events held at various performance venues in Singapore, Malaysia, Indonesia, Hong Kong and Macau. SISTIC has been providing innovative ticketing services since December 1991 and licensing its ticketing solution STiX since 2003.';
 var default_title = "SISTIC Singapore";


/* ======= */
let splitvalue= metaData.title;
let splitvalueresult=splitvalue ? splitvalue.split("|"):"";
let metatitle;
let url_id=current_location.split("/");
let url_name=url_id[3];
let url_sufix = url_id[2];
if(splitvalueresult[1]==" SISTIC"){
  if(url_name == "promotions"){
    if(splitvalueresult[0] !== "Promotions ")
      metatitle=splitvalueresult[0]+" | Promotions"+" | SISTIC";
    else{
      metatitle=splitvalueresult[0]+" | SISTIC";
    }
  }
  else if(url_name == "explore"){
    if(url_id[4] == 3){
      metatitle=splitvalueresult[0]+" | Quiz"+" | SISTIC";
    }
    else if(url_id[4] == 4){
      metatitle=splitvalueresult[0]+" | Survey"+" | SISTIC";
    }
    else {
      metatitle=splitvalueresult[0]+" | Explore"+" | SISTIC";
    }
  }else{
    metatitle=splitvalueresult[0]+" | SISTIC"
  }
}else{
  if(url_name == "promotions"){
    if(splitvalueresult[0] !== "Promotions "){
    metatitle=splitvalueresult[0]+" | Promotions"+" | SISTIC";
    }
    else{
      metatitle=splitvalueresult[0]+" | SISTIC";
    }
  }
  else if(url_name == "explore"){
    if(url_id[4] == 3){
      metatitle=splitvalueresult[0]+" | Quiz"+" | SISTIC";
    }
    else if(url_id[4] == 4){
      metatitle=splitvalueresult[0]+" | Survey"+" | SISTIC";
    }
    else{
      metatitle=splitvalueresult[0]+" | Explore"+" | SISTIC";
    }
    
  }else{
    metatitle=splitvalueresult[0]+" | SISTIC"
  }
}


let twittertitle,ogtitleresult, getTwitterTitle = metaData.title;
if(metaData.twitter_cards_title){
  getTwitterTitle = metaData.twitter_cards_title
}
let twiitersplitvalue = getTwitterTitle;
let twittersplitvalueresult=twiitersplitvalue ? twiitersplitvalue.split("|"):"";
if(twittersplitvalueresult[1]==" SISTIC"){
  if(url_name == "promotions"){
    if(twittersplitvalueresult[0] !== "Promotions ")
    twittertitle=twittersplitvalueresult[0]+" | Promotions"+" | SISTIC";
    else{
      twittertitle=twittersplitvalueresult[0]+" | SISTIC";
    }
  }
  else if(url_name == "explore"){
    if(url_id[4] == 3){
      twittertitle=twittersplitvalueresult[0]+" | Quiz"+" | SISTIC";
    }
    else if(url_id[4] == 4){
      twittertitle=twittersplitvalueresult[0]+" | Survey"+" | SISTIC";
    }
    else {
      twittertitle=twittersplitvalueresult[0]+" | Explore"+" | SISTIC";
    }
  }else{
    twittertitle=twittersplitvalueresult[0]+" | SISTIC"
  }
}else{
  if(url_name == "promotions"){
    if(twittersplitvalueresult[0] !== "Promotions "){
      twittertitle=twittersplitvalueresult[0]+" | Promotions"+" | SISTIC";
    }
    else{
      twittertitle=twittersplitvalueresult[0]+" | SISTIC";
    }
  }
  else if(url_name == "explore"){
    if(url_id[4] == 3){
      twittertitle=twittersplitvalueresult[0]+" | Quiz"+" | SISTIC";
    }
    else if(url_id[4] == 4){
      twittertitle=twittersplitvalueresult[0]+" | Survey"+" | SISTIC";
    }
    else{
      twittertitle=twittersplitvalueresult[0]+" | Explore"+" | SISTIC";
    }
    
  }else{
    twittertitle=twittersplitvalueresult[0]+" | SISTIC"
  }
}


  var getOGTitle = metaData.title;
  if(metaData.og_title){
    getOGTitle = metaData.og_title;
  }

  let ogsplitvalue=getOGTitle;
  let ogsplitvalueresult=ogsplitvalue ? ogsplitvalue.split("|"):"";
  if(ogsplitvalueresult[1]==" SISTIC"){
    if(url_name == "promotions"){
      if(ogsplitvalueresult[0] !== "Promotions ")
      ogtitleresult=ogsplitvalueresult[0]+" | Promotions"+" | SISTIC";
      else{
        ogtitleresult=ogsplitvalueresult[0]+" | SISTIC";
      }
    }
    else if(url_name == "explore"){
      if(url_id[4] == 3){
        ogtitleresult=ogsplitvalueresult[0]+" | Quiz"+" | SISTIC";
      }
      else if(url_id[4] == 4){
        ogtitleresult=ogsplitvalueresult[0]+" | Survey"+" | SISTIC";
      }
      else {
        ogtitleresult=ogsplitvalueresult[0]+" | Explore"+" | SISTIC";
      }
    }else{
      ogtitleresult=ogsplitvalueresult[0]+" | SISTIC"
    }
  }else{
    if(url_name == "promotions"){
      if(ogsplitvalueresult[0] !== "Promotions "){
        ogtitleresult=ogsplitvalueresult[0]+" | Promotions"+" | SISTIC";
      }
      else{
        ogtitleresult=ogsplitvalueresult[0]+" | SISTIC";
      }
    }
    else if(url_name == "explore"){
      if(url_id[4] == 3){
        ogtitleresult=ogsplitvalueresult[0]+" | Quiz"+" | SISTIC";
      }
      else if(url_id[4] == 4){
        ogtitleresult=ogsplitvalueresult[0]+" | Survey"+" | SISTIC";
      }
      else{
        ogtitleresult=ogsplitvalueresult[0]+" | Explore"+" | SISTIC";
      }
      
    }else{
      ogtitleresult=ogsplitvalueresult[0]+" | SISTIC"
    }
  }


  return (
    <HelmetProvider >
    <div>
      {/* {metaData.schema_event_type && metaData.schema_event_type==="Event"?
    <StructureData data={metaData}></StructureData>  :""} */}
    <Helmet>
      <meta charSet="utf-8" />
      { location_path_name ? 
          (split_path.length >= 2 || split_path[1]) ? metaData.title && (
            <title>{metatitle}</title>
          ) : (
            <title>{ metaData.title && metaData.title!=undefined?metaData.title: default_title}</title>
            // <title>{default_title}</title>
          ) 
        : (
          <title>{ metaData.title && metaData.title!=undefined?metaData.title: default_title}</title>
          // <title>{default_title}</title>
        )
      }
      {/* {metaData.title ? (
        <title>{titleContent}</title>
      ) : (
        <title>SISTIC Singapore</title>
      )} */}

      {metaData.fb_app_id ? (
        <meta property="fb:app_id" content={metaData.fb_app_id} />
      ):(
        <meta property="fb:app_id" content={Constants.FB_APP_ID} />
      )}

      { location_path_name && (location_path_name === "/content-restricted" || location_path_name === "content-restricted") ? (
          <meta name="robots" content="noindex" />
        ) : (
          metaData.robots ? (
              <meta name="robots" content={metaData.robots} />
          ) : (
              <meta name="robots" content="index, follow" />
          )
          
        )
      }
      { location_path_name && (location_path_name === "/content-restricted" || location_path_name === "content-restricted") ? (
          <meta name="googlebot" content="noindex" />
        ) : ('')
      }
      { url_sufix && (url_sufix === "previewuatcdn.sistic.com.sg" || url_sufix === "previewuatcdn.sistic.com.sg") ? (
        <meta name="robots" content="noindex" />
      ) : (
          metaData.robots ? (
            <meta name="robots" content={metaData.robots} />
          ) : (
              <meta name="robots" content="index, follow" />
            )

        )
      }
      { url_sufix && (url_sufix === "previewuatcdn.sistic.com.sg" || url_sufix === "previewuatcdn.sistic.com.sg") ? (
        <meta name="googlebot" content="noindex" />
      ) : ('')
      }
      {metaData.description ? (
        <meta name="description" content={metaData.description} />
      ) : (
        <meta
          name="description"
          content={default_mets_desc}
        />
      )}

      {metaData.article_author && (
        <meta name="author" content={metaData.article_author} />
      )}
      {metaData.product_price_currency && (
        <meta name="currency" content={metaData.product_price_currency} />
      )}
      {metaData.product_price_amount && (
        <meta name="price" content={metaData.product_price_amount} />
      )}
      {metaData.generator && (
        <meta name="generator" content={metaData.generator} />
      )}
      {metaData.geo_position && (
        <meta name="geo.position" content={metaData.geo_position} />
      )}
      {metaData.geo_region && (
        <meta name="geo.region" content={metaData.geo_region} />
      )}
      {metaData.geo_placename && (
        <meta name="geo.placename" content={metaData.geo_placename} />
      )}
      {metaData.icbm && (
        <meta name="ICBM" content={metaData.icbm} />
      )}
      {metaData.article_publisher && (
        <meta name="publisher" content={metaData.article_publisher} />
      )}
      {metaData.abstract && (
        <meta name="abstract" content={metaData.abstract} />
      )}
      {metaData.shortlink && (
        <link rel="shortlink" href={metaData.shortlink} />
      )}
      {metaData.news_keywords && (
        <meta name="news_keywords" content={metaData.news_keywords} />
      )}
      {metaData.next && (
        <link rel="next" href={metaData.next} /> 
      )}
      {metaData.prev && (
        <link rel="prev" href={metaData.prev} />
      )}
      {metaData.standout && (
        <link rel="standout" href={metaData.standout} />
      )}
      {metaData.article_section && (
        <meta name="article_section" content={metaData.article_section} />
      )}
      {metaData.generator && (
        <meta name="generator" content={metaData.generator} />
      )}
      {metaData.image_src && (
        <link rel="image_src" href={metaData.image_src} />
      )}
      {metaData.original_source && (
        <meta name="original_source" content={metaData.original_source} />
      )}
      {metaData.rating && (
        <meta name="rating" content={metaData.rating} />
      )}
      {metaData.referrer && (
        <meta name="referrer" content={metaData.referrer} />
      )}
      {metaData.refresh && (
        <meta http-equiv="refresh" content={metaData.refresh} />
      )}
      {metaData.set_cookie && (
        <meta http-equiv="set-cookie" content={metaData.set_cookie}></meta>
      )}
      {metaData.revisit_after && (
        <meta name="revisit-after" content={metaData.revisit_after}></meta>
      )}
      {metaData.cache_control && (
        <meta http-equiv="cache-control" content={metaData.cache_control}></meta>
      )}
      {metaData.expires && (
        <meta http-equiv="expires" content={metaData.expires}></meta>
      )}
      {metaData.pragma && (
        <meta http-equiv="pragma" content={metaData.pragma}></meta>
      )}
      {metaData.pragma && (
        <meta http-equiv="pragma" content={metaData.pragma}></meta>
      )}
      {metaData.article_tag && (
        <meta name="article_tag" content={metaData.article_tag} />
      )}
      {metaData.content_language && (
        <meta name="content_language" content={metaData.content_language} />
      )}
      {metaData.keywords && (
        <meta name="keywords" content={metaData.keywords} />
      )}
      {metaData.canonical_url && (
        <link rel="canonical" href={metaData.canonical_url} />
      )}
      {metaData.image_src ? (
        <link rel="preload" href={metaData.image_src} as="image" />
      ):(
        <link rel="preload" href={replace_Image} as="image" />
      )}
      {metaData.og_determiner && (
        <meta property="og:determiner" content={metaData.og_determiner} />
      )}
      {metaData.og_site_name ? (
        <meta property="og:site_name" content={metaData.og_site_name} />
      ) : (
        <meta property="og:site_name" content={metaData.title ? metaData.title +" | "+default_title : default_title} />
      )}
      {metaData.og_title ? (
        <meta property="og:title" content={ogtitleresult} />
      ):(
        <meta property="og:title" content={metaData.title ? metatitle : default_title } />
      )}
      {metaData.og_type ? (
        <meta property="og:type" content={metaData.og_type} />
      ):(
        <meta property="og:type" content={'article'} />
      )}
      {metaData.og_url ? (
        <meta property="og:url" content={metaData.og_url} />
      ):(
        <meta property="og:url" content={current_location} />
      )}
      {metaData.og_description ? (
        <meta property="og:description" content={metaData.og_description} />
      ) : (
        <meta property="og:description" content={metaData.description ? metaData.description : default_mets_desc} />
      )}
      {metaData.og_image && (
        <meta property="og:image" content={replace_Image} />
      )}
      {metaData.og_image && metaData.og_image_width && (
        <meta property="og:image:width" content={metaData.og_image_width} />
      )}
      {metaData.og_image && metaData.og_image_height && (
        <meta property="og:image:height" content={metaData.og_image_height} />
      )}
      {metaData.og_image && metaData.og_image_type ? (
        <meta property="og:image:type" content={metaData.og_image_type} />
      ) : (
        <meta property="og:image:type" content={og_image_ext && "image/"+og_image_ext}/>
      )}
      {metaData.og_video && (
        <meta property="og:video" content={metaData.og_video} />
      )}
      {metaData.og_image_url && (
        <meta property="og:image:url" content={metaData.og_image_url} />
      )}
      {metaData.og_image_secure_url && (
        <meta property="og:image:secure_url" content={metaData.og_image_secure_url} />
      )}
      {metaData.og_video_secure_url && (
        <meta property="og:video:secure_url" content={metaData.og_video_secure_url} />
      )}
      {metaData.og_video_type && (
        <meta property="og:video:type" content={metaData.og_video_type} />
      )}
      {metaData.og_video && metaData.og_video_width && (
        <meta property="og:video:width" content={metaData.og_video_width} />
      )}
      {metaData.og_video && metaData.og_video_height && (
        <meta property="og:video:height" content={metaData.og_video_height} />
      )}
      {metaData.og_video_duration && (
        <meta property="video:duration" content={metaData.og_video_duration} />
      )}
      {metaData.og_latitude && (
        <meta property="og:latitude" content={metaData.og_latitude} />
      )}
      {metaData.og_longitude && (
        <meta name="og:longitude" content={metaData.og_longitude}/>
      )}
      {metaData.og_longitude && (
        <meta name="og:longitude" content={metaData.og_longitude}/>
      )}
      {metaData.og_street_address && (
        <meta property="og:street-address" content={metaData.og_street_address} />
      )}
      {metaData.og_locality && (
        <meta property="og:locality" content={metaData.og_locality} />
      )}
      {metaData.og_region && (
        <meta property="og:region" content={metaData.og_region} />
      )}
      {metaData.og_postal_code && (
        <meta property="og:postal-code" content={metaData.og_postal_code} />
      )}
      {metaData.og_country_name && (
        <meta property="og:country-name" content={metaData.og_country_name} />
      )}
      {metaData.og_email && (
        <meta property="og:email" content={metaData.og_email} />
      )}
      {metaData.og_phone_number && (
        <meta property="og:phone_number" content={metaData.og_phone_number} />
      )}
      {metaData.og_fax_number && (
        <meta property="og:fax_number" content={metaData.og_fax_number} />
      )}
      {metaData.og_locale && (
        <meta property="og:locale" content={metaData.og_locale} />
      )}
      {metaData.og_locale_alternative && (
        <meta property="og:locale:alternate" content={metaData.og_locale_alternative} />
      )}
      {metaData.article_published_time && (
        <meta property="article:published_time" content={metaData.article_published_time} />
      )}
      {metaData.article_modified_time && (
        <meta property="article:modified_time" content={metaData.article_modified_time} />
      )}
      {metaData.article_expiration_time && (
        <meta property="article:expiration_time" content={metaData.article_expiration_time} />
      )}
      {metaData.article_expiration_time && (
        <meta property="article:expiration_time" content={metaData.article_expiration_time} />
      )}
      {metaData.book_author && (
        <meta property="book:author" content={metaData.book_author} />
      )}
      {metaData.book_isbn && (
        <meta property="book:isbn" content={metaData.book_isbn} />
      )}
      {metaData.book_release_date && (
        <meta property="book:release_date" content={metaData.book_release_date} />
      )}
      {metaData.book_tag && (
        <meta property="book:tag" content={metaData.book_tag} />
      )}
      
      {metaData.fb_pages && (
        <meta property="og:pages" content={metaData.fb_pages} />
      )}
      {metaData.fb_admins && (
        <meta property="article:author" content={metaData.fb_admins} />
      )}
      {metaData.twitter_cards_creator_id && (
        <meta name="twitter:creatorId"  content={metaData.twitter_cards_creator_id} />
      )}

      {metaData.twitter_cards_creator && (
        <meta name="twitter:creator" content={metaData.twitter_cards_creator} />
      )}

      {metaData.twitter_cards_description ? (
        <meta name="twitter:description" content={metaData.twitter_cards_description} />
      ):(
        <meta name="twitter:description" content={metaData.description ? metaData.description : default_mets_desc} />
      )}
      {metaData.twitter_cards_image ? (
        <meta name="twitter:image" content={metaData.twitter_cards_image} />
      ):(
        <meta name="twitter:image" content={replace_Image} />
      )}
      {metaData.twitter_cards_page_url ? (
        <meta name="twitter:url" content={metaData.twitter_cards_page_url} />
      ):(
        <meta property="twitter:url" content={current_location} />
      )}
      {metaData.twitter_cards_site && (
        <meta name="twitter:site" content={metaData.twitter_cards_site} />
      )}
      {metaData.twitter_cards_site_id && (
        <meta name="twitter:siteId" content={metaData.twitter_cards_site_id} />
      )}
      {metaData.twitter_cards_title ? (
        <meta name="twitter:title" content={twittertitle} />
      ):(
        <meta name="twitter:title" content={metaData.title ? metatitle : default_title} />
      )}
      {metaData.twitter_cards_type ? (
        <meta name="twitter:card" content={metaData.twitter_cards_type} />
      ):(
        <meta name="twitter:card" content='summary' />
      )}
      {metaData.twitter_cards_donottrack && (
        <meta name="twitter:card:donottrack" content={metaData.twitter_cards_donottrack} />
      )}
      {metaData.twitter_cards_image_alt && (
        <meta name="twitter:image:alt" content={metaData.twitter_cards_image_alt} />
      )}
      {metaData.twitter_cards_image_height && (
        <meta name="twitter:image:height" content={metaData.twitter_cards_image_height} />
      )}
      {metaData.twitter_cards_image_width && (
        <meta name="twitter:image:width" content={metaData.twitter_cards_image_width} />
      )}

    </Helmet>
    </div>
    </HelmetProvider>
  );
}

export default memo(MetaData);
import React from 'react';
import './style.scss';
import rightArrow from '../../../../../assets/images/right-arrow.svg';
import Image from '../../../../../components/Image';
import { Link } from 'react-router-dom';
import EventHeading from '../../../../../components/EventHeading';
import Ellipsis from '../../../../../components/Ellipsis';
import { Utilities } from '../../../../../utils';

const Review = ({ reviewData }) => {
  if (
    reviewData === null ||
    reviewData === undefined ||
    reviewData.data === undefined ||
    reviewData.data === null ||
    (reviewData && reviewData.data && reviewData.data.length === 0) ||
    (reviewData && reviewData.data === null) ||
    (reviewData && reviewData.data === undefined)
  ) {
    return null;
  }
  return (
    <div className="reviews-item">
      <div className="section-title">
        <h3>{reviewData && reviewData.name}</h3>
      </div>
      <div className="review-content-wrapper">
        <Link
          to={
            reviewData &&
            reviewData.data &&
            reviewData.data[0] &&
            reviewData.data[0].type === 'multi_show_template'
              ? `/explore/2/${reviewData.data[0].id}`
              : `/explore/1/${reviewData.data &&
                  reviewData.data[0] &&
                  reviewData.data[0].id}`
          }
          className="active-review"
        >
          <div className="review-item-image">
            <Image
              src={reviewData && reviewData.data[0] && reviewData.data[0].image}
              type="Horizontal"
            />
          </div>
          <div className="review-content">
            <EventHeading
              title={
                reviewData && reviewData.data[0] && reviewData.data[0].title
              }
              lines={1}
              height={22}
            />
            <Ellipsis
              title={
                reviewData &&
                reviewData.data &&
                reviewData.data[0] &&
                reviewData.data[0].description
              }
              lines={2}
              height={22}
            />
            {reviewData &&
              reviewData.data &&
              reviewData.data.length > 0 &&
              reviewData.data[0] &&
              reviewData.data[0].author_name && (
                <span className="review-subtext">
                  By{' '}
                  {reviewData.data[0].author_name &&
                    reviewData.data[0].author_name[0].toUpperCase() +
                      reviewData.data[0].author_name.slice(1)}
                </span>
              )}
          </div>
        </Link>
        <div className="all-reviews">
          <div className="review-item-wrapper">
            {reviewData &&
              reviewData.data &&
              reviewData.data
                .slice(1, reviewData.data.length)
                .map(
                  (
                    { image, title, author_name, type, id, description },
                    index
                  ) => {
                    return (
                      <Link
                        to={Utilities.linkAccToType(type, id)}
                        className="review-item"
                        key={index}
                      >
                        <div className="review-item-image">
                          <Image src={image} type="VdoSmall" />
                        </div>
                        <div className="review-content">
                          <EventHeading title={title} lines={1} height={22} />
                          <Ellipsis title={description} lines={1} height={22} />
                          {author_name && (
                            <span className="review-subtext">
                              By{' '}
                              {author_name[0].toUpperCase() +
                                author_name.slice(1)}
                            </span>
                          )}
                        </div>
                      </Link>
                    );
                  }
                )}
          </div>
        </div>
      </div>
      {reviewData && reviewData.name && (
        <Link
          className="more-review"
          to={`/explore/articles?c=${reviewData.id}`}
        >
          More from {reviewData.name} <img src={rightArrow} alt="arrow" />
        </Link>
      )}
    </div>
  );
};

export default Review;

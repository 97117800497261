import React from 'react';
import { Utilities } from '../../../../../utils';
import Image from '../../../../../components/Image';
import Ellipsis from '../../../../../components/Ellipsis';
import EventHeading from '../../../../../components/EventHeading';
import { Link } from 'react-router-dom';
const Item = ({ event, history }) => {
  return (
    <Link to={`/events/${event.code}`}>
      <div className="item">
        <div className="item-wrapper">
          <div className="featured-item-img">
            <div className="item-img">
              <Image
                src={event && event.image}
                className="img-fluid"
                type="Horizontal"
              />
            </div>
            <span
              className={`category ${event &&
                event.primary_genre &&
                Utilities.genreClass(event.primary_genre)}`}
            >
              {event.primary_genre}
            </span>
          </div>
          <div className="featured-desc">
            {event && event.event_date && <p className="featured-event-date" dangerouslySetInnerHTML={{ __html: (event.event_date).replace(/<\/?[^>]+(>|$)/g, "") }}></p>}
            {event && event.title && (
              <EventHeading title={event.title} lines={2} height={20} />
            )}
            {event && event.venue && (
              <Ellipsis title={event.venue} lines={2} height={20} />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Item;


const CMS_DOMAIN = process.env.REACT_APP_CMS_URL ? process.env.REACT_APP_CMS_URL : "https://cms.sistic.com.sg/";

export const APIConfig = Object.freeze({
  port: 3000,
  DOMAIN: process.env.DOMAIN || "sistic.com.sg",
  LOCAL_NODE_URL: process.env.LOCAL_NODE_URL || "http://localhost:3000",
  HOST: "cms.sistic.com.sg",
  API_URL: "",
  REDISURL:
    process.env.REACT_APP_ENV === "production"
      ? "10.221.131.163"
      : "192.168.10.197",
  REDISPORT: 6379,
  REDIS_CONNECT_TIMEOUT: process.env.REDIS_CONNECT_TIMEOUT || 100000, // TODO
  API_CONSTANT: "/sistic/docroot",
  JWT_SECRET_KEY: process.env.JWT_SECRET_KEY || "SECRET",
  HSTS_MAX_AGE: process.env.HSTS_MAX_AGE || 31536000,
  PREDICT_URL: CMS_DOMAIN + "sistic/docroot/api/predict",
  TRENDING_URL: CMS_DOMAIN + "sistic/docroot/api/trending",
  RECOMMENDER_URL: CMS_DOMAIN + "sistic/docroot/api/homepage/recommender",
  WHITELIST_URI: [
    "/general-home-settings",
    "/get-metatags",
    "/get-ticket-wallet",
    "/event-detail",
    "/promotion-detail",
    "/get-template-1",
    "/get-template-2",
    "/promotion-listing",
    "/events",
    "/promotions",
    "/api/rotational-banner",
    "/flash-sales-banner",
    "/api/side-panel",
    "/api/customize-section-three",
    "/api/home-page-takeover",
    "/homepage/recommender",
    "/news",
    "/homepage/top-picks",
    "/hot-show-page",
    "/homepage/new-release",
    "/homepage/this-week",
    "/configurations/search",
    "/similar-events",
    "/api/featured-event",
    "/get-articles-on-event-detail",
    "/api/customize-section-one",
    "/api/find-an-event",
    "/menu-venues",
    "/genres",
    "/api/leader-board",
    "/api/search-featured",
    "/venues",
    "/api/event-listing",
    "/get-articles-listing",
    "/api/customize-section-two",
    "/homepage/trending-now",
    "/attraction-categories",
    "/attractions",
    "/promotion-categories",
    "/homepage/promotions",
    "/agent-countries-regions",
    "/agents",
    "/api-partners",
    "/where-to-buy-tickets",
    "/venues-list",
    "/venue-countries-regions",
    "/venue-specific-events",
    "/about-us",
    "/sell-with-us",
    "/gift-vouchers",
    "/faq-categories",
    "/faq",
    "/landing-page",
    "/system-licensing",
    "/advertise-with-us",
    "/career/content",
    "/career/testimonials",
    "/career/get-jobs",
    "/career/get-interest",
    "/career/get-job",
    "/contact-us/enquiry-categories",
    "/contact-us/details",
    "/contact-us/faq",
    "/rest/session/token",
    "/get-explore-landing",
    "/get-template-categories",
    "/get-template-tags",
    "/get-template-tags",
    "/get-search-count-results",
    "/get-search-suggestions",
    "/search-results",
    "/get-events-search-results",
    "/get-attractions-search-results",
    "/get-promotion-search-results",
    "/get-faq-search-results",
    "/api/search-list",
    "/cms-page",
    "/check-password-protected",
    "/verify-password-page",
    "/onboard-screens",
    "/api/homescreen-popup",
    "/genre-interest",
    "/add-seller",
    "/contact-us/store",
    "/waitlist-mail/store",
    "/career/submit-interest",
    "/career/apply-job",
    "/newsletter",
    "/attachments/upload",
    "/genre-submit",
    "/submit-quiz",
    "/survey-submit",
    "/add-watchlist",
    "/remove-watchlist",
    "/service/upload",
    "/login",
    "/private-partners",
    "/get-watchlist-login",
    "/get-watch-list",
    "/watchlist-filter",
    "/notifications/add",
    "/notifications/remove",
    "/notifications/add-settings",
    "/get-settings",
    "/get-notifications",
    "/notification/update-notification",
    "/api/mobile-carousel",
    "/submit/survey",
    "/get-surveys",
    "/get-quiz",
    "/quiz-results",
    "/surveys-results",
    "/home/attractions",
  ],
  DETAIL_API_URLS: [
    "/event-detail",
    "/promotion-detail",
    "/get-template-1",
    "/get-metatags",
    "/get-ticket-wallet",
    "/similar-events",
    "/get-articles-on-event-detail",
    "/venue-specific-events",
    "/get-template-2",
    "/career/get-job",
    "/contact-us/enquiry-categories",
    "/genres",
    "/get-quiz",
    "/get-surveys",
  ],
  RECOMMENDATION_API_URLS: ["/homepage/top-picks"],
  GENERAL: [
    "/general-home-settings",
    "/news",
    "/api/rotational-banner",
    "/flash-sales-banner",
    "/api/customize-section-three",
    "/hot-show-page",
    "/homepage/new-release",
    "/homepage/this-week",
    "/configurations-search",
    "/api/featured-event",
    "/api/customize-section-one",
    "/api/find-an-event",
    "/attraction-categories",
    "/promotion-categories",
    "/agent-countries-regions",
    "/where-to-buy-tickets",
    "/venue-countries-regions",
    "/get-explore-landing",
    "/get-template-categories",
  ],
  CMS: [
    "/about-us",
    "/sell-with-us",
    "/gift-vouchers",
    "/faq-categories",
    "/faq",
    "/landing-page",
    "/system-licensing",
    "/advertise-with-us",
    "/cms-page",
  ],
  CAREER: [
    "/career/content",
    "/career/testimonials",
    "/career/get-jobs",
    "/career/get-interest",
  ],
  CONTACT_US: ["/contact-us/details", "/contact-us/faq"],
  Listing_API_URLS: [
    "/promotions",
    "/events",
    "/menu-venues",
    "/api/leader-board",
    "/venues",
    "/api/event-listing",
    "/get-articles-listing",
    "/api/customize-section-two",
    "/homepage/trending-now",
    "/attractions",
    "/homepage/promotions",
    "/agents",
    "/venues-list",
    "/api-partners",
    "/api/side-panel",
    "/private-partners",
  ],
  RANDOMISE_RESULTS_API_URLS: ["/api/rotational-banner"],
  REDIRECTION_URLS: {
    "/attraction": "/attractions",
    "/b2b": "/corporate",
    "/sell-event-tickets": "/corporate/ticket-with-us",
    "/system-licensing": "/corporate/ticketing-technology",
    "/apipartners": "/corporate/partner-with-us",
    "/media": "/corporate#media",
    "/about-us": "/corporate/about-us",
    "/advertise": "/corporate/advertise-with-us",
    "/career": "/corporate/careers",
    "/condition-of-access": "/conditions-of-access",
  },
});

import {API} from '../../helper/services';

class FaqService {
  getFaqCategoriesService(params) {
    return API.get(`faq-categories`, { params });
  }

  getFaqContentService(params) {
    return API.get(`faq`, { params });
  }
}

export default new FaqService();

import { API } from "../../helper/services";

class EventsService {
  getData(params) {
    return API.get(`/api/events`, { params: params });
  }

  getEventDetails(params) {
    return API.get(`/api/event-detail`, { params }).catch(function (err) {
      return {
        status: false,
        msg: err,
      };
    });
  }

  getPreviewEventDetails(params) {
    return API.get(`/preview/event-detail`, { params });
  }

  getFilterConfig() {
    return API.get(`/api/configurations/search`);
  }

  getSimilarEvents(params) {
    return API.get(`/api/similar-events`, { params: params });
  }

  getBuyNowUrl(params) {
    return API.get("api/event-ticket-url", { params: params })
  }

  getArticleData(params) {
    return API.get(`/api/get-articles-on-event-detail`, { params: params });
  }

  formSubmission(data) {
    return API.post(`/waitlist-mail/store`, data);
  }

  oraganizerformSubmission(data) {
    return API.post(`/api/stixlite-contact`, data);
  }

  checkPrivateEventPassword(data) {
    return API.post(`/api/privatepass`, data);
  }
}

export default new EventsService();

import React, { useEffect, useState, useContext, useRef } from "react";
import "./style.scss";
import { useParams, Link } from "react-router-dom";
import Slider from "react-slick";
import ReactPlayer from "react-player/lazy";
import EventsService from "../../services/EventsService";
import useCustomMediaQuery from "../../hooks/useCustomMediaQuery";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { Tooltip, List, ListItem } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AllFunctions } from "../../helper";
import EventCreatedWithStixLite from "../../assets/images/event-created-with-stixlite.svg";
import {
  Box,
  Button,
  Container,
  Divider,
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import {
  ContentCopy,
  Close,
} from "@mui/icons-material";
import {
  CustomAccordion,
  CustomLink,
  Presale,
  TruncateText,
  SeatMap,
  StyledTabs,
  StyledTab,
  Organizer,
} from "./components";
import { CountdownTimer, EventListing, SectionTitle } from "../";

import { PopUpMessage } from "./components/PopUpMessage";
import { Utilities } from "../../utils";
import PlayButton from "../../assets/images/play.svg";
import PasswordPrompt from "./components/PasswordPrompt";

const settings = {
  speed: 500,
  focusOnSelect: true,
  swipe: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  draggable: true,
  centerMode: false,
  arrow: false,
};
const Delimiter = ({ length, index }) => {
  if (index === length - 1) return null;
  if (length === 2 || (length !== 2 && index === length - 2)) {
    return <span> & </span>;
  }
  return <span> , </span>;
};

const pathNameList = ["previewuatcdn", "previewlink"];
const hostNameList = [
  "previewuatcdn.sistic.com.sg",
  "previewlink.sistic.com.sg",
  "https://previewuatcdn.sistic.com.sg",
  "https://previewlink.sistic.com.sg",
];

export const EventDetails = (props = {}) => {
  const { smallDown } = useCustomMediaQuery();
  const { isMobile } = useDeviceDetect();
  const params = useParams();
  const sliderRef = useRef();
  const [eventdet, setEventdet] = useState(false);
  const [saleDateE, setSaleDate] = useState(null);
  const [buyTicketLabel, setBuyTicketLabel] = useState("BUY TICKETS");
  const [buyPackageLabel, setBuyPackageLabel] = useState("Buy Package");
  const [isBuyButtonHide, setIsBuyButtonHide] = useState(false);
  const [similarEvents, setSimilarEvents] = useState({});
  const [articleEvents, setArticleEvents] = useState({});
  const [value, setValue] = useState(0);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [timeForCountdown, setTimeForCountdown] = useState(false);
  const [vidplayer, setVidPlayer] = useState({});
  const [popupopen, setpopupOpen] = useState({
    isactive: false,
    details: { title: "", desc: "" },
  });
  const [open, setOpen] = useState(false);
  const [dateopen, setdateOpen] = useState(false);
  const [isopen, setIsOpen] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [isWaitlist, setIsWaitlist] = useState(false);
  const [isorganizerform, setIsorganizerform] = useState(false);
  const [flag, setFlag] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const divRef = useRef(null);
  const [showBuyTickets, setShowBuyTickets] = useState(true);
  const [videoModal, setVideoModal] = useState(false);
  const [passwordPrompt, setPasswordPrompt] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handledateOpen = () => setdateOpen(true);
  const handledateClose = () => setdateOpen(false);
  const handlewaitlistClose = () => setIsWaitlist(false);
  const handleorganizerformClose = () => setIsorganizerform(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeBox = (event) => {
    event.preventDefault();
    setIsWaitlist(false);
  };
  const organizercloseBox = (event) => {
    event.preventDefault();
    setIsorganizerform(false);
  };

  const handleOnLitePlay = (data, id) => {
    let newList = {
      ...vidplayer,
    };
    newList[id] = true;
    setVidPlayer(newList);
  };

  const getBuyNowUrlFromServer = () => {
    const reqparams = {
      client: 1,
      code: params.id,
    };
    EventsService.getBuyNowUrl(reqparams).then((res) => {
      if (res.data?.buy_now_url) {
        window.location.href = res.data.buy_now_url;
      }
      else if (res.data?.alternate_buy_now_url) {
        window.location.href = res.data?.alternate_buy_now_url;
      }
    });
  }

  const handleBuyTicket = () => {
    if (
      (eventdet.buttons && eventdet.buttons.length != 0) ||
      (eventdet.button_groups && eventdet.button_groups.length != 0)
    ) {
      setFlag(true);
    } else {
      if ((!isSoldOut || !isComingSoon) && eventdet && eventdet.buy_now_url !== "" && eventdet.buy_now_url !== null) {
        window.location.href = eventdet && eventdet.buy_now_url;
      }
      // TODO:- for SPB-972
      // if (!isSoldOut || !isComingSoon) {
      //   getBuyNowUrlFromServer();
      // }
    }
  };

  const handleBuyPackage = () => {
    if (
      eventdet &&
      eventdet.buy_package_url !== "" &&
      eventdet.buy_package_url !== null
    ) {
      window.location.href = eventdet && eventdet.buy_package_url;
    }
  };

  const countdownCallBack = () => {
    setTimerExpired(true);
    setIsComingSoon(false);
  };

  useEffect(() => {
    const handleScroll = (data) => {
      const position = window.pageYOffset;
      if (divRef) {
        const offsetY = divRef?.current?.offsetTop;
        if (position + 700 > offsetY) {
          setShowBuyTickets(false);
        } else {
          setShowBuyTickets(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const reqparams = {
      client: 1,
      code: params.id,
    };
    EventsService.getSimilarEvents(reqparams).then((res) => {
      setSimilarEvents(res.data.data);
    });
  }, []);

  useEffect(() => {
    const reqparams = {
      client: 1,
      code: params.id,
    };
    EventsService.getArticleData(reqparams).then((res) => {
      setArticleEvents(res.data.data);
    });
  }, []);

  useEffect(() => {
    const reqparams = {
      client: 1,
      code: params.id,
    };
    props.setLoader(true);

    let getEventDetails = null;

    if ( pathNameList.includes(window.location.pathname) || hostNameList.includes(window.location.hostname) ) {
      getEventDetails = EventsService.getPreviewEventDetails(reqparams);
    } else {
      getEventDetails = EventsService.getEventDetails(reqparams);
    }
    if (getEventDetails) {
      getEventDetails.then((res) => {
        if (!res.status) {
          setEventdet({ data: [] });
        } else {
          setEventdet(res.data);
          let setIsSoldOutStat = false;
          let setIsComingSoonStat = false;
          let soldOutTempLabel = "COMING SOON";
          let currentDate = new Date();

          if (res.data && Object.keys(res.data).length > 0) {
            let onSaleDate = new Date(res.data.on_sale_date);
            let cTServertime = AllFunctions.convertTZ(
              res.headers.date,
              "Asia/Singapore"
            );
            let timeDiffMilliseconds =
              onSaleDate.getTime() - cTServertime.getTime();
            let currDatePlusTimeDiff =
              cTServertime.getTime() + timeDiffMilliseconds;
            let canStartSaleNow =
              onSaleDate.getTime() <= cTServertime.getTime();
            if (onSaleDate) setSaleDate(res.data.on_sale_date);
            /** set sold out status to true */
            if (res.data.soldout_status === 1) {
              setIsSoldOutStat = true;
            }

            /** Check and set is available */
            if (
              res.data?.on_sale === 0 &&
              res.data?.soldout_status !== 1 &&
              res.data?.is_available_for_booking === 1
            ) {
              setIsAvailable(true);
            }

            /** Check if it's coming soon */
            if (!canStartSaleNow) {
              setIsComingSoonStat = true;
              soldOutTempLabel = res.data.coming_soon_text
                ? res.data.coming_soon_text
                : soldOutTempLabel;
            }

            /** set but tickets if there is a custom one */
            let newBuyLabel = buyTicketLabel;
            if (res.data.buy_ticket_title) {
              newBuyLabel = res.data.buy_ticket_title;
            }
            setBuyTicketLabel(
              setIsSoldOutStat
                ? "SOLD OUT"
                : setIsComingSoonStat
                ? soldOutTempLabel
                : newBuyLabel
            );

            /** set package button title if there is a custom one */
            if (res.data.buy_package_title)
              setBuyPackageLabel(res.data.buy_package_title);

            /** set hide button if it is set to true */
            if (res.data.buy_tickets_button_value === "1")
              setIsBuyButtonHide(true);

            setIsComingSoon(setIsComingSoonStat); // change status of coming soon
            setIsSoldOut(setIsSoldOutStat); // change sold out status
            if (setIsComingSoonStat) setTimeForCountdown(currDatePlusTimeDiff); // set timer

            setpopupOpen({
              isactive: res.data?.pop_up_message?.title ? true : false,
              details: {
                title: res.data?.pop_up_message?.title,
                desc: res.data?.pop_up_message?.description,
              },
            });
          }
        }

        setTimeout(() => {
          props.setLoader(false);
        }, 500);
      });
    }
  }, [params.id]);

  const otherShareMethod = async () => {
    const data = {
      url: window.location.href,
      text: "",
      title: "",
    };

    if (navigator.share) {
      try {
        await navigator
          .share(data)
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };

  const onSeeMore = () => {
    return true;
  };

  const handleClickPrev = () => {
    let prevBtn = document.querySelector(".slider-video-dots-arrow-left");
    let firstParentNode = prevBtn.parentElement;
    let slickPrevBtn = firstParentNode.parentElement.parentElement.firstChild;
    slickPrevBtn.click();
  };

  const handleClickNext = () => {
    let nextBtn = document.querySelector(".slider-video-dots-arrow-right");
    let firstParentNode = nextBtn.parentElement;
    let slickNextBtn = firstParentNode.parentElement.previousSibling;
    slickNextBtn.click();
  };

  const getPriceWithCurrencySymbol = (minPrice, maxPrice, currency) => {
    // const currencySymbol = Utilities.getCurrencySymbol()
    let currencySymbol = "S$";
    if (currency) {
      currencySymbol = currency;
    }
    if (minPrice && maxPrice) {
      return (
        `${currencySymbol}${minPrice}` + " - " + `${currencySymbol}${maxPrice}`
      );
    } else if (minPrice) {
      return `${currencySymbol}${minPrice}`;
    } else if (maxPrice) {
      return `${currencySymbol}${maxPrice}`;
    }
    return "";
  };

  useEffect(() => {
    if (eventdet?.is_private_event === "1" && eventdet?.password == "1") {
      const cachedValidation = localStorage.getItem(
        eventdet?.alias + "PasswordValid"
      );

      if (cachedValidation) {
        const { timestamp } = JSON.parse(cachedValidation);
        // Check if cached validation is still within the allowed time (3 days)
        const threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
        const isCacheValid = Date.now() - timestamp < threeDaysInMilliseconds;

        if (isCacheValid) {
          setPasswordPrompt(false);
        } else {
          setPasswordPrompt(true);
          localStorage.removeItem(eventdet?.alias + "PasswordValid");
        }
      } else {
        setPasswordPrompt(true);
      }
    }
  }, [eventdet]);

  const hidePasswordPrompt = () => {
    setPasswordPrompt(false);
  };

  return (
    <div style={{ paddingBottom: 50 }}>
      {(isSoldOut || isComingSoon) &&
        eventdet.activate_waitlist_button &&
        eventdet.activate_waitlist_button.waitlist_value &&
        eventdet.activate_waitlist_button.waitlist_value === "1" && (
          <Dialog
            fullWidth
            maxWidth="sm"
            open={isWaitlist}
            PaperProps={{ style: { borderRadius: 20 } }}
            onClose={handlewaitlistClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <DialogTitle sx={{ pt: smallDown ? 4 : 3 }}>
              <Typography
                variant="h2"
                align="center"
                fontSize={smallDown ? "1rem" : "1.5rem"}
                fontWeight={600}
              >
                Don't miss the fun and join our waitlist
              </Typography>
              <IconButton
                sx={{
                  position: "absolute",
                  right: smallDown ? "0" : "10px",
                  top: smallDown ? "0" : "10px",
                }}
                onClick={() => {
                  // setFlag(false);
                  setIsWaitlist(false);
                }}
                aria-label="close"
              >
                <Close />
              </IconButton>{" "}
            </DialogTitle>
            <Presale
              eventicc={eventdet && eventdet.alias}
              popuptext={
                eventdet.activate_waitlist_button &&
                eventdet.activate_waitlist_button.popuptext
              }
              waitListOptionTitle={
                eventdet.activate_waitlist_button &&
                eventdet.activate_waitlist_button.waitlist_field
                  ? eventdet.activate_waitlist_button.waitlist_field
                  : ""
              }
              waitListOption={
                eventdet.activate_waitlist_button &&
                eventdet.activate_waitlist_button.waitlist_options
                  ? eventdet.activate_waitlist_button.waitlist_options
                  : {}
              }
              closeBox={closeBox}
            ></Presale>
          </Dialog>
        )}
      {isorganizerform && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={isorganizerform}
          PaperProps={{ style: { borderRadius: 20 } }}
          onClose={handleorganizerformClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle sx={{ pt: smallDown ? 4 : 3 }}>
            <Typography
              variant="h2"
              align="left"
              fontSize={smallDown ? "1rem" : "1.5rem"}
              fontWeight={600}
            >
              CONTACT ORGANISER
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                right: smallDown ? "0" : "10px",
                top: smallDown ? "0" : "10px",
              }}
              onClick={() => {
                // setFlag(false);
                setIsorganizerform(false);
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>{" "}
          </DialogTitle>
          <Organizer
            eventicc={eventdet && eventdet.alias}
            closeBox={organizercloseBox}
          ></Organizer>
        </Dialog>
      )}

      {passwordPrompt && (
          <PasswordPrompt
            hidePasswordPrompt={hidePasswordPrompt}
            eventIcc={eventdet?.alias}
          />
      )}

      <PopUpMessage
        isOpen={popupopen.isactive}
        IsSmallDown={smallDown}
        title={popupopen.details?.title}
        description={popupopen.details?.desc}
      />

      <Dialog
        open={showShareDialog}
        onClose={() => {}}
        fullWidth
        maxWidth="sm"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent dividers>
          <Box>
            <Typography
              id="modal-modal-title"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                letterSpacing: "0.15px",
                lineHeight: "19.5px"
              }}
              variant={smallDown ? "h5" : "h4"}
              width={"100%"}
              textAlign={"center"}
              fontWeight={700}
              fontSize="16px"
              color="#343434"
              fontFamily={"Montserrat"}
            >
              Share this event
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                right: "10px",
                top: "5px",
              }}
              onClick={() => setShowShareDialog(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Box>

          <Stack
            direction={"row"}
            spacing={{xs: 2, sm: 4}}
            justifyContent={"center"}
            marginTop={4}
          >
            <div className="social-share-icon">
              <img
                src="../imgs/24px-whatsapp.svg"
                alt="Whatsapp Icon"
                onClick={() => Utilities.shareEvent(window.location.href, eventdet.title, "WHATSAPP", isMobile)}
              />
            </div>

            <div className="social-share-icon">
              <img
                src="../imgs/24px-facebook.svg"
                alt="Facebook Icon"
                onClick={() => Utilities.shareEvent(window.location.href, eventdet.title, "FACEBOOK", isMobile)}
              />
            </div>

            <div className="social-share-icon">
              <img
                src="../imgs/24px-x.svg"
                alt="X-Twitter Icon"
                onClick={() => Utilities.shareEvent(window.location.href, eventdet.title, "X-TWITTER", isMobile)}
              />
            </div>

            <div className="social-share-icon">
              <img
                src="../imgs/24px-instagram.svg"
                alt="Instagram Icon"
                onClick={() => Utilities.shareEvent(window.location.href, eventdet.title, "INSTA", isMobile)}
              />
            </div>

            <div className="social-share-icon">
              <img
                src="../imgs/24px-tiktok.svg"
                alt="Tiktok Icon"
                onClick={() => Utilities.shareEvent(window.location.href, eventdet.title, "TICKTOK", isMobile)}
              />
            </div>

            <div className="social-share-icon">
              <img
                src="../imgs/24px-telegram.svg"
                alt="Telegram Icon"
                onClick={() => Utilities.shareEvent(window.location.href, eventdet.title, "TELEGRAM", isMobile)}
              />
            </div>

            {/* {smallDown && (
              <IconButton
                // onClick={() => shareEventUrl("OTHER")}
                onClick={() => otherShareMethod()}
                aria-label="morehoriz"
              >
                <MoreHoriz sx={{ color: "#CCCCCC" }} />
              </IconButton>
            )} */}
          </Stack>

          <Stack
            direction={"row"}
            mt={4}
            sx={{
              alignItems: "center",
              overflow: "hidden",
              border: "1px solid #d1cfcf",
              borderRadius: "6px",
              justifyContent: "space-between",
              flexDirection: "row",
              marginBottom: "20px"
            }}
          >
            <Typography
              fontWeight={400}
              marginX={1}
              fontSize="14px"
              color="#878686"
              fontFamily={"Rubik"}
              textOverflow={"ellipsis"}
              style={{
                maxWidth: "80%",
                overflow: "hidden",
                whiteSpace: "nowrap"
              }}
            >
              {/* {window.location.href} */}
              {"https://sistic.com.sg/events/final0924?utm_source=website_sistic&utm_medium=hmfe&utm_campaign=willow&utm_content=final0924"}
            </Typography>

            <Stack
              direction={"row"}
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
              }}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 1,
                paddingRight: 2,
                backgroundColor: "#0082D6",
                height: 35,
              }}
              spacing={0.1}
            >
              <IconButton
                onClick={() => setShowShareDialog(false)}
                aria-label="contentcopy"
                size={"small"}
              >
                <ContentCopy sx={{ fontSize: 15, color: "#FFFFFF" }} />
              </IconButton>
              <Typography
                fontWeight={400}
                marginLeft={1}
                fontSize="12px"
                color="#FFFFFF"
                fontFamily={"Rubik"}
              >
                {"COPY"}
              </Typography>
            </Stack>
          </Stack>

        </DialogContent>
      </Dialog>

      <Container>
        <Box component="div" pt={smallDown && "10px"}>
          {eventdet && eventdet.title && (
            <Grid container rowSpacing={3} py={smallDown ? "10px" : "15px"}>
              <Grid item xs={12}>
                <Slider
                  {...settings}
                  ref={sliderRef}
                  appendDots={(dots) => (
                    <Grid
                      container
                      id={"slider-video-dots-cnt"}
                      key={dots}
                      style={{ position: "relative", top: "8px" }}
                    >
                      <Grid item xs={1} md={4} style={{ zIndex: 4 }}>
                        <span
                          onClick={() => sliderRef.current.slickPrev()}
                          className={"slider-video-dots-arrow-left"}
                        >
                          &nbsp;
                        </span>
                      </Grid>
                      <Grid item xs={10} md={4} style={{ zIndex: 2 }}>
                        <ul className={"slider-video-dots"}> {dots} </ul>
                      </Grid>
                      <Grid item xs={1} md={4} style={{ zIndex: 4 }}>
                        <span
                          onClick={() => sliderRef.current.slickNext()}
                          className={"slider-video-dots-arrow-right"}
                        >
                          &nbsp;
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  responsive={[
                    {
                      breakpoint: 991,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                >
                  {eventdet?.mobile_desktop_images?.[
                    smallDown ? "mobile" : "desktop"
                  ]?.length > 0 &&
                    eventdet.mobile_desktop_images[
                      smallDown ? "mobile" : "desktop"
                    ].map((img, index) => {
                      if (img.type.id === 1) {
                        return (
                          <Stack position="relative">
                            <img
                              src={img.full_image}
                              style={{
                                position: "relative",
                                height: smallDown ? "360px" : "466px",
                                width: smallDown ? "360px" : "1000px",
                                borderRadius: smallDown ? "" : "20px",
                                objectFit: "contain",
                              }}
                            />

                            {/* {!smallDown && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "15px",
                                  marginLeft: "10px",
                                  bgcolor:
                                    eventdet.tag_colour &&
                                    "#" + eventdet.tag_colour,
                                  color: "#fff",
                                  fontSize: "0.875rem",
                                  fontWeight: 500,
                                  p: "5px 8px",
                                  borderRadius: "6px",
                                }}
                              >
                                {eventdet?.tag_name}
                              </Box>
                            )} */}

                            {smallDown && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "15px",
                                  p: "5px 8px",
                                  backgroundColor: "#343434",
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: 20,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  opacity: "0.7",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  setShowShareDialog(true);
                                }}
                              >
                                <img
                                  src="../imgs/24px-upload.svg"
                                  alt="Share Icon"
                                  className={"share-icon"}
                                />
                              </div>
                            )}
                          </Stack>
                        );
                      } else if (img.type.id === 2) {
                        if (smallDown) {
                          return (
                            <Stack position="relative">
                              <img
                                src={img.full_image}
                                style={{
                                  position: "relative",
                                  height: "360px",
                                  width: "360px",
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                                onClick={() => setVideoModal(true)}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  cursor: "pointer",
                                }}
                                onClick={() => setVideoModal(true)}
                              >
                                <img src={PlayButton} width={"40px"} />
                              </div>

                              <Modal
                                open={videoModal}
                                onClose={() => setVideoModal(false)}
                              >
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                >
                                  <ReactPlayer
                                    height={"360px"}
                                    width={"360px"}
                                    objectFit="contain"
                                    playing={
                                      vidplayer
                                        ? vidplayer["vidcon" + index]
                                        : false
                                    }
                                    onClickPreview={(data) =>
                                      handleOnLitePlay(data, "vidcon" + index)
                                    }
                                    url={img?.video_url}
                                  />
                                </Box>
                              </Modal>
                            </Stack>
                          );
                        } else {
                          return (
                            <ReactPlayer
                              height={"466px"}
                              width={"1000px"}
                              objectFit="contain"
                              playing={
                                vidplayer ? vidplayer["vidcon" + index] : false
                              }
                              onClickPreview={(data) =>
                                handleOnLitePlay(data, "vidcon" + index)
                              }
                              url={img?.video_url}
                            />
                          );
                        }
                      }
                    })}
                </Slider>
              </Grid>

              <Grid item sm={12}>
                <Grid container>
                  <Grid item sm={12} md={8} lg={8.5}>
                    <Stack spacing={2} mr={{ sm: 4 }} mt={{ sm: 1, md: 0 }}>
                      <Stack spacing={1} sx={{ p: "15px" }}>
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                          variant={smallDown ? "h5" : "h4"}
                          fontWeight={700}
                          fontFamily={"Montserrat"}
                          fontSize="24px"
                          color="#343434"
                          letterSpacing={"0px"}
                        >
                          {eventdet.title && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: eventdet.title,
                              }}
                            />
                          )}
                          {!smallDown && (
                            <Box
                              component="span"
                              sx={{
                                ml: 3,
                                bgcolor:
                                  eventdet.tag_colour &&
                                  "#" + eventdet.tag_colour,
                                color: "#fff",
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                p: "5px 8px",
                                borderRadius: "6px",
                              }}
                            >
                              {eventdet.tag_name && eventdet.tag_name}
                            </Box>
                          )}
                        </Typography>
                        {smallDown && (
                          <div>
                            <Box
                              component="span"
                              sx={{
                                bgcolor:
                                  eventdet.tag_colour &&
                                  "#" + eventdet.tag_colour,
                                color: "#fff",
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                p: "5px 8px",
                                borderRadius: "6px",
                              }}
                              style={{ marginTop: 11 }}
                            >
                              {eventdet.tag_name && eventdet.tag_name}
                            </Box>
                          </div>
                        )}

                        <Typography
                          variant="details"
                          color="#626262"
                          style={{ marginTop: 11 }}
                        >
                          by{" "}
                          {eventdet.promoters &&
                            eventdet.promoters.length > 0 &&
                            eventdet.promoters.map((promot, index) => {
                              if (promot.url && promot.name) {
                                return (
                                  <>
                                    <a
                                      key={`${promot.name}-${index}`}
                                      href={promot.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {promot.name}
                                    </a>
                                    <Delimiter
                                      length={eventdet.promoters.length}
                                      index={index}
                                    />
                                  </>
                                );
                              }
                              return (
                                <>
                                  <span key={`${promot.name}-${index}`}>
                                    {" "}
                                    {promot.name}{" "}
                                  </span>
                                  <Delimiter
                                    length={eventdet.promoters.length}
                                    index={index}
                                  />
                                </>
                              );
                            })}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          {eventdet.genres &&
                            eventdet.genres.length > 0 &&
                            eventdet.genres.map((item, i) => {
                              return (
                                <Box
                                  component="div"
                                  sx={{
                                    color: "#0082D6",
                                    border: "1px solid #0082D6",
                                    borderRadius: "2px",
                                    p: "3px 5px",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  {item.name && item.name}
                                </Box>
                              );
                            })}
                          {/* <img
                          {/* <img
                            src="../imgs/24px-mobility.svg"
                            alt="Mobility Icon"
                          />
                          <img src="../imgs/Vector.svg" alt="Vector Icon" />
                          <img src="../imgs/RP.svg" alt="RP Icon" /> */}
                          {/*<img src="../imgs/RP.svg" alt="RP Icon" /> */}
                        </Stack>
                      </Stack>
                      <Stack
                        direction={{ md: "row" }}
                        spacing={{ md: 10 }}
                        alignItems={{ sm: "flex-start" }}
                        //justifyContent={{ md: "space-between" }}
                        //justifyContent={{ md: "space-between" }}
                        mt={2}
                      >
                        <div
                          style={{
                            width: "65%",
                            overflow: "hidden",
                            paddingTop: 5,
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ px: "15px" }}
                          >
                            <img
                              src="../imgs/24px-calendar.svg"
                              alt="Calendar Icon"
                            />
                            <Stack pb={{ xs: 2, md: 0 }}>
                              <Typography
                                variant="caption"
                                fontSize="1rem"
                                color="#626262"
                                fontWeight={400}
                                fontFamily={"Rubik"}
                                className="event-date"
                              >
                                {eventdet && eventdet.alt_event_start_date ? (
                                  <p
                                    // style={{ marginTop: '-15px' }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        eventdet &&
                                        AllFunctions.addTargetToHref(
                                          eventdet.alt_event_start_date
                                        ),
                                    }}
                                  ></p>
                                ) : eventdet &&
                                  eventdet.event_date_notes === null &&
                                  eventdet.event_date_details &&
                                  eventdet.event_date_details != [null] &&
                                  eventdet.event_date_details?.length > 0 ? (
                                  <>
                                    {" "}
                                    {eventdet.event_date_details[0]} -{" "}
                                    {eventdet.event_date_details[1]}
                                  </>
                                ) : eventdet.start_date ===
                                  eventdet.end_date ? (
                                  eventdet.start_date
                                ) : (
                                  <>
                                    {" "}
                                    {eventdet.start_date} - {eventdet.end_date}
                                  </>
                                )}
                              </Typography>
                              {eventdet.event_date_notes &&
                                eventdet.event_date_notes?.length > 0 && (
                                  <CustomLink
                                    fontSize="0.75rem"
                                    color="#0082D6"
                                  >
                                    <a onClick={handledateOpen}>
                                      {" "}
                                      View All Dates and Timings
                                    </a>
                                  </CustomLink>
                                )}
                              <Modal
                                open={dateopen}
                                onClose={handledateClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: smallDown ? 300 : 500,
                                    bgcolor: "background.paper",
                                    border: "2px solid #000",
                                    boxShadow: 24,
                                    p: 4,
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "10px",
                                    }}
                                    onClick={handledateClose}
                                    aria-label="close"
                                  >
                                    <Close />
                                  </IconButton>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    Event Dates & Timings
                                  </Typography>
                                  <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                  >
                                    <p
                                      className={"p"}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          eventdet &&
                                          AllFunctions.addTargetToHref(
                                            eventdet.event_date_notes
                                          ),
                                      }}
                                    ></p>
                                  </Typography>
                                </Box>
                              </Modal>
                            </Stack>
                          </Stack>
                        </div>
                        <div style={{ width: "65%", overflow: "hidden" }}>
                          <Stack
                            direction="row"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{ px: "15px" }}
                          >
                            <img
                              src="../imgs/24px-map-pin.svg"
                              alt="Map Icon"
                              style={{ marginTop: 3 }}
                            />
                            <Stack>
                              <Typography
                                variant="caption"
                                fontSize="1rem"
                                color="#626262"
                                fontWeight={400}
                                fontFamily={"Rubik"}
                                textOverflow={"ellipsis"}
                                style={{ marginTop: 0 }}
                                component={Link}
                                to={`https://www.sistic.com.sg/venues?id=${eventdet.venue_name.id}&&country=${eventdet.venue_name.country}`}
                                sx={{
                                  "&:hover": { color: "#0082D6" },
                                  textDecoration: "none",
                                  WebkitLineClamp: 2,
                                }}
                              >
                                {eventdet.venue_name &&
                                  eventdet.venue_name.name}
                              </Typography>
                              {eventdet.venue_name.description &&
                                eventdet.venue_name.description?.length > 0 && (
                                  <CustomLink
                                    fontSize="0.75rem"
                                    color="#0082D6"
                                  >
                                    <a onClick={handleOpen}>{"View Venue"}</a>
                                  </CustomLink>
                                )}
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: smallDown ? 300 : 500,
                                    bgcolor: "background.paper",
                                    border: "2px solid #000",
                                    boxShadow: 24,
                                    p: 4,
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "10px",
                                    }}
                                    onClick={handleClose}
                                    aria-label="close"
                                  >
                                    <Close />
                                  </IconButton>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    Venue Details
                                  </Typography>
                                  <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                  >
                                    <p
                                      className={"p"}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          eventdet.venue_name &&
                                          AllFunctions.addTargetToHref(
                                            eventdet.venue_name.description
                                          ),
                                      }}
                                    ></p>
                                  </Typography>
                                </Box>
                              </Modal>
                            </Stack>
                          </Stack>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            height: 1,
                            width: "100%",
                          }}
                          ref={divRef}
                        ></div>
                        {smallDown && showBuyTickets && (
                          <>
                            {(eventdet?.min_price || eventdet?.max_price) && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={{ px: "15px" }}
                              >
                                <img
                                  src="../imgs/24px-dollar-circle.svg"
                                  alt="Dollar icon"
                                />
                                <Typography
                                  variant="overline"
                                  color="#626262"
                                  style={{
                                    fontSize: "1.1rem",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {getPriceWithCurrencySymbol(
                                    eventdet?.min_price,
                                    eventdet?.max_price,
                                    eventdet?.currency
                                  )}
                                </Typography>
                              </Stack>
                            )}
                            <Stack
                              spacing={1}
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                bgcolor: "#fff",
                                // pt: 5,
                                position: "fixed",
                                bottom: 0,
                                // padding: "10px 10px 10px",
                                zIndex: 10,
                                width: "100%",
                                py: 2,
                              }}
                            >
                              {/* <Stack > */}
                              {!isBuyButtonHide && (
                                <Button
                                  fullWidth
                                  disabled={isSoldOut || isComingSoon}
                                  disableElevation
                                  variant="contained"
                                  className={[
                                    "button-buyTicks",
                                    isSoldOut || isComingSoon ? "dbd" : "",
                                  ].join(" ")}
                                  onClick={handleBuyTicket}
                                >
                                  {buyTicketLabel}
                                </Button>
                              )}
                              {!isSoldOut &&
                                !isComingSoon &&
                                eventdet.buy_package_status === 1 && (
                                  <Button
                                    fullWidth
                                    disableElevation
                                    variant="contained"
                                    className={["button-buyPackages"].join(" ")}
                                    onClick={handleBuyPackage}
                                  >
                                    {buyPackageLabel}
                                  </Button>
                                )}
                              {/* </Stack> */}
                              {isComingSoon &&
                                eventdet?.countdown_timer == 1 &&
                                !timerExpired &&
                                timeForCountdown && (
                                  <div>
                                    <CountdownTimer
                                      // targetDate={timeForCountdown}
                                      targetDate={saleDateE}
                                      countdownCallBack={countdownCallBack}
                                    />
                                  </div>
                                )}
                              {(isSoldOut || isComingSoon) &&
                                eventdet.activate_waitlist_button &&
                                eventdet.activate_waitlist_button
                                  .waitlist_value &&
                                eventdet.activate_waitlist_button
                                  .waitlist_value === "1" && (
                                  <>
                                    <a
                                      onClick={() => setIsWaitlist(true)}
                                      style={{
                                        cursor: "pointer",
                                        color: "#0082D6",
                                        width: "220px",
                                        height: "40px",
                                        textAlign: "center",
                                        fontSize: "18px",
                                        fontWeight: 500,
                                        lineHeight: "17px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {eventdet &&
                                        eventdet.activate_waitlist_button &&
                                        eventdet.activate_waitlist_button.title}
                                    </a>
                                  </>
                                )}
                            </Stack>
                          </>
                        )}
                      </Stack>

                      <Stack
                        direction="row"
                        sx={{ px: "15px" }}
                        alignItems="center"
                        mt={2}
                      >
                        {eventdet.synopsis &&
                          eventdet.synopsis.length > 1 &&
                          !smallDown && (
                            <Typography
                              variant="details"
                              color="#626262"
                              fontWeight={500}
                              fontSize="0.875rem"
                              mr={2}
                            >
                              Language:
                            </Typography>
                          )}
                        {eventdet.synopsis &&
                          eventdet.synopsis.length > 1 &&
                          eventdet.synopsis.map((lang, index) => {
                            return (
                              <StyledTabs
                                value={value}
                                onChange={handleChange}
                                aria-label="Language"
                              >
                                <StyledTab
                                  label={lang.language}
                                  value={index}
                                />
                              </StyledTabs>
                            );
                          })}
                      </Stack>
                      <Stack sx={{ px: "15px" }}>
                        <TruncateText
                          length={smallDown ? 210 : 200}
                          text={
                            eventdet.synopsis &&
                            eventdet.synopsis.length > 0 &&
                            eventdet.synopsis[value].description
                          }
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={4}
                    lg={3.5}
                    sx={{ display: smallDown && "none", p: "15px" }}
                  >
                    <Box
                      sx={{
                        bgcolor: "#F8F8F8",
                        borderRadius: "10px",
                        display: "flex",
                        px: 2,
                        py: 3,
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 3,
                        mt: 4,
                      }}
                    >
                      {(eventdet?.min_price || eventdet?.max_price) && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <img
                            src="../imgs/24px-dollar-circle.svg"
                            alt="Dollar icon"
                          />
                          <Typography
                            variant="details"
                            color="#626262"
                            style={{
                              fontSize: "1.1rem",
                              position: "relative",
                              whiteSpace: "nowrap",
                              top: "1px",
                            }}
                          >
                            {getPriceWithCurrencySymbol(
                              eventdet?.min_price,
                              eventdet?.max_price,
                              eventdet?.currency
                            )}
                          </Typography>
                        </Stack>
                      )}

                      <div className={"buy-tickets"}>
                        {!isBuyButtonHide && (
                          <Button
                            disabled={isSoldOut || isComingSoon}
                            disableElevation
                            variant="contained"
                            className={[
                              "button-buyTicks",
                              isSoldOut || isComingSoon ? "dbd" : "",
                            ].join(" ")}
                            onClick={handleBuyTicket}
                          >
                            {buyTicketLabel}
                          </Button>
                        )}
                      </div>

                      {!isSoldOut &&
                        !isComingSoon &&
                        eventdet.buy_package_status === 1 && (
                          <Button
                            disableElevation
                            variant="contained"
                            className={["button-buyPackages"].join(" ")}
                            onClick={handleBuyPackage}
                          >
                            {buyPackageLabel}
                          </Button>
                        )}
                      {isComingSoon &&
                        eventdet?.countdown_timer == 1 &&
                        !timerExpired &&
                        timeForCountdown && (
                          <div>
                            <CountdownTimer
                              //targetDate={timeForCountdown}
                              targetDate={saleDateE}
                              countdownCallBack={countdownCallBack}
                            />
                          </div>
                        )}

                      <Modal
                        open={flag}
                        onClose={() => setFlag(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: smallDown ? 300 : 500,
                            bgcolor: "background.paper",
                            border: "2px solid #000",
                            boxShadow: 24,
                            p: 4,
                          }}
                        >
                          <IconButton
                            sx={{
                              position: "absolute",
                              right: "10px",
                              top: "10px",
                            }}
                            onClick={() => setFlag(false)}
                            aria-label="close"
                          >
                            <Close />
                          </IconButton>
                          <Stack
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                          >
                            {eventdet.button_groups &&
                              eventdet.button_groups.length > 0 &&
                              eventdet.button_groups.map((buttonGroup) => {
                                return (
                                  <>
                                    {" "}
                                    <Typography
                                      id="modal-modal-title"
                                      variant="h6"
                                      component="h2"
                                    >
                                      {buttonGroup.title}
                                    </Typography>
                                    {buttonGroup.buttons &&
                                      buttonGroup.buttons.length &&
                                      buttonGroup.buttons.map((button) => {
                                        return (
                                          <>
                                            <Button
                                              variant="contained"
                                              sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                backgroundColor: button.color,
                                                color: button.font_color,
                                                minWidth: "220px",
                                                maxWidth: "450px",
                                                minHeight: "40px",
                                                textAlign: "center",
                                                fontSize: `${button.font_size}px`,
                                                fontWeight: 500,
                                              }}
                                              onClick={() =>
                                                (window.location.href =
                                                  button.url)
                                              }
                                            >
                                              {button.text}
                                            </Button>
                                          </>
                                        );
                                      })}
                                  </>
                                );
                              })}
                            {eventdet.buttons &&
                              eventdet.buttons.length > 0 &&
                              eventdet.buttons.map((button) => {
                                return (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      backgroundColor: button.color,
                                      color: button.font_color,
                                      minWidth: "220px",
                                      maxWidth: "450px",
                                      minHeight: "40px",
                                      textAlign: "center",
                                      fontSize: `${button.font_size}px`,
                                      fontWeight: 500,
                                    }}
                                    onClick={() =>
                                      (window.location.href = button.url)
                                    }
                                  >
                                    {button.text}
                                  </Button>
                                );
                              })}
                          </Stack>
                        </Box>
                      </Modal>

                      <div className={"waitlist"}>
                        {(isSoldOut || isComingSoon) &&
                          eventdet.activate_waitlist_button &&
                          eventdet.activate_waitlist_button.waitlist_value &&
                          eventdet.activate_waitlist_button.waitlist_value ===
                            "1" && (
                            <>
                              <a
                                onClick={() => setIsWaitlist(true)}
                                style={{
                                  cursor: "pointer",
                                  color: "#0082D6",
                                  width: "220px",
                                  height: "40px",
                                  textAlign: "center",
                                  fontSize: "18px",
                                  fontWeight: 500,
                                  lineHeight: "17px",
                                  textDecoration: "underline",
                                }}
                              >
                                {eventdet &&
                                  eventdet.activate_waitlist_button &&
                                  eventdet.activate_waitlist_button.title}
                              </a>
                            </>
                          )}
                      </div>

                      <div
                          style={{display: "flex", flexDirection: "row", cursor: "pointer", }}
                          onClick={() => {
                            setShowShareDialog(true);
                          }}
                      >
                        <img
                          src="../imgs/16px-upload.svg"
                          alt="Share Icon"
                          className={"share-icon"}
                        />
                        <span className="share-text">{"SHARE"}</span>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              {eventdet.wallpaper && eventdet.wallpaper.image && (
                <Grid item xs={12} className={"content"}>
                  <Stack
                    component="div"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                  >
                    {" "}
                    {smallDown ? (
                      <a href={eventdet.wallpaper.url}>
                        <img
                          src={
                            eventdet.wallpaper.image && eventdet.wallpaper.image
                          }
                          style={{
                            height: "auto",
                            width: "1000px",
                            objectFit: "center",
                          }}
                        />
                      </a>
                    ) : (
                      <a href={eventdet.wallpaper.url}>
                        <img
                          src={
                            eventdet.wallpaper.image && eventdet.wallpaper.image
                          }
                          style={{
                            height: "auto",
                            // width: "1000px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                    )}
                  </Stack>
                </Grid>
              )}

              <Grid item xs={12} className={"content"}>
                {/* Price Details tab */}
                <Grid container>
                  <Grid item xs={12} md={8} lg={9}>
                    <CustomAccordion
                      isExpanded={true}
                      title="Price Details"
                      titleFontSize={isMobile ? "16px" : "20px"}
                      titleFontWeight={700}
                      padding="0px"
                      noBorderRadius
                      noInlineBorder
                      noBlockBorder
                      tooltip={
                        (eventdet?.show_bookingfee === undefined ||
                          eventdet.show_bookingfee === null ||
                          // ^ temp fix
                          // eventdet.show_bookingfee &&
                          eventdet.show_bookingfee === "Yes") && (
                          <Tooltip
                            title={
                              <>
                                <Typography
                                  color="#343434"
                                  fontFamily="RubikMedium"
                                  paddingBottom="11px"
                                >
                                  Price excludes booking fee
                                </Typography>
                                <Typography
                                  color="#626262"
                                  fontSize="14px"
                                  fontFamily="Rubik"
                                >
                                  <Typography
                                    fontFamily="Rubik"
                                    fontSize="16px"
                                    fontWeight="500"
                                  >
                                    Booking Fee Charges (Physical Events)
                                  </Typography>
                                  <List
                                    sx={{
                                      listStyleType: "disc",
                                      listStylePosition: "inside",
                                      padding: "0px",
                                    }}
                                  >
                                    <ListItem
                                      sx={{
                                        display: "list-item",
                                        padding: "0px",
                                      }}
                                    >
                                      S$4 Booking Fee per ticket for tickets
                                      priced above S$40.00;
                                    </ListItem>
                                    <ListItem
                                      sx={{
                                        display: "list-item",
                                        padding: "0px",
                                      }}
                                    >
                                      S$3 Booking Fee per ticket for tickets
                                      priced between S$20.01 - S$40.00,
                                    </ListItem>
                                    <ListItem
                                      sx={{
                                        display: "list-item",
                                        padding: "0px",
                                      }}
                                    >
                                      S$1 Booking Fee per ticket for tickets
                                      priced under S$20.00
                                    </ListItem>
                                  </List>
                                  <br />
                                  <Typography
                                    fontFamily="Rubik"
                                    fontSize="16px"
                                    fontWeight="500"
                                  >
                                    Booking Fee Charges (Live Stream Events)
                                  </Typography>
                                  <List
                                    sx={{
                                      listStyleType: "disc",
                                      listStylePosition: "inside",
                                      padding: "0px",
                                    }}
                                  >
                                    <ListItem
                                      sx={{
                                        display: "list-item",
                                        padding: "0px",
                                      }}
                                    >
                                      S$4 Booking Fee per ticket for tickets
                                      priced above S$40.00;
                                    </ListItem>
                                    <ListItem
                                      sx={{
                                        display: "list-item",
                                        padding: "0px",
                                      }}
                                    >
                                      S$3 Booking Fee per ticket for tickets
                                      priced between S$20.01 - S$40.00
                                    </ListItem>
                                    <ListItem
                                      sx={{
                                        display: "list-item",
                                        padding: "0px",
                                      }}
                                    >
                                      S$1 Booking Fee per ticket for tickets
                                      priced between S$10.01 - S$20.00,
                                    </ListItem>
                                    <ListItem
                                      sx={{
                                        display: "list-item",
                                        padding: "0px",
                                      }}
                                    >
                                      S$0.50 Booking Fee per ticket for tickets
                                      priced under $10.00
                                    </ListItem>
                                  </List>
                                </Typography>
                              </>
                            }
                            placement="top"
                            arrow
                            enterTouchDelay={0}
                            leaveTouchDelay={60000}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: "common.white",
                                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                                  borderRadius: "12px",
                                  padding: "20px",
                                  minHeight: "319px",
                                  minWidth: "337px",
                                },
                              },
                              arrow: {
                                sx: {
                                  color: "common.white",
                                },
                              },
                            }}
                          >
                            <InfoOutlinedIcon
                              style={{
                                color: "#747474",
                                position: "relative",
                                top: "4px",
                                left: "12px",
                              }}
                            />
                          </Tooltip>
                        )
                      }
                    >
                      <Stack
                        direction={{ md: "row" }}
                        alignItems={{ sm: "flex-start", md: "" }}
                        spacing={smallDown ? 1.5 : 10}
                      >
                        <Stack spacing={1}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Box
                              sx={{
                                width: "15px",
                                height: "15px",
                                borderRadius: "50%",
                              }}
                            />
                            <Typography
                              variant="caption"
                              fontSize={smallDown ? "0.875rem" : "1rem"}
                              fontWeight={400}
                              color="#626262"
                            >
                              <p
                                className={"p"}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    eventdet &&
                                    AllFunctions.addTargetToHref(
                                      eventdet.ticket_pricing
                                    ),
                                }}
                              ></p>
                            </Typography>
                          </Stack>
                          {eventdet.seating_plan &&
                            eventdet.seating_plan.length > 0 && (
                              <Box component="div" pt={smallDown ? 2 : 4}>
                                <Button
                                  disableElevation
                                  variant="outlined"
                                  fullWidth={smallDown ? true : false}
                                  sx={{
                                    color: "#0082D6",
                                    width: smallDown ? "100%" : "220px",
                                    height: "40px",
                                    textAlign: "center",
                                    fontSize: "0.875rem",
                                    fontWeight: 500,
                                    border: "1px solid #0082D6",
                                  }}
                                  onClick={() => setIsOpen(true)}
                                >
                                  SEAT MAP
                                </Button>
                              </Box>
                            )}
                          {isopen && eventdet.seating_plan && (
                            <SeatMap
                              imgArr={eventdet.seating_plan}
                              showModal={isopen}
                              heading="Seat Map"
                              handleClose={() => setIsOpen(false)}
                            />
                          )}
                        </Stack>
                      </Stack>
                    </CustomAccordion>
                    <Divider sx={{ m: "5px 0", borderColor: "#F0F0F0" }} />
                  </Grid>
                  {eventdet.promoters && eventdet.promoters.length > 0 && (
                    <Grid item xs={12} md={4} lg={3}>
                      <Box ml={{ lg: 5 }}>
                        <CustomAccordion
                          isExpanded={true}
                          title="Organiser"
                          titleFontSize="20px"
                          titleFontWeight={700}
                          padding="0px"
                          noBorderRadius
                          noInlineBorder
                          noBlockBorder
                        >
                          <Stack
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              variant="caption"
                              fontSize="1rem"
                              fontWeight={400}
                              color="#343434"
                            >
                              {eventdet.promoters[0].name}
                            </Typography>
                            {eventdet.stixLite &&
                              eventdet.promoters[0].email !== null && (
                                <Button
                                  disableElevation
                                  variant="outlined"
                                  className={[
                                    "button-contact",
                                    smallDown ? "wder" : "",
                                  ].join(" ")}
                                  sx={{ mt: 4 }}
                                  onClick={() => setIsorganizerform(true)}
                                >
                                  CONTACT
                                </Button>
                              )}
                            {eventdet.stixLite && (
                              <img
                                src={EventCreatedWithStixLite}
                                alt="event created with stixlite"
                              />
                            )}
                          </Stack>
                          <Divider
                            sx={{ m: "5px 0", borderColor: "#F0F0F0" }}
                          />
                        </CustomAccordion>
                      </Box>
                      {eventdet.rectangle_image &&
                        eventdet.rectangle_image.length > 0 && (
                          <Grid
                            item
                            sx={{ display: smallDown && "none", p: "15px" }}
                          >
                            {eventdet.rectangle_image.map((obj, index) => {
                              return (
                                <Box id={index}>
                                  <img
                                    src={obj.image}
                                    style={{
                                      objectFit: "center",
                                    }}
                                  />
                                </Box>
                              );
                            })}
                          </Grid>
                        )}
                    </Grid>
                  )}
                </Grid>

                {/* Promotions Tab if any from API */}
                {eventdet && eventdet.promotions.length > 0 && (
                  <Grid item xs={12} md={8} lg={9} mt={"24px"} mb={"12px"}>
                    <CustomAccordion
                      isExpanded={true}
                      title={"Promotions"}
                      titleFontSize={isMobile ? "16px" : "20px"}
                      titleFontWeight={700}
                      padding="0px"
                      marginTop={2}
                      noBorderRadius
                      noInlineBorder
                      noBlockBorder
                    >
                      {eventdet.promotions.map((obj, index) => {
                        return (
                          <CustomAccordion
                            isExpanded={false}
                            title={obj.title}
                            titleFontSize={isMobile ? "14px" : "16px"}
                            titleFontColor="#626262"
                            titleFontWeight={700}
                            titleFontFamily="Rubik, sans-serif"
                            chevronFontSize="16px"
                            padding="0px"
                            marginTop={2}
                            noBorderRadius
                            noInlineBorder
                            noBlockBorder
                          >
                            <Stack spacing={2}>
                              <Stack>
                                <Typography
                                  variant="caption"
                                  fontSize="1rem"
                                  fontWeight={400}
                                  color="#626262"
                                >
                                  <p
                                    className={"p"}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        obj &&
                                        AllFunctions.addTargetToHref(
                                          obj.description
                                        ),
                                    }}
                                  />
                                </Typography>
                              </Stack>
                            </Stack>
                          </CustomAccordion>
                        );
                      })}
                    </CustomAccordion>
                    <Divider sx={{ m: "5px 0", borderColor: "#F0F0F0" }} />
                  </Grid>
                )}

                {/* Content in "Tabs" from API */}
                <Grid item xs={12} md={8} lg={9}>
                  {eventdet.tabs &&
                    eventdet.tabs.length > 0 &&
                    eventdet.tabs.map((obj, idx) => {
                      return (
                        <>
                          <CustomAccordion
                            isExpanded={true}
                            title={obj.title}
                            titleFontSize={isMobile ? "16px" : "20px"}
                            titleFontWeight={700}
                            padding="0px"
                            marginTop={2}
                            noBorderRadius
                            noInlineBorder
                            noBlockBorder
                          >
                            <Stack spacing={2}>
                              <Stack>
                                <Typography
                                  variant="caption"
                                  fontSize="1rem"
                                  fontWeight={400}
                                  color="#626262"
                                >
                                  <p
                                    className={"p"}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        obj &&
                                        AllFunctions.addTargetToHref(
                                          obj.description
                                        ),
                                    }}
                                  ></p>
                                </Typography>
                              </Stack>
                            </Stack>
                          </CustomAccordion>
                          <Divider
                            sx={{ m: "5px 0", borderColor: "#F0F0F0" }}
                          />
                        </>
                      );
                    })}
                </Grid>
                {/* )} */}
              </Grid>
            </Grid>
          )}

          {eventdet && eventdet.data && eventdet.data.length === 0 && (
            <Grid container className={"invalidEvent"}>
              <div className={"txt"}>The event is currently not available.</div>
            </Grid>
          )}
        </Box>
      </Container>

      {isMobile &&
        eventdet.rectangle_image &&
        eventdet.rectangle_image.length > 0 && (
          <Container sx={{ maxWidth: "1100px" }}>
            <Grid item xs={12} className={"content"}>
              {eventdet.rectangle_image.map((obj, index) => {
                return (
                  <Box id={index}>
                    <img
                      src={obj.image}
                      style={{
                        objectFit: "center",
                      }}
                    />
                  </Box>
                );
              })}
              <Divider sx={{ m: "5px 0", borderColor: "#F0F0F0" }} />
            </Grid>
          </Container>
        )}

      {similarEvents && similarEvents.length > 0 && (
        <>
          <Container sx={{ maxWidth: "1100px" }}>
            <EventListing
              title={"Similar Events"}
              items={similarEvents}
              swipe={true}
              className={"stix-featured"}
              isMobile={isMobile}
              slidesToShow={4}
              slidesToScroll={4}
              subtitleClass={"hide"}
              seeMoreClass={"hide"}
              infinite={false}
            />
          </Container>
        </>
      )}

      {articleEvents && articleEvents.length > 0 && (
        <>
          <Container sx={{ maxWidth: "1100px" }}>
            <EventListing
              title={"Articles"}
              items={articleEvents}
              swipe={true}
              infinite={false}
              className={"stix-featured"}
              isMobile={isMobile}
              slidesToShow={4}
              slidesToScroll={4}
              subtitleClass={"hide"}
              seeMoreClass={"hide"}
            />
          </Container>
        </>
      )}
    </div>
  );
};

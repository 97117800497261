import {API} from '../../helper/services';

class ContactUsService {
  getCSRFToken() {
    return API.get(`rest/session/token`);
  }

  getEnquiry(params) {
    return API.get(`contact-us/enquiry-categories`, { params });
  }

  getContactDetail() {
    return API.get(`contact-us/details`);
  }

  getFaqs(params) {
    return API.get(`contact-us/faq`, { params });
  }

  formSubmission(data) {
    return API.post(`contact-us/store`, data);
  }

  uploadAttachement(files) {
    return API.post('service/upload', files);
  }
}

export default new ContactUsService();

export const placeholderData = [
    {
      "title": "Loading...",
      "alt_text": "",
      "full_image": "",
      "thumb_image": "",
      "venue_name": "",
      "tracking_code": "",
      "tracking_template": "",
      "navigation_link": "",
      "alias": "",
      "record_type": "event",
      "tag_name": "",
      "tag_colour": null
    },
    {
      "title": "Loading...",
      "alt_text": "",
      "full_image": "",
      "thumb_image": "",
      "venue_name": "",
      "tracking_code": "",
      "tracking_template": "",
      "navigation_link": "",
      "alias": "",
      "record_type": "event",
      "tag_name": "",
      "tag_colour": null
    },
    {
      "title": "Loading...",
      "alt_text": "",
      "full_image": "",
      "thumb_image": "",
      "venue_name": "",
      "tracking_code": "",
      "tracking_template": "",
      "navigation_link": "",
      "alias": "",
      "record_type": "event",
      "tag_name": "",
      "tag_colour": null
    },
    {
      "title": "Loading...",
      "alt_text": "",
      "full_image": "",
      "thumb_image": "",
      "venue_name": "",
      "tracking_code": "",
      "tracking_template": "",
      "navigation_link": "",
      "alias": "",
      "record_type": "event",
      "tag_name": "",
      "tag_colour": null
    },
    {
      "title": "Loading...",
      "alt_text": "",
      "full_image": "",
      "thumb_image": "",
      "venue_name": "",
      "tracking_code": "",
      "tracking_template": "",
      "navigation_link": "",
      "alias": "",
      "record_type": "event",
      "tag_name": "",
      "tag_colour": null
    },
  ]
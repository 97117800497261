import React, { Fragment } from 'react';
import './style.scss';
import arrowWhite from '../../../../src/assets/images/career/opening-blue-arrow.svg';
import arrowBlack from '../../../../src/assets/images/career/opening-black-arrow.svg';
import { Link } from 'react-scroll';
import { Utilities } from '../../../utils';

const OurTeam = ({ banner, shimmer }) => {
  return (
    <Fragment>
      {banner && banner.title ? (
        <div className="row our-team no-gutters">
          <div className="col-lg-5 col-md-6 our-team-content">
            <div className="ourteam-inner">
              <h4>{banner && banner.title}</h4>
              <h2>{banner && banner.subtitle}</h2>
              <div
                className="career-subtext"
                dangerouslySetInnerHTML={{
                  __html: banner && banner.description
                }}
              ></div>
              <Link to="opening" offset={Utilities.mobilecheck() ? -20 : -50}>
                <button>
                  See Job Openings
                  <img className="arrow-white" src={arrowWhite} alt="arrow" />
                  <img
                    className="arrow-black"
                    src={arrowBlack}
                    alt="black-arrow"
                  />
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-7 col-md-6 our-team-img"></div>
        </div>
      ) : (
        shimmer()
      )}
    </Fragment>
  );
};

export default OurTeam;

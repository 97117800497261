import * as templates from '../pages/templates';
import queryString from "query-string";

const AllFunctions = {
    createScriptElement: (url, async = true) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = async;
        document.head.appendChild(script);
    },
    createLinkElement: (href, rel ) => {
        const link = document.createElement('link');
        link.href = href;
        link.rel = rel;
        document.head.appendChild(link);
    },
    createCustomLinkElement: (label = 'href', hrefVal, rel ) => {
        const link = document.createElement('link');
        link.setAttribute(label, hrefVal.toString());
        link.setAttribute("rel", rel);
        document.head.appendChild(link);
    },
    createMetaElement: (name, content ) => {
        const meta = document.createElement('meta');
        meta.name = name;
        meta.content = content;
        document.head.appendChild(meta);
    },
    createCustomMetaElement: (label = 'name', name, content ) => {
        const meta = document.createElement('meta');
        meta.setAttribute(label, name);
        meta.content = content;
        document.head.appendChild(meta);
    },
    removeMetaWithSameName: (label, name) => {
        const counts = document.querySelectorAll("meta["+label+"='"+name+"']").length;
        for(let i=1; i<=counts; i++){
            document.querySelector("meta["+label+"='"+name+"']").remove();
        }
    },
    customTitle: (name = '') => {
        window.document.title = name;
    },
    loadTemplate: (env) => {
        const template = templates[env];
        return template;
    },
    addEllipsis: (str, limit) => {
        return (str.length > limit) ? str.slice(0, limit-1) + '...' : str;
    },
    removeTags: (str) => {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
        return str.replace( /(<([^>]+)>)/ig, '');
    },
    getCookie: (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return decodeURIComponent(parts.pop().split(';').shift());
    },
    addTargetToHref: (str) => {
        let newString = str;
        if(newString){
            newString = newString.replace(/href="(.*?)"/, function(m, $1) {
                return 'href="' + $1 + '" target="_blank"';
            });
        }
        return newString;
    },
    eventsDeleteUrlParam: (uparam = '') => {
        let q = queryString.parse(window.location.search);
        let nurl = "";
        if(uparam !=='' && q[uparam]){
            delete q[uparam];
        }

        if(Object.keys(q).length > 0){
            nurl = "/events?"+queryString.stringify(q);
        }else{
            nurl = "/events";
        }

        window.history.pushState(null, false, nurl);
    },
    convertTZ: (date, tzString, defaultdate = '') => {
        return new Date(
          (typeof date === "string" ? new Date(date) : date).toLocaleString(
            "en-US",
            { timeZone: tzString }
          )
        );
    }

}

export {
    AllFunctions
};
import "./style.scss";
import React from "react";
import { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Box,
  Collapse,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material/";

const BmgTable = () => {
  const [prodTypeUuids, setProdTypeUuids] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [order, setOrder] = useState("asc"); // 'asc' or 'desc'
  const [orderBy, setOrderBy] = useState("title"); // column to sort by
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const prodApiKey = "d091cf0070f13291978c27011e14466e8e07fd52";
  const headers = {
    "X-Authorization": prodApiKey,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get list of products
        const response = await fetch("/v2/products", { headers });
        const data = await response.json();
        const prodUuidsArray = await data.data.map((prod) => prod.uuid);

        // Get list of unique product types
        const uniqueProdTypeUuids = [];
        const fetchProductTypes = prodUuidsArray.map(async (uuid) => {
          const response = await fetch(`/v2/products/${uuid}/product-types`, {
            headers,
          });
          const data = await response.json();
          data.data.map((prod) =>
            !uniqueProdTypeUuids.includes(prod.uuid)
              ? uniqueProdTypeUuids.push(prod.uuid)
              : ""
          );
        });

        // Wait for all promises to resolve
        await Promise.all(fetchProductTypes);
        setProdTypeUuids(uniqueProdTypeUuids);
        setIsButtonDisabled(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchProdTypeDetailsAndPricing = async () => {
    setIsButtonDisabled(true);
    setIsLoading(true);

    try {
      const combinedArray = [];
      const fetchProductTypeDetails = prodTypeUuids.map(async (uuid) => {
        // Get product type details
        const prodTypeDetails = await fetch(`/v2/product-types/${uuid}`, {
          headers,
        });
        const prodTypeDetailsJson = await prodTypeDetails.json();

        // Get product type pricing
        const prodTypePricing = await fetch(
          `/v2/product-types/${uuid}/price-lists`,
          { headers }
        );
        const prodTypePricingJson = await prodTypePricing.json();
        const prodTypePricingObj = {
          pricing: prodTypePricingJson.data,
        };

        // Combine both and add to array
        const combinedObject = {
          ...prodTypeDetailsJson.data,
          ...prodTypePricingObj,
        };
        combinedArray.push(combinedObject);
      });

      // Wait for all promises to resolve
      await Promise.all(fetchProductTypeDetails);
      setProdList(combinedArray);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const checkIfBlank = (data) => {
    if (typeof data === "undefined" || data === null || data === false) {
      return "-";
    } else {
      return data;
    }
  };

  // start of sorting functions
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const toBeSortedArray = prodList.slice();
    toBeSortedArray.sort(getComparator(order, orderBy));

    setProdList(toBeSortedArray);
  };
  // end of sorting functions

  const ProgressBar = (props) => {
    const { duration } = props;
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, duration);

      return () => {
        clearInterval(timer);
      };
    }, []);

    return (
      <Box className={"progress-bar-wrapper"}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    );
  };

  const selectedVariables = [
    "title",
    "uuid",
    "firstAvailabilityDate",
    "recommendedMarkup",
    "childRecommendedMarkup",
    "seniorRecommendedMarkup",
    "adultParityPrice",
    "childParityPrice",
    "seniorParityPrice",
  ];

  const Row = (props) => {
    const { prod } = props;
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow key={prod.uuid} hover>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          {selectedVariables.map((variable, index) => (
            <TableCell align={index === 0 ? "left" : "right"}>
              {checkIfBlank(prod[variable])}
            </TableCell>
          ))}
          <TableCell align="right">
            {checkIfBlank(prod.validity.date)}
          </TableCell>
        </TableRow>
        {/* Collapsible Section */}
        <TableRow>
          <TableCell style={{ padding: "0px" }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Typography gutterBottom>Pricing Details</Typography>
                <Table size="small" aria-label="pricing-details">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Day of the Week</TableCell>
                      <TableCell align="right">Adult Pricing</TableCell>
                      <TableCell align="right">Child Pricing</TableCell>
                      <TableCell align="right">Senior Pricing</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prod.pricing &&
                      prod.pricing.map((data) => (
                        <TableRow hover>
                          <TableCell>{data.date}</TableCell>
                          <TableCell>{data.weekday}</TableCell>
                          <TableCell align="right">
                            {checkIfBlank(data.prices.adults["1"])}
                          </TableCell>
                          <TableCell align="right">
                            {checkIfBlank(data.prices.children["1"])}
                          </TableCell>
                          <TableCell align="right">
                            {checkIfBlank(data.prices.seniors["1"])}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <div className="bmg-table-container">
      <TableContainer>
        <Table aria-label="BMG Products" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={order}
                  onClick={() => handleSort("title")}
                  sx={{ minWidth: "300px" }}
                >
                  Product Type Name
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" sx={{ minWidth: "175px" }}>
                Product Type UUID
              </TableCell>
              <TableCell align="right">firstAvailabilityDate</TableCell>
              <TableCell align="right">recommendedMarkup</TableCell>
              <TableCell align="right">childRecommendedMarkup</TableCell>
              <TableCell align="right">seniorRecommendedMarkup</TableCell>
              <TableCell align="right">adultParityPrice</TableCell>
              <TableCell align="right">childParityPrice</TableCell>
              <TableCell align="right">seniorParityPrice</TableCell>
              <TableCell align="right" sx={{ minWidth: "110px" }}>
                validity
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prodList.length > 0 && prodList.map((prod) => <Row prod={prod} />)}
          </TableBody>
        </Table>
      </TableContainer>

      <LoadingButton
        variant="outlined"
        disabled={isButtonDisabled}
        loading={isLoading}
        onClick={fetchProdTypeDetailsAndPricing}
      >
        <span>Generate rows</span>
      </LoadingButton>

      <Box className={"text-top"}>
        Total number of Product Types:{" "}
        {prodTypeUuids.length === 0 ? (
          <ProgressBar duration={400} />
        ) : (
          prodTypeUuids.length
        )}
      </Box>

      <Box className={"text-btm"}>
        {isLoading ? (
          <>
            Generating Rows...
            <ProgressBar duration={3000} />
          </>
        ) : (
          prodList.length !== 0 && (
            <> Number of Rows generated: {prodList.length}</>
          )
        )}
      </Box>
    </div>
  );
};

export default BmgTable;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'

export const HotShow = (props = {}) => {
    const { closeBox } = props;
    const hotShowCards = (item, index) => {
        return(
            <div className="stxmodal-card" key={index}>
                <div className="stxmodal-image">
                    <img 
                        src={item.full_image} 
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src="/imgs/default-banner-mobile.png"; // default fallback img
                        }}
                        alt={"hot show banner"}/>
                </div>
                <div className="stxmodal-description" dangerouslySetInnerHTML={{__html: item.description}}></div>
                <div className="stxmodal-buttons">
                    <a className="button-m"  href={item.buttons[0].b_url}>
                        {item.buttons[0].b_name ? item.buttons[0].b_name : "BUY TICKETS"}
                    </a>
                </div>
            </div>
        )
    }

    return(
        <div className='stxmodal'>
            <div className="stxmodal-dialog">
                <div className="stxmodal-content">
                    <div className="stxmodal-header">
                        <h4 className="stxmodal-title" id="myModalLabel">{"Get your tickets now!"}</h4>
                    </div>
                    <div className="stxmodal-body">
                        { props.data && props.data.map((item,index) =>
                            { return (
                                    hotShowCards(item, index)
                                )
                            }
                        )}
                    </div>
                </div>
                <a href="https://www.sistic.com.sg" className="stxmodal-closebox" onClick={(event) => closeBox(event)} aria-label="Close">
                    <FontAwesomeIcon icon={faX} />
                </a>
            </div>
        </div>
    )
}
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
  Box,
  Typography
} from "@mui/material";
import ExploreService from '../../services/ExploreService';
import useDeviceDetect from '../../hooks/useDeviceDetect'

export const ExploreContainer = ({ pageType }) => {
  const { isMobile } = useDeviceDetect();
  const [state, setState] = useState({
    selectedTab: pageType
  });

  const [content, setContent] = useState({
    conditions:{
      description:"",
      title:""
    },
    cookie_policy:{
      description:"",
      title:""
    },
    privacy_policy:{
      description:"",
      title:""
    },
    terms_cond:{
      description:"",
      title:""
    }
  });

  useEffect(()=>{
    setState({...state, selectedTab: pageType});
  },[pageType]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    fetchExploreLandingDestails();
  }, []);

  const fetchExploreLandingDestails = () => {
    ExploreService.getExploreLanding()
      .then(res => {
        if (res && res.data && res.data.data) {
          let data = res.data.data;
          setContent({
            ...content,
            ...data
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleTabChange = (event, newValue) => {
    setState({...state, selectedTab: newValue});
    window.history.pushState(null, false, "/"+stixItems[newValue].urlParam);
  }

  return (
    <Fragment>
      <section className="explore-container">
        <div className="explore-header">
          {/* <img src={termsBanner} className="img-fluid" alt="page-banner" /> */}
          <div className="banner-overlay">
            <h1>testing </h1>
          </div>
        </div>
        <div className={"explore-body"}>
          
        </div>
      </section>
    </Fragment>
  );
};


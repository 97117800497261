import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button
} from '@mui/material';
import Slider from 'react-slick';
import {SectionTitle} from '../';
import ReactPlayer from 'react-player/lazy';
import { useNavigate  } from "react-router-dom";
import { Utilities } from '../../utils';


export const SliderWithTwoColumns = (props = {}) => {
    const [isMobile, setIsMobile] = React.useState(props.isMobile);
    const [vidplayer, setVidPlayer] = React.useState({});
    const sliderRef = useRef();
    const navigate = useNavigate();

    const handleOnClick = (e) => {
        const url = e.currentTarget.id;
        if(url){
             if(url.includes("http")){
                 window.location.href =url;
             }else{
                 navigate(url);
             }
        } 
     }


    const handleClickPrev = () => {
        let prevBtn = document.querySelector('.slider-video-dots-arrow-left')
        let firstParentNode = prevBtn.parentElement
        let slickPrevBtn = firstParentNode.parentElement.parentElement.firstChild
        slickPrevBtn.click();
    }

    const handleClickNext = () => {
        let nextBtn = document.querySelector('.slider-video-dots-arrow-right') 
        let firstParentNode = nextBtn.parentElement
        let slickNextBtn = firstParentNode.parentElement.previousSibling
        slickNextBtn.click();
    }

    var items = [];
    if(props.items){
        items = props.items
    }

    const onSeeMore = () => {
        if(props.seeMore) window.location.href = props.seeMore;
    }

    const handleBeforeChange = () => {
        //stop the all the videos when slide to next
        if(vidplayer && Object.keys(vidplayer).length > 0){
            Object.keys(vidplayer).map((row)=>{
                setVidPlayer({
                    ...vidplayer,
                    [row]:false
                });
            });
            
        }
    }

    const handleOnPlay = (id) => {
        //add player to state when started playing
        setVidPlayer({
            ...vidplayer,
            [id]:true
        });
    }
    const handleOnLitePlay = (data,id) => {
        let newList = {
            ...vidplayer
        }
        newList[id]=true;
        //add player to state when started playing
        setVidPlayer(newList);
    }

    // useEffect(()=>{
    //     sliderRef.current.slickGoTo(0); // to default to first video
    //     setIsMobile(props.isMobile);
    // },[props.isMobile]);

    return (<>
        <div 
            className={['stix-featured-container normal', props.containerClass && props.containerClass].join(" ")}
            style={{
                background: props.bg ? props.bg : 'transparent',
                backgroundSize: 'cover',
                minHeight: '354px'
            }}
            >
            <SectionTitle 
                title={props.title && props.title}
                seeMore={props.isSeeMore && <a onClick={onSeeMore}>See more</a>}
                />
            <Slider
                {...props}
                infinite={true}
                speed={500}
                initialSlide={0}
                focusOnSelect={true}
                pauseOnHover={true}
                draggable={true}
                lazyLoad= {false}
                dots={true}
                beforeChange={handleBeforeChange}
                arrows={true}
                // ref={sliderRef}
                appendDots={ (dots) => (
                    <Grid container id={"slider-video-dots-container"} key={dots}>
                        <Grid item xs={1} md={5} style={{zIndex:4}}>
                            <span onClick={() => handleClickPrev()} className={"slider-video-dots-arrow-left"}>&nbsp;</span>
                        </Grid>
                        <Grid item xs={10} md={2} style={{zIndex:2}}>
                            <ul className={"slider-video-dots"}> {dots} </ul>
                        </Grid>    
                        <Grid item xs={1} md={5} style={{zIndex:4}}>
                            <span onClick={() => handleClickNext()} className={"slider-video-dots-arrow-right"}>&nbsp;</span>
                        </Grid>  
                    </Grid>
                    )}
                >
                    
                {
                    items.map( (item, i) => { 
                         if(i < 20 && item){
                            return (<div key={i}><Grid container spacing={2} className={'sistic-vid'}>
                                     <Grid item xs={12} md={6} className={'sistic-vid-left'}>
                                        
                                        <ReactPlayer 
                                            className={"react-player video-panel"}
                                            id={i}
                                            light={<img src={item.video_thumb} alt='Thumbnail' />}
                                            playing={(vidplayer) ? vidplayer["vidcon"+i] : false}
                                            onPlay={()=>handleOnPlay("vidcon"+i)}
                                            onClickPreview={(data)=>handleOnLitePlay(data,"vidcon"+i)}
                                            url={item.video_url} 
                                            />
                                     </Grid>
                                     <Grid item xs={12} md={6} className={'sistic-vid-right'}>
                                        <label>Featured this month</label>
                                        <h4 className={'h4'}>{item.title}</h4>
                                        <p className={"p"} dangerouslySetInnerHTML={{__html: item.description ? Utilities.showLimitedChars(item.description, 250) : ''}} /> 
                                        <div className={'homepage-video-buy-tickets'}>
                                            <Button 
                                                variant="contained"
                                                className={"buyTicks"}
                                                href={item.buy_ticket_url}
                                            >
                                                BUY TICKETS
                                            </Button> 
                                        </div>
                                     </Grid>
                            </Grid></div>);
                        }
                  })
                }
            </Slider>
            
            
            
        </div>
    </>);
}

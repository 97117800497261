import React,{useEffect} from 'react';
import { StixOverlayLoader } from '../components';
import { useLocation, useNavigate } from "react-router-dom";

const toRedirectList = [/*'terms-and-conditions','privacy-policy','conditions-of-access','cookie-policy','explore', 'promotions' */];

export const ForRedirectionPage = (props) => {
    const location = useLocation ();
    const navigate = useNavigate();
    useEffect(()=>{
        if(location.pathname && location.pathname.length > 0){
            const splitPathName = location.pathname.split("/");
            if(splitPathName.length > 1){
                if(toRedirectList.includes(splitPathName[1])){
                    /** redirect to explore page */
                    window.location.href = 'https://www.sistic.com.sg' + location.pathname;
                }else{
                    navigate('/404'); // just throw 404 page
                }
            }else{
                console.log(splitPathName);
            }

        }
    },[]);

    return (<div style={{minHeight: '500px'}}>
            <StixOverlayLoader />
    </div>);
}

import { Box, Fade, Grid, Typography } from "@mui/material";
import { useState } from "react";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { Tooltip } from "@mui/material";

export const CustomAccordion = ({
  isExpanded = false,
  title = "",
  children,
  noBorderRadius = false,
  noInlineBorder = false,
  noBlockBorder = false,
  showExpandIconWithGreyBg = false,
  padding = "18px",
  titleFontSize = "1rem",
  titleFontWeight = 400,
  titleFontColor = "#343434",
  titleFontFamily = "Montserrat, sans-serif",
  chevronFontSize = "24px",
  marginTop = 0,
  tooltip = "",
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  return (
    <Grid
      container
      sx={{
        ...(!noInlineBorder && { borderInline: "1px solid #D9DBE1" }),
        ...(!noBlockBorder && { borderBlock: "1px solid #D9DBE1" }),
        ...(!noBorderRadius && {
          borderRadius: (theme) => theme.custom.borderRadius,
        }),
        paddingBlock: "unset",
        padding,
      }}
      mt={marginTop}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          cursor: "pointer",
          userSelect: "none",
          mb: 1,
        }}
        onClick={() => setExpanded((expanded) => !expanded)}
      >
        <Grid container spacing={1}>
          <Grid item xs={11}>
            {typeof title === "string" ? (
              <Typography
                variant="details"
                fontSize={titleFontSize}
                fontWeight={titleFontWeight}
                sx={{
                  color: titleFontColor,
                }}
                style={{ fontFamily: titleFontFamily }}
              >
                {title}
                {tooltip}
              </Typography>
            ) : (
              <>{title}</>
            )}
          </Grid>
          <Grid item xs={1}>
            {showExpandIconWithGreyBg ? (
              <Box
                sx={{
                  background: (theme) => theme.palette.primary.light,
                  borderRadius: "50%",
                  px: 1.1,
                  pt: 0.7,
                  mr: 2.5,
                }}
              >
                <ExpandLessOutlinedIcon
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    fontSize: chevronFontSize,
                    ...(!expanded && { transform: "rotate(180deg)" }),
                  }}
                />
              </Box>
            ) : (
              <ExpandLessOutlinedIcon
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontSize: chevronFontSize,
                  ...(!expanded && { transform: "rotate(180deg)" }),
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      {expanded && (
        <Fade in={expanded}>
          <Box sx={{ width: "100%" }}>{children}</Box>
        </Fade>
      )}
    </Grid>
  );
};
import React, { useEffect, useState } from "react";
import BaseContext from "../contexts";
import { EventListing, EventsPage} from "../components";
import useDeviceDetect from "../hooks/useDeviceDetect";

export const NewEventResultPage = (props) => {
  const base = React.useContext(BaseContext);
  const { isMobile } = useDeviceDetect();
  const topPicks = base.data.recommendations && base.data.recommendations;

  return (
    <div className={"eventsPage"} style={{ maxWidth: "1093px", margin: "0 auto", minHeight:"300px" }}>
      <EventsPage {...props}/>

      {topPicks && topPicks.length > 0 && (
        <div className={'events-browse-top-picks'}>
          <EventListing
            title={"You May Also Like"}
            items={topPicks}
            swipe={true}
            className={"stix-featured"}
            isMobile={isMobile}
            slidesToShow={5}
            slidesToScroll={5}
          />
        </div>
      )}
    </div>
  );
};

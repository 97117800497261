import { any } from 'prop-types';
import React,{useEffect} from 'react';
import BaseContext from '../../../../contexts';
import {SisticHeader} from './sisticHeader';


export const Header = (props) => {

    return (
    <BaseContext.Consumer>
        {data => (
            <HeaderContent 
                isLandingPage={props.isLandingPage}
                data={data}
                />
        )}
    </BaseContext.Consumer>
    );
}



const HeaderContent = (props) => {
    let conf = props.data;
    return (<>
            {(conf.data.config && Object.keys(conf.data.config).length > 0) && <SisticHeader {...props} data={conf.data}
                header={conf.data.config.header}/>}
    </>)
}

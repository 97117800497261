import React, { Fragment, useState, useEffect } from 'react';
import { Constants } from '../../utils';
import GiftVouchersService from '../../services/GiftVouchersService';
import GiftVoucherHeader from './GiftVoucherHeader';
import Vouchers from './Vouchers';
import SendGiftCard from './SendGiftCard';
import './style.scss';
import ShimmerEffect from '../../components/ShimmerEffect';
//import MetaData from '../../shared/components/MetaData';

const GiftVouchers = props => {
  // const [GiftVouchersDetails, SetGiftVouchersDetails] = useState(null);
  const [state, setState] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const params = {
      client: Constants.CLIENT
    };
    if (!state.data) {
      GiftVouchersService.getGiftVouchers(params)
        .then(res => {
          if (res && res.data && res.data.data) {
            setState(res.data)
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  return state.data ? (
    <Fragment>
      <div className="giftvoucher-wrapper">
        {/* <MetaData location={props.location} data={props.staticContext} /> */}
        <GiftVoucherHeader
          bannerDescription={state.data.banner_description}
          bannerUrl={state.data.banner_url}
        />
        <div className="giftvoucher-body">
          <div className="container">
            <div className="easy-give-receive">
              <h1>{state.data.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: state.data.description
                }}
              />
            </div>
          </div>
          <div className="pick-voucher" id="gift-boucher">
            <div className="container-fluid custom-container">
              <h3>Pick a Voucher that suits you best</h3>
              <Vouchers vouchers={state.data.vouchers} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <ShimmerEffect
      propCls="shm_col-xs-6 col-md-12"
      height={80}
      count={3}
      type="TILE"
    />
  );
};

export default GiftVouchers;

import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
} from "@mui/material";
import BaseContext from "../../../contexts";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { EventCard } from "../../planYourVisit/components/EventCard";

export const Items = (props) => {
  const base = React.useContext(BaseContext);
  const middleBanner =
    base.data.midAdvertisement && base.data.midAdvertisement.data;
  const { smallDown } = useCustomMediaQuery();
  const [detailsState, setDetailsState] = useState(
    props.details ? props.details : []
  );
  const eventlimit = smallDown ? 7 : 11;

  useEffect(() => {
    // monitor props change
  }, [props]);

  return (
    <Box className={"eventListings-container"}>
      <Grid
        container
        columnSpacing={{
          sm: "15px",
          xs: "10px",
        }}
        rowSpacing={{
          sm: "20px",
          xs: "15px",
        }}
      >
        {props.details &&
          props.details.map((event, idx) => (
            <>
              <Grid item>
                <EventCard details={event} />
              </Grid>
              {idx == eventlimit && (
                <div className={"events-browse-midpage-banner"}>
                  {middleBanner && (
                    <Grid item xs={12}>
                      {middleBanner[0] && (
                        <a
                          href={
                            middleBanner[0].navigation_link
                              ? middleBanner[0].navigation_link
                              : "#"
                          }
                          target={
                            middleBanner[0].navigation_link ? "_blank" : ""
                          }
                        >
                          <img
                            src={middleBanner[0].full_image}
                            alt={middleBanner[0].title}
                          />
                        </a>
                      )}
                    </Grid>
                  )}
                </div>
              )}
            </>
          ))}
      </Grid>
    </Box>
  );
};

export default Items;

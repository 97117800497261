import React, { useEffect, useState, useContext } from "react";
import BaseContext from "../../../contexts";
import { Slider, TextField, InputAdornment, Divider } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export const PriceRange = (props) => {
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [pricesList, setPricesList] = useState({});
  const [barData, setBarData] = useState({});

  const barWidth = 10; // width of each histogram bar
  const data = props.prices;
  const minPrice = data[0];
  const maxPrice = Math.ceil(data.slice(-1) / barWidth) * barWidth;
  const base = useContext(BaseContext);

  useEffect(() => {
    const data = props.prices;
    const priceRanges = {};

    for (const number of data) {
      // calc which range (bar) the current int belongs to
      const bar = Math.floor(number / barWidth) * barWidth;

      // increment frequency of each bar
      if (priceRanges[bar] === undefined) {
        priceRanges[bar] = 1;
      } else {
        priceRanges[bar]++;
      }
    }

    // set frequency of non-existent bars to 0
    for (let i = 0; i <= Math.max(...data); i += barWidth) {
      if (priceRanges[i] === undefined) {
        priceRanges[i] = 0;
      }
    }
    setPricesList(priceRanges);

    if (
      base.data &&
      base.data.user &&
      base.data.user.planVisit &&
      base.data.user.planVisit.priceRange
    ) {
      setSelectedPrice([
        base.data.user.planVisit.priceRange.min,
        base.data.user.planVisit.priceRange.max,
      ]);
    } else {
      setSelectedPrice([minPrice, maxPrice]);
    }
  }, []);

  const onPriceRangeChange = () => {
    props.updateBase &&
      props.updateBase({
        user: {
          ...base.data.user,
          planVisit: {
            ...base.data.user.planVisit,
            priceRange: { min: selectedPrice[0], max: selectedPrice[1] },
          },
        },
      });
  };

  const handleSliderChange = (event, newPriceRange, activeThumb) => {
    if (!Array.isArray(newPriceRange)) {
      return;
    }

    // keep minimum distance between the 2 slider thumbs
    if (activeThumb === 0) {
      setSelectedPrice([
        Math.min(newPriceRange[0], selectedPrice[1] - barWidth),
        selectedPrice[1],
      ]);
    } else {
      setSelectedPrice([
        selectedPrice[0],
        Math.max(newPriceRange[1], selectedPrice[0] + barWidth),
      ]);
    }
  };

  const handleInputChange = (e) => {
    const numStr = e.target.value;
    if (numStr === "") {
      if (e.target.id === "minInput") {
        setSelectedPrice([0, selectedPrice[1]]);
      } else if (e.target.id === "maxInput") {
        setSelectedPrice([selectedPrice[0], maxPrice]);
      }
    }

    const num = parseInt(numStr);
    const regex = /^[0-9\b]+$/;
    if (regex.test(numStr)) {
      if (e.target.id === "minInput") {
        setSelectedPrice([num, selectedPrice[1]]);
      }
      if (e.target.id === "maxInput") {
        setSelectedPrice([selectedPrice[0], num]);
      }
    }
  };

  const updateGraph = (priceRanges) => {
    setBarData({
      labels: Object.keys(priceRanges),
      datasets: [
        {
          data: Object.values(priceRanges),
          backgroundColor: Object.keys(priceRanges).map((val) =>
            val >= selectedPrice[0] && val < selectedPrice[1]
              ? "#11416C"
              : "#E6F3FB"
          ),
        },
      ],
    });
  };

  useEffect(() => {
    updateGraph(pricesList);
    onPriceRangeChange();
  }, [selectedPrice]);

  const graphOptions = {
    elements: {
      bar: { borderRadius: 2 },
    },
    scales: {
      x: { display: false },
      y: { display: false },
    },
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };

  return (
    <div className={"plan-visit-step-priceRange"}>
      <h2>What is your budget?</h2>
      <div className="price-slider-container">
        {Object.keys(barData).length > 0 && (
          <div className="histogram-wrapper">
            <Bar data={barData} options={graphOptions} redraw={false} />
            <Slider
              min={minPrice}
              max={maxPrice}
              value={selectedPrice}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
            />
          </div>
        )}

        <div className="input-boxes-container">
          <TextField
            label="Minimum"
            id="minInput"
            value={selectedPrice[0]}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">S$</InputAdornment>
              ),
              disableUnderline: true,
            }}
            variant="filled"
          />
          <Divider />
          <TextField
            label="Maximum"
            id="maxInput"
            value={selectedPrice[1]}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">S$</InputAdornment>
              ),
              disableUnderline: true,
            }}
            variant="filled"
          />
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import '../bootstrap/bootstrap.scss';
import '../bootstrap/bootstrap-grid.scss';
import './style.scss';
import { Constants } from '../../utils';
import FaqSearch from './FaqSearch';
import FaqCategory from './FaqCategory';
import FaqService from '../../services/FaqService';
import { Link, useParams } from 'react-router-dom';
//import PageNotFound from '../PageNotFound';
import ShimmerEffect from '../../components/ShimmerEffect';
//import MetaData from '../../shared/components/MetaData';

const contactUsLink = "https://www.corporate.sistic.com.sg/contact-us";

const Faq = props => {
  // const [faqContentData, setFaqContentData] = useState(null);
  const params = useParams();
  const [suggestionsData, setSuggestionsData] = useState([]);
  const [faqCategoryData, setFaqCategoryData] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [toggleContent, setToggleContent] = useState(true);
  const [suggestions, setSuggestions] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchFaqCategoriesService();
    if (suggestionsData?.length == 0) {
      fetchFaqContentService();
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (faqCategoryData?.length > 0 && params.id) {
      setLoading(true);
      let url = false;
      faqCategoryData.findIndex((category, index) => {
        if (category.name.toLowerCase().replace(/[^a-z]/g, '') === params.id.toLowerCase().replace(/[^a-z]/g, '')) {
          url = true;
          setLoading(false);
          setCategoryId(category.id);
          setCategoryName(category.name);
        } else if (!url && faqCategoryData.length === index + 1) {
          setLoading(null);
        }
      });
    }
  }, [params.id, faqCategoryData]);

  const fetchFaqCategoriesService = () => {
    const params = {
      client: Constants.CLIENT
    };
    FaqService.getFaqCategoriesService(params)
      .then(res => {
        setFaqCategoryData(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchFaqContentService = () => {
    const params = {
      client: Constants.CLIENT
    };
    FaqService.getFaqContentService(params)
      .then(res => {
        setTimeout(() => {
          // setFaqContentData(res.data.data);
          if (res && res.data && res.data.data) {
            setSuggestionsData(res.data?.data || [])
          }
        }, 700);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const toggleContentHandler = toggleValue => {
    setToggleContent(toggleValue);
  };

  const contactUsClicked = (event) => {
    event.preventDefault();
    window.location.href = "https://www.corporate.sistic.com.sg/contact-us";
  }

  return loading ? (
    <ShimmerEffect
      propCls="shm_col-xs-6 col-md-12"
      height={80}
      count={4}
      type="TILE"
    />
  ) : loading === false ? (
    <div onClick={() => setSuggestions(!suggestions)}>
      {/* <MetaData location={props.location} data={props.staticContext} /> */}
      <FaqSearch
        suggestions={suggestionsData}
        categories={faqCategoryData}
        setFilteredSuggestions={suggestions}
        toggleContentHandler={toggleContentHandler}
      />
      <div className="faq-body-wrapper">
        <div className="container-fluid custom-container">
          <FaqCategory
            {...props}
            categoryId={categoryId}
            categoryName={categoryName}
            categories={faqCategoryData} 
            faqContentData={suggestionsData}
            toggleContent={toggleContent}
            toggleContentHandler={toggleContentHandler}
          />
          <span className="faq-help-text">
            Still need help? <a href={contactUsLink} onClick={contactUsClicked}>Contact Us</a> Here
          </span>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default Faq;

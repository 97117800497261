import React, { useEffect, useState, useCallback } from 'react';
import { EventListing } from "../eventListing";

export const FeaturedEvents = (props = {}) => {
  const { isMobile, items } = props;
  const [widthSize, setWidthSize] = useState(window.innerWidth)
  let slidesToFeature = 5;

  let firstArray = [];
  let secondArray = [];
  let mobileDataArray = [...items];
  let dummyData = {
    id: "",
    title: "",
    alt_text: "",
    full_image: "",
    thumb_image: "",
    venue_name: "",
    tracking_code: "",
    tracking_template: "",
    navigation_link: "",
    alias: "",
    record_type: "",
    stixLite: false,
  };

  if (mobileDataArray.length < 5) {
    // Add Dummy Object if the row items length lessthan 5
    let additionalDummyDataLength = slidesToFeature - mobileDataArray.length;
      for (let i = 0; i < additionalDummyDataLength; i++) {
        mobileDataArray.push(dummyData);
      }
  }

  if (items && items.length > slidesToFeature) {
    firstArray = items.slice(0, slidesToFeature);
    secondArray = items.slice(slidesToFeature, items.length);

    // Add Dummy Object if the row items length lessthan 5
    if (secondArray && secondArray.length < slidesToFeature) {
      let additionalDummyDataLength = slidesToFeature - secondArray.length;
      for (let i = 0; i < additionalDummyDataLength; i++) {
        secondArray.push(dummyData);
      }
    }
  }
  else if (items.length > 0) {
    firstArray = items.slice(0, slidesToFeature);
    
    // Add Dummy Object if the row items length lessthan 5
    if (firstArray && firstArray.length < slidesToFeature) {
      let additionalDummyDataLength = slidesToFeature - firstArray.length;
      for (let i = 0; i < additionalDummyDataLength; i++) {
        firstArray.push(dummyData);
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
        let viewportWidth = window.innerWidth
        setWidthSize(viewportWidth)
    }

    window.addEventListener('resize', handleResize)

    return _ => {
        window.removeEventListener('resize', handleResize)
    }
  })

  const twoRowsView = () => {
    return (
      <>
        <EventListing
          title={"Featured Events"}
          items={firstArray}
          swipe={false}
          infinite={true}
          className={"stix-featured"}
          containerClass={"no-bottomPadding dark-blue"}
          titleClass={"white"}
          h4Class={"featured-events"}
          subtitleClass={"hide"}
          seeMoreClass={"hide"}
          isMobile={isMobile}
          seeMore={"https://www.sistic.com.sg/events"} //temp
          slidesToShow={slidesToFeature}
          slidesToScroll={0}
          showArrows={false}
        />
        <EventListing
          title={"Featured Events"}
          items={secondArray}
          swipe={false}
          infinite={true}
          className={"stix-featured -no-bottomMargin"}
          containerClass={"no-bottomPadding dark-blue"}
          sectionTitleClass={"hide"}
          h4Class={"featured-events"}
          isMobile={isMobile}
          seeMore={"https://www.sistic.com.sg/events"} //temp
          slidesToShow={slidesToFeature}
          slidesToScroll={0}
          showArrows={false}
        />
      </>
    );
  };

  const oneRowView = () => {
    return (
      <>
        <EventListing
          title={"Featured Events"}
          items={mobileDataArray}
          swipe={items.length > 2 ? true : false}
          infinite={items.length > 2 ? true : false}
          className={"stix-featured"}
          containerClass={"dark-blue"}
          titleClass={"white marginTopBottom"}
          h4Class={"featured-events"}
          subtitleClass={"hide"}
          seeMoreClass={"hide"}
          isMobile={isMobile}
          seeMore={"https://www.sistic.com.sg/events"} //temp
          slidesToShow={mobileDataArray.length < 5 ? 5 : mobileDataArray.length}
          slidesToScroll={1}
        />
      </>
    );
  };

  return <>{(widthSize < 1024) ? oneRowView() : twoRowsView()}</>;
};

import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Horizontal from '../../../assets/images/horizontal.png';
import Vertical from '../../../assets/images/vertical.png';
import Tile from '../../../assets/images/tile.png';
import Small from '../../../assets/images/small.png';
import Medium from '../../../assets/images/medium.png';
import MediumVertical from '../../../assets/images/mediumVertical.png';
import MediumHorizontal from '../../../assets/images/mediumHorizontal.png';
import TopPicks from '../../../assets/images/topPicks.png';
import HorizontalMin from '../../../assets/images/HorizontalMin.png';
import VerticalTopPic from '../../../assets/images/verticalTopPic.png';
import BigBanner from '../../../assets/images/BigBanner.png';
import NoImage from '../../../assets/images/NoImage.png';
import Smaller from '../../../assets/images/smaller.png';
import Promotion from '../../../assets/images/promotion.png';
import SimilarPicks from '../../../assets/images/similarPicks.png';
import { useInView } from 'react-intersection-observer';
import './style.scss';

const previewImage = type => {
  switch (type) {
    case 'Vertical':
      return Vertical;
    case 'MediumVertical':
      return MediumVertical;
    case 'MediumHorizontal':
      return MediumHorizontal;
    case 'Tile':
      return Tile;
    case 'TopPicks':
      return TopPicks;
    case 'HorizontalMin':
      return HorizontalMin;
    case 'VerticalTopPic':
      return VerticalTopPic;

    case 'BigBanner':
      return BigBanner;

    case 'Medium':
      return Medium;
    case 'Small':
      return Small;
    case 'VdoSmall':
      return NoImage;
    case 'Smaller':
      return Smaller;
    case 'Promotion':
      return Promotion;
    case 'SimilarPicksImg':
      return SimilarPicks;
    default:
      return Horizontal;
  }
};
function Image(props) {
  const preview = previewImage(props.type);
  const [ref, inView] = useInView({
    triggerOnce: true
    // rootMargin: '200px 0px',
  });
  const [source, SetSource] = useState('');
  const [className, setClassName] = useState('');
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    if (inView && !props.productId) {
      SetSource(!props.largeImage ? props.src : props.largeImage);
    }
  }, [inView, props.src]);


  const onLoad = () => {
    setClassName('loaded');
  };

  const onError = () => {
    SetSource(preview);
    setErrored(true);
  };

  return (
    <div className="image-conatiner" ref={ref}>
      <img
        className={`image ${props.className ||
          ''} placeholder-image ${className}`}
        src={preview}
        alt="pic"
      />
      {source && (
        <img
          className={`image ${props.className || ''} large-image ${className}`}
          src={source}
          alt="pic"
          onLoad={() => {
            onLoad();
            if (props && props.onLoad) {
              props.onLoad();
            }
          }}
          onError={() => onError()}
        />
      )}
      {inView && !errored && (
        <img
          className={`image ${props.className || ''} preview ${className}`}
          src={props.src || source}
          alt="pic"
          onError={() => onError()}
        />
      )}
    </div>
  );
}

export default memo(Image);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  largeImage: PropTypes.string,
  alt: PropTypes.string,
  type: PropTypes.string
};

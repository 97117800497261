import React, { Fragment } from 'react';
import PromotionCard from '../PromotionCard';
import './style.scss';
import { Utilities } from '../../utils';
import SortBy from '../SortBy';
import ShimmerEffect from '../ShimmerEffect';
import DownArrow from '../../assets/images/down-arrow-blue.svg';

const Tab = props => {
  const {
    handleLoadMore,
    tabsSort,
    handleFilters,
    limit,
    handleLocation
  } = props;
  const { first, listingArray, totalRecords, loading } = props.state;

  return (
    <Fragment>
      <div className="promotion-grid">
        <div className="sortby-filter">
          {tabsSort && tabsSort.isSortBy && (
            <SortBy
              defaultSortType="Promotion Date - Earliest to Latest"
              handleFilters={handleFilters}
              sortList={tabsSort.sortList}
            />
          )}
        </div>
        <div className="tab-content-wrapper">
          <div className="promotions-listing">
            {loading ? (
              <ShimmerEffect
                height={Utilities.mobilecheck() ? 200 : 350}
                count={Utilities.mobilecheck() ? 2 : 4}
                type="SOLID"
                propCls={`shm_col-xs-2 col-md-${
                  Utilities.mobilecheck() ? 12 : 6
                }`}
              />
            ) : (
              listingArray.map((elem, index, array) => {
                if (index % 2 === 0) {
                  if (array[index] && array[index + 1]) {
                    return (
                      <div className="promotion-events-row" key={index}>
                        <PromotionCard
                          data={array[index]}
                          handleLocation={handleLocation}
                          {...props}
                        />
                        <PromotionCard
                          data={array[index + 1]}
                          handleLocation={handleLocation}
                          {...props}
                        />
                      </div>
                    );
                  } else if (array[index]) {
                    return (
                      <div className="promotion-events-row" key={index}>
                        <PromotionCard
                          data={array[index]}
                          arrayIndex={index}
                          handleLocation={handleLocation}
                          {...props}
                        />
                      </div>
                    );
                  }
                }
              })
            )}
          </div>
        </div>
        {totalRecords - listingArray.length > 0 && (
          <div
            className="promotion-load-more"
            onClick={() => handleLoadMore(first + limit)}
          >
            <a className="btn-link load-more-btn">
              <span>Load More ({totalRecords - listingArray.length})</span>
              <img src={DownArrow} alt="down-arrow" />
            </a>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Tab;

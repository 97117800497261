
import React, {useRef, useEffect} from 'react';
import {
    TextField,
    InputAdornment,
    Grid,
    Button
} from '@mui/material';
import { useNavigate  } from "react-router-dom";
import {Constants} from '../../utils';
import BaseContext from '../../contexts';
import AdvertisementService from '../../services/AdvertisementService';
import EventsService from '../../services/EventsService';
import {Normal, NonSlider} from '../eventListing';

export const Search = (props) => {
    const navigate = useNavigate();
    const inputRef = useRef();
    const base = React.useContext(BaseContext);
    const [state, setState] = React.useState({
        top:props.isOpen
    });
    const [onSearch, setOnSearch] = React.useState({
        focus: true,
        val:""
    });
    
    const [sresult, setSResult] = React.useState([])
    const [feature, setFeature] = React.useState([])

    useEffect(() => {
        autoFocusSearchBox()
    }, [])

    React.useEffect(()=>{
        setState({
            ...state,
            top: props.isOpen
        });
        
    },[props.isOpen]);  

    React.useEffect( ()=>{
        
        const params = {
            client: Constants.CLIENT,
            limit: 3,
            first: 0
            };    
        
        AdvertisementService.getMostViewedService(params)
        .then(res => {
            const uniqueNames = res.data.data.filter((val, id, array) => array.indexOf(val) == id);
            setFeature(uniqueNames);
        })
        .catch(err => {
            console.log(err);
        }); 

    },[]);

    const autoFocusSearchBox = () => {
        setTimeout(() => {
            inputRef.current && inputRef.current.focus();
        }, 100);
    }

    const handleOnSearchChange = (e) => {
        const val = e.currentTarget.value;

        setOnSearch({
            ...onSearch,
            focus:val.length > 0 ? false : true,
            val:val
        })
    }

    const handleNavigateToEvent = (e) => {
        const url = e.currentTarget.id;
        if(url){
             if (url.includes("http")){
                 window.location.href =url;
             } else {
                 props.searchCallBack && props.searchCallBack(false);
                 setState({ ...state, top: false}); // close search box
                 setOnSearch({...onSearch, val: ""}); //emtpy search box
                 navigate("events/" + url);
             }
             return true;
        } 
    }

    const gotToSearchResult = () => {
        setState({ ...state, top: false}); // close search box
        if(props.searchCallBack){
            props.searchCallBack(false);
        }
        navigate('/events?s='+onSearch.val); // redirect
        setOnSearch({...onSearch, val: ""}); //emtpy search box
    }

    const toggleDrawer = (anchor, open) => {
        setState({ ...state, [anchor]: open });
        if(props.searchCallBack){
            props.searchCallBack(open);
        }
    };

    const handleSearchTextField = (stat) => {
        setOnSearch({
            ...onSearch,
            focus: stat
        })
    }

    const handleSearchTextFieldBlur = (stat) => {
        setTimeout(()=>{
            setOnSearch({
                ...onSearch,
                focus: stat,
            })
        },400);
        
    }

    const handleSearchTextFieldKeyUp = () => {
        const params = {
            first: 0,
            limit: 6,
            client: Constants.CLIENT,
            search: onSearch.val,
            index: 'global'
        };
        
        EventsService.getData(params).then(res => {
            const result = res.data;
            setSResult(result.data);
        });

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setState({ ...state, top: false}); // close search box
            if(props.searchCallBack){
                props.searchCallBack(false);
            }
            navigate('/events?s='+onSearch.val); // redirect
            setOnSearch({...onSearch, val: ""}); //emtpy search box
            return true;
        } 
    }

    const wordHiglighter = (p, s) => {
        const text = p ? p : "";
        let searchText = s;
        let newText = ""
        newText = text.replace(searchText, `<strong>${searchText}</strong>`);
        const capitalizedText = searchText.charAt(0).toUpperCase() + searchText.slice(1);
        newText = text.replace(capitalizedText, `<strong>${capitalizedText}</strong>`);
        return (<p dangerouslySetInnerHTML={{__html: newText}} /> );
    }

    const searchIcon = props.header && props.header.fieldSearchIcon;
    const topPicks = base.data.recommendations && base.data.recommendations;

    return (<>
          <div
           // anchor={"top"}
          //  open={state.top}
           // onClose={toggleDrawer("top", false)}
            className={"stix-search-box"}
          >
            {props.parentHeader}
            <div className={"stix-search-box-container"}>
                <div className="close-container">
                    <span className={"cbtn"} onClick={()=>toggleDrawer("top", false)}><img src="/imgs/close.png"/></span>
                </div>
                <div className={"stix-search-box-container-field"} style={{ maxWidth: "670px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                id={"xsearch"}
                                inputRef={inputRef} 
                                autoComplete={"Off"}
                                sx={{ 
                                    m: 1, 
                                    width: '25ch',
                                    "& .MuiOutlinedInput-root.Mui-focused": {
                                        "& > fieldset": {
                                            borderWidth: "1px"
                                        }
                                    }
                                }}
                                value={onSearch.val}
                                placeholder={"Search"}
                                onChange={handleOnSearchChange}
                                onKeyDown={handleKeyDown}
                                onKeyUp={handleSearchTextFieldKeyUp}
                                onFocus={()=>handleSearchTextField(true)}
                                onBlur={()=>handleSearchTextFieldBlur(true)}
                                InputProps={{
                                    startAdornment: <InputAdornment 
                                                        position="start"
                                                        className={"sbtn"} 
                                                        onClick={gotToSearchResult}><img src={searchIcon} />
                                                    </InputAdornment>,
                                    style: {
                                        borderRadius: '10px',
                                    }
                                }}
                                autoFocus
                                />
                        </Grid>
                    </Grid>
                    {onSearch.val.length > 0 && <div className={"autosuggest"} >
                        <ul>
                            {sresult.length > 0 && sresult.map((r)=>{
                                if(r){
                                    return (<li id={r.alias} className={"clickable-title"} onClick={handleNavigateToEvent}>
                                        {wordHiglighter(r.title,onSearch.val)}
                                    </li>)
                                }else{
                                    return false;
                                }
                                
                            })}
                            <li className={"search-res"} onClick={gotToSearchResult}> 
                                <span>See all results from <b>{onSearch.val}</b></span> 
                            </li>
                        </ul>
                    </div>}
                </div>
                {/* {(onSearch.focus && onSearch.val.length === 0) && <div className={"stix-search-box-container-ads"}>
                    <div className={"trends"}>
                        <strong className={'sxlabel'}>Trending:</strong> 
                        <ul>
                            <li><Button variant="outlined" disabled>Frozen</Button></li>
                            <li><Button variant="outlined" disabled>Justin Bieber</Button></li>
                            <li><Button variant="outlined" disabled>Pinkfong</Button></li>
                            <li><Button variant="outlined" disabled>One Love</Button></li> 
                        </ul>
                    </div>
                </div>} */}

                <div className={'featured-panel-search'}>
                    {(onSearch.focus && !onSearch.val) && <div className={"stix-search-box-container-ads"} style={{ maxWidth: "670px" }}>
                        <div className={"suggestions"}>
                            <div><strong className={'sxlabel'}>Featured</strong><br/></div>
                            <div>
                                <Normal 
                                    items={feature}
                                    toggleDrawer={() => toggleDrawer("top", false)}
                                />
                            </div>
                        </div>
                    </div>}
                </div>

                <div className={'top-picks-search'}>
                    {(onSearch.focus && !onSearch.val) &&<div className={"stix-search-box-container-ads"} style={{ maxWidth: "670px" }}>
                        <div className={"suggestions"}>
                            <div><strong className={'sxlabel'}>Suggested for You</strong><br/></div>
                            <div>
                                <NonSlider 
                                    customClass={'full-border'}
                                    items={topPicks} 
                                    noSeeMore={true}
                                    noCat={true}
                                    noDate={true}
                                    toggleDrawer={() => toggleDrawer("top", false)}
                                />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
          </div>
    </>);
}

export {SearchResult} from './searchResult';
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Box, Stepper, Step, StepButton, Button, Grid } from "@mui/material";
import { StixProgressLoader } from "../UI/loader";
import { DateRange, PriceRange, GenreCategories, Venues } from "./components";
import { Constants, Utilities } from "../../utils";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import EventsService from "../../services/EventsService";
import HomeService from "../../services/HomeService";
import AgentService from "../../services/AgentService";
import BaseContext from "../../contexts";

const steps = [
  "Date Range",
  "Price Range",
  "Genre/Categories",
  // 'Venues'
];

export const PlanYourVisit = (props) => {
  const base = React.useContext(BaseContext);
  const navigate = useNavigate();
  const stepslist = ["dateRange", "priceRange", "categories", "venue"];
  const { isMobile } = useDeviceDetect();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [prices, setPrices] = useState([]);
  const [categories, setCategories] = React.useState([]);
  const [venues, getVenues] = React.useState([]);
  const [defaultVenues, getDefaultVenues] = React.useState([]);
  const [isBtnActive, setIsBtnActive] = React.useState(true);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleBack = () => {
    if (activeStep !== 2) {
      let newCompleted = completed;
      newCompleted[activeStep] = false;
      setCompleted(newCompleted);
    }

    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (activeStep === 2) {
      setCompleted({ 0: true, 1: true, 2: true });
    }
    clearStepsWhenSkip(activeStep);

    let newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    handleNext();
  };

  const handleComplete = () => {
    let newCompleted = completed;
    if (activeStep === 2) {
      newCompleted = { 0: true, 1: true, 2: true };
    } else {
      newCompleted[activeStep] = true;
    }
    setCompleted(newCompleted);
    handleNext();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    setIsBtnActive(true);
    window.scrollTo(0, 0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    setIsBtnActive(true);
    window.scrollTo(0, 0);
  };

  // Fetch prices
  useEffect(() => {
    let queryParams = {
      first: 0,
      sort_type: "price",
      sort_order: "ASC",
      client: 1,
    };

    EventsService.getData(queryParams)
      .then((results) => {
        const eventsInSgd = results.data.data.filter(
          (event) => event.currency === "S$"
        );
        const minPrices = eventsInSgd.map((event) =>
          parseInt(event.min_price)
        );
        const minPricesFiltered = minPrices.filter((value) => !isNaN(value));
        setPrices(minPricesFiltered);
      })
      .catch((err) => {
        console.error("[PV Price Range Err]: ", err);
      });
  }, []);

  useEffect(() => {
    let param = {
      client: Constants.CLIENT,
      country: 15,
      region: 0,
      sort_type: "name",
      sort_order: "ASC",
    };
    HomeService.getGenre(param).then((response) => {
      setCategories(Utilities.rearrangeGenre(response.data.data));
    });

    AgentService.getVenues(param).then((response) => {
      let defVen = [];
      getVenues(response.data.data);
      // (response.data.data && response.data.data.length > 0) && response.data.data.map((vns)=>{
      // if(){
      //     defVen.push();
      // }
      // });
      getDefaultVenues(defVen);
    });
  }, []);

  const clearStepsWhenSkip = (actvsep) => {
    props.updateBase &&
      props.updateBase({
        user: {
          ...base.data.user,
          planVisit: {
            ...base.data.user.planVisit,
            [stepslist[actvsep]]: null,
          },
        },
      });
  };

  const resetPlanVisit = () => {
    props.updateBase &&
      props.updateBase({
        user: {
          ...base.data.user,
          planVisit: {
            dateRange: null,
            priceRange: null,
            categories: null,
            venue: null,
          },
        },
      });
  };

  useEffect(() => {
    let btnNextActive = true;
    if (base.data.user.planVisit[stepslist[activeStep]] !== null) {
      btnNextActive = false;
    }
    setIsBtnActive(btnNextActive);
  }, [base.data.user.planVisit]);

  const handleLoaderCallback = (resp) => {
    if (resp === "done") {
      const planVisit = base.data.user.planVisit;
      let customparams = [];
      Object.keys(planVisit).map((pv) => {
        if (planVisit[pv] !== null) {
          if (pv === "venue") {
            customparams.push("venues=" + planVisit[pv].join());
          } else if (pv === "categories") {
            customparams.push("genre=" + planVisit[pv].join());
          } else if (pv === "dateRange") {
            const start = planVisit[pv].start
              ? moment(new Date(planVisit[pv].start)).format(
                  `YYYY-MM-DDT00:00:00`
                )
              : "";
            const end = planVisit[pv].end
              ? moment(new Date(planVisit[pv].end)).format(
                  `YYYY-MM-DDT00:00:00`
                )
              : "";
            customparams.push("start_date=" + start + "&end_date=" + end);
          } else if (pv === "priceRange") {
            const min = planVisit[pv].min;
            const max = planVisit[pv].max;
            customparams.push("min_price=" + min + "&max_price=" + max);
          }
        }
      });
      resetPlanVisit(); //clear plan visit data
      navigate("/plan-listings?" + customparams.join("&"));
    }
  };

  const renderTheStepperContentDynamically = (stepnum) => {
    switch (stepnum) {
      case 0:
        return <DateRange {...props} />;
      case 1:
        return <PriceRange {...props} prices={prices} />;
      case 2:
        return <GenreCategories {...props} categories={categories} />;
      case 3:
        return (
          <Venues
            {...props}
            venues={venues}
            handleComplete={handleComplete}
            handleSkip={handleSkip}
            isBtnActive={isBtnActive}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className={"plan-visit-container"}>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {!allStepsCompleted() && (
              <React.Fragment>
                <Box className={"plan-visit-container-body"}>
                  {renderTheStepperContentDynamically(activeStep)}
                </Box>
                <Box className={"plan-visit-container-footer"}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={4} order={{ xs: 3, sm: 1 }}>
                      {activeStep !== 0 && (
                        <Button
                          className={"bck"}
                          onClick={handleBack}
                          variant="outlined"
                        >
                          BACK
                        </Button>
                      )}
                    </Grid>
                    {isMobile ? (
                      <>
                        <Grid item xs={12} sm={4} order={{ xs: 2 }}>
                          <Button
                            className={"skp"}
                            onClick={handleSkip}
                            variant="outlined"
                          >
                            SKIP
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} order={{ xs: 1 }}>
                          <Button
                            className={"nxt"}
                            onClick={handleComplete}
                            variant="contained"
                            disabled={isBtnActive}
                          >
                            {activeStep === 2 ? "GENERATE" : "NEXT"}
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          order={{ sm: 2 }}
                          display={"flex"}
                          justifyContent={"end"}
                        >
                          <Button
                            className={"skp"}
                            onClick={handleSkip}
                            variant="outlined"
                          >
                            SKIP
                          </Button>
                          <Button
                            className={"nxt"}
                            onClick={handleComplete}
                            variant="contained"
                            disabled={isBtnActive}
                          >
                            {activeStep === 2 ? "GENERATE" : "NEXT"}
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
        {allStepsCompleted() && (
          <StixProgressLoader
            isMobile={isMobile}
            thisCallback={handleLoaderCallback}
          />
        )}
      </div>
    </>
  );
};

import React, { useState, useEffect, Fragment } from 'react';
import './style.scss';
import attach from '../../assets/images/attach.svg';
import ContactUsService from '../../services/ContactUsService';
import { Stack, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import moment from 'moment';

const Attachement = ({
  attachedFiles,
  submit,
  mandatory,
  cv,
  errMsg,
  selectedFiles,
  shouldFileRemoved
}) => {
  const [maxFileLimitMsg, setMaxFileLimitMsg] = useState('');
  const [serverErr, setServerErr] = useState([]);
  var [fileArr, setFileArr] = useState([]);

  useEffect(() => {
    if (submit) {
      setFileArr([]);
    }
  }, [submit]);

  const handleFiles = files => {
    setMaxFileLimitMsg('');
    if (files) {
      fileArr = [...fileArr, ...files];
      let fileArrLength = fileArr.length;
      for (let key in fileArr) {
        if (fileArr.hasOwnProperty(key)) {
          let fileSize = fileArr[key].size;
          let sizeInMB = fileSize / (1024 * 1024);
          if (sizeInMB > 5) {
            setMaxFileLimitMsg('Files can be uploaded, with up to 5MB size.');
            setFileArr([]);
            return;
          }
        }
      }
      if (fileArrLength > 3) {
        setMaxFileLimitMsg('Max 3 files can be uploaded, with up to 5MB size.');
        setFileArr([]);
      } else {
        submitUploadAttachment(fileArr);
        setMaxFileLimitMsg('');
        setFileArr(fileArr);
        if (selectedFiles) {
          selectedFiles(fileArr);
        }
      }
    }
    setServerErr([]);
  };

  const submitUploadAttachment = files => {
    if (files) {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        const fileNameArry = file.name.split(".");
        const timeStamp = moment().format("YYYYMMDDHHmm");
        const newFileName = `${fileNameArry[0]}_${timeStamp}.${fileNameArry[1]}`;
        // console.log("newFileName: ", newFileName)
        formData.append('files[' + i + ']', file, newFileName);
      }
      ContactUsService.uploadAttachement(formData)
        .then(res => {
          if (res && res.data) {
            attachedFiles(res.data.path);
          }
        })
        .catch(err => {
          if (err && err.response) {
            attachedFiles(err.response.data);
            setServerErr(err.response.data);
          }
        });
    }
  };

  const removeFiles = fileIndex => {
    fileArr.splice(fileIndex, 1);
    submitUploadAttachment(fileArr);
  };

  return (
    <div className="form-group attach-doc">
      <div className="row no-gutters">
        <span className="col-lg-5 col-5 pl-2 attach-text">
          <span className="attach-text">
            Attach Documents{' '}
            {mandatory ? <span className="astrick">*</span> : null}
          </span>
        </span>
        <div className="col-lg-7 col-7 resume">
          <label
            htmlFor="file-upload"
            className={
              mandatory && errMsg && !fileArr.length
                ? 'custom-file-upload form-control center-content error'
                : 'custom-file-upload form-control center-content'
            }
          >
            <Stack direction={"row"} sx={{width: '100%', justifyContent: "space-between"}}>
              <span className="placeholder">Resume/CV</span>
              <img src={attach} height="20" width="20" />
            </Stack>
          </label>
          <input
            encType="multipart/form-data"
            id="file-upload"
            className="form-control"
            type="file"
            multiple
            onChange={e => handleFiles(e.target.files)}
            accept={
              mandatory || cv
                ? '.pdf,.doc,.docx,.txt,.jpg,.png'
                : '.jpeg,.png,.pdf,.doc,.docx,.jpg'
            }
          />
        </div>
      </div>
      {maxFileLimitMsg && <p className="text-danger">{maxFileLimitMsg}</p>}

      {fileArr &&
        fileArr.map((file, i) => {
          return (
            <Fragment key={i}>
              <div className="col-lg-7 col-7 ml-auto file-preview">
                <div
                  className={
                    mandatory || shouldFileRemoved ? 'show-border' : ''
                  }
                >
                  {file.name}
                  {(mandatory || shouldFileRemoved) && (
                    <IconButton aria-label="delete" onClick={() => {removeFiles(i)}}>
                      <Close color={"disabled"} style={{width: 20, height: 20}}/>
                    </IconButton>
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}
      {serverErr && serverErr?.length > 0 &&
        serverErr.map(err => {
          return (
            <p key={err} className="text-danger">
              {err}
            </p>
          );
        })}
    </div>
  );
};

export default Attachement;

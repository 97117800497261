import * as React from 'react';
import {
  Backdrop,
  CircularProgress
} from '@mui/material';


export const StixLoader = (props) => {
  return (<>
        <div className={['stix-loader', props.align ? props.align : ''].join(' ')}>
            {props.no && <img 
                src={'/imgs/loader-' + props.no + '.gif'} 
                width={props.size ? props.size : 82}
                />}
        </div>
  </>);
};


export const StixOverlayLoader = (open = true) => {
  return (
    <div>
      {open && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
    </div>
  );
}

export const StixCustomOverlayLoader = (open = true) => {

  return (
    <div>
      {open && <Backdrop
        sx={{ 
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1 , 
            backgroundColor: "#fff"
        }}
        open={true}
      >
        {<img 
                src={'/imgs/sistic-loader.gif'} 
                width={82}
                />}
      </Backdrop>}
    </div>
  );
}

export const StixProgressLoader = ({open = true, isMobile = false, thisCallback = null}) => {
    const [img, setimg] = React.useState(['/imgs/planvisit/loader01', isMobile ? '-mobile.svg' : '.svg'].join(''));

    React.useEffect(()=>{
      setTimeout(() => {
        setimg(['/imgs/planvisit/loader02', isMobile ? '-mobile.svg' : '.svg'].join(''));
        setTimeout(() => {
          thisCallback && thisCallback('done');
        }, 1000);
      }, 3000);
    },[]);

    return (
      <div>
        {open && <Backdrop
          sx={{ 
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1 , 
              backgroundColor: "#fff"
          }}
          open={true}
        >
          {<img 
                  src={img} 
                  />}
        </Backdrop>}
      </div>
    );
  }
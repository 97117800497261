import React, { useState, useEffect, useCallback } from 'react';
import SearchIcon from '../../../assets/images/search-icon-gray.svg';
import downloadOrange from '../../../assets/images/download-orange.svg';
import downArrow from '../../../assets/images/downarrow-blue.svg';
import AgentService from '../../../services/AgentService';
import AgentVenuePopUp from '../../AgentVenuePopUp';
import { Utilities } from '../../../utils';
import ShimmerEffect from '../../ShimmerEffect';
import useStickyPanel from '../../../hooks/useStickyPanel';
import './style.scss';
import { Link } from 'react-scroll';

const SearchAgent = props => {
  const {
    initialItems,
    countryFileUrl,
    showOnMapClick,
    venue,
    countryName,
    handleAttractionValue,
    handleEventValue,
    handleMapFilter,
    searchText,
    eventValue,
    attractionValue,
    toggleFindInMapHandler
  } = props;

  const [scrollContainerRef, styleObj] = useStickyPanel({
    sticky: { top: 30, paddingTop: '0px' },
    pixelBuffer: 30,
    distanceFromTop: 30,
    bottom: '100%'
  });

  const [scrollContainerRef1, styleObj1] = useStickyPanel({
    sticky: {
      top: 0,
      paddingTop: '0px',
      height: 50,
      background: '#fff',
      zIndex: 1
    },
    paddingTop: 30,
    pixelBuffer: 30
  });

  const [data, setData] = useState([]);
  const [popUpDetail, setPopUpDetail] = useState('');

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    document.addEventListener('click', closePopup);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('click', closePopup);
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const closePopup = e => {
    if (e.target.classList.contains('show-details')) return;
    if (e.target.classList.contains('agent-info')) return;
    if (e.target.closest('.agent-info')) return;
    setPopUpDetail({});
  };
  const escFunction = e => {
    if (e.keyCode === 27) {
      closePopup(e);
    }
  };
  const handleScroll = useCallback(() => {
    if (
      document.getElementsByClassName('pop-up-list active') &&
      document.getElementsByClassName('pop-up-list active').length
    ) {
      if (
        document
          .getElementsByClassName('pop-up-list active')[0]
          .getBoundingClientRect().top < 85
      ) {
        setPopUpDetail({});
      }
    }
  });

  useEffect(() => {
    handleScroll();
  }, [initialItems]);

  const handleSubmit = event => {
    event.preventDefault();
  };

  const showPopUp = detail => {
    const params = {
      venue_id: detail.id
    };
    const cachedVenue = data.find(item => item.id === detail.id);
    if (cachedVenue) detail = cachedVenue;
    setPopUpDetail(detail);
    if (venue && !cachedVenue) {
      fetchCurrentlyShowingData(params, detail);
    }
  };

  const fetchCurrentlyShowingData = (params, detail) => {
    AgentService.getVenueSpecificEvents(params)
      .then(res => {
        if (res.data && res.data.data) {
          detail.currentlyShowingData = res.data.data;
          let newData = [...data, { ...detail }];
          setData(newData);
          setPopUpDetail(detail);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  let isFile;
  if (countryFileUrl) {
    isFile = Utilities.isFileExt(countryFileUrl);
  }

  return (
    <div style={{ position: 'relative' }} ref={scrollContainerRef1}>
      <div className="search-agent-header-strip" style={styleObj1}></div>
      <div
        className="search-agent"
        style={{ position: 'relative' }}
        ref={scrollContainerRef}
      >
        <div className="search-agent-header" style={styleObj}>
          <h2>
            {venue ? 'Venues in ' : 'Agents in '}{' '}
            {countryName ? countryName : 'Singapore'}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="agent-search">
              <button type="submit" className="search-btn">
                <img src={SearchIcon} alt="search-icon" />
              </button>
              <input
                className="form-control"
                type="text"
                value={searchText}
                onChange={e => handleMapFilter(e)}
                placeholder={
                  venue ? 'Search for Location' : 'Search for an agent'
                }
              />
            </div>
          </form>
          {venue ? (
            <ul className="list-option">
              <li>
                <input
                  type="checkbox"
                  onChange={() =>
                    handleAttractionValue(!attractionValue ? 1 : 0)
                  }
                  className="styled-checkbox"
                  id="1"
                  checked={attractionValue ? true : false}
                />
                <label htmlFor="1"> Attractions</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  onChange={() => handleEventValue(!eventValue ? 1 : 0)}
                  className="styled-checkbox"
                  id="2"
                  checked={eventValue ? true : false}
                />
                <label htmlFor="2"> Venues with Events on Sale</label>
              </li>
            </ul>
          ) : null}
          {!initialItems && searchText === '' && (
            <ShimmerEffect
              propCls="shm_col-xs-6 col-md-12"
              height={65}
              count={2}
              type="TILE"
            />
          )}
          {!venue && isFile && initialItems ? (
            <h6 className="festive-hour">
              <a href={countryFileUrl} download target="_blank">
                Festive Period Operating Hours{' '}
                <img src={downloadOrange} alt="Download" />{' '}
              </a>
            </h6>
          ) : null}
        </div>
        <ul className="list-group">
          {initialItems &&
            initialItems.map((item, index) => {
              return (
                <li
                  className={
                    item.id === popUpDetail.id
                      ? 'pop-up-container active'
                      : 'pop-up-container'
                  }
                  key={index}
                >
                  <h3>
                    <span>
                      <strong className="event-title">{item.name}</strong>
                    </span>
                    {item.name.length > 25 ? <br /> : null}{' '}
                    <span>
                      <a
                        className="show-details"
                        onClick={() => {
                          showPopUp(item);
                          toggleFindInMapHandler();
                          showOnMapClick(item);
                        }}
                      >
                        See Details
                      </a>
                      <a
                        href={`http://maps.google.com/?q=${item &&
                          item.latitude},${item && item.longitude}`}
                        className="direction"
                        target="_blank"
                      >
                        Get Directions
                      </a>
                    </span>
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: item.address }}></p>
                  {item.id === popUpDetail.id && (
                    <AgentVenuePopUp
                      item={item}
                      popUpDetail={popUpDetail}
                      venue={venue}
                      currentlyShowingData={popUpDetail.currentlyShowingData}
                      closePopup={closePopup}
                    />
                  )}
                </li>
              );
            })}
          {initialItems && initialItems.length === 0 ? (
            <h4>No result found</h4>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default SearchAgent;


const TrendingNow = {
    trendingNow:[]
}
export default TrendingNow;

// {
//     "id": "80821",
//     "title": "Richard Clayderman Live @ Singapore 2023",
//     "alias": "clayderman0923",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/RCRevised13JulVertBanner.png",
//     "event_date": "Fri, 08 Sep 2023",
//     "venue_name": "The Star Theatre, The Star Performing Arts Centre",
//     "primary_genre": "Concert",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// },
// {
//     "id": "81947",
//     "title": "6th Asia Dance Arts Festival [G]",
//     "alias": "asiadance0823",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/420X652_0.jpg",
//     "event_date": "Thu, 03 Aug 2023 - Sun, 06 Aug 2023",
//     "venue_name": "Anglo-Chinese Junior College, Faith Centre For Performing Arts ",
//     "primary_genre": "Dance",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// },
// {
//     "id": "81829",
//     "title": "Love in the Air Fan Meeting In Singapore [R18]",
//     "alias": "air0823",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/LITA_420x652.jpg",
//     "event_date": "Sat, 12 Aug 2023",
//     "venue_name": "The Theatre at Mediacorp",
//     "primary_genre": "Concert",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// },
// {
//     "id": "81906",
//     "title": "One Love Asia Festival Singapore 2023 [Rating:TBA]",
//     "alias": "onelove1023",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/Onelove2023VertRev13Jul.png",
//     "event_date": "Sat, 28 Oct 2023 - Sun, 29 Oct 2023",
//     "venue_name": "Bayfront Event Space",
//     "primary_genre": "Concert",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// },
// {
//     "id": "56584",
//     "title": "AIA Singapore Premier League 2023 - Lion City Sailors vs Tanjong Pagar United\r\n",
//     "alias": "lcs2023a",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/sistic_vertical_july20.png",
//     "event_date": "Thu, 20 Jul 2023",
//     "venue_name": "Bishan ActiveSG Stadium",
//     "primary_genre": "Sports",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// },
// {
//     "id": "81912",
//     "title": "Ballet Under the Stars 2023 Presented by Singapore Ballet [G]",
//     "alias": "stars0923",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/SB23-BUTS-SISTIC-Main%20Event%20Image%20-%20Portrait.jpg",
//     "event_date": "Fri, 01 Sep 2023 - Sun, 10 Sep 2023",
//     "venue_name": "Fort Canning Green",
//     "primary_genre": "Dance",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// },
// {
//     "id": "80207",
//     "title": "经典回响·百年乐韵 Hits of Classics [G]",
//     "alias": "lhzb0823",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/lhzb0823-vertical.jpg",
//     "event_date": "Sat, 05 Aug 2023",
//     "venue_name": "Singapore Chinese Orchestra (SCO Conference Hall)",
//     "primary_genre": "Orchestra",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// },
// {
//     "id": "81250",
//     "title": "A Dash of Rouge - 一枝红艳露凝香 [G]\r\n",
//     "alias": "moon1023",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/Moon_420%20%28w%29%20x%20652%20%28h%29.jpg",
//     "event_date": "Sun, 22 Oct 2023",
//     "venue_name": "SOTA Concert Hall",
//     "primary_genre": "Concert",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// },
// {
//     "id": "80398",
//     "title": "JUMP 2023 : Non-verbal Martial Art Comedy Performance [Rating : TBA]",
//     "alias": "jump1023",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/Jump23_420x652.jpg",
//     "event_date": "Fri, 20 Oct 2023 - Sun, 22 Oct 2023",
//     "venue_name": "Esplanade Theatre",
//     "primary_genre": "Theatre",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// },
// {
//     "id": "80700",
//     "title": "Bellini’s La Sonnambula [G]",
//     "alias": "bellini0923",
//     "thumb_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/Bellini-VerticleImage.jpg",
//     "event_date": "Fri, 01 Sep 2023 - Sun, 03 Sep 2023",
//     "venue_name": "The Ngee Ann Kongsi Theatre @ Wild Rice, Funan",
//     "primary_genre": "Theatre",
//     "stixLite": false,
//     "record_type": "event",
//     "event_status": "",
//     "tag_colour": null,
//     "tag_name": null
// }
import React from 'react';

export const SocialMedia = (props) => {

    const socmed = props.socmed ? props.socmed : {}
    return (
        <div className={'social-media'}>
            <ul className="medias">
                {Object.keys(socmed).map((sm)=> {
                    const html = socmed[sm].url 
                    ? (<li key={sm}>
                        <a href={socmed[sm].url} target="_blank" rel="noreferrer">
                            <img src={socmed[sm].icon} className="" alt="" style={{height: "25px"}}/>
                        </a>
                      </li>) 
                    : '';
                    return html;
                })}
            </ul>
        </div>
    );
}
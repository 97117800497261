import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';
import { Utilities } from '../../utils';

const SocialShare = ({ shareUrl, showSocialShare, title }) => {
  return (
    <div className={`social-share ${showSocialShare ? 'share-visible' : ''}`}>
      <div className="social-share-wrapper">
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon title="Facebook" size={30} round />
        </FacebookShareButton>
        <span style={{width: 5}}></span>
        <TelegramShareButton url={shareUrl} title={title}>
          <TelegramIcon size={30} round />
        </TelegramShareButton>
        <span style={{width: 5}}></span>
        <EmailShareButton url={shareUrl} subject={title}>
          <EmailIcon size={30} round />
        </EmailShareButton>
        <span style={{width: 5}}></span>
        {Utilities.mobilecheck() || Utilities.mobileAndTabletcheck() ? (
          <Fragment>
            <WhatsappShareButton url={shareUrl} title={title}>
              <WhatsappIcon size={30} round />
            </WhatsappShareButton>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default memo(SocialShare);

SocialShare.propTypes = {
  shareUrl: PropTypes.string.isRequired,
  showSocialShare: PropTypes.bool.isRequired
};


// Whatsapp: 
// Web: https://web.whatsapp.com/send?text=""
// Mobile: https://api.whatsapp.com/send?text=""

// Facebook:
// Web & Mobile: https://www.facebook.com/sharer/sharer.php?u=""

// X (Twitter)
// Web & Mobile: https://twitter.com/intent/tweet?url=""&text=""

// Instagram
// Web & Mobile: instagram://story/share?url=""

// Ticktok
// Web & Mobile: tiktok://share?url=""

// Teligram
// Web & Mobile : https://telegram.me/share/url?url=""&text=""

import React from 'react';
import './style.scss';
import Select from '../../../components/SelectBox';
import stayUpdate from '../../../assets/images/career/stay-update.png';
import Attachement from '../../../components/Attachement';
const StayUpdated = ({
  stayUpdated,
  staticContentErr,
  options,
  areaOfInterestErr,
  email,
  selectedAreas,
  errMsg,
  submit,
  successMsg,
  serverErr,
  filePath,
  fileSelect,
  loading,
  handleEmail,
  handleFileSelect,
  handleFiles,
  handleSubmit,
  onClickSubmit,
  onSelect
}) => {
  return stayUpdated && stayUpdated.title ? (
    <div className="container">
      <div className="row mb-5">
        <div className="col-lg-5 stay-update-img">
          <img src={stayUpdate} alt="satyupdate" />
        </div>
        <div className="col-lg-7">
          <div className="stay-update">
            <h2 className="career-title">{stayUpdated && stayUpdated.title}</h2>
            <p
              className="career-subtext"
              dangerouslySetInnerHTML={{
                __html: stayUpdated && stayUpdated.description
              }}
            ></p>
            <form onSubmit={handleSubmit}>
              <div
                className={
                  errMsg && !selectedAreas.length
                    ? 'form-group err selectbox'
                    : 'form-group selectbox'
                }
              >
                <Select
                  submit={submit}
                  options={submit ? [] : options}
                  multiple
                  onSelect={onSelect}
                  placeholder="Select Area(s) of interest"
                  onClickSubmit={onClickSubmit}
                />
              </div>
              {errMsg && !selectedAreas.length ? (
                <span className="text-danger">
                  Please select Area(s) of interest
                </span>
              ) : null}
              <div
                className={errMsg && !email ? 'form-group err' : 'form-group'}
              >
                <input
                  className="form-control email custom-required"
                  name="email"
                  type="email"
                  //placeholder=""
                  value={email}
                  onChange={e => handleEmail(e.target.value)}
                />
                {!email && (
                  <label className="custom-label">Enter Your Email</label>
                )}
              </div>
              {errMsg && !email ? (
                <span className="text-danger">Please enter your email</span>
              ) : null}
              <Attachement
                attachedFiles={handleFiles}
                submit={submit}
                cv={true}
                mandatory={false}
                shouldFileRemoved={true}
                errMsg={errMsg}
                selectedFiles={handleFileSelect}
              />
              <input
                className="form-control col-lg-5 btn-link"
                type="submit"
                value="Stay Tuned"
                disabled={
                  (fileSelect.length &&
                    filePath.length !== fileSelect.length) ||
                  loading
                    ? true
                    : false
                }
              />
            </form>
            <div className="career-form-msg">
              {successMsg && <h5 className="text-success">{successMsg}</h5>}
              {serverErr &&
                serverErr.map((elem, index) => {
                  return (
                    <h5 key={index} className="text-danger">
                      {elem}
                    </h5>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default StayUpdated;

import React, {} from "react";
import "./style.scss";
import {Stack, Typography, Box, Container, Divider, Button} from "@mui/material";
import Done from "@mui/icons-material/Done";

const DisplayTextWithTick = ({text}) => {

    return(
        <Stack direction={"row"} spacing={1}>
                <Done style={{color: "#57BD7A"}} fontSize={"medium"} />
                <Typography
                    variant={"body2"}
                    fontFamily={"Rubik"}
                    fontWeight={350}
                    color={"#626262"}
                >
                    {text}
                </Typography>
            </Stack>
    )
}
const CreateEventComp = ({title = "", subTitle = "", data = [], btnTitle = "", link = ""}) => {

    return (
        <Container style={{ display: "flex", paddingTop: 20, paddingBottom: 20, backgroundColor: "#FFFFFF", flexDirection: "column", alignItems: "center" }} className="card-style">
            <Stack direction={"column"} spacing={0.5}>
                <Typography
                    variant={"h6"}
                    fontFamily={"Rubik"}
                    fontWeight={500}
                    color={"#343434"}
                    textAlign={"center"}
                >
                    {title}
                </Typography>
                <Typography
                    variant={"body2"}
                    fontFamily={"Rubik"}
                    fontWeight={300}
                    color={"#343434"}
                    textAlign={"center"}
                >
                    {subTitle}
                </Typography>
            </Stack>
            <Divider style={{ width: "100%", marginTop: 10, marginBottom: 20, color: "#F0F0F0" }} />
            <Stack direction={"column"} spacing={3} style={{paddingRight: 20, height: 360, overflow: "hidden", paddingBottom: 5}}>
                {data.map((value, index) => {
                    return (<DisplayTextWithTick text={value} />)
                })}
            </Stack>
            <Button variant={"contained"} style={{ marginTop: 20, fontWeight: 400, fontFamily: "Montserrat", }} href={link}>{btnTitle}</Button>
        </Container>
    )
}

export default CreateEventComp;
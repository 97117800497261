import React from 'react';
import './style.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image from '../../../../components/Image';
import { AdvWrapper } from '../../../../components/AdvWrapper';

const MusicFestival = ({ sectionThree }) => {
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return sectionThree &&
    ((sectionThree.sub_section_three &&
      sectionThree.sub_section_three.length > 0) ||
      sectionThree.heading) ? (
    <section>
      <div className="container-fluid custom-container">
        <div className="music-fest">
          <h2>{sectionThree.heading}</h2>
          <Slider {...settings}>
            {sectionThree.sub_section_three &&
              sectionThree.sub_section_three.map(
                ({ description, title, url, image }) => {
                  return (
                    <div className="music-fest-box" key={title}>
                      <div className="fest-img">
                        <AdvWrapper navigationLink={url}>
                          <Image src={image} type="BigBanner" />
                        </AdvWrapper>
                      </div>
                      <div className="fest-cont">
                        <AdvWrapper navigationLink={url}>
                          <h2>{title}</h2>
                        </AdvWrapper>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: description
                          }}
                        />
                      </div>
                    </div>
                  );
                }
              )}
          </Slider>
        </div>
      </div>
    </section>
  ) : null;
};

export default MusicFestival;

import {API} from '../../helper/services';

class AttractionsService {
	getData(params) {
		return API.get(`api/attractions`, { params: params })
	}
	getAttractionsCategory() {
		return API.get(`api/attraction-categories`)
	}
	getFilterConfig() {
		return API.get(`/configurations/search`)
	}
	getSimilarEvents(params) {
		return API.get(`/similar-events`, { params: params });
	}
}

AttractionsService = new AttractionsService();
export default AttractionsService;

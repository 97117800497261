import React, { useEffect, useState } from 'react';
import { typeOf } from 'react-is';
import { useCountdown } from '../../hooks/useCountdown';


export const CountdownTimer = ({ targetDate, countdownCallBack }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const DateTimeDisplay = ({ value, isDanger }) => {
    return (
      <div className={isDanger ? 'countdown danger' : 'countdown'}>
        <p>{value}</p>
      </div>
    );
  };

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="show-counter">
        <div className="countdown-link">
          { (days > 0) ? 
            <>
              <DateTimeDisplay value={days} isDanger={days <= 3} />
              <p className="separator">:</p>
            </>
            : null
          }
          <DateTimeDisplay value={hours} isDanger={false} />
          <p className="separator">:</p>
          <DateTimeDisplay value={minutes} isDanger={false} />
          <p className="separator">:</p>
          <DateTimeDisplay value={seconds} isDanger={false} />
        </div>
      </div>
    );
  };

  useEffect(()=> {
    let timerTotal = days + hours + minutes + seconds;
    if(timerTotal <= 0) {
      countdownCallBack()
    }
  },[days,hours,minutes,seconds])
  
  return(
    (days + hours + minutes + seconds <= 0) 
    ? null
    : <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
  )
};
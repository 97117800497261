import React, { Component } from 'react';
import './style.scss';
import OurTeam from './OurTeam';
import Mission from './Mission';
import CoreValues from './CoreValues';
import Opening from './Opening';
import StayUpdated from './StayUpdated';
import Testimonials from './Testimonial';
import CareerService from '../../services/CareerService';
import { Constants, Utilities } from '../../utils';
import ShimmerEffect from '../../components/ShimmerEffect';
//import MetaData from '../../shared/components/MetaData';

class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staticContent: '',
      staticContentErr: '',
      testimonial: [],
      testimonialErr: '',
      jobListing: [],
      jobListingErr: '',
      areaOfInterest: [],
      areaOfInterestErr: '',
      selectedAreas: [],
      selectedIds: [],
      email: '',
      fileSelect: [],
      filePath: [],
      maxFileLimitMsg: '',
      errMsg: '',
      submit: false,
      isMultiple: true,
      loading: false,
      successMsg: '',
      serverErr: []
    };
  }

  componentDidMount() {
    if (this.props.location && !this.props.location.linkFrom) {
      this.getStaticContent();
      this.getJobListing();
      this.getAreaOfInterest();
      this.getTestimonial();
      this.scrollToTop(0);
    } else {
      this.getJobListing();
      setTimeout(() => {
        this.getStaticContent();
        this.getAreaOfInterest();
        this.getTestimonial();
      }, 200);
    }
  }

  scrollToTop(fromTop) {
    window.scrollTo({ top: fromTop });
  }

  getStaticContent() {
    const params = {
      client: Constants.CLIENT
    };
    CareerService.getStaticContent(params)
      .then(res => {
        if (res && res.data) {
          this.setState({ staticContent: res.data });
        }
      })
      .catch(err => {
        if (err) {
          this.setState({ staticContentErr: 'Something went wrong...' });
        }
      });
  }

  getTestimonial() {
    CareerService.getTestimonial()
      .then(res => {
        if (res && res.data) {
          this.setState({ testimonial: res.data });
        }
      })
      .catch(err => {
        if (err) {
          this.setState({ testimonialErr: 'Something went wrong...' });
        }
      });
  }

  getJobListing() {
    CareerService.getJobListing()
      .then(res => {
        if (res && res.data) {
          this.setState({ jobListing: res.data });
        }
      })
      .catch(err => {
        if (err) {
          this.setState({ jobListingErr: 'Something went wrong...' });
        }
      });
  }

  getAreaOfInterest() {
    CareerService.getAreaOfInterest()
      .then(res => {
        if (res && res.data) {
          this.setState({ areaOfInterest: res.data.data });
        }
      })
      .catch(err => {
        if (err) {
          this.setState({ areaOfInterestErr: 'Something went wrong...' });
        }
      });
  }

  getJobDetail(id) {
    const params = {
      job_id: id
    };
    CareerService.getJobDetail(params)
      .then(res => {
        if (res && res.data) {
          this.setState({ jobDetail: res.data });
        }
      })
      .catch(err => {
        if (err) {
          this.setState({ jobDetail: 'Something went wrong...' });
        }
      });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { email, selectedIds, filePath } = this.state;
    if (email && selectedIds.length) {
      const data = {
        email,
        area_of_interest: selectedIds,
        attachment: filePath
      };
      this.setState({ loading: true });
      this.submitInterestUpdates(data);
    } else {
      this.setState({ errMsg: 'Please select all mandatory fields' });
    }
  };

  submitInterestUpdates(data) {
    CareerService.formSubmission(data)
      .then(res => {
        if (res && res.data) {
          this.setState({
            email: '',
            submit: true,
            selectedAreas: [],
            selectedIds: [],
            errMsg: '',
            successMsg: res.data.message,
            loading: false
          });
        }
      })
      .catch(err => {
        if (err && err.response) {
          this.setState({ serverErr: err.response.data, loading: false });
        }
      });
  }

  handleEmail = email => {
    this.setState({ email, errMsg: '', successMsg: '', serverErr: [] });
  };

  handleFileSelect = selectedFiles => {
    this.setState({ fileSelect: selectedFiles });
  };

  handleFiles = filePath => {
    this.setState({ filePath });
  };

  onClickSubmit = () => {
    this.setState({ submit: false });
    if (!this.state.multiple) {
      this.setState({ selectedAreas: [] });
    }
  };

  onSelect = values => {
    if (values && typeof values === 'object') {
      let selectedIds = [];
      this.state.areaOfInterest.filter(elem => {
        values.map(e => {
          if (elem.name === e) {
            selectedIds.push(Number(elem.id));
          }
        });
      });
      this.setState({ selectedAreas: values, selectedIds });
    }
  };

  reusedShimmer = textAlignment => {
    return (
      <div className="career-shimmer">
        <ShimmerEffect
          propCls="col-md-12"
          height={400}
          count={2}
          type="CARRIER"
          textAlignment={textAlignment}
        />
      </div>
    );
  };

  render() {
    const {
      staticContent,
      staticContentErr,
      testimonial,
      testimonialErr,
      jobListing,
      jobListingErr,
      selectedAreas,
      areaOfInterest,
      areaOfInterestErr,
      email,
      successMsg,
      serverErr,
      fileSelect,
      filePath,
      loading,
      maxFileLimitMsg,
      errMsg,
      submit
    } = this.state;
    return (
      <div>
        {/* <MetaData
          location={this.props.location}
          data={this.props.staticContext}
        /> */}
        <OurTeam banner={staticContent.banner} shimmer={this.reusedShimmer} />
        <Mission
          mission={staticContent.section_one}
          shimmer={this.reusedShimmer}
        />
        <CoreValues
          coreValues={staticContent.section_two}
          shimmer={this.reusedShimmer}
        />
        <Opening
          jobListing={jobListing}
          jobListingErr={jobListingErr}
          linkFrom={this.props.location && this.props.location.linkFrom}
        />
        <StayUpdated
          stayUpdated={staticContent.section_three}
          staticContentErr={staticContentErr}
          options={areaOfInterest}
          areaOfInterestErr={areaOfInterestErr}
          selectedAreas={selectedAreas}
          email={email}
          maxFileLimitMsg={maxFileLimitMsg}
          errMsg={errMsg}
          submit={submit}
          successMsg={successMsg}
          serverErr={serverErr}
          loading={loading}
          filePath={filePath}
          fileSelect={fileSelect}
          handleEmail={this.handleEmail}
          handleFileSelect={this.handleFileSelect}
          handleFiles={this.handleFiles}
          handleSubmit={this.handleSubmit}
          onClickSubmit={this.onClickSubmit}
          onSelect={this.onSelect}
        />
        <Testimonials
          testimonial={testimonial}
          testimonialErr={testimonialErr}
        />
      </div>
    );
  }
}
export default Careers;

import React from 'react';

export const NotFound = (props) => {

    return (<article className={'not-found'}>
        <h1>404 Not Found</h1>
        <div>
            <p>You have entered an invalid URL.</p>
        </div>
    </article>);
}

import React, { useEffect, useState } from "react";
import BaseContext from "../contexts";
import { AgreementContainer} from "../components";
import useDeviceDetect from "../hooks/useDeviceDetect";

export const AgreementPage = (props) => {
  const base = React.useContext(BaseContext);
  const { isMobile } = useDeviceDetect();

  return (
    <div className={"agreement-wrapper"}>
        <AgreementContainer
          pageType={props.id}
        />
    </div>);
};

import React, { Fragment, useState, useEffect } from "react";
import EventsService from "../../../services/EventsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import {
  TextField,
  DialogContent,
  FormHelperText,
  Typography,
  Stack,
  FormControl,
  Dialog,
  DialogActions,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const Presale = (props = {}) => {
  const { smallDown } = useCustomMediaQuery();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_number, setcontact_number] = useState("");
  const [customOption, setCustomOption] = useState({});
  const [termsAgreement, setTermsAgreement] = useState(false);
  const { eventicc, popuptext } = props;
  const [isMobile, setIsMobile] = useState(props.isMobile);
  const [submitResponse, setSubmitResponse] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [divFlg, setDivFlg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [headerErr, setHeaderErr] = useState("");
  const [submit, setSubmit] = useState(false);
  const closeBox = props.closeBox;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && email && contact_number && termsAgreement) {
      setErrMsg("");
      setLoading(true);
      let cOptions = [];
     
      if(customOption && Object.keys(customOption).length > 0){
        Object.keys(customOption).map((cw)=>{(customOption[cw] === true) && cOptions.push(cw)});
      }

      const data = {
        name,
        email,
        contact_number,
        event_icc: eventicc,
        waitlist_option: cOptions.join(),
      };
      submitForm(data);
    } else {
      setErrMsg("Please complete all fields");
    }
  };
  const submitForm = (data) => {
    let succemsg = (
      <Fragment>
        <h5
          style={{
            color: "#343434",
            marginTop: "20px",
            textAlign: "center",
            fontWeight: "400",
          }}
        >
          <h5>
            {"Hi " + name + "! "}
            <br />
            {"We have successfully noted your interest in the event."}
          </h5>
          <h6>
            {"You will be notified of further updates via email. Thank you.."}
          </h6>
        </h5>
      </Fragment>
    );
    EventsService.formSubmission(data)
      .then((res) => {
        if (res && res.data) {
          setTimeout(() => {
            setLoading(false);
            setSuccessMsg(succemsg);
            setSubmit(true);
            //  handlePresale && handlePresale('Join');
            setName("");
            setEmail("");
            setcontact_number("");
            setDivFlg(true);
            setError(false);
            setErrMsg("");
          }, 1000);
        }
      })
      .catch((err) => {
        if (err && err.response.data === null) {
          setHeaderErr("Something went wrong ");
          setLoading(false);
        } else if (err && !err.response.data.message) {
          setError(true);
          setErrMsg("");
          setTimeout(() => {
            setSubmitResponse(err.response.data);
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setHeaderErr(err.response.data.message);
            setLoading(false);
          }, 1000);
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        let val = value.trim();
        if (val.length > 0) {
          setName(value);
        } else {
          setName("");
        }
        break;
      case "email":
        setEmail(value);
        break;
      case "contact_number":
        const contact_numberregEx = /^[0-9\b]+$/g;
        if (!contact_numberregEx.test(value) && value !== "") {
          // setcontact_number('');
        } else {
          setcontact_number(value);
        }

      default:
        return;
    }

    setErrMsg("");
    setSubmitResponse([]);
    setLoading(false);
    setError(false);
    setHeaderErr("");
    setSuccessMsg("");
  };

  React.useEffect(() => {
    let setOptionsIdenticalValue = {};

    props.waitListOption &&
      Object.keys(props.waitListOption).length > 0 &&
      Object.keys(props.waitListOption).map((w) => {
        setOptionsIdenticalValue[w] = false;
      });

    setCustomOption(setOptionsIdenticalValue);
  }, [props.waitListOption]);

  return (
    <>
      <DialogContent>
        <Stack>
          <Typography variant="details" align="center">
            {popuptext}
          </Typography>
          {!divFlg ? (
            <form>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="off"
                    name="name"
                    id="name"
                    placeholder="What's your name"
                    type="text"
                    value={name}
                    onChange={handleChange}
                    variant="standard"
                    helperText={
                      errMsg &&
                      !name && (
                        <FormHelperText
                          error={errMsg && !name}
                          sx={{ color: "red" }}
                        >
                          Please enter name
                        </FormHelperText>
                      )
                    }
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    name="email"
                    autoComplete="off"
                    fullWidth={!smallDown}
                    id="email"
                    placeholder="Enter your email here"
                    type="email"
                    value={email}
                    onChange={handleChange}
                    variant="standard"
                    helperText={
                      errMsg && !email ? (
                        <FormHelperText
                          error={errMsg && !email}
                          sx={{ color: "red" }}
                        >
                          Please enter email
                        </FormHelperText>
                      ) : (
                        ""
                      )
                    }
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    name="contact_number"
                    fullWidth={!smallDown}
                    autoComplete="off"
                    id="contact_number"
                    placeholder="Enter your contact number"
                    value={contact_number}
                    onChange={handleChange}
                    variant="standard"
                    helperText={
                      errMsg && !contact_number ? (
                        <FormHelperText
                          error={errMsg && !contact_number}
                          sx={{ color: "red" }}
                        >
                          Please enter contact number
                        </FormHelperText>
                      ) : (
                        ""
                      )
                    }
                  />
                </FormControl>

                <FormGroup className={"stixCustomOptions"}>
                  <FormLabel component="legend">
                    {props.waitListOptionTitle && props.waitListOptionTitle}
                  </FormLabel>
                  {props.waitListOption &&
                    Object.keys(props.waitListOption).length > 0 &&
                    Object.keys(props.waitListOption).map((wo) => {
                      return (
                        <FormControlLabel
                          value={wo}
                          name={"waitlist_option"}
                          control={
                            <Checkbox
                              onChange={(event) =>
                                setCustomOption({
                                  ...customOption,
                                  [wo]: event.target.checked,
                                })
                              }
                              size="small"
                            />
                          }
                          label={props.waitListOption[wo]}
                        />
                      );
                    })}
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    className="terms-agreement"
                    control={<Checkbox size="small" required />}
                    onChange={(event) =>
                      setTermsAgreement(event.target.checked)
                    }
                    label="By submitting your information, you agree to receive emails from SISTIC and the event promoter(s) containing communications on marketing and event updates."
                  />

                  {errMsg && !termsAgreement && (
                    <FormHelperText
                      error={errMsg && !termsAgreement}
                    >
                      This is required*
                    </FormHelperText>
                  )}
                </FormGroup>

                {submitResponse &&
                  typeof submitResponse === "object" &&
                  submitResponse.map((elem, index) => {
                    return (
                      <h5
                        key={index}
                        className={error ? "text-danger" : "text-success"}
                      >
                        {elem}
                      </h5>
                    );
                  })}
                {typeof submitResponse === "string" && (
                  <h5 className={error ? "text-danger" : "text-success"}>
                    {submitResponse}
                  </h5>
                )}
                {headerErr && <h5 className="text-danger">{headerErr}</h5>}
              </Stack>
            </form>
          ) : (
            successMsg && (
              <Typography
                variant="h4"
                color="#343434"
                fontWeight={400}
                align="center"
              >
                {successMsg}
              </Typography>
            )
          )}
        </Stack>
      </DialogContent>
      {!divFlg && (
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <LoadingButton
            disableElevation
            variant="contained"
            loading={loading}
            disabled={loading}
            loadingIndicator="Joining.."
            onClick={handleSubmit}
            sx={{ width: 100, height: 38 }}
            type="submit"
          >
            {loading ? "Joining.." : "Join"}
          </LoadingButton>
        </DialogActions>
      )}
    </>
  );
};

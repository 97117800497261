import React from 'react';
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import {
    DesktopVenue,
    MobileVenue
} from './venue';


export const Venues = (props) => {
    const { isMobile } = useDeviceDetect();

    return (
        <div className={'plan-visit-step-venues'}>
            <h2>Do you have any venues that you prefer to visit?</h2>
            {isMobile ? <MobileVenue {...props} initialVenues={initialVenues}/> : <DesktopVenue {...props} initialVenues={initialVenues}/>}
        </div>
    );
}

const initialVenues = [{
    "id": "9113",
    "name": "Marina Bay Sands",
    "address": "10 Bayfront Avenue, Singapore 018956",
    "country_id": "15",
    "country": "Singapore",
    "region": "South",
    "seating_capacity": null,
    "how_to_get_there": "Nearest MRT: Bayfront",
    "map_pin_color": "#FFFFFF",
    "website_url": "http://www.marinabaysands.com/",
    "parking": "Drivers are urged to consider carpooling or public transport as parking lots on the property are limited. No motorcycle or bicycle parking is available in the property.",
    "latitude": "1.28304",
    "longitude": "103.860497",
    "contact_details": "Tel: +65 6688 8868",
    "image": "",
    "map_pin_icon": "",
    "food_beverages": [
        {
            "name": "Numerous options to suit every taste and budget are available at Marina Bay Sands.",
            "image": "/sistic/docroot/",
            "url": null
        }
    ]
},
{
    "id": "9085",
    "name": "Esplanade Concert Hall",
    "address": "1 Esplanade Drive, Singapore 038981",
    "country_id": "15",
    "country": "Singapore",
    "region": "Central",
    "seating_capacity": "1827",
    "how_to_get_there": "<p>Nearest MRT: Esplanade or City Hall<\/p>\r\n",
    "map_pin_color": "#FFFFFF",
    "website_url": "www.esplanade.com",
    "parking": "<p>Available at Basement 1 &amp; 2<\/p>\r\n",
    "latitude": "1.289857",
    "longitude": "103.8552799",
    "contact_details": "<p>Tel: +65 6828 8377<br \/>\r\nFax: +65 6337 3633<br \/>\r\nEmail: venues@esplanade.com<\/p>\r\n",
    "image": "https:\/\/static.sistic.com.sg\/sistic\/docroot\/sites\/default\/files\/2020-06\/EsplanadeConcerthall%20resized.jpg",
    "map_pin_icon": "",
    "food_beverages": [
        {
            "name": "The Esplanade Mall",
            "image": "http:\/\/cms.sistic.com.sg\/sistic\/docroot\/",
            "url": null
        }
    ]
},
{
    "id": "27111",
    "name": "Singapore Indoor Stadium",
    "address": "2 Stadium Walk, Singapore 397691",
    "country_id": "15",
    "country": "Singapore",
    "region": "East",
    "seating_capacity": "11000",
    "how_to_get_there": "<p>MRT Circle Line: Stadium Station<\/p>\r\n",
    "map_pin_color": "#FFFFFF",
    "website_url": "https:\/\/www.sportshub.com.sg\/",
    "parking": "<p>Singapore Indoor Stadium<br \/>\r\nSinagapore National Stadium<\/p>\r\n",
    "latitude": "1.300506",
    "longitude": "103.874585",
    "contact_details": null,
    "image": "",
    "map_pin_icon": "",
    "food_beverages": [
        {
            "name": "Kallang Wave Mall",
            "image": "http:\/\/cms.sistic.com.sg\/sistic\/docroot\/",
            "url": "https:\/\/www.sportshub.com.sg\/kallang-wave-mall"
        }
    ]
}]
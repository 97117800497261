import * as React from 'react';
import {
    Button,
    Popper,
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Grow
  } from '@mui/material';
import DatePicker from 'react-datepicker';

export const StixDropDownDate = (props) => {
    const [isChanged, setIsChanged] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const anchorRef = React.useRef(null);

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
            setIsChanged(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
            setIsChanged(false);
        }
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setOpen(false);
        setIsChanged(false);
    }

    const onDateChange = (dates) => {
        const [start, end] = dates;
        setIsChanged(true);
        setStartDate(start);
        setEndDate(end);
      };
    
    const renderDayContents = (day, date) => {
        return <div className={'dpkr'}>{day}</div>;
    }

    const handleReset = () => {
        setStartDate(null);
        setEndDate(null);    
        setIsChanged(true);
        const data = {
            start: null,
            end: null
        } 
        props.componentCallBack && props.componentCallBack(props.id, data);
    }

    const handleClickDone = () => {
        const data = {
            start: startDate,
            end: endDate ? endDate : null
        } 

        let status = (startDate !== null || endDate !== null) 
        props.componentCallBack && props.componentCallBack(props.id, data, status);
        setOpen(false);
        setIsChanged(false);
    }

    return (<div className={"xdropdown"}>
        <Button
            className={`xbtn ${props.dateRangeActive === true ? 'active' : ''}`}
            ref={anchorRef}
            onClick={()=>setOpen((prevOpen) => !prevOpen)}
            endIcon={<img src={'./imgs/calendar.png'} />}
        >
            <span className={"xbtn-name"}>{props.name && props.name}</span>
        </Button>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            className={'xpopper'}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{
                transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
            >
                <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                    autoFocusItem={open}
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    >
                        <MenuItem className={"xheader"}>
                            <span className={'xname'}>{props.name && props.name}</span> 
                            <span className={'reset'} onClick={handleReset}>Reset</span>
                        </MenuItem>

                        <MenuItem className={'xdate'}>
                            <DatePicker
                                className={'stix-dpicker'}
                                selected={startDate}
                                onChange={onDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                renderDayContents={renderDayContents}
                                selectsRange
                                inline
                                />

                                
                        </MenuItem>
                        <div className={'xfooter'}>
                            <Button onClick={handleClickDone}>Done</Button>
                        </div>
                    </MenuList>
                </ClickAwayListener>
                </Paper>
            </Grow>
            )}
        </Popper>
    </div>);
}

import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

export function AdvWrapper(props) {
  let { navigationLink, children, linkClass, style } = props;
  let advertisement;
  if (
    !navigationLink ||
    (navigationLink &&
      typeof navigationLink === 'string' &&
      navigationLink.includes('.'))
  ) {
    if (
      navigationLink &&
      typeof navigationLink === 'string' &&
      !navigationLink.includes('http')
    ) {
      navigationLink = 'http://' + navigationLink;
    }
    let target = '_self';
    if (
      navigationLink &&
      typeof window !== 'undefined' &&
      !navigationLink.includes(window.location.host)
    ) {
      target = '_blank';
    }
    advertisement = (
      <a
        href={navigationLink}
        target={target}
        key={navigationLink}
        className={`no-link-handle midpage-banner ${linkClass ? linkClass : ``}`}
        onClick={e => {
          if (!navigationLink) e.preventDefault();
        }}
        style={style ? style : null}
      >
        {children}
      </a>
    );
  } else {
    advertisement = (
      <Link
        to={`/${navigationLink}`}
        key={navigationLink}
        className={`no-link-handle midpage-banner ${linkClass ? linkClass : ``}`}
      >
        {children}
      </Link>
    );
  }

  return advertisement;
}

import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/joy/Checkbox';
import {
    Button,
    Popper,
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Grow,
    FormControlLabel
  } from '@mui/material';
  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const StixDropDownCheck = (props) => {
    const [isChanged, setIsChanged] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [items, setItems]= useState({});
    
    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
            setIsChanged(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
            setIsChanged(false)
        }
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setOpen(false);
        setIsChanged(false);
    }

    const setItemFromProps = (onlyThis='') => {
        let newitem = {};
        props.items && props.items.map((item)=>{
            if(onlyThis){
                const splitCats = onlyThis.split(",");
                if(splitCats.length > 0){
                    splitCats.map((rowcat)=>{
                        newitem[rowcat] = true;
                    });
                }
            }else{
                newitem[item.id] = false;
            }
            
        });
        setItems(newitem);
    }

    const handleOnChageCallBack = () => {
        props.callBackOnItemChange && props.callBackOnItemChange();
    }

    const handleItemChange = (e) => {
        setItems({
            ...items,
            [e.target.id]: e.target.checked,
        });
        setIsChanged(true);
        handleOnChageCallBack();
    }

    const handleReset =() => {
        setItemFromProps();
        handleOnChageCallBack();
        setIsChanged(true);
    }

    useEffect(() => {
        setItemFromProps(props.overrideItem === 0 ? '' : props.overrideItem);
    },[props.overrideItem]);

    useEffect(() => {
        if (props.selectedCategories && props.selectedCategories.length > 0) {
            let newitem = {};
            props.items && props.items.map((item) => {
                if (props.selectedCategories.includes(item.id)) {
                    newitem[item.id] = true;
                } else {
                    newitem[item.id] = false;
                }
            });
            setItems(newitem);
        }
    },[]);

    useEffect(() => {
        let itemsChecked = [];
        items && Object.keys(items).map((k)=>{
            if(items[k] === true){
                itemsChecked.push(k);
            }
        });

        const data = {
            items: itemsChecked,
            total: itemsChecked.length
        } 

        let status = itemsChecked.length > 0

        if(isChanged && props.componentCallBack){
            props.componentCallBack(props.id, data, status);
        }
        
    },[items])

    const resetItems = () => {
        Object.keys(items).forEach(key => items[key] = false)
        props.triggerResetCallBack && props.triggerResetCallBack();
    };
    
    useEffect(() => {
        if (props.triggerReset) {
            if (!props.triggerReset.cat) {
                resetItems();
            }
        }
    }, [props.triggerReset]);

    return (
        <div className={"xdropdown"}>
            <Button
                className={"xbtn"}
                ref={anchorRef}
                onClick={()=>setOpen((prevOpen) => !prevOpen)}
                endIcon={<KeyboardArrowDownIcon />}
            >
                <span className={"xbtn-name"}>{props.name && props.name}</span>
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                className={'xpopper'}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                    transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                            autoFocusItem={open}
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                            >
                            <MenuItem className={"xheader"}>
                                <span className={'xname'}>{props.name && props.name}</span> 
                                <span className={'reset'} onClick={handleReset}>Reset</span>
                            </MenuItem>

                            {(props.items && props.items.length > 0) && props.items.map((item)=>{
                                return (<MenuItem className={'xcheck'}><FormControlLabel 
                                    control={<Checkbox 
                                        id={item.id}    
                                        checked={items && items[item.id] ? true : false} 
                                        onChange={handleItemChange} 
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        variant="outlined" />} 
                                    label={item.name} /></MenuItem>)
                            })}
                            
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
        </div>
    );
}

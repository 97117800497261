import React, { useEffect, useState } from "react";
import { Tab, Tabs, Grid, Stack } from "@mui/material";
import { Constants, Utilities } from "../../../utils";
import HomeService from "../../../services/HomeService";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import {AllFunctions} from "../../../helper/functions";
import queryString from "query-string";


export const TopCategories = (props) => {
    let qstring = queryString.parse(window.location.search);
    const { smallDown } = useCustomMediaQuery();
    const [categories, setCategories] = useState([]);
    const [catvalue, setCatValue] = useState('0');

  // When user chooses single category from category banner
  const handleChangeCategory = (event, categoryId) => {
    setCatValue(categoryId);
    AllFunctions.eventsDeleteUrlParam('genre');
    props.catSelectionCallback && props.catSelectionCallback(categoryId);
  };

  useEffect(()=>{
    if(qstring && qstring.genre){
      const getGenres = qstring.genre.split(",");
      if(getGenres.length > 0){
        const allQSgenre = getGenres.toString();
        setCatValue(allQSgenre);
        props.catSelectionCallback && props.catSelectionCallback(allQSgenre);
      }
    }
  },[]);

  useEffect(() => {
    let param = { client: Constants.CLIENT };
    HomeService.getGenre(param).then((res) => {
      setCategories(Utilities.rearrangeGenresNewMethod(res.data.data));
    });
  }, []);

  return (
    <>
      <Grid container spacing={2} pt={2} className={"genre"}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            bgcolor={smallDown ? "#F2F2F2" : ""}
            spacing={2}
          >
            <div className={"genre-filter"}>
            <Tabs
              scrollButtons={false}
              value={catvalue}
              variant="scrollable"
              sx={{ textTransform: "capitalize" }}
              onChange={handleChangeCategory}
              aria-label="Language"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
            >
              <Tab
                label="All"
                sx={{
                  fontFamily: "Rubik",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  fontWeight: "400",
                  letterSpacing: "0.25px",
                }}
                value={0}
              />
              
                {categories &&
                  categories.length > 0 &&
                  categories.map((item, i) => {
                    return (
                      i < 10 && (
                        <Tab
                          className="tabs"
                          sx={{
                            fontFamily: "Rubik",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "400",
                            letterSpacing: "0.25px",
                          }}
                          label={item.name}
                          value={item.id}
                          key={i}
                        />
                      )
                    );
                  })}
            </Tabs>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default TopCategories;
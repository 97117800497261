import React, {useState} from "react";
import { Container, Grid } from "@mui/material";
import Listing from "./components/Listing";
import EventTopBanner from "./components/EventTopBanner";
import TopCategories from "./components/TopCategories";
import queryString from 'query-string';

export const EventsPage = (props = {}) => {
  let qString = queryString.parse(window.location.search);
  const [selectedCat, setSelectedCat] = useState(null);

  const handleCatSelectionCallback = (catVal) => {
    setSelectedCat(catVal);
  }

  let isSearch = qString.s && qString.s !== "" ? true : false;  

  return (
    <Container maxWidth="xl">
      <Grid container rowSpacing={3} my={2}>
        <EventTopBanner />
      </Grid>
      {isSearch ? '' : <TopCategories catSelectionCallback={handleCatSelectionCallback}/>}
      <Listing {...props} topCatSelected={selectedCat} />
    </Container>
  );
};


export const baseType = {
    APPBASE:    'APPBASE'
}

const updateBase = (base, state) => {
    return {
        ...state,
        data: base.data
    }
}

export const baseReducer = (state, action) => {
    switch (action.type) {
        case baseType.APPBASE:
            return updateBase(action.payload, state);
        default:
            return state;
    }
}
import React from 'react';
import StixMap from './StixMap';
import { AllFunctions } from '../../../../helper';
import BaseContext from '../../../../contexts';
import {
    Box,
    Grid,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Card,
    CardContent,
    Stack,
    Dialog,
    Button,
    AppBar,
    Toolbar,
    Slide
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import MapIcon from "../../../../assets/images/mapIcon.svg";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export const MobileVenue = (props) => {
    const base = React.useContext(BaseContext);
    const [openMap,setOpenMap] = React.useState(false);
    const [state, setState] = React.useState({
        searchVal:'',
        results: props.initialVenues,
        allResults: [],
        selectedVenues: []
    });
    const [isBtnActive, setIsBtnActive] = React.useState(props.isBtnActive);

    React.useEffect(()=>{
        setIsBtnActive(props.isBtnActive);
    },[props.isBtnActive]);

    const handleOnSearch = (e) => {
        const val = e.target.value;
        let results = [], allresults=[];

        (props.venues && props.venues.length > 0) && props.venues.map((venue)=>{
            const name = venue.name;
            const venueChecker = name.toLowerCase().search(val.toLowerCase());
            if( venueChecker !== -1){
                if(results.length < 4){
                    results.push(venue);
                }
                allresults.push(venue);
            }
        });

        setState({
            ...state,
            searchVal: val,
            results: val.length > 0 ? results : props.initialVenues,
            allResults: val.length > 0 ? allresults : []
        });
    }

    const onCardClick = (svn) => {
        let newVNs = state.selectedVenues;
        if(newVNs.includes(svn.id)){
            const index = newVNs.indexOf(svn.id);
            newVNs.splice(index, 1);
        }else{
            newVNs.push(svn.id);
        }

        setState({
            ...state,
            selectedVenues: newVNs
        });

        props.updateBase && props.updateBase({
            user:{
                ...base.data.user,
                planVisit:{
                    ...base.data.user.planVisit,
                    venue: (newVNs && newVNs.length > 0) ? newVNs : null
                }
            }
        });
    }

    const handleClearSearch = () => {
        setState({
            ...state,
            searchVal:"",
            results: props.initialVenues,
            allResults:[]
        });
    }

    const handleMapPopup = () => {
        setOpenMap(openMap ? false : true);
    }

    const countResults = state.allResults.length;
    const resLabel = countResults > 1 ? 'results' : 'result';
    return (
        <div className={'mobile'}>
                    <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} className={'stixMapVenues'}>
                        <div className={'stixMapSearchArea'}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <FormControl variant="outlined">
                                    <OutlinedInput
                                        id="search-id"
                                        type={'text'}
                                        onChange={handleOnSearch}
                                        placeholder='Search venue'
                                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle clear"
                                            edge="end"
                                            onClick={handleClearSearch}
                                            >
                                            {<ClearIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        value={state.searchVal}
                                        label="Search venue"
                                    />
                                </FormControl>
                                <IconButton aria-label="map" className={'mMapIcon'} onClick={handleMapPopup}>
                                    <img height="24" width="24" src={MapIcon} alt="map" />
                                </IconButton>
                            </Stack>

                            <div className={'results-container'}>
                                <h3>{state.searchVal ? [countResults, resLabel].join(" "): 'Venues in Singapore'}</h3>
                                
                                {(state.results && state.results.length > 0) && state.results.map((vnr)=>{
                                    const vnrName=AllFunctions.removeTags(vnr.name);
                                    const vnrAddress=AllFunctions.removeTags(vnr.address);

                                    return (<Card 
                                        className={['results-card-wrapper', state.selectedVenues.includes(vnr.id) ? 'active' : ''].join(" ")}>
                                        <CardContent className={'results-card-content'} onClick={()=>onCardClick(vnr)}>
                                            <h1>{AllFunctions.addEllipsis(vnrName, 24)}</h1>
                                            <p>{AllFunctions.addEllipsis(vnrAddress, 32)}</p>
                                            <a 
                                                href={`http://maps.google.com/?q=${vnr.latitude},${vnr.longitude}`}
                                                className="direcrtion-icn"
                                                target="_blank">
                                                    Get Directions
                                            </a>
                                        </CardContent>
                                    </Card>);
                                })}

                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>

            <Dialog
                fullScreen
                open={openMap}
                onClose={handleMapPopup}
                TransitionComponent={Transition}
                className={'stixMobilePopUp'}
            >
                <AppBar sx={{ position: 'relative' }} className={'appbar-header'}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleMapPopup}
                        aria-label="close"
                        >
                        {<ArrowBackIosNewIcon />}
                        </IconButton>
                        <div className={'title'}><h3>Map View</h3></div>
                    </Toolbar>
                </AppBar>
                <div className={'stixPopBody'}>
                    <h4>Select for prepared venues</h4>
                    <div className={'stixGMap'}>
                        <StixMap 
                            {...props} 
                            searchResults={state.allResults} 
                            search={state.searchVal} 
                            selectedVenues={state.selectedVenues} />
                    </div>
                    <Box className={'plan-visit-container-footer'}>
                        <Grid container justify="flex-start">
                            <Grid item xs={12} sm={6} md={6} lg={6} order={{ xs: 2, sm: 1 }}>
                                <Button 
                                    className={'skp'} 
                                    onClick={()=> props.handleSkip && props.handleSkip()} 
                                    sx={{ mr: 1 }} 
                                    variant="outlined">
                                    SKIP
                                </Button>
                            </Grid>
                            <Grid 
                                item 
                                xs={12} 
                                sm={6} 
                                md={6} 
                                lg={6} 
                                order={{ xs: 1, sm: 2 }}>
                                    <Button 
                                        className={'nxt'}
                                        onClick={() => props.handleComplete && props.handleComplete()} 
                                        variant="contained" 
                                        disabled={isBtnActive}
                                        >
                                        {'GENERATE'}
                                    </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
        </div>
    );
}
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Box, ImageListItem, ImageListItemBar, Stack } from "@mui/material";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { SectionTitle } from "../";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CreatedWithStixLite from "../../assets/images/created-with-stixlite.svg";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { Utilities } from "../../utils";
import HomePageItemComp from "../HomePageItemComp";

export const EventListing = (props = {}) => {
  const [items, setItems] = useState([]);
  const [useSlick, setUseSlick] = useState(false);
  const sliderRef = useRef();
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const [iconFontSize, setIconFontSize] = useState(0);
  const [showArrows, setShowArrows] = useState(
    props?.showArrows !== undefined ? props?.showArrows : false
  );
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const { isMobile } = useDeviceDetect();

  const handleBeforeChange = (currentSlide, nextSlide) => {
    if (nextSlide === 0) {
      setCanScrollLeft(false);
    } else {
      setCanScrollLeft(true);
    }
    if (nextSlide === items.length - 1) {
      setCanScrollRight(false);
    } else {
      setCanScrollRight(true);
    }
  };

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const handleOnItemClick = useCallback(
    (e) => {
      if (dragging) {
        e.stopPropagation();
      } else if (!dragging) {
        const url = e.currentTarget.id;
        if (url) {
          if (url.includes("http")) {
            window.location.href = url;
          } else {
            navigate(url);
          }
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 500);
        }
      }
    },
    [dragging]
  );

  const onSeeMore = () => {
    if (props.seeMore) {
      window.location.href = props.seeMore;
    }
  };

  const filterSubcontent = (content) => {
    const newcount = content.length;

    if (newcount > 120) {
      content = content.substr(0, 120) + "...";
    } else {
      content = content;
    }
    return content;
  };

  const getUrl = (item) => {
    let newUrl = "";
    if (item.navigation_link) {
      newUrl = item.navigation_link;
    } else if (item.alias) {
      if (!item.alias.includes("http")) {
        newUrl = "/events/" + item.alias;
      } else {
        newUrl = item.alias;
      }
    } else {
      newUrl = "/explore/1/" + item.id;
    }
    return newUrl;
  };

  useEffect(() => {
    if (props.items && props.items.length > 0) {
      // Need to add dummy data if items.length < slidesToShow (due to limitations of Slider component)
      if (props.items.length <= props.slidesToShow) {
        let cloneArray = [...props.items];
        let dummyObj = { ...cloneArray[0] };

        Object.keys(dummyObj).forEach((key) => (dummyObj[key] = ""));
        let additionalDummyDataLength = props.slidesToShow - props.items.length;
        for (let i = 0; i < additionalDummyDataLength; i++) {
          // console.log("dummy: ", dummyObj)
          cloneArray.push(dummyObj);
        }
        setItems(cloneArray);
      } else {
        setItems(props.items);
        if (props?.showArrows === undefined) {
          setShowArrows(true);
        }
      }
    }
  }, [props]);

  useEffect(() => {
    if (isMobile) {
      if (useSlick) {
        sliderRef.current.slickGoTo(0); // to default to first item
      }
    }
  }, [props, useSlick]);

  const usesVerticalBanner = () => {
    const section = props.title;
    if (
      section === "What's New" ||
      section === "On This Week" ||
      section === "Trending Now"
    ) {
      return true;
    }
    return false;
  };

  const itemsComponent = () => {
    return items.map((item, i) => {
      let imgUrl = item.id == "" ? "" : item.thumb_image ? item.thumb_image : usesVerticalBanner() ? "./imgs/default-vertical-thumb.png" : "./imgs/default-thumb-mobile.png";
      const brTitleStr = item?.title?.replace(/&lt;br&gt;/g, "<br>");
      const updatedTitle =  Utilities.removeBrfromString(brTitleStr);

      if (imgUrl == "") return <div></div>;
      if (i < 20 && item) {
        const subT = item.subTitleOn ? item.subTitleOn : item.synopsis ? filterSubcontent(item.synopsis) : "";
          return (
            <HomePageItemComp 
                index={i}
                handleOnItemClick={handleOnItemClick}
                itemId={getUrl(item)}
                isBlur={props.isBlur}
                noAnimate={props.noAnimate}
                tag_colour={item.tag_colour}
                tag_name={item.tag_name}
                imgUrl={imgUrl}
                title={props.title}
                showTrendingTitle={props?.showTrendingTitle}
                eventName={item?.title}
                stixLite={item.stixLite}
                isMobile={isMobile}
                h4Class={props.h4Class}
                subtitleClass={props.subtitleClass}
                subTitle={subT}
                lblPosition={props.lblPosition}
            />
          )
      }
    });
  };

  const handleClickLeft = () => {
    if (isInitialized) {
      sliderRef?.current?.slickPrev();
    }
  };

  const handleClickRight = () => {
    if (isInitialized) {
      sliderRef?.current?.slickNext();
    }
  };

  const itemsWithSlider = () => {
    return (
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          minHeight: { sm: 200, md: 300 },
          width: "100%",
          flexDirection: "row",
        }}
        onMouseEnter={() => {
          setIconFontSize(40);
        }}
        onMouseLeave={() => {
          setIconFontSize(0);
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            height: { sm: 180, md: 240 },
            width: "2%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showArrows && canScrollLeft && (
            <IconButton
              onClick={handleClickLeft}
              aria-label="ChevronLeft"
              size={"medium"}
            >
              <ChevronLeft
                sx={[
                  { fontSize: iconFontSize },
                  props?.arrowsStyle ? props?.arrowsStyle : null,
                ]}
              />
            </IconButton>
          )}
        </Box>
        <Box sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
          <Slider
            style={{
              paddingTop: 0,
            }}
            initialSlide={0}
            animation={true}
            infinite={true}
            speed={500}
            focusOnSelect={true}
            pauseOnHover={true}
            draggable={false}
            centerMode={false}
            lazyLoad={true}
            arrows={false}
            dots={false}
            ref={sliderRef}
            slidesToScroll={3}
            // useTransform={false}
            onInit={() => setIsInitialized(true)}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  infinite: false,
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  infinite: false,
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  infinite: false,
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ]}
            beforeChange={handleBeforeChange}
            afterChange={handleAfterChange}
            {...props}
          >
            {itemsComponent()}
          </Slider>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            height: { sm: 180, md: 240 },
            width: "2%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showArrows && canScrollRight && (
            <IconButton
              onClick={handleClickRight}
              aria-label="ChevronRight"
              size={"medium"}
            >
              <ChevronRight
                sx={[
                  { fontSize: iconFontSize },
                  props?.arrowsStyle ? props?.arrowsStyle : null,
                ]}
              />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <div
        className={[
          "stix-featured-container no-arrow",
          props.containerClass && props.containerClass,
        ].join(" ")}
      >
        <SectionTitle
          containerClass={
            props.sectionTitleClass ? props.sectionTitleClass : ""
          }
          titleClass={props.titleClass ? props.titleClass : ""}
          seeMoreClass={props.seeMoreClass ? props.seeMoreClass : ""}
          title={props.title && props.title}
          seeMore={<a onClick={onSeeMore}>See more</a>}
        />

        {items && items.length >= props.slidesToShow ? (
          itemsWithSlider()
        ) : (
          <div className="unslick-container" style={{ paddingLeft: "3%" }}>
            {" "}
            {itemsComponent()}{" "}
          </div>
        )}
      </div>
    </>
  );
};

export { NonSlider } from "./nonSlider";
export { SliderWithTwoColumns } from "./sliderWithTwoColumns";
export { Normal } from "./normal";
export { CustomPanel } from "./customPanel";


// return (
//   <ImageListItem
//     key={i}
//     onClickCapture={handleOnItemClick}
//     id={getUrl(item)}
//     className={[props.isBlur ? "blur" : "noblur"].join(" ")}
//   >
//     <div
//       className={[
//         "animate-img-sec",
//         props.noAnimate ? "stop" : "",
//       ].join(" ")}
//       style={{ position: "relative" }}
//     >
//       <span
//         className={"utaginfo"}
//         style={{
//           background: item.tag_colour ? "#" + item.tag_colour : "",
//         }}
//       >
//         {item.tag_name ? item.tag_name : ""}
//       </span>
//       <img
//         id={getUrl(item)}
//         src={`${imgUrl}?w=248&fit=crop&auto=format`}
//         srcSet={`${imgUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
//         alt={""}
//         loading={props.title === "Featured Events" ? "" : "lazy"}
//       />
//       {props?.showTrendingTitle && (
//         <Box
//           sx={{
//             flex: 1,
//             height: "50%",
//             width: "100%",
//             background: `linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))`,
//             bottom: 0,
//             borderBottomLeftRadius: 8,
//             borderBottomRightRadius: 8,
//             // paddingLeft: '10px',
//             // paddingRight: '20px',
//             position: "absolute",
//             display: "flex",
//             overflow: "hidden",
//           }}
//         >
//           <Stack
//             direction={"row"}
//             spacing={2}
//             sx={{ alignItems: "flex-end", pl: "5px", pr: "5px" }}
//           >
//             <span
//               style={{
//                 color: "#FFFFFF",
//                 opacity: "40%",
//                 fontSize: 100,
//                 fontWeight: 500,
//                 marginBottom: "-22px",
//                 fontFamily: "Rubik",
//               }}
//             >
//               {i + 1}
//             </span>
//             <Stack
//               direction={"column"}
//               spacing={0}
//               style={{ marginLeft: 0, marginBottom: 5 }}
//             >
//               <h4
//                 style={{
//                   color: "#FFFFFF",
//                   fontSize: 14,
//                   fontWeight: 400,
//                   fontFamily: "Rubik",
//                 }}
//                 // <br> showing up as &lt;br&gt due to title being treated as plain text
//                 dangerouslySetInnerHTML={{
//                   __html: updatedTitle,
//                 }}
//               />
//               {/* <p>{subT}</p> */}
//             </Stack>
//           </Stack>
//         </Box>
//       )}
//       {item.stixLite ? (
//         <img
//           src={CreatedWithStixLite}
//           alt="created with stixlite"
//           height={isMobile ? "10px" : "15px"}
//           style={{
//             position: "absolute",
//             bottom: 0,
//             zIndex: 1,
//             backgroundColor: "#B0B0B0",
//             opacity: "0.9",
//             borderRadius: "0px 0px 10px 10px",
//             padding: "2px 0px",
//           }}
//         />
//       ) : null}
//     </div>
//     {!props?.showTrendingTitle && (
//       <ImageListItemBar
//         title={
//           <h4
//             className={props.h4Class ? props.h4Class : ""}
//             // <br> showing up as &lt;br&gt due to title being treated as plain text
//             dangerouslySetInnerHTML={{
//               __html: updatedTitle,
//             }}
//           />
//         }
//         subtitle={
//           <p
//             className={props.subtitleClass ? props.subtitleClass : ""}
//             style={{ height: subT?.length > 0 ? null : 0 }}
//           >
//             {subT}
//           </p>
//         }
//         position={props.lblPosition ? props.lblPosition : "below"}
//       />
//     )}
//   </ImageListItem>
// );
import React, { useEffect, useState } from "react";
import useDeviceDetect from "../../hooks/useDeviceDetect";


export const HomepageTakeoverTopBanner = (props = {}) => {
  const { isMobile } = useDeviceDetect();
  const { data } = props;
  const { sticky_banner_image, top_banner_url, top_banner_image } = data;

  const [showBigBanner, setShowBigBanner] = useState(true);
  const [showStickyBanner, setShowStickyBanner] = useState(true);

  const handleCloseBanner = () => {
    setShowStickyBanner(false);
  };

  // When the user scrolls the page, execute scrollFunction
  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    let header = document.querySelector(".stix-sticky-header");
    let topBannerContainer = document.querySelector(
      ".homepage-takeover-image-top"
    );
    let topBanner = document.getElementById("topBanner");
    let homepage = document.getElementsByClassName("homepage")[0];
    let newsTicker = document.querySelector(".news-ticker");

    let scrollPosition = 0;

    // Scrolling down will trigger elements to be sticky
    if (window.pageYOffset > scrollPosition) {
      header.classList.add("sticky-header");
      setShowBigBanner(false);

      if (newsTicker) {
        topBanner && topBanner.classList.add("sticky-banner-newsticker");
      } else {
        topBanner && topBanner.classList.add("sticky-banner");
      }

      topBannerContainer && topBannerContainer.classList.remove("centered");

      // To get rid of jerk
      if (homepage) {
        let stickyBannerHeight = topBanner && topBanner.offsetHeight;
        homepage.style.paddingTop =
          header.offsetHeight + stickyBannerHeight + 100 + "px";
      }
    } else {
      // Scrolling back up switch to big banner
      header.classList.remove("sticky-header");
      setShowBigBanner(true);

      if (newsTicker) {
        topBanner && topBanner.classList.remove("sticky-banner-newsticker");
      } else {
        topBanner && topBanner.classList.remove("sticky-banner");
      }

      topBannerContainer && topBannerContainer.classList.add("centered");

      if (homepage) {
        homepage.style.paddingTop = "0px";
      }
    }
  };

  const topBannerImg = showBigBanner
    ? top_banner_image
    : showStickyBanner && sticky_banner_image;

  const homepageTakeoverClass = showBigBanner
    ? "homepage-takeover-big"
    : "homepage-takeover-small";

  return (
    <div className="homepage-takeover-image-top centered">
      <a href={top_banner_url}>
        {topBannerImg && (
          <img
            id="topBanner"
            className={homepageTakeoverClass}
            src={topBannerImg}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `/imgs/default-banner${isMobile ? '-mobile' : '-1920'}.png`; // default fallback img
            }}
            alt={"homepage takeover top banner"}
          />
        )}
      </a>
      {showStickyBanner && !showBigBanner && (
        <div class="close-button" onClick={handleCloseBanner}>
          &times;
        </div>
      )}
    </div>
  );
};

import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
  Box,
  Typography
} from "@mui/material";
import termsBanner from '../../assets/images/tc-banner.png';
import TermsAndPrivacyService from '../../services/TermsAndPrivacyService';
import useDeviceDetect from '../../hooks/useDeviceDetect'

const stixItems = {
  terms_cond:{
    label:"Terms and Conditions of Ticket Sales",
    urlParam:"terms-and-conditions"
  },
  privacy_policy:{
    label:"Privacy Policy",
    urlParam:"privacy-policy"
  },
  conditions:{
    label:"Conditions of Access",
    urlParam:"conditions-of-access"
  },
  cookie_policy:{
    label:"Cookie Policy",
    urlParam:"cookie-policy"
  }
};

const StixTab = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`stix-tabpanel-${index}`} className={'p'} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div dangerouslySetInnerHTML={{__html: children}} />
        </Box>
      )}
    </div>
  );
}

StixTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export const AgreementContainer = ({ pageType }) => {
  const { isMobile } = useDeviceDetect();
  const [state, setState] = useState({
    selectedTab: pageType
  });

  const [content, setContent] = useState({
    conditions:{
      description:"",
      title:""
    },
    cookie_policy:{
      description:"",
      title:""
    },
    privacy_policy:{
      description:"",
      title:""
    },
    terms_cond:{
      description:"",
      title:""
    }
  });

  useEffect(()=>{
    setState({...state, selectedTab: pageType});
  },[pageType]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    fetchTermsConditions();
  }, []);

  const fetchTermsConditions = () => {
    TermsAndPrivacyService.getTermsAndPrivacyService()
      .then(res => {
        if (res && res.data && res.data.data) {
          let data = res.data.data;
          setContent({
            ...content,
            ...data
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleTabChange = (event, newValue) => {
    setState({...state, selectedTab: newValue});
    window.history.pushState(null, false, "/"+stixItems[newValue].urlParam);
  }

  return (
    <Fragment>
      <section className="agreement-container">
        <div className="agreement-header">
          <img src={termsBanner} className="img-fluid" alt="page-banner" />
          <div className="banner-overlay">
            <h1>{stixItems[state.selectedTab].label}</h1>
          </div>
        </div>
        <div className={"agreement-body"}>
          <Tabs value={state.selectedTab} onChange={handleTabChange} centered>
            {Object.keys(stixItems).map((rw)=>{
              return <Tab 
                label={isMobile ? '' : stixItems[rw].label} 
                icon={isMobile ? <i className={['icon-'+rw, rw===state.selectedTab ? 'active' : ''].join(" ")} /> : ''} 
                value={rw}
                />;
            })}
          </Tabs>

          {Object.keys(stixItems).map((rw)=>{
              return <StixTab value={state.selectedTab} index={rw}>{content[rw].description}</StixTab>;
          })}
          
        </div>
      </section>
    </Fragment>
  );
};


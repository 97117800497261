import React, { Fragment, useState } from 'react';
import './style.scss';
import BreadcrumbSlug from '../../../../components/BreadcrumbSlug';
import SocialShare from '../../../../components/SocialShare';
import shareIcon from '../../../../assets/images/share-icon.svg';
import { Constants } from '../../../../utils';

const Welcome = ({ breadcrumbSlug, subtitle, title, description }) => {
  const [socialShare, setSocialShare] = useState(false);
  const handleSocialShare = () => {
    setSocialShare(!socialShare);
  };

  return (
    <Fragment>
      <section>
        <div className="container-fluid custom-container">
          <div className="Home-tab ">
            <div className="explore-nav">
              <BreadcrumbSlug breadcrumbSlug={breadcrumbSlug} />
            </div>
            <div className="share-rightalign">
              <span className="share" onClick={handleSocialShare}>
                <img src={shareIcon} alt="share-icon" />
              </span>
              <SocialShare
                  shareUrl={
                    typeof window == 'undefined'
                      ? Constants.WEBSITE_URL
                      : window.location.href
                  }
                  showSocialShare={socialShare}
                  title={title}
                />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid custom-container">
          <div className="welcome-sifa">
            <h2>{title}</h2>
            <span>{subtitle}</span>
            <p
              dangerouslySetInnerHTML={{
                __html: description.replace(
                  /<a href/g,
                  '<a target="_blank" href'
                )
              }}
            ></p>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Welcome;

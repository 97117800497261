
import {
     Tabs, Tab
  } from "@mui/material";
  import { styled } from '@mui/material/styles';
const StyledTabs = styled(Tabs)({
    borderBottom: '0px',
    minHeight: '0px',
    '& .MuiTabs-indicator': {
      display: 'none',  },
  });
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontSize: '0.75rem',
    padding: '0 10px',
    minHeight: '28px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
  '&.Mui-selected': {
      background: '#1890ff',
      color: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
      border: '0px',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));
  export {StyledTabs,StyledTab}
import React from 'react';
import './style.scss';
import { Link } from 'react-scroll';
import { Utilities } from '../../../utils';

const giftVoucherHeader = ({ bannerDescription, bannerUrl }) => {
  return (
    <div className="banner-leftalign-wrapper">
      <div className="container-fluid custom-container">
        <div className="banner-leftalign">
          <div className="banner-desc">
            <div
              className="banner-title"
              dangerouslySetInnerHTML={{
                __html: bannerDescription
              }}
            />
            <Link
              className="buy-now"
              to={'gift-boucher'}
              spy={true}
              smooth={true}
              offset={
                Utilities.mobilecheck()
                  ? 0
                  : Utilities.mobileAndTabletcheck()
                  ? -50
                  : -100
              }
            >
              <span>BUY NOW</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default giftVoucherHeader;

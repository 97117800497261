import React, { Fragment, useState, useEffect } from "react";
import "./style.scss";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import termsBanner from "../../assets/images/tc-banner.png";
import PropTypes from 'prop-types';
import ContentData_En from "./data_en.json";
import ContentData_VN from "./data_vn.json";
import ContentData_ZH_HK from "./data_zh_hk.json";
import ContentData_ZH_EN from "./data_zh_en.json";
import { Link } from "react-router-dom";
import BaseContext from '../../contexts';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Divider,
  Collapse,
  ListItemIcon,
  Tabs, Tab,
  Stack,
  Typography,
  Grid
} from '@mui/material';
import { Constants } from "../../utils";
import EN from "../../contexts/en";
import { SocialMedia, IsoCertificate, CyberCertificate } from "../../components";
import { Utilities } from "../../utils";

const StixTab = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`stix-tabpanel-${index}`} className={'p'} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div dangerouslySetInnerHTML={{ __html: children }} />
        </Box>
      )}
    </div>
  );
}

StixTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const LanguateText = ({ text, selected, onClick }) => {
  return (
    <Typography
      variant={"body1"}
      component={"h1"}
      sx={{
        color: selected ? "#0082D6" : "#FFFFFF",
        fontWeight: "600",
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      {text}
    </Typography>
  )
}

const hk_Codes = ["EN", "CH_1", "CH_2"]
const vn_Codes = ["EN", "VN"]


const NewMultiLangTerms = (props) => {

  const { isMobile } = useDeviceDetect();
  const [selectedLang, setSelectedLang] = useState(hk_Codes[0])
  const base = React.useContext(BaseContext);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [totalItemsInCart, setTotalItemsInCart] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [languageCodes, setLanguageCodes] = useState(props.path == "hk" ? hk_Codes : vn_Codes)
  const [state, setState] = useState({
    selectedTab: props.id,
    selectedPath: props.path
  });

  const [content, setContent] = useState({
    conditions: {
      description: [],
      title: ""
    },
    cookie_policy: {
      description: [],
      title: ""
    },
    privacy_policy: {
      description: [],
      title: ""
    },
    terms_cond: {
      description: [],
      title: ""
    }
  });

  useEffect(() => {
    setLanguageCodes(props.path == "hk" ? hk_Codes : vn_Codes)
    setState({ ...state, selectedTab: props.id, selectedPath: props.path });
  }, [props.id, props.path]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setDefaultLanguage()
  }, []);

  const setDefaultLanguage = () => {
    let lCode = Utilities.getCookie("lang_code");
    if (lCode) {
      if (state.selectedPath == "vn" && (lCode == "CH_1" || lCode == "CH_2")) {
        lCode = "EN";
      }
      else if (state.selectedPath == "hk" && lCode == "VN") {
        lCode = "EN";
      }
      onClickOfLanguage(lCode, false)
    } else {
      onClickOfLanguage(selectedLang, false)
    }
  }

  React.useEffect(() => {
    // console.log("useEffect called: ", base.data.user)
    let isLoginStatus = base.data.user.login.loginStatus;
    let totalItemsCart = base.data.user.login.totalLineItems;
    if (isLoginStatus === 0) {
      setIsLoggedIn(true);
      setTotalItemsInCart(totalItemsCart ? totalItemsCart : 0);
    } else {
      setIsLoggedIn(false);
      setTotalItemsInCart(0);
    }
  }, [base.data.user]);

  const handleLogin = () => {
    window.location.href = Constants.FALLBACK_URL + window.location.href;
  }

  const handleTabChange = (event, newValue) => {
    setState({ ...state, selectedTab: newValue });
    window.history.pushState(null, false, `/${state.selectedPath == "hk" ? "hk" : "vn"}` + content[newValue].urlParam);
  }

  const onClickOfLanguage = (langCode, gotoTerms) => {
    setSelectedLang(langCode);
    switch (langCode) {
      case "EN":
        let contentForEnglish = {};
        if (state.selectedPath == "vn") {
          contentForEnglish = {
            terms_cond: {
              "urlParam": ContentData_En.terms_cond.urlParam,
              "title": ContentData_En.terms_cond.title,
              "description": ContentData_En.terms_cond.description
            },
            privacy_policy: {
              "urlParam": ContentData_En.privacy_policy.urlParam,
              "title": ContentData_En.privacy_policy.title,
              "description": ContentData_En.privacy_policy.description
            }
          }
        }else {
          contentForEnglish = ContentData_En;
        }
        setContent(contentForEnglish);
        break;
      case "VN":
        setContent(ContentData_VN);
        break;
      case "CH_1":
        setContent(ContentData_ZH_HK);
        break;
      case "CH_2":
        setContent(ContentData_ZH_EN);
        break;
    }
    if (gotoTerms) {
      setState({ ...state, selectedTab: "terms_cond" });
      window.history.pushState(null, false, `/${state.selectedPath == "hk" ? "hk" : "vn"}` + content["terms_cond"].urlParam);
    }
    Utilities.setCookie("lang_code", langCode)
  }

  const headerContent = () => {
    const logo = "/imgs/sistic/logo.png";
    const cartIcon = "/imgs/cart.svg";
    const userIcon = "/imgs/user.svg";
    const headerClass = props.header ? props.header.parentClass : '';
    const loginDescription = props.header ? props.header.loginDescription : '';
    const logoUrl = process.env.REACT_APP_ENV === "production" ? 'https://sistic.com.sg' : window.location.origin

    return (
      <div className={"sistic-header-container"} style={{height: "70px"}}>
        <div className="f-left">
          <Link to={logoUrl} className="dLogo" onClick={() => { }}><img src={logo} alt={"logo"} /></Link>
        </div>
        <div className="f-right">
          <Stack
            direction={"row"}
            spacing={3}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "center"
              }}
              divider={<Divider orientation="vertical" sx={{ backgroundColor: "#FFFFFF", width: "2px", height: "70%" }} />}
              spacing={1}
            >
              <LanguateText text={"EN"} selected={selectedLang == languageCodes[0] ? true : false} onClick={() => onClickOfLanguage(languageCodes[0], true)} />
              {state.selectedPath == "hk" && (
                <LanguateText text={"繁"} selected={selectedLang == languageCodes[1] ? true : false} onClick={() => onClickOfLanguage(languageCodes[1], true)} />
              )}
              {state.selectedPath == "hk" && (
                <LanguateText text={"简"} selected={selectedLang == languageCodes[2] ? true : false} onClick={() => onClickOfLanguage(languageCodes[2], true)} />
              )}
              {state.selectedPath == "vn" && (
                <LanguateText text={"VN"} selected={selectedLang == languageCodes[1] ? true : false} onClick={() => onClickOfLanguage(languageCodes[1], true)} />
              )}
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
            >
              <IconButton
                color="inherit"
                className={'cart'}
                onClick={() => { }}
              >
                <Badge badgeContent={totalItemsInCart.toString()}>
                  <img src={cartIcon} />
                </Badge>
              </IconButton>

              {<IconButton
                color="inherit"
                className={"user"}
                sx={{ ml: 2 }}
                onClick={handleLogin}
              >
                <img src={userIcon} />
                <span className={["ustat", isLoggedIn ? "active" : ''].join(" ")}></span>
              </IconButton>}
            </Stack>
          </Stack>
        </div>
      </div>
    );
  }

  return (
    <html>
      <head>
        <meta name="robots" content="noindex, nofollow" />
      </head>
      <body>
        <Fragment>
          <div className="header">
            {headerContent()}
          </div>
          <section className="newAgreement-container">
            <div className="newAgreement-header">
              <img src={termsBanner} className="img-fluid" alt="page-banner" />
              <div className="banner-overlay">
                <h1>{content[state.selectedTab].title}</h1>
              </div>
            </div>
            <div className={"newAgreement-body"}>
              <Tabs value={state.selectedTab} onChange={handleTabChange} centered>
                {Object.keys(content).map((rw, index) => {
                  return <Tab
                    key={"Tabs" + index}
                    label={isMobile ? '' : content[rw].title}
                    icon={isMobile ? <i className={['icon-' + rw, rw === state.selectedTab ? 'active' : ''].join(" ")} /> : ''}
                    value={rw}
                  />;
                })}
              </Tabs>

              {Object.keys(content).map((rw, index) => {
                return <StixTab key={"STIX" + index} value={state.selectedTab} index={rw}>{content[rw].description.map((value) => value)}</StixTab>;
              })}

            </div>

          </section>
          <footer className="footer">
            <Box sx={{ flexGrow: 1 }}>
              <Grid item container spacing={2} xs={12}>
                <div className="footer-bottom">

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                  >
                    <div className="copyrights">
                      <span>Copyright 1998 - {new Date().getFullYear()}. © SISTIC.com Pte Ltd</span>
                    </div>
                    <div>
                      <Stack
                        direction={"row"}
                        spacing={2}
                      >
                        <Grid
                          item
                          container
                          className="socialmedia-iso"
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <SocialMedia socmed={EN.en.footer.social_media} />
                        </Grid>

                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <IsoCertificate isoCertificate={EN.en.footer.isoCertificate} />
                          <div style={{ width: 10 }}></div>
                          <CyberCertificate cyberCertificate={EN.en.footer.cyberCertificate} />
                        </Stack>
                      </Stack>
                    </div>

                  </Stack>

                </div>
              </Grid>
            </Box>
          </footer>
        </Fragment>
      </body>
    </html>
  );
}

export default NewMultiLangTerms;
import React, { Fragment } from 'react';
import MacBook from '../../../assets/images/website.png';
import { Link } from 'react-router-dom';
import Iphone from '../../../assets/images/mobile-app-section.png';
import Map from '../../../assets/images/map.png';
import Contact from '../../../assets/images/contact-us.png';
import appleImage from '../../../assets/images/apple.svg';
import androidImage from '../../../assets/images/android.png';
import Image from '../../../components/Image';
import { Constants } from '../../../utils';

const contactUsLink = "https://www.corporate.sistic.com.sg/contact-us";

const Content = ({ whereBuyTicketsDetails, apiPartners }) => {
  let contactNumber;
  if (whereBuyTicketsDetails[4].title.length > 0 && whereBuyTicketsDetails) {
    let index = whereBuyTicketsDetails[4].title.match(/\d/).index;
    if (index) {
      let initial = whereBuyTicketsDetails[4].title.slice(0, index);
      let number = whereBuyTicketsDetails[4].title.slice(
        index,
        whereBuyTicketsDetails[4].title.length
      );
      contactNumber = (
        <>
          {initial} <a href={`tel:+${number}`}>{number}</a>
        </>
      );
    }
  }

  return (
    <Fragment>
      <div className="">
        <div
          className="sistic-singapore-section"
          id={whereBuyTicketsDetails[0].title}
        >
          <div className="container-fluid custom-container">
            <div className="wtbt-desc">
              <div className="wtbt-content">
                <h1>{whereBuyTicketsDetails[0].title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: whereBuyTicketsDetails[0].description
                  }}
                />
                <Link
                  className="wtbt-btn"
                  to={`/events?c=${Constants.PRESELECTED_GENRES}`}
                >
                  Find an Event
                </Link>
              </div>
              <div className="wtbt-image">
                <Image src={MacBook} alt="partner" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="mobile-app-section"
          id={whereBuyTicketsDetails[1].title}
        >
          <div className="container-fluid custom-container">
            <div className="wtbt-desc">
              <div className="wtbt-content">
                <h1>{whereBuyTicketsDetails[1].title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: whereBuyTicketsDetails[1].description
                  }}
                />

                <div className="download-option">
                  <a href={Constants.SISTIC_APP_STORE_URL} target="_blank">
                    <Image src={appleImage} className="ios" alt="" />
                    <span style={{color: "black", marginLeft: "10px"}}>
                      Download on the
                      <br />
                      <strong style={{ fontSize: '19px' }}>App Store</strong>
                    </span>
                  </a>
                  <a href={Constants.SISTIC_PLAY_STORE_URL} target="_blank">
                    <Image src={androidImage} className="android" alt="" />
                    <span style={{color: "black", marginLeft: "10px"}}>
                      GET IT ON
                      <br />
                      <strong style={{ fontSize: '19px' }}>Google Play</strong>
                    </span>
                  </a>
                </div>
              </div>
              <div className="wtbt-image">
                <Image src={Iphone} alt="partner" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="agent-section" id={whereBuyTicketsDetails[2].title}>
          <div className="container-fluid custom-container">
            <div className="wtbt-desc">
              <div className="wtbt-content">
                <h1>{whereBuyTicketsDetails[2].title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: whereBuyTicketsDetails[2].description
                  }}
                />
                <Link className="wtbt-btn" to="/agents">
                  Find Agents
                </Link>
              </div>
              <div className="wtbt-image">
                <Image src={Map} alt="partner" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="api-partners-section"
          id={whereBuyTicketsDetails[3].title}
        >
          <div className="container-fluid custom-container">
            <div className="wtbt-desc">
              <div className="wtbt-image">
                {/* <div className="img-fluid"> */}
                {apiPartners &&
                  apiPartners.map(partner => {
                    return (
                      <a
                        href={partner.url}
                        key={partner.logo}
                        className="api-images"
                      >
                        <Image
                          src={partner.logo}
                          key={partner.logo}
                          alt="partner-logo"
                          className="img-fluid"
                        />
                      </a>
                    );
                  })}
                {/* </div> */}
              </div>
              <div className="wtbt-content">
                <h1>{whereBuyTicketsDetails[3].title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: whereBuyTicketsDetails[3].description
                  }}
                />
                {/* <Link
                  className="wtbt-btn api-see-all-btn"
                  to="/corporate/partner-with-us"
                >
                  See All Partners
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="hotline-section" id={whereBuyTicketsDetails[4].title}>
          <div className="container-fluid custom-container">
            <div className="wtbt-desc">
              <div className="wtbt-content">
                <h1>
                  {contactNumber
                    ? contactNumber
                    : whereBuyTicketsDetails[4].title}
                </h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: whereBuyTicketsDetails[4].description
                  }}
                />
                <a href={contactUsLink}>Contact Us</a>
              </div>
              <div className="wtbt-image">
                <Image src={Contact} alt="partner" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Content;

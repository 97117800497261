import { any } from 'prop-types';
import React,{useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import BaseContext from '../../contexts';
import {AllFunctions} from '../../helper/functions';
import {Constants} from '../../utils';
import jsonp from 'jsonp';

export const Header = (props) => {
    const base = React.useContext(BaseContext);
    const [isActivate, setActivate] = useState(true);
    const location = useLocation();

    useEffect(()=>{
        jsonp(Constants.FALLBACK_FETCH_CART_DATA_URL, null, (err, data) => {
            if (err) {
              console.error(err.message);
            } else {
                if(process.env.REACT_APP_ENV === "development"){
                    console.log("[Header]",props,err, data, base.data);
                }
                let setData = base.data;
                setData.user = {
                  ...base.data.user,
                  login: data
                }
                
                props.updateBase && props.updateBase(setData);
            }
          });
    },[]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setActivate(true);
        }, 4000);
        return () => clearTimeout(timer);
    }, []);


    return (
    <BaseContext.Consumer>
        
        {data => (<>
            <HeaderContent 
                {...props} 
                data={data}
                />
        </>)}
    </BaseContext.Consumer>
    );
}


const HeaderContent = (props) => {
    const HeaderTemplate = AllFunctions.loadTemplate(props.env).Header;
    return (<>
        <HeaderTemplate {...props}/>
    </>)
}

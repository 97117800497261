import React, { useState, useEffect, Fragment } from 'react';
import { AdvWrapper } from '../../../components/AdvWrapper';

const Item = ({ elem }) => {
  return (
    <div className="adds-container">
      <div className="container-fluid custom-container p-0">
        <span className="giftcard-anchor">
          <img
            src={elem && elem.full_image}
            className="img-fluid"
            alt={elem && elem.alt_text}
            title={elem && elem.title}
          />
        </span>
      </div>
    </div>
  );
};

const GiftCard = ({ api, params }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api(params)
      .then(res => {
        if (res && res.data) {
          setTimeout(() => {
            setData(res.data.data);
            setLoading(false);
          }, 2000);
        }
      })
      .catch(err => {
        setError(true);
      });
  };
  if (!loading && data && data.length === 0) {
    return null;
  }

  if (error) return null;

  return (
    <Fragment>
      {data &&
        data.map(elem => {
          return (
            <AdvWrapper navigationLink={elem.navigation_link}>
              <Item elem={elem} />
            </AdvWrapper>
          );
        })}
    </Fragment>
  );
};

export default GiftCard;

import React from "react";
import "./style.scss";
import {
    Grid,
    Box,
    Card,
    Stack,
    Typography
} from '@mui/material';
import Image from "../Image";
import Slider from 'react-slick';
import { AdvWrapper } from '../../components/AdvWrapper';


const data = {
    title: "",
    description: "",
    data: [
        {
            "image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-09/hob1023%28new%29_436x326.jpg",
            "title": "Immersive Disney Animation",
            "description": "<p>M18 [Mature Content]<br />\r\nEight hundred years ago, Xie Lian was the Crown Prince of the Xian Le kingdom; one who was beloved by his citizens and the darling of the world. Unsurprisingly, he ascended to the Heavens at a very young age.</p>\r\n",
            "button_text": "Book Now",
            "button_url": "https://www.sistic.com.sg/events/hob1023"
        },
        {
            "image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-09/willliu_436x326.jpg",
            "title": "The World of Nickelodeon Singapore",
            "description": "<p>Chinese artiste, Liu Geng Hong (刘畊宏), will be headlining a concert at Sands Grand Ballroom, performing alongside mandopop group Nan Quan Mama (南拳妈妈) to stage a night of show-stopping performances.</p>\r\n",
            "button_text": "Book Now",
            "button_url": "https://www.sistic.com.sg/events/willliu1023"
        },
        {
            "image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-05/mammamia-landscape-436x326.jpg",
            "title": "MAMMA MIA! [G]",
            "description": "<p>Having thrilled over 65 million people worldwide, the sunniest and most exhilarating musical of all-time is coming to Singapore this October to let you experience that Greek holiday treat you deserve! </p>\r\n",
            "button_text": "Book Now",
            "button_url": "https://www.sistic.com.sg/events/mammamia1123"
        }
    ]
}

const sampleText = "Copy Lorem ipsum dolor sit amet consectetur. Vulputate ac sit vulputate purus lectus tincidunt. Maecenas lacus nisi neque lacus viverra. Orci odio rhoncus bibendum in iaculis tristique."

const NewItemsListPanel = ({itemsData, title, description}) => {

    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        autoplay: false,
        speed: 500,
        slidesToShow: 3.2,
        slidesToScroll: 3
      };
      
    const listItems = (item, index) => {
        return (
            // <li key={index}>
            <AdvWrapper navigationLink={item?.event_url} linkClass="event">
                <Box key={i} sx={{ paddingY: "10px", marginRight: "25px" }} component={"div"} className="box-style">
                    <Card className="card-style">
                        <Stack>
                            <Image
                                src={item?.event_image}
                                type="Horizontal"
                            />
                            <Box sx={{ height: '240px', paddingInline: '20px', paddingTop: "20px", paddingBottom: "10px", overflow: "hidden" }}>
                                <Typography
                                    variant={"body1"}
                                    component={"span"}
                                    fontFamily={"Montserrat"}
                                    fontWeight={600}
                                    color={"#343434"}
                                    textAlign={"start"}
                                    className="title-style"
                                >
                                    {item.event_title}
                                </Typography>
                                <div style={{ height: "15px" }}></div>
                                <Typography
                                    variant={"body2"}
                                    component={"span"}
                                    fontFamily={"Rubik"}
                                    fontWeight={400}
                                    fontSize={14}
                                    color={"#626262"}
                                    textAlign={"start"}
                                >
                                    {item.event_description}
                                </Typography>
                            </Box>
                        </Stack>
                    </Card>
                </Box>
            </AdvWrapper>
            // </li>
        )
    };

    if (itemsData?.length == 0) {
        return null;
    }

    return (
        <>
            <section className="new-items-list-panel">
                <div className="container-fluid custom-container">
                    <Grid container spacing={1} sx={{}}>
                        <Grid sm={12} md={3.3} lg={3.3} xl={3.3}>
                            <div className="left-conetnt">
                                <Stack>
                                    <Typography
                                        variant={"h3"}
                                        component="h2"
                                        fontFamily={"Montserrat"}
                                        fontWeight={700}
                                        color={"#343434"}
                                        textAlign={"start"}
                                    >
                                        {title}
                                    </Typography>
                                    <Typography 
                                        variant={"body2"} 
                                        component={"label"}
                                        fontFamily={"Rubik"}
                                        fontWeight={400}
                                        color={"#626262"}
                                        textAlign={"start"}
                                    >
                                    {description}
                                    </Typography>
                                </Stack>
                            </div>
                        </Grid>
                        <Grid sm={12} md={8.7} lg={8.7} xl={8.7} sx={{ display: "flex", position: "relative", justifyContent: "center", alignItems: "center"}} className="right-content">
                            <div className="gray-div-style"></div>
                            <div className="items-div-style">
                                {/* <ul style={{listStyleType: "none"}}>
                                    {itemsData.map((item, index) => {
                                        return (listItems(item, index))
                                    })}
                                </ul> */}
                                <Slider 
                                    {...settings}
                                    responsive={[
                                        {
                                            breakpoint: 600,
                                            settings: {
                                                slidesToShow: 2.2,
                                                slidesToScroll: 2
                                            }
                                        },
                                        {
                                            breakpoint: 480,
                                            settings: {
                                                slidesToShow: 2.2,
                                                slidesToScroll: 2
                                            }
                                        }
                                    ]}
                                >
                                {itemsData.map((item, index) => {
                                        return (listItems(item, index))
                                    })}
                                </Slider>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </>
    )
}

export default NewItemsListPanel;
import React from "react";
import "../styles/sistic/_clearCache.scss";
import {
    Box,
    Grid,
    Typography,
} from '@mui/material';
import ClearCacheDataComp from "../components/ClearCacheDataComp";
import DesktopImg from "../assets/images/device-desktop.svg";
import TabletMobileImg from "../assets/images/device-tablet-mobile.svg";
import useDeviceDetect from "../hooks/useDeviceDetect";

const mobileData = [
    {
        title: "Safari (iOS)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendiss malesuada lacus ex, sit amet blandit leo lobortis eget."
    },
    {
        title: "Google Chrome (iOS)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendiss malesuada lacus ex, sit amet blandit leo lobortis eget."
    },
    {
        title: "Google Chrome (Android)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendiss malesuada lacus ex, sit amet blandit leo lobortis eget."
    },
    {
        title: "Samsung Internet (Android)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendiss malesuada lacus ex, sit amet blandit leo lobortis eget."
    }
]

const desktopData = [
    {
        title: "Safari (MacOS)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendiss malesuada lacus ex, sit amet blandit leo lobortis eget."
    },
    {
        title: "Google Chrome (Windows/MacOS)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendiss malesuada lacus ex, sit amet blandit leo lobortis eget."
    },
    {
        title: "Mozilla Firefox (Windows/MacOS)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendiss malesuada lacus ex, sit amet blandit leo lobortis eget."
    },
    {
        title: "Microsoft Edge (Windows)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendiss malesuada lacus ex, sit amet blandit leo lobortis eget."
    }
]

export const ClearCachePage = () => {

    const { isMobile } = useDeviceDetect();

    return (
        <div className="clear-cache-wrapper">
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height: "100px", backgroundColor: "white" }}>
                <Typography
                    variant={"h6"}
                    fontFamily={"Rubik"}
                    fontWeight={600}
                    color={"#343434"}
                >
                    {"How to Clear Browser Cache?"}
                </Typography>
            </Box >
            <Box sx={{ display: "flex", backgroundColor: "white", paddingInline: isMobile ? "10px" : "50px", paddingY: "20px" }}>
                <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} >
                    <Grid item xs={12} sm={12} md={6}>
                        <ClearCacheDataComp title={"Mobile"} image={TabletMobileImg} data={mobileData} isMobile={isMobile}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <ClearCacheDataComp title={"Desktop"} image={DesktopImg} data={desktopData} isMobile={isMobile}/>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { Box, Grid, Avatar } from "@mui/material";
import { Constants } from "../../../utils";

import AdsService from "../../../services/AdvertisementService";

export const EventTopBanner = (props) => {
  const [bannerData, setbannerData] = useState([]);
  useEffect(() => {
    const params = {
      client: Constants.CLIENT,
      first:0,
      limit:2
    };
    AdsService.getEventListService(params).then((res) => {
      setbannerData(res.data.data);
    });
  }, []);
  return (
    <Grid container spacing={2} pt={2}>
      {bannerData &&
        bannerData.map((_d, idx) => (
          <Grid item xs={12} md={6} key={idx}>
            <div className={`events-browse-advertisement-banner-${idx + 1}`}>
              <Box component="div">
                <a href={_d.navigation_link}>
                  <Avatar
                    src={_d.full_image}
                    title={_d.title}
                    alt={_d.alt_text}
                    variant="square"
                    sx={{ height: "auto", width: "100%" }}
                  />
                </a>
              </Box>
            </div>
          </Grid>
        ))}

      {/* <Grid item xs={12} md={6}>
        <Box component="div">
          <Avatar
            src="../imgs/banner-2.svg"
            variant="square"
            sx={{ height: 'auto', width: "100%" }}
          />
        </Box>
      </Grid> */}
    </Grid>
  );
};
export default EventTopBanner;
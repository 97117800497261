import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Popper,
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Grow,
    TextField,
    Slider
  } from '@mui/material';
  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const StixDropDownPrice = (props) => {
    // for price range slider
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(100);
    const [isChanged, setIsChanged] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [pRangeVal, setPRange] = useState([minValue, maxValue]);
    const [selectedPriceRangeVal, setSelectedPriceRangeVal] = useState([]);
    const [changeStat, setChangeStat] = useState(false);

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
            setIsChanged(false)
        } else if (event.key === 'Escape') {
            setOpen(false);
            setIsChanged(false);
        }
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setIsChanged(false);
        setOpen(false);
    }

    const handleSliderPriceRangeChange = (e) => {
        const val = e.target.value;
        setPRange(val);
    } 

    const handleSliderPriceRangeChangeCommitted = (event, newValue) => {
        setSelectedPriceRangeVal([newValue[0], newValue[1]])
        setChangeStat(true);
        setIsChanged(true);
      };

    const handleReset =() => {
        setPRange([minValue, maxValue]);
        setSelectedPriceRangeVal([]);
        setIsChanged(true);
    }

    const handleTextFieldPriceRangeChangeOnBlur = () => {
        
        setSelectedPriceRangeVal(pRangeVal)
        setIsChanged(true);        
        setChangeStat(true);
    };

    useEffect(()=>{
        if(props.priceRange){
            setPRange([props.priceRange.min, props.priceRange.max]);
            setMinValue(props.priceRange.min);
            setMaxValue(props.priceRange.max);
        }
    },[props.priceRange]);

    useEffect(()=>{
        if (selectedPriceRangeVal?.length > 0) {
            const data = {
                min: selectedPriceRangeVal[0],
                max: selectedPriceRangeVal[1]
            } 
            if(isChanged && props.componentCallBack){
                props.componentCallBack(props.id, data, changeStat);
            }
        } 
    },[selectedPriceRangeVal])

    return (<div className={"xdropdown"}>
        <Button
            className={"xbtn"}
            ref={anchorRef}
            onClick={()=>setOpen((prevOpen) => !prevOpen)}
            endIcon={<KeyboardArrowDownIcon />}
        >
            <span className={"xbtn-name"}>{props.name && props.name}</span>
        </Button>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            className={'xpopper'}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{
                transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
            >
                <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                    autoFocusItem={open}
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    >
                    <MenuItem className={"xheader"}>
                        <span className={'xname'}>{props.name && props.name}</span> 
                        <span className={'reset'} onClick={handleReset}>Reset</span>
                    </MenuItem>

                    <MenuItem className={'xprice'}>
                        <div className={"pRange"}>
                            <div className={'xInputContainer'}>
                                <TextField 
                                    className="left" 
                                    variant="outlined" 
                                    value={["S$", pRangeVal[0]].join('')}
                                    onChange={(e) => {
                                        const enterdVal = Number(e.target.value.substring(2));
                                        if (enterdVal >= minValue || enterdVal < maxValue) {
                                            setPRange([enterdVal, pRangeVal[1]]);
                                        }
                                    }}
                                    onBlur={handleTextFieldPriceRangeChangeOnBlur}
                                />
                                <TextField 
                                    className="right" 
                                    variant="outlined" 
                                    value={["S$", pRangeVal[1]].join('')}
                                    onChange={(e) => {
                                        const enterdVal = Number(e.target.value.substring(2));
                                        if (enterdVal > minValue || enterdVal <= maxValue) {
                                            setPRange([pRangeVal[0], enterdVal]);
                                        }                                        
                                    }}
                                    onBlur={handleTextFieldPriceRangeChangeOnBlur}
                                />
                            </div>
                            
                            <Slider
                                onChange={handleSliderPriceRangeChange}
                                onChangeCommitted={handleSliderPriceRangeChangeCommitted}
                                // aria-label="Price Range"
                                value={pRangeVal}
                                defaultValue={pRangeVal}
                                min={minValue}
                                max={maxValue}
                                disableSwap={true}
                            />
                        </div>
                    </MenuItem>
                    
                    </MenuList>
                </ClickAwayListener>
                </Paper>
            </Grow>
            )}
        </Popper>
    </div>);
}

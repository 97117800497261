import React, {useState, useEffect, useContext } from "react";
import moment from "moment";
import { Constants, Utilities } from "../../../utils";
import BaseContext from '../../../contexts';
import HomeService from "../../../services/HomeService";
import Items from "./Items";
import EventsService from "../../../services/EventsService";
import FilterContainer from "./FilterContainer";
import queryString from "query-string";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import {StixOverlayLoader} from '../../UI';

export const Listing = (props = {}) => {
    const base = useContext(BaseContext);
    const { isMobile } = useDeviceDetect();
    const { smallDown } = useCustomMediaQuery();
    let qstring = queryString.parse(window.location.search);
    const [loading, setLoading] = useState(false);
    const [eventData, setEventData] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [categories, setCategories] = useState([]);
    const [catvalue, setCatValue] = useState(0);
    const [search, setSearch] = useState(qstring.s ? qstring.s : ''); 
    const [filterState, setFilterState] = useState({});
    const [sortDetails, setSortDetails] = useState({ type: "date", asc: true });
    const [APICallCounter, setAPICounter] = useState(0);
    const [bulkForFilter, setBulkForFilter] = useState({
      selectedCategories: [],
      priceRange: {
        max: 0,
        min: 0,
        val: [0, 0],
      },
      dateRange: {
        start: null,
        end: null,
      },
    });

    const handleSortCallBack = (type = "date", asc = true) => {
      // Keep track of sorting params
      setSortDetails({ type: type, asc: asc });
  
      sortingData(type, asc);
    };

    useEffect(() => {
      if (APICallCounter === 1) {
        handleSortCallBack();
      }
    }, [APICallCounter])
    
    useEffect(()=>{
      if(!search && props.topCatSelected === null){
        apiCall(catvalue);
      }
    },[]);

    useEffect(()=>{
      if(!search && props.topCatSelected !== null){
        setCatValue(props.topCatSelected);
        apiCall(props.topCatSelected);
      }
    },[props.topCatSelected]);

    useEffect(()=>{
      if(base.data.search){
        setSearch(base.data.search);
        apiCall(catvalue);
      }
    },[base.data.search]);
  
    const sortingData = (type = "", asc = true) => {
      let newdata = eventData;
  
      if (type === "title") {
        newdata = eventData.sort((a, b) => {
          if (asc === false) {
            return a.title > b.title ? -1 : 1;
          } else {
            return a.title > b.title ? 1 : -1;
          }
        });
      } else if (type === "date") {
        newdata = eventData.sort((a, b) => {
          let c = new Date(a.start_date);
          let d = new Date(b.start_date);
  
          // if asc === true (earliest to oldest)
          if (asc === true) {
            return c-d;
          // if asc === false (oldest to earliest)
          } else {
            return d-c
          }
        });
  
        // for asc, need to sort events with start_date > 1 mths to back of list 
        // (i.e. events > 1 mths before today, and > 1 mths in the future)
        if (asc === true) {
          let todayDate = moment();
          let moreThan1MthInThePast = []
          let moreThan1MthInTheFuture = []
          let eventsToFeatureFirst = [] 
    
          newdata.forEach((item) => {
            let dateToTest = item.start_date;
            let diffInMthsFromToday = todayDate.diff(dateToTest, 'months')
    
            if(diffInMthsFromToday > 0)  {
              moreThan1MthInThePast.push(item)
            } else if (diffInMthsFromToday < 0) {
              moreThan1MthInTheFuture.push(item)
            } else {
              eventsToFeatureFirst.push(item)
            }
          })
  
          newdata = [...eventsToFeatureFirst, ...moreThan1MthInTheFuture, ...moreThan1MthInThePast]
        }
  
      } else if (type === "price") {
        newdata = eventData.sort((a, b) => {
          let c = a.min_price
          let d = b.min_price
  
          const multiplier = (asc === true) ? 1 : -1;
  
          if (c === d) { // identical? return 0
            return 0;
          } else if (c === null) { // a is null? last 
            return 1;
          } else if (d === null) { // b is null? last
            return -1;
          } else { // compare, negate if descending
            return (parseInt(c) - parseInt(d)) * multiplier;
          }
        });
      }
  
      setEventData(newdata);
    };
  
    const handleCallbackWithFilter = () => {
          if(bulkForFilter){
              let genreList = bulkForFilter.selectedCategories;
              const priceRange = bulkForFilter.priceRange.val;
              const addtfilter = {};
              if(bulkForFilter.dateRange.start) addtfilter.start_date = bulkForFilter.dateRange.start;
              if(bulkForFilter.dateRange.end) addtfilter.end_date = bulkForFilter.dateRange.end;
              if(priceRange) addtfilter.min_price = priceRange[0];
              if(priceRange) addtfilter.max_price = priceRange[1];
  
              apiCall(genreList.length > 0 ? genreList.join(",") : "", {
                ...addtfilter,
              });
      }
    };
  
    const handleCallbackFromDesktopFilter = (filter) => {
          if(filter){
              let genreList = [];
              if (filter?.categories?.items) {
                genreList = filter?.categories?.items;
              }
              
              const addtfilter = {};
              if (filter.dateRange) {
                const dateRange = filter.dateRange;
                if(dateRange && dateRange.start) addtfilter.start_date = dateRange.start;
                if(dateRange && dateRange.end) addtfilter.end_date = dateRange.end;
              }

              if (filter.priceRange) {
                const priceRange = filter.priceRange;
                if(priceRange && priceRange.min) addtfilter.min_price = priceRange.min;
                if(priceRange && priceRange.max) addtfilter.max_price = priceRange.max;
              }
              
              setFilterState(filter);
              apiCall(
                  genreList.length > 0 ? genreList.join(',') : '',
                  {...addtfilter},
                  false
              );  
          }
    }
  
    const apiCall = async (catvalue, additionalParam = {}, setbulk = true) => {
      const getSearchVal = base.data.search ? base.data.search : qstring.s ? qstring.s : search;
      const params = {
        first: 0,
        limit: "1000",
        sort_type: "date",
        sort_order: "ASC",
        genre: catvalue === 0 ? qstring.genre ? qstring.genre : "" : catvalue,
        client: Constants.CLIENT,
        ...additionalParam,
      };
  
      if (getSearchVal) {
        params.search = getSearchVal;
      }
      setLoading(true);
      await EventsService.getData(params).then((res) => {
        let maxPrice = 100, minPrice = 0;
        res.data.data && res.data.data.length > 0 && res.data.data.map((d) => {
            if (d) {
              maxPrice = !maxPrice ? parseInt(d.max_price) : (d.max_price && parseInt(d.max_price) > maxPrice ? parseInt(d.max_price) : maxPrice);
              minPrice = !minPrice ? parseInt(d.min_price) : (d.min_price && parseInt(d.min_price) < minPrice ? parseInt(d.min_price) : minPrice);
            }
        });

           if (setbulk && APICallCounter < 1) {
          setBulkForFilter({
            ...bulkForFilter,
            priceRange: {
              min: minPrice ? minPrice : 0,
              max: maxPrice ? maxPrice : 100,
              val: [minPrice ? minPrice : 0, maxPrice ? maxPrice : 100],
            },
          });
        }
        setAPICounter(APICallCounter + 1);
        setEventData(res.data.data);
        setLoading(false);
      });
    };
  
    const handleIndividualReset = () => {
        setBulkForFilter({
            selectedCategories:[],
            priceRange:{
                max:0,
                min:0,
                val:[0,0]
            },
            dateRange:{
                start: null,
                end: null
            }
        })
    }

    return (<>
        <FilterContainer
          {...props}
          setBulkForFilter={setBulkForFilter}
          forFilter={bulkForFilter}
          callbackWithFilter={handleCallbackWithFilter}
          callbackFromDesktopFilter={handleCallbackFromDesktopFilter}
          sortCallBack={handleSortCallBack}
          onCatChange={catvalue}
        /> 
  
        {!smallDown && eventData && eventData.length > 0 && (<div className={"search-count"}>
            {eventData.length} results found {(search !== '') ? <>for <strong>{`"${search}"`}</strong></> : ''}
        </div>)}
        
        <Items details={eventData} sortDetails={sortDetails} />
  
        {eventData && eventData.length === 0 && (
          <div className={"search-count"}>0 results found</div>
        )}
        {loading && <StixOverlayLoader />}
    </>);
  };
  
  export default Listing;
  
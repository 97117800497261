import React, { useEffect, useState } from "react";
import BaseContext from "../contexts";
import { ExploreContainer} from "../components";
import useDeviceDetect from "../hooks/useDeviceDetect";

export const ExplorePage = (props) => {
  const base = React.useContext(BaseContext);
  const { isMobile } = useDeviceDetect();

  return (
    <div className={"explore-wrapper"}>
        <ExploreContainer
          pageType={props.id}
        />
    </div>);
};

import { React, useState, useEffect } from "react";
import { Box, Grid, TextField, InputAdornment } from "@mui/material";
import { EventCard } from "./EventCard";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import EventsService from "../../../services/EventsService";
import queryString from "query-string";
import moment from "moment";
import EventsPlannerIllustration from "../../../assets/images/events-planner-illustration.svg";
import SearchIconGray from "../../../assets/images/search-gray.svg";

export const Listings = (props) => {
  const [events, setEvents] = useState([]);
  const [filter, setFilter] = useState("");
  const { smallDown } = useCustomMediaQuery();

  useEffect(() => {
    let qstringval = queryString.parse(window.location.search);
    let queryParams = {
      first: 0,
      limit: 1000,
      sort_type: "date",
      sort_order: "ASC",
      client: 1,
      min_price: 0,
    };

    qstringval &&
      Object.keys(qstringval).length > 0 &&
      Object.keys(qstringval).map((qs) => {
        if (qs === "start_date" || qs === "end_date") {
          queryParams[qs] = qstringval[qs]
            ? moment(new Date(qstringval[qs])).format(`YYYY-MM-DDT00:00:00`)
            : "";
        } else {
          queryParams[qs] = qstringval[qs];
        }
      });

    EventsService.getData(queryParams)
      .then((results) => {
        setEvents(results.data.data);
      })
      .catch((err) => {
        console.error("[PV Err]: ", err);
      });
  }, []);

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredEvents = events.filter(
    (event) =>
      event.venue_name &&
      event.venue_name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className={"plan-listings"}>
      <div className={"plan-visit-header"}>
        <div className="plan-visit-header-wrapper">
          <div className="text">
            <h1>Here are the events or attractions that best suits you!</h1>
            {/* <p>
              Share these activities and experiences with your family and
              friends now.
            </p> */}
          </div>
          <img src={EventsPlannerIllustration} />
        </div>
      </div>
      <Grid container direction="column">
        <Grid
          item
          container
          direction="column"
          className="search-box-container"
        >
          <p>Find your Venue</p>
          <TextField
            value={filter}
            onChange={handleInputChange}
            placeholder="Search by building, area or address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIconGray} />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </Grid>
        <Box className={"eventListings-container"}>
          <div className="selection-wrapper">
            {/* You may select multiple events to share, or click into the card to
            read more or buy tickets. */}
            Click into the event thumbnails to learn more or get tickets!
          </div>
          {filter.length > 0 && (
            <div className="results">
              {filteredEvents.length} results found for <span>"{filter}"</span>
            </div>
          )}
          <Grid
            container
            columnSpacing={{
              sm: "15px",
              xs: "10px",
            }}
            rowSpacing={{
              sm: "20px",
              xs: "15px",
            }}
          >
            {filteredEvents &&
              filteredEvents.map((event) => (
                <Grid item>
                  <EventCard details={event} />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

import axios from 'axios';
import { Constants } from '../utils';

const errorStatus = [403, 404, 500, 503];
const config = {
    timeout: 30000,
    headers:{
      pragma: 'no-cache',
      'Cache-Control': 'no-store, no-cache'
    }
};

const API = axios.create({
    ...config,
    baseURL: Constants.BASE_URL
});

const AllServices = {
    GetService: (url, params) => {
        let urlParams = '';
        if(params && Object.keys(params) && Object.keys(params).length > 0){
            urlParams = new URLSearchParams(params).toString();
            urlParams = '?' + urlParams;
        }

        return new Promise((resolve, reject) => {
            fetch(Constants.BASE_URL + url + urlParams)
            .then((response) => {
                if (errorStatus.includes(response.status)){
                    console.log("handle error here: ", response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                resolve(responseJson);
            })
            .catch((error) => {
                console.log('err: ', error);
                reject(error)
            });
        });
    },
    PostService: (api, postData = {}, defaultMethod = 'POST') => {
        // Local variable declaration
        let BaseUrl = Constants.BASE_URL + api;
        let auth = null;
        let hds = {};
        if (postData.auth) {
            auth = postData.auth
            delete postData.auth
            hds = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            }
        } else {
            hds = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    
        return new Promise((resolve, reject) => {
            fetch(BaseUrl, {
                method: defaultMethod,
                headers: hds,
                credentials: 'same-origin',
                body: JSON.stringify(postData)
            })
                .then((response) => {
                    if (errorStatus.includes(response.status)){
                        console.log("handle error here: ", response.status);
                    }
                    return response.json()
                })
                .then((responseJson) => {
                    resolve(responseJson);
                })
                .catch((error) => {
                    console.log("request error message", error);
                    reject(error);
                });
        });
    },
    UploadService: (api, postData, docType = '', fileName = '') => {
        // Local variable declaration
        let BaseUrl =Constants.BASE_URL + api;
        var formdata = new FormData();
        if (fileName) {
            formdata.append("file", postData, fileName);
        } else {
            formdata.append("file", postData);
        }
        formdata.append("documentType", docType);
        return new Promise((resolve, reject) => {
            fetch(BaseUrl, {
                method: 'POST',
                mode: 'no-cors',
                credentials: 'same-origin',
                body: formdata
            })
                .then((response) => {
                    if (errorStatus.includes(response.status)) {
                        console.log("handle error here: ", response.status);
                    }
                    return response.json()
                })
                .then((responseJson) => {
                    resolve(responseJson);
                })
                .catch((error) => {
                    console.log("request error message", error);
                    reject(error);
                });
        });
    }
}


export {
    AllServices,
    API
};
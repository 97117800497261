import React, {useEffect} from 'react';
import { Outlet} from "react-router-dom";
import {Header} from './header';
import {Footer} from './footer';
import {useLocation} from "react-router-dom";
import {SrollToTopBtn} from '../../components';
import {MetaData} from '../../components';
import { Leaderboard } from '../../components/Leaderboard';

export const AppLayout = (props) => {    
    const curLoc = useLocation();
    /** implementing global scroll */
    useEffect(() => {
        const onScroll = e => {
            let header = document.querySelector(".stix-sticky-header");
            let scrollPosition = 0;
            if (window.pageYOffset > scrollPosition) { 
                header.classList.add("sticky-header");
            }else{
                header.classList.remove("sticky-header")
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (<>
        <Leaderboard 
            isLandingPage={window.location.pathname === '/' ? true : false}
        />
        <MetaData pageAlias={curLoc.pathname}/>
        <Header 
            {...props} 
            isLandingPage={curLoc && curLoc.pathname && curLoc.pathname === '/' ? true : false}
            />
            <Outlet/>
            <SrollToTopBtn />
         <Footer {...props}/>   
    </>);
}
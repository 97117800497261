import React from 'react';
import './style.scss';
import Image from '../../../../components/Image';
import { Utilities } from '../../../../utils';
import { AdvWrapper } from '../../../../components/AdvWrapper';

const PollsNServeys = ({ sectionSeven }) => {
  return sectionSeven && (sectionSeven.image || sectionSeven.heading) ? (
    <section className="polls-survey">
      <div className="container-fluid custom-container">
        <div className="polls-wrapper">
          {sectionSeven.heading && <h2>{sectionSeven.heading}</h2>}
          <div className="polls-box">
            <div className="polls-img">
              <Image src={sectionSeven.image} type="BigBanner" />
            </div>
            <div className="polls-cont">
              {sectionSeven.title && <h2>{sectionSeven.title}</h2>}
              <p
                className="polls-count-para"
                dangerouslySetInnerHTML={{
                  __html: sectionSeven.description
                }}
              />
              {sectionSeven.button_text && (
                <AdvWrapper navigationLink={sectionSeven.button_url}>
                  {sectionSeven.button_text}
                </AdvWrapper>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};
export default PollsNServeys;

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
// Strict mode re-render twice
// root.render(
//   <React.StrictMode>
//     <App env={'sistic'}/>
//   </React.StrictMode>
// );

root.render(<App env={'sistic'}/>);

reportWebVitals();

import React from "react";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);
  const [isTablet, setTablet] = React.useState(false);


  let resizeWindow = () => {
    setMobile(window.screen.width <= 600 ? true : false);
    setTablet(window.screen.width >= 601 &&  window.screen.width <= 912 ? true : false);
  };

  React.useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return { isMobile, isTablet };
}
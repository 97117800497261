import { API, AllServices } from '../../helper';

class AdvertisementService {
  getLeaderboardImage(params) {
    return API.get(`api/leader-board`, { params });
  }

  fetchLeaderboardImage(params) {
    return AllServices.GetService(`api/leader-board`, params);
  }

  getSidePanelBetweenTopPicksFeaturedEvents(params) {
    return API.get(`api/side-panel`, { params });
  }

  getFeaturedEvents(params) {
    return API.get(`api/featured-event`, { params });
  }

  getMostViewedService(params) {
    return API.get(`api/search-featured`, { params });
  }
  getSearchListService(params) {
    return API.get(`api/search-list`, { params });
  }

  getEventListService(params) {
    return API.get(`/api/event-listing`, { params });
  }

  getGiftCardService(params) {
    return API.get(`api/side-panel`, { params });
  }

  getCustomizeSectionOne(params) {
    return API.get(`api/customize-section-one`, { params });
  }

  getCustomizeSectionTwo(params) {
    return API.get(`api/customize-section-two`, { params });
  }

  getCustomizeSectionThree(params) {
    return API.get(`api/customize-section-three`, { params });
  }
  getFindAnEventAds(params) {
    return API.get(`api/find-an-event`, { params });
  }
}

export default new AdvertisementService();

import React, { useState } from "react";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import { SectionTitle } from "../";
import { useNavigate } from "react-router-dom";
import { AllFunctions } from "../../helper";
import { Utilities } from "../../utils";

export const NonSlider = (props) => {
  const navigate = useNavigate();
  const onSeeMore = () => {
    if (props.seeMore) window.location.href = props.seeMore;
  };

  const handleOnClick = (e) => {
    const url = e.currentTarget.id;
    if (url) {
      props.toggleDrawer();
      if (url.includes("http")) {
        window.location.href = url;
      } else {
        navigate(url);
      }
    }
  };

  const getUrl = (item) => {
    let newUrl = "";
    if (item.navigation_link) {
      newUrl = item.navigation_link;
    } else if (item.alias) {
      if (props.title === "Explore") {
        const splitAry = item.alias.split("explore/");
        if (splitAry.length > 1) {
          newUrl = "/explore/" + splitAry[1];
        }
      } else {
        newUrl = "/events/" + item.alias;
      }
    }
    return newUrl;
  };

  const utags = (cat) => {
    return (
      <span
        className={"utags"}
        style={{ background: props.color ? props.color : "#9570C3" }}
      >
        {cat && cat[0] ? cat[0] : ""}
      </span>
    );
  };

  return (
    <div className={"stix-event-container"}>
      {!props.noSeeMore && (
        <SectionTitle
          title={props.title && props.title}
          seeMore={<a onClick={onSeeMore}>See more</a>}
        />
      )}
      <Grid container>
        {props.items &&
          props.items.length > 0 &&
          props.items.map((item, i) => {
            let newDate = item.author_posted_date
              ? new Date(item.author_posted_date)
              : false;
            const options = { year: "numeric", month: "long", day: "numeric" };

            let formattedDate;
            if (newDate) {
              formattedDate = newDate.toLocaleDateString("en-SG", options);
            }
            const titleName = Utilities.removeBrfromString(item.title)
            if (titleName) {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={i}
                  onClick={handleOnClick}
                  id={getUrl(item)}
                >
                  <List className={"event-left-thumb"}>
                    <ListItem
                      key={i}
                      className={props.customClass ? props.customClass : ""}
                    >
                      <img
                        className={props.customClass ? props.customClass : ""}
                        alt={titleName}
                        src={
                          item.thumb_image
                            ? item.thumb_image
                            : "/imgs/default-thumb-mobile.png"
                        }
                      />
                      <ListItemText
                        className={props.customClass ? props.customClass : ""}
                        primary={props.noCat ? "" : utags(item.categories)}
                        secondary={
                          <span className={"elabels"}>
                            <span className={"h4"}>
                              {AllFunctions.addEllipsis(titleName, 88)}
                            </span>
                            {props.noDate ? "" : <span>{formattedDate}</span>}
                          </span>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              );
            }
          })}
      </Grid>
    </div>
  );
};

import React, { useContext, useEffect, useReducer, useState } from "react";
import "./styles/sistic.scss";
import { BrowserRouter } from "react-router-dom";
//import { HelmetProvider } from "react-helmet-async";
import { AppRouters, AllSettings, AllFunctions } from "./helper";
import { baseReducer, baseType } from "./reducers";
import BaseContext from "./contexts";
import { Constants } from "./utils";
import { StixCustomOverlayLoader} from "./components";
import HomeService from "./services/HomeService";
import AdvertisementService from "./services/AdvertisementService";
import Cookies from "./components/Cookies";

function App(props) {
  const base = useContext(BaseContext);
  const [listEvnt, setListEvnt] = useState({});
  const [predInterval, setPredInterval] = useState(1000);
  const [failTimes, setFailTimes] = useState(0);
  const [loader, setLoader] = useState(true);
  const [initialState, setInitialState] = useState({
    loading: true,
    data: base.data,
  });

  const [state, dispatch] = useReducer(baseReducer, initialState);

  const emptyCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        console.log(`Delete all the cache files`);
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  };

  const updateBase = (base, type = baseType.APPBASE) => {
    dispatch({
      type: type,
      payload: base,
    });
  };

  const globalBaseUpdate = (data) => {
    updateBase({
      data: {
        ...state.data,
        ...data,
      },
    });
  };

  useEffect(() => {
    emptyCache();
    callTheConFig();
  }, []);

  useEffect(() => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    } else {
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      };
    }
  }, []);

  const callTheConFig = async () => {
    const params = {
      client: Constants.CLIENT,
    };

    /** temporary only  */
    const getAllDataFromHomepage = await HomeService.getHomepageData(params);
    const midAds =
      await AdvertisementService.getSidePanelBetweenTopPicksFeaturedEvents({
        ...params,
        page: 1,
    });
    const customPanels = await HomeService.getCustomPanel();
   // const trendingNow = await HomeService.getTrendingNow(getTrendingReqData());


    let setAllEvents = {
      ...state.data,
      rotationBanner: getAllDataFromHomepage.data.rotational_bannners, //banners && banners.data,
      news: getAllDataFromHomepage.data.news,
      featuredEvents: getAllDataFromHomepage.data["featured-event"], //featured && featured.data,
      explore: getAllDataFromHomepage.data.article_listing, //explore && explore.data,
      whatsNew: getAllDataFromHomepage.data["new-release"] ? getAllDataFromHomepage.data["new-release"] : [], //whatsNew && whatsNew.data,
      attraction: getAllDataFromHomepage.data.attractions, // && attraction.data,
      thisWeek: getAllDataFromHomepage.data["this-week"], //thisWeek && thisWeek.data,
      midAdvertisement: midAds && midAds.data,
      videoEvents: getAllDataFromHomepage.data["video-panel"], //getVideoEvents && getVideoEvents.data,
      flashSale: getAllDataFromHomepage.data["flash-sale"]
        ? getAllDataFromHomepage.data["flash-sale"]
        : [], //flashSale && flashSale.data
      hotShow: getAllDataFromHomepage.data["hot-show"]
        ? getAllDataFromHomepage.data["hot-show"]
        : [], // hotShow && hotShow.data
      homepageTakeover: getAllDataFromHomepage.data["homepage_takeover"]
        ? getAllDataFromHomepage.data["homepage_takeover"]
        : [], // // homepageTakeover && homepageTakeover.data
      customPanels: customPanels && customPanels.data,
     // trendingNow: trendingNow && trendingNow.data ? trendingNow.data : []
    };

    globalBaseUpdate(setAllEvents);
    setListEvnt(setAllEvents);

    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const getTrendingReqData = () => {
    let cookieid = AllFunctions.getCookie(Constants.GTMKey);
    let deviceid = AllFunctions.getCookie(Constants.deviceIdKey);
    const reqData = {
      cookieID: cookieid,
      device_id: deviceid,
      browsed: [],
      req_ts: AllFunctions.convertTZ(new Date(), "Asia/Singapore"),
      n_rec: 10,
    };
    
    return reqData;
  };

  /** set interval to check if predict API already been set */
  const callPredictApi = async (basedata) => {
    let cookieid = AllFunctions.getCookie(Constants.GTMKey);
    let deviceid = AllFunctions.getCookie(Constants.deviceIdKey);
    cookieid = cookieid && JSON.parse(cookieid);
    deviceid = deviceid && JSON.parse(deviceid);
    let recomendationParams = {
        userid:base.data && base.data.user && base.data.user.login && base.data.user.login.patron && base.data.user.login.patron.accountNo,
        useremail:base.data && base.data.user && base.data.user.login && base.data.user.login.patron && base.data.user.login.patron.email,
        cookieID: cookieid && cookieid.g ? cookieid.g : "d279b2e5-a07e-b5c0-96be-a7c978d66a9c",
        device_id: deviceid && deviceid.g ? deviceid.g : 1,  
        browsed:
          localStorage.getItem("recommenderevents") &&
          localStorage.getItem("recommenderevents") != undefined
            ? JSON.parse(localStorage.getItem("recommenderevents"))
            : [],
        req_ts: AllFunctions.convertTZ(new Date(), "Asia/Singapore"),
        n_rec: 10,
    };
    setPredInterval(5000);
    setFailTimes(failTimes + 1);
    let predictApiResponseData = {};
    try {
        await HomeService.getNewRecommender(recomendationParams).then(
          async (res) => {
            if (res && res.data && res.data) {
              predictApiResponseData = res.data;
              let iccdata = "";
              if (!!res.data?.top_6) {
                iccdata = res.data?.top_6?.join() || "";
              }
              const detparams = {
                client: Constants.CLIENT,
                iccs: iccdata,
              };
              await HomeService.getrecommenderdetail(detparams)
                .then((resdet) => {
                  if (resdet && resdet.data) {
                      globalBaseUpdate({
                        ...basedata, //take the latest context api data from attr basedata
                        recommendations: resdet.data,
                        predictApiResponseData: predictApiResponseData
                      });
                  }
                })
                .catch(() => {
                  /*catch error*/
                });
            }
          }
        );
      } catch (e) {
        console.log(failTimes, "ERROR: While getting cookie: ", e.toString());
      }
    }

  useEffect(() => {
      const interval = setInterval(() => {
          if(state.data.recommendations && state.data.recommendations.length === 0 && listEvnt && Object.keys(listEvnt).length > 0){
              if(failTimes < 5){
                callPredictApi(state.data); // set recommended events with the latest context data
              }else{
                clearInterval(interval);
              } 
          }else{
            clearInterval(interval);
          }
      }, predInterval);
      return () => clearInterval(interval);
  }, [predInterval, failTimes, state]);

    /** end predict API checker */

  useEffect(() => {
    const handleBackButtonClick = () => {
      const getHomeScrollPosition = localStorage.getItem("homeLastScroll");
      if(getHomeScrollPosition){
        setTimeout(() => {
          window.scroll({ top: getHomeScrollPosition, behavior: "smooth" });
        }, 1000);
      }
    }
    window.addEventListener('popstate', handleBackButtonClick);
    return () => {
      window.removeEventListener('popstate',()=> handleBackButtonClick);
    };
  }, []);


  return (
    <BrowserRouter basename="/">
      <BaseContext.Provider
        value={{
          ...state,
          updateBase
        }}
      >
        {/* <HelmetProvider> */}
          <AppRouters
            updateBase={globalBaseUpdate}
            env={props.env}
            appLoading={loader}
            setLoader={setLoader}
          />
          {loader && <StixCustomOverlayLoader />}
        {/* </HelmetProvider> */}
      </BaseContext.Provider>
      {<Cookies />}
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect, Fragment } from "react";
import "./style.scss";
import axios from "axios";
import { Grid, Link } from "@mui/material";

const SocialWall = ({ socialUrl, name }) => {
  let brakePoints = [375, 500, 750];

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [user, setUser] = useState("");
  const [socialwallurl, setSocialwallurl] = useState("");

  const SISTIC_INSTAGRAM_ACCESS_TOKEN =
    "IGQWROLS1raTRxeS1uNkpmd0hpV2tjdGpXMC1Ga0VFUFlreHV4aXBwR3hNODIyTHpKWDdteGNFT25KSnBQTlFzOWprMU9ncG5SM0pVRXdtR3FfajR2ZAlpWOUktdnBuNXFXT1RKYjdZAMlR3aG5nVFU0YXdLWW5aQ0EZD";

  const mediaFields = "id,username,caption,media_url,thumbnail_url,permalink";

  const apiCall = `https://graph.instagram.com/me/media?fields=${mediaFields}&access_token=${SISTIC_INSTAGRAM_ACCESS_TOKEN}`;

  useEffect(() => {
    if (socialUrl) {
      setSocialwallurl(socialUrl);
      // axios
      //   .get(`${socialUrl}/?__a=1`)
      //   .then(result => {
      //     setFeeds(result.data.graphql.user.edge_owner_to_timeline_media.edges);
      //     setUser(result.data.graphql.user.username);
      //     setIsLoaded(true);
      //   })
      //   .catch(error => {
      //     setIsLoaded(false);
      //     setError(error);
      //   });
      axios
        .get(apiCall)
        .then((result) => {
          setFeeds(result.data.data);
          setIsLoaded(true);
        })
        .catch((error) => {
          setIsLoaded(false);
          setError(error);
        });
    }
  }, [socialUrl]);

  // if (error) {
  //   return null;
  // } else if (!isLoaded) {
  //   return <div className="instafeeds-loading">Loading...</div>;
  // } else {
  return (
    <Fragment>
      {socialUrl && (
        <section className="sistic-moments">
          <div className="container-fluid custom-container">
            <div className="social-wall">
              {/* <h2>Instagram gallery @{user}</h2> */}
              <h2>Social Wall</h2>
              <div className="sistic-moments-wrapper">
                {/* <Masonry brakePoints={brakePoints}>
                    {feeds &&
                      feeds.map(({ node }, id) => {
                        return (
                          <Tiles
                            src={node.thumbnail_resources[4].src}
                            key={id}
                          />
                        );
                      })}
                  </Masonry> */}
                {/* <div dangerouslySetInnerHTML={{ __html: socialUrl }}></div> */}
                <Grid container spacing={0.5}>
                  {feeds &&
                    feeds.slice(0, 6).map((post) => (
                      <Grid item className={"image-container"}>
                        <Link href={post.permalink && post.permalink}>
                          {post.thumbnail_url ? (
                            <img src={post.thumbnail_url} />
                          ) : (
                            post.media_url && <img src={post.media_url} />
                          )}
                        </Link>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default SocialWall;

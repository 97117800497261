import React from 'react';
import './style.scss';
import CustomSectionThree from '../../../Home/CustomSectionThree';

const VideoGallery = ({ videoHeading, sectionEight }) => {
  return (
    <CustomSectionThree heading={videoHeading} customData={sectionEight} />
  );
};

export default VideoGallery;

import React, { useEffect, useState, memo } from 'react';
import './style.scss';
import Image from '../Image';
import { Utilities } from '../../utils';
import ExploreService from '../../services/ExploreService';
import { Link } from 'react-router-dom';
import EventHeading from '../EventHeading';
import { OneBigEightSmall } from '../ShimmerEffect/HomeShimmer';
import Ellipsis from '../Ellipsis';

const ExploreContainer = props => {
  const [exploreData, setExploreData] = useState(
    props.article ? null : null);
  useEffect(() => {
    getExploreData();
  }, [props.excludeId]);
  const getExploreData = () => {
    const params = {
      first: 0,
      limit: 5,
      client: 1
    };
    if (props.excludeId) {
      params.exclude_id = props.excludeId;
    }
    ExploreService.getExploreArticleList(params)
      .then(res => {
        setExploreData(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return exploreData && exploreData.length ? (
    <section className="explore">
      <div className="container-fluid custom-container">
        <div className="section-top-wrapper">
          <h2>{props.heading}</h2>
          {!props.article && (
            <div className="carousel-dots">
              <Link to="/explore">
                See all{' '}
                <img
                  src="../../../assets/images/right-arrow.svg"
                  className="img-fluid"
                  alt="arrow"
                />
              </Link>
            </div>
          )}
        </div>
        {!props.article && (
          <span
            className="explore-text"
            dangerouslySetInnerHTML={{ __html: props.description }}
          ></span>
        )}
        <div className="grid-container">
          <Link
            to={Utilities.linkAccToType(exploreData[0].type, exploreData[0].id)}
            className="item"
          >
            <div className="item-wrapper explore-article">
              <div className="explore-image item-img">
                <Image
                  src={exploreData[0].thumb_image}
                  className="img-fluid"
                  alt="explore"
                />
              </div>
              <div className="explore-text-wrapper">
                {exploreData[0].categories.slice(0, 1).map(category => {
                  return (
                    <span
                      className="explore-category"
                      key={category}
                    >
                      {category}
                    </span>
                  );
                })}
                <EventHeading
                  title={exploreData[0].title}
                  lines={
                    Utilities.mobilecheck()
                      ? 3
                      : Utilities.mobileAndTabletcheck()
                      ? 2
                      : 1
                  }
                  height={
                    Utilities.mobilecheck()
                      ? 18
                      : Utilities.mobileAndTabletcheck()
                      ? 18
                      : 20
                  }
                />
                <Ellipsis
                  title={exploreData[0].description}
                  lines={1}
                  height={17}
                />
              </div>
            </div>
          </Link>
          {exploreData.slice(1).map(cardData => {
            return (
              <Link
                to={Utilities.linkAccToType(cardData.type, cardData.id)}
                key={cardData.id}
                className="item explore-rightside-content"
              >
                <div className="item-wrapper">
                  <div className="explore-image item-img">
                    <Image src={cardData.thumb_image} className="img-fluid" />
                  </div>
                  <div className="explore-text-wrapper">
                    {cardData.categories &&
                      cardData.categories.slice(0, 1).map(category => {
                        return (
                          <span key={category} className="explore-category">
                            {category}
                          </span>
                        );
                      })}
                    <EventHeading
                      title={cardData.title}
                      lines={3}
                      height={
                        Utilities.mobilecheck()
                          ? 18
                          : Utilities.mobileAndTabletcheck()
                          ? 18
                          : 20
                      }
                    />
                    <Ellipsis
                      title={cardData.description}
                      lines={2}
                      height={17}
                    />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  ) : exploreData === null ? (
    <OneBigEightSmall></OneBigEightSmall>
  ) : null;
};

export default memo(ExploreContainer);

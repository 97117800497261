import React from 'react';
import BaseContext from '../../../../contexts';
import {SisticFooter} from './sisticFooter';


const FooterContent = (props) => {
    let conf = props.data;

    return (
        <>
            {conf.data.config && (
                <SisticFooter 
                    footer={conf.data.config.footer}
                />
            )}
        </>
    )
}

export const Footer = (props) => {  
    return (
        <BaseContext.Consumer>
            {data => (
                <FooterContent {...props} data={data}/>
            )}
        </BaseContext.Consumer>
    );
}



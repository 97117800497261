import React from "react";
import { AttractionResult } from "../components";
import useDeviceDetect from "../hooks/useDeviceDetect";

export const AttractionResultPage = (props) => {
  return (
    <>
      <AttractionResult title={"Attractions Results"} {...props} />
    </>
  );
};

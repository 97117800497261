import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// Hook
const useCustomMediaQuery = () => {
  const theme = useTheme();

  return {
    extraSmall: useMediaQuery(theme.breakpoints.up('xs')),
    extraSmallDown: useMediaQuery(theme.breakpoints.down('xs')),
    small: useMediaQuery(theme.breakpoints.up('sm')),
    smallDown: useMediaQuery(theme.breakpoints.down('sm')),
    mediumDown: useMediaQuery(theme.breakpoints.down('md')),
    medium: useMediaQuery(theme.breakpoints.up('md')),
    large: useMediaQuery(theme.breakpoints.up('lg')),
    largeDown: useMediaQuery(theme.breakpoints.down('lg')),
    extraLarge: useMediaQuery(theme.breakpoints.up('xl')),
    extraLargeDown: useMediaQuery(theme.breakpoints.down('xl')),
  };
};

export default useCustomMediaQuery;

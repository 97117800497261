import axios from 'axios';
import { API, APIConfig, AllServices } from "../../helper";

class HomeService {
  setBaseURL(url, device_id) {
    API.defaults.baseURL = url;
    if (device_id) {
      API.defaults.headers.common["device_id"] = device_id;
    }
  }

  getHomepageData(params) {
    return API.get(`api/homepage-main`, { params });
  }

  getMetadata(params) {
    return API.get(`/get-metatags`, { params });
  }

  fetchMetadata(params) {
    return AllServices.GetService(`/get-metatags`, params);
  }

  getProductImage(params) {
    return API.get(`api/get-ticket-wallet`, { params });
  }
  getData() {
    return API.get(
      `api/search/repositories?q=stars:>1+language:javascript&sort=stars&order=desc&type=Repositories`
    );
  }

  getFlashSale(params) {
    return API.get(`api/flash-sales-banner`, { params });
  }

  getNewsTicker(params) {
    return API.get(`api/news`, { params });
  }

  getGenre(params) {
    return API.get(`genres`, { params });
  }

  getVenues(search) {
    return API.get(`api/venues?search=${search}`);
  }

  getHomepageVenues(first, limit, search) {
    return API.get(
      `api/menu-venues?first=${first}&limit=${limit}&search=${search}`
    );
  }

  getHotShowPopupData() {
    return API.get(`api/hot-show-page`);
  }

  getNewRelease(params) {
    return API.get(`api/homepage/new-release`, { params });
  }

  getCurrentlyShowing(params) {
    return API.get(`api/homepage/this-week`, { params });
  }

  getVideoEvents(params) {
    return API.get(`api/video-panel`, { params });
  }

  getPromotions(params) {
    return API.get(`api/homepage/promotions`, { params });
  }

  getTopPics(params) {
    return API.get(`api/homepage/top-picks`, { params });
  }

  getTrendingNow(data) {
    return API.post(`api/trending`, data);
  }

  getItemsOrder(params) {
    return API.get(`api/general-home-settings`, { params });
  }
  gethomepagebanner() {
    return API.get(`api/home-page-takeover?client=1`, {});
  }
  getNewRecommender(reqdata) {
    let data = reqdata;
    let headers = {
      "Content-type": "application/json",
    };
    return axios.post(APIConfig.PREDICT_URL, data, {headers: headers, timeout: 30000})
  }
  getrecommenderdetail(params) {
    return API.get(`api/homepage/recommender`, { params });
  }
  getattraction(params) {
    return API.get(`api/home/attractions`, { params });
  }
  getRotationalBanner() {
    return API.get(`api/rotational-banner?client=1`, {});
  }
  getCustomPanel(){
    return API.get(`api/custom-panels?client=1`, {});
  }
}

export default new HomeService();

import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import CardList from './CardList';
import DownArrowBlue from '../../../assets/images/down-arrow-blue.svg';
import Breadcrumb from '../../../scenes/App/Breadcrumb';
import filterIcon from '../../../assets/images/down_arrow.svg';
import ShimmerEffect from '../../../components/ShimmerEffect';
import { Utilities } from '../../../utils';
import ExploreService from '../../../services/ExploreService';
import Filter from './Filter';
import loaderImage from '../../../assets/images/sistic-loader.gif';
import useStickyPanel from '../../../hooks/useStickyPanel';
import BreadCrumbData from './breadCrumbData';
import selectOrClearAll from './selectOrClearAll';
import fetchFilterData from './fetchFilterData';
import handleFilter from './handleFilters';
import { Constants } from '../../../utils';
import { useCustomWidth } from '../../../components/CustomHooks';
import query from "../../../helper/queryString"
import MetaDataOld from '../../../components/MetaDataOld';

const ArticleList = ({ location, staticContext }) => {
  useCustomWidth();
  let stickyObj = {
    sticky: { top: 153 },
    pixelBuffer: 153,
    distanceFromTop: 153
  };
  const node = useRef(null);
  const [history, setHistory] = useState([]);
  const [cardInViewConstant, setCardInViewConstant] = useState(3);
  const [scrollContainerRef, styleObj] = useStickyPanel(stickyObj);
  const [articleList, setArticleList] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [first, setFirst] = useState(0);
  const [filteredTags, setFilteredTags] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState(
    query(location).c ? [query(location).c] : []
  );
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showTags, setShowTags] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [filteredTagsForMobile, setFilteredTagsForMobile] = useState([]);
  const [loadWithFilters, setLoadWithFilters] = useState(false);
  const [
    filteredCategoriesForMobile,
    setFilteredCategoriesForMobile
  ] = useState([]);
  let mobileCheck = showTags || showCategories;

  useEffect(() => {
    fetchFilterData(setCategories, ExploreService.getCategories);
    fetchFilterData(setTags, ExploreService.getTags);
    setCardInViewConstant(window.innerWidth > 850 ? 3 : 2);
  }, []);

  useEffect(() => {
    if (articleList.length && query(location).c) {
      handleFilter(
        false,
        categories,
        setCategories,
        null,
        setFilteredCategoriesForMobile,
        setFilteredCategories,
        mobileCheck,
        query(location).c
      );
    }
  }, [query(location).c, articleList.length]);

  useEffect(() => {
    getArticleList();
  }, [first, filteredTags.toString(), filteredCategories.toString()]);

  const getArticleList = () => {
    const params = {
      first: first,
      client: 1,
      limit: Constants.LIMIT,
      category: filteredCategories.toString(),
      tags: filteredTags.toString()
    };
    if (query(location).id) {
      params.id = query(location).id;
    }
    if (!loadMore) {
      setLoadWithFilters(true);
      params.first = 0;
      setFirst(0);
      setTotalResults(0);
    }
    setTimeout(() => {
      ExploreService.getExploreArticleList(params)
        .then(res => {
          if (loadMore) {
            setArticleList([...articleList, ...res.data.data]);
          } else {
            setArticleList(res.data.data);
          }
          setLoadWithFilters(false);
          setTotalResults(res.data.total_records);
          setLoadMore(false);
        })
        .catch(err => {
          setLoadMore(false);
          console.log(err);
        });
    }, 1000);
  };

  const selectOrClearAllHandler = (isChecked, filterTitle) => {
    setHistory([...history, '/explore/articles'])
    if (filterTitle === 'Tags') {
      selectOrClearAll(isChecked, tags, setTags, setFilteredTags);
    }
    if (filterTitle === 'Categories') {
      selectOrClearAll(
        isChecked,
        categories,
        setCategories,
        setFilteredCategories
      );
    }
  };

  const handleFilters = (selected, isChecked, filterTitle) => {
    setHistory([...history, '/explore/articles'])
    if (filterTitle === 'Tags') {
      handleFilter(
        isChecked,
        tags,
        setTags,
        selected,
        setFilteredTagsForMobile,
        setFilteredTags,
        mobileCheck
      );
    }
    if (filterTitle === 'Categories') {
      handleFilter(
        isChecked,
        categories,
        setCategories,
        selected,
        setFilteredCategoriesForMobile,
        setFilteredCategories,
        mobileCheck
      );
    }
  };

  const closeFilters = (filterTitle, action) => {
    if (action === 'back') {
      if (filterTitle === 'Tags') {
        let restoredTags = tags.map(el => {
          return {
            ...el,
            isChecked: filteredTags.indexOf(el.id) === -1 ? false : true
          };
        });
        setTags(restoredTags);
        setFilteredTagsForMobile(filteredTags);
      }
      if (filterTitle === 'Categories') {
        let restoredCategories = categories.map(el => {
          return {
            ...el,
            isChecked: filteredCategories.indexOf(el.id) === -1 ? false : true
          };
        });
        setCategories(restoredCategories);
        setFilteredCategoriesForMobile(filteredCategories);
      }
    }
    setShowTags(false);
    setShowCategories(false);
  };

  const handleFiltersForMobile = filterTitle => {
    if (filterTitle === 'Tags') {
      setFilteredTags(filteredTagsForMobile);
    }
    if (filterTitle === 'Categories') {
      setFilteredCategories(filteredCategoriesForMobile);
    }
  };

  const filterComponent = (data, title, showComponent) => {
    return showComponent || !Utilities.mobilecheck() ? (
      data && data.length ? (
        <Filter
          dataToFilter={data}
          handleFilters={handleFilters}
          filterTitle={title}
          selectOrClearAllHandler={selectOrClearAllHandler}
          showHeader={mobileCheck}

          closeFilters={closeFilters}
          handleFiltersForMobile={handleFiltersForMobile}
        />
      ) : null
    ) : null;
  };

  return (
    <div className="events-page-wrapper articlelist-wrapper">
      <MetaDataOld location={location} data={staticContext} />
      <Breadcrumb breadCrumbData={BreadCrumbData} />
      <section >
        <div className="container-fluid custom-container">
          <div className="wrapper-events-listing">
            {/* // Filters */}
            <div className={`filters ${mobileCheck ? `open` : ``}`}>
              <div className="filter-conatiner">
                <div
                  style={{
                    position: 'relative',
                    display: 'block',
                    height: '100%',
                    zIndex: 2
                  }}
                  ref={scrollContainerRef}
                >
                  <div className="inner" style={styleObj}>
                    {!Utilities.mobilecheck() && (
                      <div className="filter-heading">
                        <h3>FILTERS</h3>
                        <span
                          onClick={() => {
                            if (tags && tags.length) {
                              selectOrClearAllHandler(false, 'Tags');
                            }
                            if (categories && categories.length) {
                              selectOrClearAllHandler(false, 'Categories');
                            }
                          }}
                        >
                          Clear all
                        </span>
                      </div>
                    )}
                    {filterComponent(categories, 'Categories', showCategories)}
                    {filterComponent(tags, 'Tags', showTags)}
                  </div>
                </div>
              </div>
            </div>
            {/* // Artical List */}
            <div
              className={`events-listing ${
                isNaN(totalResults) ? `article-list-notfound` : ``
              }`}
            >
              <div className="events-section">
                <CardList
                  articleList={articleList}
                  totalRecords={totalResults}
                  history={history}
                  ref={node}
                  selectedCategories={categories}
                />
                {loadWithFilters && articleList.length ? (
                  <div className="filter-loader">
                    <img src={loaderImage} alt="loading-image" />
                  </div>
                ) : null}
                {loadMore && (
                  <ShimmerEffect
                    propCls={`${
                      Utilities.mobileAndTabletcheck() ? 'shm_col-xs-6' : ''
                    } col-md-4`}
                    height={150}
                    count={Utilities.mobileAndTabletcheck() ? 2 : 3}
                    type="LIST"
                  />
                )}
              </div>
              {totalResults - articleList.length > 0 && !loadMore && (
                <div className="promotion-load-more">
                  <button
                    onClick={() => {
                      setFirst(first + Constants.LIMIT);
                      setLoadMore(true);
                      if (!Utilities.mobilecheck()) {
                        window.scrollTo(
                          0,
                          node.current.clientHeight *
                            (
                              articleList.length / cardInViewConstant
                            ).toFixed() -
                            node.current.clientHeight / 2
                        );
                      }
                    }}
                    className="btn-link load-more-btn"
                    target=""
                  >
                    <span>Load More ({totalResults - articleList.length})</span>
                    <img src={DownArrowBlue} alt="down arrow blue" />
                  </button>
                </div>
              )}
            </div>
            <div className="fixed-buttons-events">
              {tags && tags.length ? (
                <a
                  className="sortby"
                  onClick={() => {
                    setShowTags(true);
                  }}
                >
                  Tags
                  <img src={filterIcon} alt="icon" />
                </a>
              ) : null}
              {categories && categories.length ? (
                <a
                  className="filter"
                  onClick={() => {
                    setShowCategories(true);
                  }}
                >
                  Categories
                  <img src={filterIcon} alt="icon" />
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArticleList;

import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EventsService from "../../../services/EventsService";

const PasswordPrompt = ({ hidePasswordPrompt, eventIcc }) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    setPassword(e.target.value);
    setPasswordIncorrect(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === "") {
      setPasswordIncorrect(true);
      return;
    }
    setLoading(true);

    const form = {
      password: password,
      icc: eventIcc,
    };
    const res = await EventsService.checkPrivateEventPassword(form);
    setLoading(false);

    if (res.data.data[0] === "Valid") {
      hidePasswordPrompt();

      const timestamp = Date.now();
      const validationData = { timestamp };
      localStorage.setItem(
        eventIcc + "PasswordValid",
        JSON.stringify(validationData)
      );
    } else {
      setPasswordIncorrect(true);
    }
  };

  return (
    <div className="password-prompt-dialog-container">
      <div className="password-prompt-dialog">
        <form className="form-container" onSubmit={handleSubmit}>
          <label htmlFor="password">
            Please enter the password provided to access this page:
          </label>
          <TextField
            type={showPassword ? "text" : "password"}
            id="password"
            value={password}
            placeholder="Password"
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={passwordIncorrect}
            helperText={passwordIncorrect && "Incorrect password!"}
          />
          <LoadingButton variant="contained" type="submit" loading={loading}>
            Submit
          </LoadingButton>
        </form>
      </div>
    </div>
  );
};

export default PasswordPrompt;

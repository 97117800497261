import React from "react";
import {
    Box,
    Stack,
    Grid,
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Collapse
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ClearCacheDataComp = ({title, image, data, isMobile = false }) => {

    const imageWidthHeight = isMobile ? 140 : 180;

    return (
        <Stack>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center", marginBottom: "25px" }}>
                <Stack direction={"column"}>
                    <img
                        // src={"https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-06/436x326.jpg"}
                        src={image}
                        width={imageWidthHeight}
                        height={imageWidthHeight}
                    />
                    <Typography
                        variant={"h6"}
                        fontFamily={"Rubik"}
                        fontWeight={600}
                        color={"#343434"}
                        style={{ marginTop: 15 }}
                        textAlign={"center"}
                    >
                        {title}
                    </Typography>
                </Stack>
            </Box>
            <Container>
                {data.map((item, index) => {
                    return (
                        <Accordion
                            square={true}
                            disableGutters={true}
                            sx={{ backgroundColor: "#E6F3FB", marginBottom: "10px" }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    variant={"body1"}
                                    fontFamily={"Rubik"}
                                    fontWeight={500}
                                    color={"#343434"}
                                >
                                    {item.title}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails
                                sx={{ backgroundColor: "white", paddingInline: "30px", paddingY: "15px" }}
                            >
                                <Typography>
                                    {item.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Container>
        </Stack>
    )
}

export default ClearCacheDataComp;
import React from 'react';

export const IsoCertificate = (props) => {

    const isoCertificate = props.isoCertificate ? props.isoCertificate : {}

    return (
        <div className={'iso-cert'}>
            <ul className="medias">
                {Object.keys(isoCertificate).map((sm) => {
                    return(
                        <img src={isoCertificate[sm].icon} className="" alt="" key={sm}/>
                    )
                })}
            </ul>
        </div>
    );
}
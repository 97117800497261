export default (setFilters, getFilters) => {
  window.scrollTo(0, 0);
  getFilters()
    .then(res => {
      if (!res.data.data || !res.data.data.length) {
        setFilters(null);
        return;
      }
      let data = res.data.data.map(filter => {
        let formatFilters = { ...filter, isChecked: false };
        const urlParams = new URLSearchParams(window.location.search);
        const idsParam = urlParams.get('c');
        const splitIds = idsParam && idsParam.split(',');
        const first_id = splitIds && splitIds[0];
        const second_id = splitIds && splitIds[1];
        if (first_id && second_id) {
          if (filter.id === second_id || filter.id === first_id) {
            formatFilters.isChecked = true;
          }
        }
        window.scrollTo(0, 0);
        return formatFilters;
      });
      setFilters(data);
    })
    .catch(err => {
      setFilters(null);
    });
};

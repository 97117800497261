import React, { useState, useEffect } from "react";
import BaseContext from "../../contexts";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Slider,
  TextField,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import Checkbox from "@mui/joy/Checkbox";
import { RiSearchLine } from "react-icons/ri";
import queryString from "query-string";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomInput } from "../attractionResult/components/CustomInput";
import {AllFunctions} from '../../helper/functions';
import DatePicker from "react-datepicker";

export const FilterMobile = (props) => {
  const base = React.useContext(BaseContext);
  const [expanded, setExpanded] = useState("panel1");
  const [bulkFilter, setBulkFilter] = useState(
    props.forFilter ? props.forFilter : {}
  );
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [pRangeVal, setPRange] = useState([0, 100]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [categories, setCategories] = useState({});
  const [applyBtn, setApplyBtn] = useState(false);
  const [search, setSearch] = useState({
    value: props.searchVal ? props.searchVal : "",
  });

  const setCategoriesFromProps = () => {
    let newcat = {};
    props.category &&
      props.category.map((cat) => {
        if (
          props.forFilter &&
          props.forFilter.selectedCategories &&
          props.forFilter.selectedCategories.length > 0
        ) {
          newcat[cat.id] = props.forFilter.selectedCategories.includes(cat.id)
            ? true
            : false;
        } else {
          newcat[cat.id] = false;
        }
      });

    setCategories(newcat);
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleOnSearchChange = (e) => {
    const val = e.currentTarget.value;
    let q = queryString.parse(window.location.search);
    q.s = val; // change url param val
    window.history.pushState(
      null,
      false,
      "/events?" + queryString.stringify(q)
    );
    setSearch({
      ...search,
      value: val,
    });
    props.updateBase && props.updateBase({...base.data, search: val});
    //if (props.monitorSearch) props.monitorSearch(val);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    (panel === "panel3") && setApplyBtn(true);
  };

  const closeFilter = () => {
    if (props.onDrawerClose) {
      props.onDrawerClose();
    }
  };

  const handleCatChange = (e) => {
    setCategories({
      ...categories,
      [e.target.id]: e.target.checked,
    });
    AllFunctions.eventsDeleteUrlParam('genre');
  };

  const handleSliderPriceRangeChange = (e) => {
    const val = e.target.value;
    setPRange(val);
  };

  const renderDayContents = (day, date) => {
    return <div className={"dpkr"}>{day}</div>;
  };

  const handleApplyFilter = () => {
    if (applyBtn) {
      closeFilter();
      if (props.callbackWithFilter) props.callbackWithFilter();
    }
  };

  const handleClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setCategoriesFromProps();
    let priceRange = [minValue, maxValue];
    if (bulkFilter && bulkFilter.priceRange) {
      priceRange = [bulkFilter.priceRange.min, bulkFilter.priceRange.max];
      setPRange(priceRange);
    }

    setBulkFilter({
      selectedCategories: [],
      priceRange: {
        min: minValue,
        max: maxValue,
        val: [minValue, maxValue],
      },
      dateRange: {
        start: null,
        end: null,
      },
    });

    if (props.setBulkForFilter) {
      props.setBulkForFilter({
        ...bulkFilter,
        selectedCategories: [],
        priceRange: {
          ...bulkFilter.priceRange,
          val: priceRange,
        },
        dateRange: {
          start: null,
          end: null,
        },
      });
    }
  };

  const handleTextFieldPriceRangeChange = (newRange) => {
    if (newRange[0] < minValue || newRange[0] > maxValue) {
      return;
    }
    if (newRange[1] < minValue || newRange[1] > maxValue) {
        return;
    }
    
    setPRange(newRange);
  };

  const handleResetIndividual = (id) => {
    let currentFilter = {};
    if (id === 1) {
      setCategoriesFromProps();
      currentFilter.selectedCategories = [];
    } else if (id === 2) {
      setStartDate(null);
      setEndDate(null);
      currentFilter.dateRange = {
        start: null,
        end: null,
      };
    } else if (id === 3) {
      let prange = [minValue, maxValue];
      if (bulkFilter && bulkFilter.priceRange) {
        prange = [bulkFilter.priceRange.min, bulkFilter.priceRange.max];
        setPRange(prange);
      }
      currentFilter.priceRange = {
        ...bulkFilter.priceRange,
        val: prange,
      };
    } else {
      /** handle else scenario */
    }

    if (props.setBulkForFilter) {
      const clearFilTers = {
        ...bulkFilter,
        ...currentFilter,
      };
      setBulkFilter(clearFilTers);
      props.setBulkForFilter(clearFilTers);
    }
  };

  useEffect(() => {
    if (bulkFilter && bulkFilter.dateRange) {
      const setstart = bulkFilter.dateRange.start
        ? new Date(reArrangeDate(bulkFilter.dateRange.start))
        : null;
      const setend = bulkFilter.dateRange.end
        ? new Date(reArrangeDate(bulkFilter.dateRange.end))
        : null;
      setStartDate(setstart);
      setEndDate(setend);
    }
  }, [bulkFilter]);

  useEffect(()=>{
    if(props.forFilter.priceRange){
        setPRange([props.forFilter.priceRange.min, props.forFilter.priceRange.max]);
        setMinValue(props.forFilter.priceRange.min);
        setMaxValue(props.forFilter.priceRange.max);
    }
  },[]);


  /** set list of categories */
  useEffect(() => {
    setCategoriesFromProps();
  }, []);

  // useEffect(() => {
  //   if (props.monitorSearch) props.monitorSearch(props.searchVal);
  // }, [props.searchVal]);

  /** monitor categories */
  useEffect(() => {
    let btnstat = false;
    let scat = [];
    // check if there are selected categories
    categories &&
      Object.keys(categories).map((ct) => {
        if (categories[ct]) {
          btnstat = true;
          scat.push(ct);
        }
      });
    // check if there is any change from price range then enable apply button
    if (pRangeVal) {
      if (
        pRangeVal[0] !== bulkFilter.priceRange.min ||
        pRangeVal[1] !== bulkFilter.priceRange.max
      ) {
        btnstat = true;
      }
    }
    // check if there is a set date then enable apply button
    if (startDate || endDate) {
      btnstat = true;
    }

    if (props.setBulkForFilter) {
      props.setBulkForFilter({
        ...bulkFilter,
        selectedCategories: scat,
        priceRange: {
          ...bulkFilter.priceRange,
          val: pRangeVal,
        },
        dateRange: {
          start: startDate ? moment(startDate).format("DD-MM-YYYY") : null,
          end: endDate ? moment(endDate).format("DD-MM-YYYY") : null,
        },
      });
    }
    setApplyBtn(expanded === "panel3" ? true : btnstat);
  }, [categories, pRangeVal, startDate, endDate]);

  return (
    <div className={"stix-mobile-filter"}>
      <div className={"stix-mobile-filter-header"}>
        <ArrowBackIosNewIcon onClick={closeFilter} />{" "}
        <span className={"h3"}>Filter Results</span>
      </div>
      <div className={"stix-mobile-filter-search"}>
        {props.searchVal && (
          <CustomInput
            className={"sxbox"}
            autoComplete="off"
            placeholder="Search..."
            type="search"
            value={search.value}
            onChange={handleOnSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => {
                    props.monitorSearch && props.monitorSearch(search.value);
                    closeFilter();
                  }}
                >
                  <RiSearchLine size={24} style={{ color: "#CCCCCC" }} />
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>
      <div className={"stix-mobile-filter-main"}>
        <Accordion
          className={"accd"}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>Genre/ Categories</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <div className={"reset"} onClick={() => handleResetIndividual(1)}>
              Reset
            </div>

            <FormGroup className={"check-list"}>
              {props.category &&
                props.category.length > 0 &&
                props.category.map((cat, index) => {
                  return (
                    <FormControlLabel
                      key={"FormControlLabel_" + index}
                      control={
                        <Checkbox
                          id={cat.id}
                          checked={
                            categories && categories[cat.id] ? true : false
                          }
                          onChange={handleCatChange}
                          inputProps={{ "aria-label": "controlled" }}
                          variant="outlined"
                        />
                      }
                      label={cat.name}
                    />
                  );
                })}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={"accd d"}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>Date Range</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <div className={"reset"} onClick={() => handleResetIndividual(2)}>
              Reset
            </div>
            <DatePicker
              className={"stix-dpicker"}
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              renderDayContents={renderDayContents}
              selectsRange
              inline
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={"accd d"}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>Price Range</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <div className={"reset"} onClick={() => handleResetIndividual(3)}>
              Reset
            </div>
            <div className={"pRange"}>
              <TextField
                className="left"
                variant="outlined"
                value={["S$", pRangeVal[0]].join('')}
                onChange={(e) => {
                    handleTextFieldPriceRangeChange([Number(e.target.value.substring(2)), pRangeVal[1]]);
                }}
              />
              <TextField
                className="right"
                variant="outlined"
                value={["S$", pRangeVal[1]].join('')}
                onChange={(e) => {
                    handleTextFieldPriceRangeChange([pRangeVal[0], Number(e.target.value.substring(2))]);
                }}
              />
              <Slider
                onChange={handleSliderPriceRangeChange}
                aria-label="Price Range"
                value={pRangeVal}
                defaultValue={pRangeVal}
                min={bulkFilter.priceRange.min}
                max={bulkFilter.priceRange.max}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion
          className={"accd d"}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>Accessibility</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <div className={"reset"} onClick={() => handleResetIndividual(4)}>
              Reset
            </div>
            <FormGroup className={"check-list single"}>
              {props.accessibility &&
                props.accessibility.length > 0 &&
                props.accessibility.map((acc) => {
                  return (
                    <FormControlLabel
                      control={<Checkbox variant="outlined" />}
                      label={acc.name}
                    />
                  );
                })}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={"accd d"}
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>More Filters</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography>{/** */}{/*</Typography>
          </AccordionDetails>
        </Accordion> */}
      </div>

      <div className={"stix-mobile-filter-btn-sec"}>
        <Button
          className={["apply", !applyBtn ? "disabled" : ""].join(" ")}
          variant="contained"
          onClick={handleApplyFilter}
        >
          Apply
        </Button>
        <Button
          className={"reset-all"}
          variant="text"
          onClick={handleClearFilter}
        >
          Reset All
        </Button>
      </div>
    </div>
  );
};

const convertNameToId = (str) => {
  let newStr = str ? str.replace(/[^a-zA-Z0-9 ]/g, "") : "noid";
  newStr = newStr.replace(" ", "");
  return newStr;
};


const reArrangeDate = (dd) => {
  let newDate = [];
  if(dd){
    const splitDate = dd.split('-');
    if(splitDate.length === 3){
      newDate= [splitDate[2], splitDate[1], splitDate[0]];
    }
    return newDate.join('-');
  }else{
    return false;
  }
}
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PromotionsMain from './PromotionsMain';

const Promotions = props => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
        <PromotionsMain
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    );
};

export default Promotions;

import { styled } from '@mui/material/styles';
// import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

export const CustomInput = styled(TextField)(({ theme }) => ({
  label: {
    color: theme.palette.secondary.dark,
    fontSize: '0.875rem',
    top: -7,
  },
  'label + &': {
    marginTop: theme.spacing(0.5),
  },
  '& .MuiInputBase-input': {
    borderRadius: '10px',
    height: '42px',
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#2b2b2b',
    // border: '0px',
    borderColor: '#DCDFE3',
    fontSize: '0.875rem',
    width: '100%',
    padding: '0px 16px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      borderWidth: '0px',
    },
  },
}));

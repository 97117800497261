import React, { useEffect, useRef, useState } from 'react';
import {
    Grid,
    Button
} from '@mui/material';
import "./style.scss"
import Slider from 'react-slick';
import ReactPlayer from 'react-player/lazy';
import { useNavigate } from "react-router-dom";
import { Utilities } from '../../../../utils';
import Image from '../../../../components/Image';

const NewRoyalSection = ({ heading, customData, url, className, containerClass, isMobile, slidesToShow, center }) => {

    const sliderRef = useRef();
    const navigate = useNavigate();
    const [itemsData, setItemsData] = useState(customData ? customData : [])
    const [vidplayer, setVidPlayer] = React.useState({});

    useEffect(() => {
        console.log("print this")
    }, [])

    const handleOnClick = (e) => {
        const url = e.currentTarget.id;
        if (url) {
            if (url.includes("http")) {
                window.location.href = url;
            } else {
                navigate(url);
            }
        }
    }

    const handleClickPrev = () => {
        let prevBtn = document.querySelector('.slider-video-dots-arrow-left')
        let firstParentNode = prevBtn.parentElement
        let slickPrevBtn = firstParentNode.parentElement.parentElement.firstChild
        slickPrevBtn.click();
    }

    const handleClickNext = () => {
        let nextBtn = document.querySelector('.slider-video-dots-arrow-right')
        let firstParentNode = nextBtn.parentElement
        let slickNextBtn = firstParentNode.parentElement.previousSibling
        slickNextBtn.click();
    }

    const handleBeforeChange = () => {
        //stop the all the videos when slide to next
        if (vidplayer && Object.keys(vidplayer).length > 0) {
            Object.keys(vidplayer).map((row) => {
                setVidPlayer({
                    ...vidplayer,
                    [row]: false
                });
            });

        }
    }

    const handleOnPlay = (id) => {
        //add player to state when started playing
        setVidPlayer({
            ...vidplayer,
            [id]: true
        });
    }
    const handleOnLitePlay = (data, id) => {
        let newList = {
            ...vidplayer
        }
        newList[id] = true;
        //add player to state when started playing
        setVidPlayer(newList);
    }

    return (<>
        <section className="royal-wrapper">
            <div className='container-fluid custom-container'>
            <div
                className={['stix-featured-container normal', containerClass && containerClass].join(" ")}
                style={{
                    backgroundSize: 'cover',
                    // minHeight: '354px',
                    padding: '0px 0px 20px'
                }}
            >

                <Slider
                    // {...props}
                    infinite={true}
                    speed={500}
                    initialSlide={0}
                    focusOnSelect={true}
                    pauseOnHover={true}
                    draggable={true}
                    lazyLoad={false}
                    dots={true}
                    beforeChange={handleBeforeChange}
                    arrows={true}
                    // ref={sliderRef}
                    appendDots={(dots) => (
                        <Grid container id={"slider-video-dots-container"} key={dots}>
                            <Grid item xs={1} md={5} style={{ zIndex: 4 }}>
                                <span onClick={() => handleClickPrev()} className={"slider-video-dots-arrow-left"}>&nbsp;</span>
                            </Grid>
                            <Grid item xs={10} md={2} style={{ zIndex: 2 }}>
                                <ul className={"slider-video-dots"}> {dots} </ul>
                            </Grid>
                            <Grid item xs={1} md={5} style={{ zIndex: 4 }}>
                                <span onClick={() => handleClickNext()} className={"slider-video-dots-arrow-right"}>&nbsp;</span>
                            </Grid>
                        </Grid>
                    )}
                >
                    {itemsData.map((item, i) => {
                            if (i < 8 && item) {
                                return (<div key={i}>
                                    <Grid container spacing={2} >
                                        <Grid item sm={8} md={8} lg={8} xl={8}>
                                            <Image
                                                src={item.full_image}
                                                type="MediumHorizontal"
                                            />
                                        </Grid>
                                        <Grid item sm={4} md={4} lg={4} xl={4} className={'sistic-vid-right right-content'}>
                                            <a href={url} className='heading-text'>{heading}</a>
                                            <h4 className={'h4'}>{item.title}</h4>
                                            <label className='date-text'>{item.event_date}</label>
                                            <div className={'homepage-video-buy-tickets'}>
                                                <Button
                                                    variant="contained"
                                                    className={"buyTicks"}
                                                    href={item.navigation_link}
                                                >
                                                    BUY TICKETS
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>);
                            }
                        })
                    }
                </Slider>

            </div>
            </div>
        </section>

    </>);
}

export default NewRoyalSection;

// "full_image": "https://static.sistic.com.sg/sistic/docroot/sites/default/files/2023-07/fiba2023_1194x556.jpg",
//             "title": "FIBA Intercontinental Cup Singapore 2023",
//             "event_date": "21 Sep 2023",
//             "navigation_link": "https://www.sistic.com.sg/events/fiba0923?cid=exsports-fiba0923",
//             "alt_text": ""
